import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import Dropzone from 'react-dropzone';
import Answer from "../../components/activity-answer-eggstudio/Answer";
import EggStudio from "../../components/eggstudionew/EggStudio";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import GoodJob from "../../components/congratulations/GoodJob";
import CancelIcon from "../../images/activity-sitcom/cancel-icon2.png";
import HelpIcon from "../../images/activity-sitcom/help-icon2.png";
import Background from "../../images/egg-studio/background.png";
import filmPattern from "../../images/egg-studio/film-pattern.png";
import complete_btn2 from "../../images/activity-button/complete_btn2.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";

//캐릭터
import grandmother from "../../images/word-play/hht2-image/01_grandmother.png";
import aunt from "../../images/word-play/hht2-image/02_aunt.png";
import sister from "../../images/word-play/hht2-image/03_sister.png";
import mother from "../../images/word-play/hht2-image/04_mother.png";
import grandfather from "../../images/word-play/hht2-image/05_grandfather.png";
import father from "../../images/word-play/hht2-image/06_father.png";
import uncle from "../../images/word-play/hht2-image/07_uncle.png";
import cousin from "../../images/word-play/hht2-image/08_cousin.png";
import camerapicture from "../../images/word-play/hht2-image/camerapic.png";
import grouppic from "../../images/word-play/hht2-image/grouppic.png";
import balcony from "../../images/word-play/hht2-image/11_balcony.png";
import bathroom from "../../images/word-play/hht2-image/12_bathroom.png";
import garden from "../../images/word-play/hht2-image/13_garden.png";
import livingroom from "../../images/word-play/hht2-image/14_living room.png";
import kitchen from "../../images/word-play/hht2-image/15_kitchen.png";
import bedroom from "../../images/word-play/hht2-image/16_bedroom.png";


import Sound from "../../images/word-play/word-sound.png";
import Blank from "../../images/word-play/polariod_blank.png";
import GuideAni from "../../images/direction/quide-fingure.png";


/*css*/
import "../../css/wordplay.css";

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Index = () => {
    let location = useLocation();
    let history = useHistory();
    const userSelector = useSelector(store => store.userStore);
    const dispatch = useDispatch();

    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
    const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
    const mediaPath = actInfo.media_path.replace(`${apiHost}`, '');

    const [orgActList, setOrgActList] = useState([]);
    const [actList, setActList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categorySelected, setCategorySelected] = useState({});

    const [setNum, setSetNum] = useState(1);
    const [maxSetNum, setMaxSetNum] = useState(1);
    const [minCategoryNum, setMinCategoryNum] = useState(0);
    const [maxCategoryNum, setMaxCategoryNum] = useState(0);
    let minRecordCategoryNum = 0;
    console.log('page_orgActList', orgActList);
    console.log('page_actList', actList);
    console.log('page_categoryList', categoryList);
    console.log('page_categorySelected', categorySelected);
    console.log('page_setNum', setNum);
    console.log('page_maxSetNum', maxSetNum);
    console.log('page_minCategoryNum', minCategoryNum);
    console.log('page_maxCategoryNum', maxCategoryNum);

    const [fake, setFake] = useState(false);
    const [yesmodal, setYesmodal] = useState(false);
    const [record, setRecord] = useState(false);

    const [pass, setPass] = useState(false); // 16문제 다 맞추면 pass
    const [done, setDone] = useState(false);

    const [guideFinished, setGuideFinished] = useState(false);
    const [camera, setCamera] = useState();
    const [group01, setGroup01] = useState();
    const [group02, setGroup02] = useState();
    const [group03, setGroup03] = useState();
    const [group04, setGroup04] = useState();
    const [group05, setGroup05] = useState();
    const [group06, setGroup06] = useState();
    const [group07, setGroup07] = useState();
    const [group08, setGroup08] = useState();
    const [group09, setGroup09] = useState();
    const [group10, setGroup10] = useState();
    const [group11, setGroup11] = useState();
    const [group12, setGroup12] = useState();
    const [group13, setGroup13] = useState();
    const [group14, setGroup14] = useState();
    const [group15, setGroup15] = useState();
    const [group16, setGroup16] = useState();

    const [pass01, setPass01] = useState(false);
    const [pass02, setPass02] = useState(false);
    const [pass03, setPass03] = useState(false);
    const [pass04, setPass04] = useState(false);
    const [pass05, setPass05] = useState(false);
    const [pass06, setPass06] = useState(false);
    const [pass07, setPass07] = useState(false);
    const [pass08, setPass08] = useState(false);
    const [pass09, setPass09] = useState(false);
    const [pass10, setPass10] = useState(false);
    const [pass11, setPass11] = useState(false);
    const [pass12, setPass12] = useState(false);
    const [pass13, setPass13] = useState(false);
    const [pass14, setPass14] = useState(false);
    const [pass15, setPass15] = useState(false);
    const [pass16, setPass16] = useState(false);

    const [page01, setPage01] = useState(true);
    const [page02, setPage02] = useState(false);
    const [page03, setPage03] = useState(false);
    const [page04, setPage04] = useState(false);

    const [drop01, setDrop01] = useState(false);
    const [drop02, setDrop02] = useState(false);
    const [drop03, setDrop03] = useState(false);
    const [drop04, setDrop04] = useState(false);
    const [drop05, setDrop05] = useState(false);
    const [drop06, setDrop06] = useState(false);
    const [drop07, setDrop07] = useState(false);
    const [drop08, setDrop08] = useState(false);
    const [drop09, setDrop09] = useState(false);
    const [drop10, setDrop10] = useState(false);
    const [drop11, setDrop11] = useState(false);
    const [drop12, setDrop12] = useState(false);
    const [drop13, setDrop13] = useState(false);
    const [drop14, setDrop14] = useState(false);
    const [drop15, setDrop15] = useState(false);
    const [drop16, setDrop16] = useState(false);

    // left
    const [leftData, setLeftData] = useState([{
        text: ''
    }]);
    // var leftData = [];
    // right
    const [rightData, setRightData] = useState([{
        text: ''
    }]);
    // var rightData = [];
    // var tempLeftData = [];
    // var tempRightData = [];
    // leftData = tempLeftData;



    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive);
    };


    const errorModalRef = useRef();
    const routeLearningHome = () => {
        let routerName = '';
        switch (userSelector.info.productName.toUpperCase()) {
            case 'HHT1':
                routerName = '/hh1/learning-home';
                break;
            case 'HHT2':
                routerName = '/hh2/learning-home';
                break;
            case 'CULTURE':
                routerName = '/culture/learning-home';
                break;
            default:
                break;
        }
        history.push(routerName);
    };
    useEffect(() => {
        console.log('useEffect1')
        const fetchData = async (actContentData) => {
            let filePathReplaced = actInfo.file_path.replace(`${apiHost}`, '');
            await fetch(filePathReplaced)
                .then(res => res.json())
                .then(res => {
                    console.log("data_eggstudioindex.jsx_res >>>", res);
                    setOrgActList(JSON.parse(JSON.stringify(res)));
                    console.log("data_eggstudioindex.jsx_JSON.parse(JSON.stringify(res)>", JSON.parse(JSON.stringify(res)));

                    try {
                        const stdAnswer = JSON.parse(actContentData.stdAnswer);

                        // res.map((res_item, res_index) => {
                        //   res_item.category.map((category_item, category_index) => {
                        //     if (category_item.name === 'record') {
                        //       category_item.sentence.map((sentence_item, sentence_index) => {
                        //         if (sentence_item) {
                        //           sentence_item.list.map((item, index) => {
                        //             if (stdAnswer.length > res_index &&
                        //               stdAnswer[res_index].category.length > category_index &&
                        //               stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                        //               stdAnswer[res_index].category[category_index].sentence[sentence_index].list.length > index) {
                        //               var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].list[index].choiceSelected;
                        //               if (choiceSelected) {
                        //                 item.choiceSelected = choiceSelected;
                        //               }
                        //             }
                        //           });
                        //         }
                        //         if (stdAnswer.length > res_index &&
                        //           stdAnswer[res_index].category.length > category_index &&
                        //           stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                        //           var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                        //           if (fileUrlPath) {
                        //             sentence_item.fileUrlPath = fileUrlPath;

                        //             var player = new Audio(fileUrlPath);
                        //             if (player) {
                        //               sentence_item.player = player;
                        //             }
                        //           }
                        //         }
                        //       });
                        //     }
                        //   });
                        // });
                    } catch (error) {
                        console.log('Incorrect student answer data, ', error);
                    }

                    setActList(res);

                    const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
                    setMaxSetNum(maxSetNum);

                    const categoryList = res.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
                    setCategoryList(categoryList);

                    categoryList.forEach((value, index) => {
                        if (index === 0) {
                            setMinCategoryNum(value.order);
                            setCategorySelected(categoryList[value.order]);
                        } else if (index === categoryList.length - 1) {
                            setMaxCategoryNum(value.order);
                        }
                    });
                })
                .catch(error => {
                    console.error(error);
                    errorModalRef.current.openModal();
                });
        };

        async function callApiAct() {
            const data = await API('get', 'act', {
                product: userSelector.info.productId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                episodeCode: episodeInfo.episodeCode,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId
            }, {
                studentId: userSelector.info.studentId,
                oCode: userSelector.info.oCode
            }).catch((error) => {
                console.error(error);
            });

            fetchData(data ? data.actContentData : data);
        }
        callApiAct();
    }, [setNum]);

    useEffect(() => {
        console.log('useEffect2')
        if (actList.length > 0) {
            const categoryList = actList.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
            setCategoryList(categoryList);

            categoryList.forEach((item, index) => {
                if (index === 0) {
                    setMinCategoryNum(item.order);
                    setCategorySelected(categoryList[item.order]);
                } else if (index === categoryList.length - 1) {
                    setMaxCategoryNum(item.order);
                }
            });
        }
        // audio01Ref.current.play();
        // pass01Ref.current.style.display = 'block';
    }, [setNum]);

    const alertModalRef = useRef();
    const closeActivityModalRef = useRef();

    const handleClose = () => {
        closeActivityModalRef.current.openModal();
    };
    const handleGuide = () => {
        setGuideFinished(false);
    };

    console.log('handleDragEnter', 'camera', camera);


    const onMouseOver01_1 = () => {
        setCamera(categorySelected.question01.text1);
    };

    const onMouseOver01_2 = () => {
        setCamera(categorySelected.question01.text2);
    };

    const onMouseOver01_3 = () => {
        setCamera(categorySelected.question01.text3);
    };

    const onMouseOver01_4 = () => {
        setCamera(categorySelected.question01.text4);
    };

    const onMouseOver02_1 = () => {
        setCamera(categorySelected.question02.text1);
    };

    const onMouseOver02_2 = () => {
        setCamera(categorySelected.question02.text2);
    };

    const onMouseOver02_3 = () => {
        setCamera(categorySelected.question02.text3);
    };

    const onMouseOver02_4 = () => {
        setCamera(categorySelected.question02.text4);
    };

    const onMouseOver03_1 = () => {
        setCamera(categorySelected.question03.text1);
    };

    const onMouseOver03_2 = () => {
        setCamera(categorySelected.question03.text2);
    };

    const onMouseOver03_3 = () => {
        setCamera(categorySelected.question03.text3);
    };

    const onMouseOver03_4 = () => {
        setCamera(categorySelected.question03.text4);
    };

    const onMouseOver04_1 = () => {
        setCamera(categorySelected.question04.text1);
    };

    const onMouseOver04_2 = () => {
        setCamera(categorySelected.question04.text2);
    };

    const onMouseOver04_3 = () => {
        setCamera(categorySelected.question04.text3);
    };

    const onMouseOver04_4 = () => {
        setCamera(categorySelected.question04.text4);
    };

    const onMouseOver05_1 = () => {
        setCamera(categorySelected.question05.text1);
    };

    const onMouseOver05_2 = () => {
        setCamera(categorySelected.question05.text2);
    };

    const onMouseOver05_3 = () => {
        setCamera(categorySelected.question05.text3);
    };

    const onMouseOver05_4 = () => {
        setCamera(categorySelected.question05.text4);
    };

    const onMouseOver06_1 = () => {
        setCamera(categorySelected.question06.text1);
    };

    const onMouseOver06_2 = () => {
        setCamera(categorySelected.question06.text2);
    };

    const onMouseOver06_3 = () => {
        setCamera(categorySelected.question06.text3);
    };

    const onMouseOver06_4 = () => {
        setCamera(categorySelected.question06.text4);
    };

    const onMouseOver07_1 = () => {
        setCamera(categorySelected.question07.text1);
    };

    const onMouseOver07_2 = () => {
        setCamera(categorySelected.question07.text2);
    };

    const onMouseOver07_3 = () => {
        setCamera(categorySelected.question07.text3);
    };

    const onMouseOver07_4 = () => {
        setCamera(categorySelected.question07.text4);
    };

    const onMouseOver08_1 = () => {
        setCamera(categorySelected.question08.text1);
    };

    const onMouseOver08_2 = () => {
        setCamera(categorySelected.question08.text2);
    };

    const onMouseOver08_3 = () => {
        setCamera(categorySelected.question08.text3);
    };

    const onMouseOver08_4 = () => {
        setCamera(categorySelected.question08.text4);
    };

    const onMouseOver09_1 = () => {
        setCamera(categorySelected.question09.text1);
    };

    const onMouseOver09_2 = () => {
        setCamera(categorySelected.question09.text2);
    };

    const onMouseOver09_3 = () => {
        setCamera(categorySelected.question09.text3);
    };

    const onMouseOver09_4 = () => {
        setCamera(categorySelected.question09.text4);
    };

    const onMouseOver10_1 = () => {
        setCamera(categorySelected.question10.text1);
    };

    const onMouseOver10_2 = () => {
        setCamera(categorySelected.question10.text2);
    };

    const onMouseOver10_3 = () => {
        setCamera(categorySelected.question10.text3);
    };

    const onMouseOver10_4 = () => {
        setCamera(categorySelected.question10.text4);
    };

    const onMouseOver11_1 = () => {
        setCamera(categorySelected.question11.text1);
    };

    const onMouseOver11_2 = () => {
        setCamera(categorySelected.question11.text2);
    };

    const onMouseOver11_3 = () => {
        setCamera(categorySelected.question11.text3);
    };

    const onMouseOver11_4 = () => {
        setCamera(categorySelected.question11.text4);
    };

    const onMouseOver12_1 = () => {
        setCamera(categorySelected.question12.text1);
    };

    const onMouseOver12_2 = () => {
        setCamera(categorySelected.question12.text2);
    };

    const onMouseOver12_3 = () => {
        setCamera(categorySelected.question12.text3);
    };

    const onMouseOver12_4 = () => {
        setCamera(categorySelected.question12.text4);
    };

    const onMouseOver13_1 = () => {
        setCamera(categorySelected.question13.text1);
    };

    const onMouseOver13_2 = () => {
        setCamera(categorySelected.question08.text2);
    };

    const onMouseOver13_3 = () => {
        setCamera(categorySelected.question13.text3);
    };

    const onMouseOver13_4 = () => {
        setCamera(categorySelected.question13.text4);
    };

    const onMouseOver14_1 = () => {
        setCamera(categorySelected.question14.text1);
    };

    const onMouseOver14_2 = () => {
        setCamera(categorySelected.question14.text2);
    };

    const onMouseOver14_3 = () => {
        setCamera(categorySelected.question14.text3);
    };

    const onMouseOver14_4 = () => {
        setCamera(categorySelected.question14.text4);
    };

    const onMouseOver15_1 = () => {
        setCamera(categorySelected.question15.text1);
    };

    const onMouseOver15_2 = () => {
        setCamera(categorySelected.question15.text2);
    };

    const onMouseOver15_3 = () => {
        setCamera(categorySelected.question15.text3);
    };

    const onMouseOver15_4 = () => {
        setCamera(categorySelected.question15.text4);
    };

    const onMouseOver16_1 = () => {
        setCamera(categorySelected.question16.text1);
    };

    const onMouseOver16_2 = () => {
        setCamera(categorySelected.question16.text2);
    };

    const onMouseOver16_3 = () => {
        setCamera(categorySelected.question16.text3);
    };

    const onMouseOver16_4 = () => {
        setCamera(categorySelected.question16.text4);
    };


    const handleDragEnter01 = (e) => {
        e.preventDefault();


        if (categorySelected.question01.answer === camera) {
            setTimeout(() => {
                setPass01(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter02 = (e) => {
        e.preventDefault();


        if (categorySelected.question02.answer === camera) {
            setPass01(false);
            setTimeout(() => {
                setPass02(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter03 = (e) => {
        e.preventDefault();


        if (categorySelected.question03.answer === camera) {
            setPass02(false);
            setTimeout(() => {
                setPass03(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter04 = (e) => {
        e.preventDefault();


        if (categorySelected.question04.answer === camera) {
            setPass03(false);
            setTimeout(() => {
                setPass04(true);
            }, 1300);
            setTimeout(() => {
                onPage02(); //2페이지
            }, 1700);
        }
        else {
            return;
        }
    };

    const handleDragEnter05 = (e) => {
        e.preventDefault();


        if (categorySelected.question05.answer === camera) {
            setPass04(false);
            setTimeout(() => {
                setPass05(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter06 = (e) => {
        e.preventDefault();


        if (categorySelected.question06.answer === camera) {
            setPass05(false);
            setTimeout(() => {
                setPass06(true);
            }, 1300);
        }
        else {
            return;
        }
    };


    const handleDragEnter07 = (e) => {
        e.preventDefault();


        if (categorySelected.question07.answer === camera) {
            setPass06(false);
            setTimeout(() => {
                setPass07(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter08 = (e) => {
        e.preventDefault();


        if (categorySelected.question08.answer === camera) {
            setPass07(false);
            setTimeout(() => {
                setPass08(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter09 = (e) => {
        e.preventDefault();


        if (categorySelected.question09.answer === camera) {
            setPass08(false);
            setTimeout(() => {
                setPass09(true);
            }, 1300);
            setTimeout(() => {
                onPage03(); //3페이지
            }, 1700);
        }
        else {
            return;
        }
    };

    const handleDragEnter10 = (e) => {
        e.preventDefault();


        if (categorySelected.question10.answer === camera) {
            setPass09(false);
            setTimeout(() => {
                setPass10(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter11 = (e) => {
        e.preventDefault();


        if (categorySelected.question11.answer === camera) {
            setPass10(false);
            setTimeout(() => {
                setPass11(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter12 = (e) => {
        e.preventDefault();


        if (categorySelected.question12.answer === camera) {
            setPass11(false);
            setTimeout(() => {
                setPass12(true);
            }, 1300);
            setTimeout(() => {
                onPage04(); //4페이지
            }, 1700);
        }
        else {
            return;
        }
    };

    const handleDragEnter13 = (e) => {
        e.preventDefault();


        if (categorySelected.question13.answer === camera) {
            setPass12(false);
            setTimeout(() => {
                setPass13(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter14 = (e) => {
        e.preventDefault();


        if (categorySelected.question14.answer === camera) {
            setPass13(false);
            setTimeout(() => {
                setPass14(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter15 = (e) => {
        e.preventDefault();


        if (categorySelected.question15.answer === camera) {
            setPass14(false);
            setTimeout(() => {
                setPass15(true);
            }, 1300);
        }
        else {
            return;
        }
    };

    const handleDragEnter16 = (e) => {
        e.preventDefault();


        if (categorySelected.question15.answer === camera) {
            setPass15(false);
            setTimeout(() => {
                setPass16(true);
            }, 1300);
        }
        else {
            return;
        }
    };




    const onDragLeave1 = () => {
        setGroup01(categorySelected.question01.answer);
    }

    const onDragLeave2 = () => {
        setGroup02(categorySelected.question02.answer);
        console.log('group02', group02);
    }

    const onDragLeave3 = () => {
        setGroup03(categorySelected.question03.answer);
    }

    const onDragLeave4 = () => {
        setGroup04(categorySelected.question04.answer);
    }

    const onDragLeave5 = () => {
        setGroup05(categorySelected.question05.answer);
    }

    const onDragLeave6 = () => {
        setGroup06(categorySelected.question06.answer);
    }

    const onDragLeave7 = () => {
        setGroup07(categorySelected.question07.answer);
    }

    const onDragLeave8 = () => {
        setGroup08(categorySelected.question08.answer);
    }

    const onDragLeave9 = () => {
        setGroup09(categorySelected.question09.answer);
    }

    const onDragLeave10 = () => {
        setGroup10(categorySelected.question10.answer);
    }

    const onDragLeave11 = () => {
        setGroup11(categorySelected.question11.answer);
    }

    const onDragLeave12 = () => {
        setGroup12(categorySelected.question12.answer);
    }

    const onDragLeave13 = () => {
        setGroup13(categorySelected.question13.answer);
    }

    const onDragLeave14 = () => {
        setGroup14(categorySelected.question14.answer);
    }

    const onDragLeave15 = () => {
        setGroup15(categorySelected.question15.answer);
    }

    const onDragLeave16 = () => {
        setGroup16(categorySelected.question16.answer);
    }

    const handleLeftDragEnter = (e) => {
        console.log('L');
        setPass(false);
        e.preventDefault();


        if (categorySelected.answerGroup1 === group01) {
            setDrop01(true);
            setGroup01('1');
            leftData.pop();
            rightData.pop();
            const leftData1 = {
                "src": mediaPath + '/' + categorySelected.question01.image,
                "text": categorySelected.question01.text,
                "transform": categorySelected.question01.transform

            };
            setLeftData([...leftData, leftData1]);
        } else if (categorySelected.answerGroup1 === group02) {

            setDrop01(false);
            setDrop02(true);
            setGroup02('2');
            const leftData2 = {
                "src": mediaPath + '/' + categorySelected.question02.image,
                "text": categorySelected.question02.text,
                "transform": categorySelected.question02.transform
            };

            setLeftData([...leftData, leftData2]);

        } else if (categorySelected.answerGroup1 === group03) {

            setDrop02(false);
            setDrop03(true);
            setGroup03('3');
            const leftData3 = {
                "src": mediaPath + '/' + categorySelected.question03.image,
                "text": categorySelected.question03.text
            };
            setLeftData([...leftData, leftData3]);

        } else if (categorySelected.answerGroup1 === group04) {
            setDrop03(false);
            setDrop04(true);
            setGroup04('4');
            const leftData4 = {
                "src": mediaPath + '/' + categorySelected.question04.image,
                "text": categorySelected.question04.text
            };
            setLeftData([...leftData, leftData4]);
        } else if (categorySelected.answerGroup1 === group05) {
            setDrop04(false);
            setDrop05(true);
            setGroup05('5');
            const leftData5 = {
                "src": mediaPath + '/' + categorySelected.question05.image,
                "text": categorySelected.question05.text
            };
            setLeftData([...leftData, leftData5]);
        } else if (categorySelected.answerGroup1 === group06) {
            setDrop05(false);
            setDrop06(true);
            setGroup06('6');
            const leftData6 = {
                "src": mediaPath + '/' + categorySelected.question06.image,
                "text": categorySelected.question06.text
            };
            setLeftData([...leftData, leftData6]);
        } else if (categorySelected.answerGroup1 === group07) {
            setDrop06(false);
            setDrop07(true);
            setGroup07('7');
            const leftData7 = {
                "src": mediaPath + '/' + categorySelected.question07.image,
                "text": categorySelected.question07.text
            };
            setLeftData([...leftData, leftData7]);
        } else if (categorySelected.answerGroup1 === group08) {
            setDrop07(false);
            setDrop08(true);
            setGroup08('8');
            const leftData8 = {
                "src": mediaPath + '/' + categorySelected.question08.image,
                "text": categorySelected.question08.text
            };
            setLeftData([...leftData, leftData8]);
        } else if (categorySelected.answerGroup1 === group09) {
            setDrop08(false);
            setDrop09(true);
            setGroup09('9');
            const leftData9 = {
                "src": mediaPath + '/' + categorySelected.question09.image,
                "text": categorySelected.question09.text
            };
            setLeftData([...leftData, leftData9]);
        } else if (categorySelected.answerGroup1 === group10) {
            setDrop09(false);
            setDrop10(true);
            setGroup10('10');
            const leftData10 = {
                "src": mediaPath + '/' + categorySelected.question10.image,
                "text": categorySelected.question10.text
            };
            setLeftData([...leftData, leftData10]);
        } else if (categorySelected.answerGroup1 === group11) {
            setDrop10(false);
            setDrop11(true);
            setGroup11('11');
            const leftData11 = {
                "src": mediaPath + '/' + categorySelected.question11.image,
                "text": categorySelected.question11.text
            };
            setLeftData([...leftData, leftData11]);
        } else if (categorySelected.answerGroup1 === group12) {
            setDrop11(false);
            setDrop12(true);
            setGroup12('12');
            const leftData12 = {
                "src": mediaPath + '/' + categorySelected.question12.image,
                "text": categorySelected.question12.text
            };
            setLeftData([...leftData, leftData12]);
        } else if (categorySelected.answerGroup1 === group13) {
            setDrop12(false);
            setDrop13(true);
            setGroup13('13');
            const leftData13 = {
                "src": mediaPath + '/' + categorySelected.question13.image,
                "text": categorySelected.question13.text
            };
            setLeftData([...leftData, leftData13]);
        } else if (categorySelected.answerGroup1 === group14) {
            setDrop13(false);
            setDrop14(true);
            setGroup14('14');
            const leftData14 = {
                "src": mediaPath + '/' + categorySelected.question14.image,
                "text": categorySelected.question14.text
            };
            setLeftData([...leftData, leftData14]);
        } else if (categorySelected.answerGroup1 === group15) {
            setDrop14(false);
            setDrop15(true);
            setGroup15('15');
            const leftData15 = {
                "src": mediaPath + '/' + categorySelected.question15.image,
                "text": categorySelected.question15.text
            };
            setLeftData([...leftData, leftData15]);
        } else if (categorySelected.answerGroup1 === group16) {
            setDrop15(false);
            setDrop16(true);
            setGroup16('16');
            const leftData16 = {
                "src": mediaPath + '/' + categorySelected.question16.image,
                "text": categorySelected.question16.text
            };
            setLeftData([...leftData, leftData16]);

            /**
                   * 학생 Act 학습 정보 저장 API
                   * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
                   */
            async function callApiAct() {
                if (actInfo.actStatus !== 'Completed') {
                    const data = await API('post', 'act', {}, {
                        oCode: userSelector.info.oCode,
                        productId: userSelector.info.productId,
                        studentId: userSelector.info.studentId,
                        courseId: userSelector.info.courseId,
                        bookId: userSelector.info.bookId,
                        level: actInfo.level,
                        episodeId: episodeInfo.episodeId,
                        episodeCode: episodeInfo.episodeCode,
                        lessonId: lessonInfo.lessonId,
                        actId: actInfo.actId,
                        actType: actInfo.actType,
                        actStatus: "Completed",
                        actPoint: actInfo.actCompletPoint,
                        actCurrentPage: setNum,
                        question: JSON.stringify(orgActList),
                        answer: JSON.stringify(actList)
                    }).catch((error) => {
                        console.error(error);
                    });

                    console.log(data);

                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .actStatus = "Completed";
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .file = categorySelected.file;
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .fileUrl = categorySelected.fileUrl;
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .player = categorySelected.player;

                    dispatch(userStore.save(userSelector.info));
                }

                let nextCategory = {
                    name: 'finished',
                    order: categorySelected.order,
                    retry: () => {
                        setSetNum(1);
                        if (actList.length > 0) {
                            const categoryList = actList.find(item => item.set === 1).category;
                            setCategoryList(categoryList);

                            categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                                if (index === 0) {
                                    setMinCategoryNum(item.order);
                                    setCategorySelected(categoryList[item.order]);
                                } else if (index === categoryList.length - 1) {
                                    setMaxCategoryNum(item.order);
                                }
                            });
                        }
                    },
                };
                setCategorySelected(nextCategory);
            }
            callApiAct();
            setDone(true);
        } else {
            return;
        }

    };

    const handleRightDragEnter = (e) => {
        console.log('R');
        setPass(false);
        e.preventDefault();


        if (categorySelected.answerGroup2 === group01) {
            e.preventDefault();

            // setPass(false);
            setDrop01(true);
            setGroup01('1');
            leftData.pop();
            rightData.pop();
            const rightData1 = {
                "src": mediaPath + '/' + categorySelected.question01.image,
                "text": categorySelected.question01.text
            };
            setRightData([...rightData, rightData1]);

        } else if (categorySelected.answerGroup2 === group02) {

            setDrop01(false);
            setDrop02(true);
            setGroup02('2');
            const rightData2 = {
                "src": mediaPath + '/' + categorySelected.question02.image,
                "text": categorySelected.question02.text
            };
            setRightData([...rightData, rightData2]);

        } else if (categorySelected.answerGroup2 === group03) {

            setDrop02(false);
            setDrop03(true);
            setGroup03('3');
            const rightData3 = {
                "src": mediaPath + '/' + categorySelected.question03.image,
                "text": categorySelected.question03.text
            };
            setRightData([...rightData, rightData3]);

        } else if (categorySelected.answerGroup2 === group04) {

            setDrop03(false);
            setDrop04(true);
            setGroup04('4');
            const rightData4 = {
                "src": mediaPath + '/' + categorySelected.question04.image,
                "text": categorySelected.question04.text
            };
            setRightData([...rightData, rightData4]);
        } else if (categorySelected.answerGroup2 === group05) {
            setDrop04(false);
            setDrop05(true);
            setGroup05('5');
            const rightData5 = {
                "src": mediaPath + '/' + categorySelected.question05.image,
                "text": categorySelected.question05.text
            };
            setRightData([...rightData, rightData5]);
        } else if (categorySelected.answerGroup2 === group06) {
            setDrop05(false);
            setDrop06(true);
            setGroup06('6');
            const rightData6 = {
                "src": mediaPath + '/' + categorySelected.question06.image,
                "text": categorySelected.question06.text
            };
            setRightData([...rightData, rightData6]);
        } else if (categorySelected.answerGroup2 === group07) {
            setDrop06(false);
            setDrop07(true);
            setGroup07('7');
            const rightData7 = {
                "src": mediaPath + '/' + categorySelected.question07.image,
                "text": categorySelected.question07.text
            };
            setRightData([...rightData, rightData7]);
        } else if (categorySelected.answerGroup2 === group08) {
            setDrop07(false);
            setDrop08(true);
            setGroup08('8');
            const rightData8 = {
                "src": mediaPath + '/' + categorySelected.question08.image,
                "text": categorySelected.question08.text
            };
            setRightData([...rightData, rightData8]);
        } else if (categorySelected.answerGroup2 === group09) {
            setDrop08(false);
            setDrop09(true);
            setGroup09('9');
            const rightData9 = {
                "src": mediaPath + '/' + categorySelected.question09.image,
                "text": categorySelected.question09.text
            };
            setRightData([...rightData, rightData9]);
        } else if (categorySelected.answerGroup2 === group10) {
            setDrop09(false);
            setDrop10(true);
            setGroup10('10');
            const rightData10 = {
                "src": mediaPath + '/' + categorySelected.question10.image,
                "text": categorySelected.question10.text
            };
            setRightData([...rightData, rightData10]);
        } else if (categorySelected.answerGroup2 === group11) {
            setDrop10(false);
            setDrop11(true);
            setGroup11('11');
            const rightData11 = {
                "src": mediaPath + '/' + categorySelected.question11.image,
                "text": categorySelected.question11.text
            };
            setRightData([...rightData, rightData11]);
        } else if (categorySelected.answerGroup2 === group12) {
            setDrop11(false);
            setDrop12(true);
            setGroup12('12');
            const rightData12 = {
                "src": mediaPath + '/' + categorySelected.question12.image,
                "text": categorySelected.question12.text
            };
            setRightData([...rightData, rightData12]);
        } else if (categorySelected.answerGroup2 === group13) {
            setDrop12(false);
            setDrop13(true);
            setGroup13('13');
            const rightData13 = {
                "src": mediaPath + '/' + categorySelected.question13.image,
                "text": categorySelected.question13.text
            };
            setRightData([...rightData, rightData13]);
        } else if (categorySelected.answerGroup2 === group14) {
            setDrop13(false);
            setDrop14(true);
            setGroup14('14');
            const rightData14 = {
                "src": mediaPath + '/' + categorySelected.question14.image,
                "text": categorySelected.question14.text
            };
            setRightData([...rightData, rightData14]);
        } else if (categorySelected.answerGroup2 === group15) {
            setDrop14(false);
            setDrop15(true);
            setGroup15('15');
            const rightData15 = {
                "src": mediaPath + '/' + categorySelected.question15.image,
                "text": categorySelected.question15.text
            };
            setRightData([...rightData, rightData15]);
        } else if (categorySelected.answerGroup2 === group16) {
            setDrop15(false);
            setDrop16(true);
            setGroup16('16');
            const rightData16 = {
                "src": mediaPath + '/' + categorySelected.question16.image,
                "text": categorySelected.question16.text
            };
            setRightData([...rightData, rightData16]);

            /**
                   * 학생 Act 학습 정보 저장 API
                   * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
                   */
            async function callApiAct() {
                if (actInfo.actStatus !== 'Completed') {
                    const data = await API('post', 'act', {}, {
                        oCode: userSelector.info.oCode,
                        productId: userSelector.info.productId,
                        studentId: userSelector.info.studentId,
                        courseId: userSelector.info.courseId,
                        bookId: userSelector.info.bookId,
                        level: actInfo.level,
                        episodeId: episodeInfo.episodeId,
                        episodeCode: episodeInfo.episodeCode,
                        lessonId: lessonInfo.lessonId,
                        actId: actInfo.actId,
                        actType: actInfo.actType,
                        actStatus: "Completed",
                        actPoint: actInfo.actCompletPoint,
                        actCurrentPage: setNum,
                        question: JSON.stringify(orgActList),
                        answer: JSON.stringify(actList)
                    }).catch((error) => {
                        console.error(error);
                    });

                    console.log(data);

                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .actStatus = "Completed";
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .file = categorySelected.file;
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .fileUrl = categorySelected.fileUrl;
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .player = categorySelected.player;

                    dispatch(userStore.save(userSelector.info));
                }

                let nextCategory = {
                    name: 'finished',
                    order: categorySelected.order,
                    retry: () => {
                        setSetNum(1);
                        if (actList.length > 0) {
                            const categoryList = actList.find(item => item.set === 1).category;
                            setCategoryList(categoryList);

                            categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                                if (index === 0) {
                                    setMinCategoryNum(item.order);
                                    setCategorySelected(categoryList[item.order]);
                                } else if (index === categoryList.length - 1) {
                                    setMaxCategoryNum(item.order);
                                }
                            });
                        }
                    },
                };
                setCategorySelected(nextCategory);
            }
            callApiAct();
            setDone(true);

        } else {
            return;
        }

    };
    console.log('leftData', leftData);
    console.log('rightData', rightData);

    const onComplete = () => {
        nextCategory()
        setPass(true);
    }

    const nextCategory = () => {

        setCategorySelected(categoryList[categorySelected.order + 1]);

    };

    const guideaniRef = useRef();
    const pictureRef = useRef();

    const onPage01 = () => {
        setPage01(true);
        setPage02(false);
    }

    const onPage02 = () => {
        setPage01(false);
        setPage02(true);
        setPage03(false);
    }

    const onPage03 = () => {
        setPage02(false);
        setPage03(true);
        setPage04(false);
    }

    const onPage04 = () => {
        setPage04(true);
        setPage03(false);
    }


    useEffect(() => {

        if (pass) {
            setTimeout(() => {
                guideaniRef.current.style.display = 'none';
            }, 6500);
        }

    }, [setNum]);



    const RenderActivityArea = () => {
        if (!guideFinished) {
            return (
                <Guide setGuideFinished={setGuideFinished} />
            )
        } else {
            if (categorySelected.name === 'finished') {
                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <GoodJob
                            categorySelected={categorySelected}
                            setCategorySelected={setCategorySelected}
                            categoryList={categoryList}
                        />
                    </div>
                );
            } else if (categorySelected.name === 'Camera') {

                let audio1 = new Audio(mediaPath + '/' + categorySelected.question01.audio);
                let audio2 = new Audio(mediaPath + '/' + categorySelected.question02.audio);
                let audio3 = new Audio(mediaPath + '/' + categorySelected.question03.audio);
                let audio4 = new Audio(mediaPath + '/' + categorySelected.question04.audio);
                let audio5 = new Audio(mediaPath + '/' + categorySelected.question05.audio);
                let audio6 = new Audio(mediaPath + '/' + categorySelected.question06.audio);
                let audio7 = new Audio(mediaPath + '/' + categorySelected.question07.audio);
                let audio8 = new Audio(mediaPath + '/' + categorySelected.question08.audio);
                let audio9 = new Audio(mediaPath + '/' + categorySelected.question09.audio);
                let audio10 = new Audio(mediaPath + '/' + categorySelected.question10.audio);
                let audio11 = new Audio(mediaPath + '/' + categorySelected.question11.audio);
                let audio12 = new Audio(mediaPath + '/' + categorySelected.question12.audio);
                let audio13 = new Audio(mediaPath + '/' + categorySelected.question13.audio);
                let audio14 = new Audio(mediaPath + '/' + categorySelected.question14.audio);
                let audio15 = new Audio(mediaPath + '/' + categorySelected.question15.audio);
                let audio16 = new Audio(mediaPath + '/' + categorySelected.question16.audio);

                const onAudio1 = () => {
                    audio1.play();
                };

                const onAudio2 = () => {
                    audio2.play();
                };


                const onAudio3 = () => {
                    audio3.play();
                };

                const onAudio4 = () => {
                    audio4.play();
                };

                const onAudio5 = () => {
                    audio5.play();
                };

                const onAudio6 = () => {
                    audio6.play();
                };

                const onAudio7 = () => {
                    audio7.play();
                };

                const onAudio8 = () => {
                    audio8.play();
                };

                const onAudio9 = () => {
                    audio9.play();
                };

                const onAudio10 = () => {
                    audio10.play();
                };

                const onAudio11 = () => {
                    audio11.play();
                };

                const onAudio12 = () => {
                    audio12.play();
                };

                const onAudio13 = () => {
                    audio13.play();
                };

                const onAudio14 = () => {
                    audio14.play();
                };

                const onAudio15 = () => {
                    audio15.play();
                };

                const onAudio16 = () => {
                    audio16.play();
                };
                const camerapic = [camerapicture, // 빈화면사진
                    mediaPath + '/' + categorySelected.question01.image,
                    mediaPath + '/' + categorySelected.question02.image,
                    mediaPath + '/' + categorySelected.question03.image,
                    mediaPath + '/' + categorySelected.question04.image,
                    mediaPath + '/' + categorySelected.question05.image,
                    mediaPath + '/' + categorySelected.question06.image,
                    mediaPath + '/' + categorySelected.question07.image,
                    mediaPath + '/' + categorySelected.question08.image,
                    mediaPath + '/' + categorySelected.question09.image,
                    mediaPath + '/' + categorySelected.question10.image,
                    mediaPath + '/' + categorySelected.question11.image,
                    mediaPath + '/' + categorySelected.question12.image,
                    mediaPath + '/' + categorySelected.question13.image,
                    mediaPath + '/' + categorySelected.question14.image,
                    mediaPath + '/' + categorySelected.question15.image,
                    mediaPath + '/' + categorySelected.question16.image];


                return (
                    <>
                        <div className="polaroid-wrap">
                            <p className="titleW">Drag the correct word to the space <br />under the picture.</p>
                            <div className="inner">
                                {/*단어*/}
                                <div className="word-piece-wrap">
                                    {pass15 ?
                                        <>
                                            <div className="word-piece">
                                                <div draggable="true" onMouseOver={onMouseOver16_1} >
                                                    <span className="green">{categorySelected.question16.text1}</span>
                                                </div>
                                                <div draggable="true" onMouseOver={onMouseOver16_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                    <span className="pink">{categorySelected.question16.text2}</span>
                                                </div>
                                                <div draggable="true" onMouseOver={onMouseOver16_3} >
                                                    <span className="blue">{categorySelected.question16.text3}</span>
                                                </div>
                                                <div draggable="true" onMouseOver={onMouseOver16_4} >
                                                    <span className="beige">{categorySelected.question16.text4}</span>
                                                </div>
                                            </div>
                                        </>
                                        : pass14 ?
                                            <>
                                                <div className="word-piece">
                                                    <div draggable="true" onMouseOver={onMouseOver15_1} >
                                                        <span className="green">{categorySelected.question15.text1}</span>
                                                    </div>
                                                    <div draggable="true" onMouseOver={onMouseOver15_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                        <span className="pink">{categorySelected.question15.text2}</span>
                                                    </div>
                                                    <div draggable="true" onMouseOver={onMouseOver15_3} >
                                                        <span className="blue">{categorySelected.question15.text3}</span>
                                                    </div>
                                                    <div draggable="true" onMouseOver={onMouseOver15_4} >
                                                        <span className="beige">{categorySelected.question15.text4}</span>
                                                    </div>
                                                </div>
                                            </>
                                            : pass13 ?
                                                <>
                                                    <div className="word-piece">
                                                        <div draggable="true" onMouseOver={onMouseOver14_1} >
                                                            <span className="green">{categorySelected.question14.text1}</span>
                                                        </div>
                                                        <div draggable="true" onMouseOver={onMouseOver14_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                            <span className="pink">{categorySelected.question14.text2}</span>
                                                        </div>
                                                        <div draggable="true" onMouseOver={onMouseOver14_3} >
                                                            <span className="blue">{categorySelected.question14.text3}</span>
                                                        </div>
                                                        <div draggable="true" onMouseOver={onMouseOver14_4} >
                                                            <span className="beige">{categorySelected.question14.text4}</span>
                                                        </div>
                                                    </div>
                                                </>
                                                : pass12 ?
                                                    <>
                                                        <div className="word-piece">
                                                            <div draggable="true" onMouseOver={onMouseOver13_1} >
                                                                <span className="green">{categorySelected.question13.text1}</span>
                                                            </div>
                                                            <div draggable="true" onMouseOver={onMouseOver13_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                <span className="pink">{categorySelected.question13.text2}</span>
                                                            </div>
                                                            <div draggable="true" onMouseOver={onMouseOver13_3} >
                                                                <span className="blue">{categorySelected.question13.text3}</span>
                                                            </div>
                                                            <div draggable="true" onMouseOver={onMouseOver13_4} >
                                                                <span className="beige">{categorySelected.question13.text4}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : pass11 ?
                                                        <>
                                                            <div className="word-piece">
                                                                <div draggable="true" onMouseOver={onMouseOver12_1} >
                                                                    <span className="green">{categorySelected.question12.text1}</span>
                                                                </div>
                                                                <div draggable="true" onMouseOver={onMouseOver12_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                    <span className="pink">{categorySelected.question12.text2}</span>
                                                                </div>
                                                                <div draggable="true" onMouseOver={onMouseOver12_3} >
                                                                    <span className="blue">{categorySelected.question12.text3}</span>
                                                                </div>
                                                                <div draggable="true" onMouseOver={onMouseOver12_4} >
                                                                    <span className="beige">{categorySelected.question12.text4}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : pass10 ?
                                                            <>
                                                                <div className="word-piece">
                                                                    <div draggable="true" onMouseOver={onMouseOver11_1} >
                                                                        <span className="green">{categorySelected.question11.text1}</span>
                                                                    </div>
                                                                    <div draggable="true" onMouseOver={onMouseOver11_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                        <span className="pink">{categorySelected.question11.text2}</span>
                                                                    </div>
                                                                    <div draggable="true" onMouseOver={onMouseOver11_3} >
                                                                        <span className="blue">{categorySelected.question11.text3}</span>
                                                                    </div>
                                                                    <div draggable="true" onMouseOver={onMouseOver11_4} >
                                                                        <span className="beige">{categorySelected.question11.text4}</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : pass09 ?
                                                                <>
                                                                    <div className="word-piece">
                                                                        <div draggable="true" onMouseOver={onMouseOver10_1} >
                                                                            <span className="green">{categorySelected.question10.text1}</span>
                                                                        </div>
                                                                        <div draggable="true" onMouseOver={onMouseOver10_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                            <span className="pink">{categorySelected.question10.text2}</span>
                                                                        </div>
                                                                        <div draggable="true" onMouseOver={onMouseOver10_3} >
                                                                            <span className="blue">{categorySelected.question10.text3}</span>
                                                                        </div>
                                                                        <div draggable="true" onMouseOver={onMouseOver10_4} >
                                                                            <span className="beige">{categorySelected.question10.text4}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : pass08 ?
                                                                    <>
                                                                        <div className="word-piece">
                                                                            <div draggable="true" onMouseOver={onMouseOver09_1} >
                                                                                <span className="green">{categorySelected.question09.text1}</span>
                                                                            </div>
                                                                            <div draggable="true" onMouseOver={onMouseOver09_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                <span className="pink">{categorySelected.question09.text2}</span>
                                                                            </div>
                                                                            <div draggable="true" onMouseOver={onMouseOver09_3} >
                                                                                <span className="blue">{categorySelected.question09.text3}</span>
                                                                            </div>
                                                                            <div draggable="true" onMouseOver={onMouseOver09_4} >
                                                                                <span className="beige">{categorySelected.question09.text4}</span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : pass07 ?
                                                                        <>
                                                                            <div className="word-piece">
                                                                                <div draggable="true" onMouseOver={onMouseOver08_1} >
                                                                                    <span className="green">{categorySelected.question08.text1}</span>
                                                                                </div>
                                                                                <div draggable="true" onMouseOver={onMouseOver08_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                    <span className="pink">{categorySelected.question08.text2}</span>
                                                                                </div>
                                                                                <div draggable="true" onMouseOver={onMouseOver08_3} >
                                                                                    <span className="blue">{categorySelected.question08.text3}</span>
                                                                                </div>
                                                                                <div draggable="true" onMouseOver={onMouseOver08_4} >
                                                                                    <span className="beige">{categorySelected.question08.text4}</span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : pass06 ?
                                                                            <>
                                                                                <div className="word-piece">
                                                                                    <div draggable="true" onMouseOver={onMouseOver07_1} >
                                                                                        <span className="green">{categorySelected.question07.text1}</span>
                                                                                    </div>
                                                                                    <div draggable="true" onMouseOver={onMouseOver07_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                        <span className="pink">{categorySelected.question07.text2}</span>
                                                                                    </div>
                                                                                    <div draggable="true" onMouseOver={onMouseOver07_3} >
                                                                                        <span className="blue">{categorySelected.question07.text3}</span>
                                                                                    </div>
                                                                                    <div draggable="true" onMouseOver={onMouseOver07_4} >
                                                                                        <span className="beige">{categorySelected.question07.text4}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : pass05 ?
                                                                                <>
                                                                                    <div className="word-piece">
                                                                                        <div draggable="true" onMouseOver={onMouseOver06_1} >
                                                                                            <span className="green">{categorySelected.question06.text1}</span>
                                                                                        </div>
                                                                                        <div draggable="true" onMouseOver={onMouseOver06_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                            <span className="pink">{categorySelected.question06.text2}</span>
                                                                                        </div>
                                                                                        <div draggable="true" onMouseOver={onMouseOver06_3} >
                                                                                            <span className="blue">{categorySelected.question06.text3}</span>
                                                                                        </div>
                                                                                        <div draggable="true" onMouseOver={onMouseOver06_4} >
                                                                                            <span className="beige">{categorySelected.question06.text4}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : pass04 ?
                                                                                    <>
                                                                                        <div className="word-piece">
                                                                                            <div draggable="true" onMouseOver={onMouseOver05_1} >
                                                                                                <span className="green">{categorySelected.question05.text1}</span>
                                                                                            </div>
                                                                                            <div draggable="true" onMouseOver={onMouseOver05_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                                <span className="pink">{categorySelected.question05.text2}</span>
                                                                                            </div>
                                                                                            <div draggable="true" onMouseOver={onMouseOver05_3} >
                                                                                                <span className="blue">{categorySelected.question05.text3}</span>
                                                                                            </div>
                                                                                            <div draggable="true" onMouseOver={onMouseOver05_4} >
                                                                                                <span className="beige">{categorySelected.question05.text4}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    : pass03 ?
                                                                                        <>
                                                                                            <div className="word-piece">
                                                                                                <div draggable="true" onMouseOver={onMouseOver04_1} >
                                                                                                    <span className="green">{categorySelected.question04.text1}</span>
                                                                                                </div>
                                                                                                <div draggable="true" onMouseOver={onMouseOver04_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                                    <span className="pink">{categorySelected.question04.text2}</span>
                                                                                                </div>
                                                                                                <div draggable="true" onMouseOver={onMouseOver04_3} >
                                                                                                    <span className="blue">{categorySelected.question04.text3}</span>
                                                                                                </div>
                                                                                                <div draggable="true" onMouseOver={onMouseOver04_4} >
                                                                                                    <span className="beige">{categorySelected.question04.text4}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        : pass02 ?
                                                                                            <>
                                                                                                <div className="word-piece">
                                                                                                    <div draggable="true" onMouseOver={onMouseOver03_1} >
                                                                                                        <span className="green">{categorySelected.question03.text1}</span>
                                                                                                    </div>
                                                                                                    <div draggable="true" onMouseOver={onMouseOver03_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                                        <span className="pink">{categorySelected.question03.text2}</span>
                                                                                                    </div>
                                                                                                    <div draggable="true" onMouseOver={onMouseOver03_3} >
                                                                                                        <span className="blue">{categorySelected.question03.text3}</span>
                                                                                                    </div>
                                                                                                    <div draggable="true" onMouseOver={onMouseOver03_4} >
                                                                                                        <span className="beige">{categorySelected.question03.text4}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            pass01 ?
                                                                                                <>
                                                                                                    <div className="word-piece">
                                                                                                        <div draggable="true" onMouseOver={onMouseOver02_1} >
                                                                                                            <span className="green">{categorySelected.question02.text1}</span>
                                                                                                        </div>
                                                                                                        <div draggable="true" onMouseOver={onMouseOver02_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                                            <span className="pink">{categorySelected.question02.text2}</span>
                                                                                                        </div>
                                                                                                        <div draggable="true" onMouseOver={onMouseOver02_3} >
                                                                                                            <span className="blue">{categorySelected.question02.text3}</span>
                                                                                                        </div>
                                                                                                        <div draggable="true" onMouseOver={onMouseOver02_4} >
                                                                                                            <span className="beige">{categorySelected.question02.text4}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div className="word-piece">
                                                                                                        <div draggable="true" onMouseOver={onMouseOver01_1}  >
                                                                                                            <span className="green">{categorySelected.question01.text1}</span>
                                                                                                        </div>
                                                                                                        <div draggable="true" onMouseOver={onMouseOver01_2} > {/*className="check"정답시 check 클래스 추가 */}
                                                                                                            <span className="pink">{categorySelected.question01.text2}</span>
                                                                                                        </div>
                                                                                                        <div draggable="true" onMouseOver={onMouseOver01_3}  >
                                                                                                            <span className="blue">{categorySelected.question01.text3}</span>
                                                                                                        </div>
                                                                                                        <div draggable="true" onMouseOver={onMouseOver01_4}  >
                                                                                                            <span className="beige">{categorySelected.question01.text4}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                    }
                                </div>
                                {/*단어*/}
                                {/*폴라로이드*/}
                                <div className="polaroid">
                                    <div className="camera">
                                        <span className="count">
                                            <em className="point">
                                                {pass16 ?
                                                    <>{categorySelected.question16.number}</>
                                                    : pass15 ?
                                                        <>{categorySelected.question16.number}</>
                                                        : pass14 ?
                                                            <>{categorySelected.question15.number}</>
                                                            : pass13 ?
                                                                <>{categorySelected.question14.number}</>
                                                                : pass12 ?
                                                                    <>{categorySelected.question13.number}</>
                                                                    : pass11 ?
                                                                        <>{categorySelected.question12.number}</>
                                                                        : pass10 ?
                                                                            <>{categorySelected.question11.number}</>
                                                                            : pass09 ?
                                                                                <>{categorySelected.question10.number}</>
                                                                                : pass08 ?
                                                                                    <>{categorySelected.question09.number}</>
                                                                                    : pass07 ?
                                                                                        <>{categorySelected.question08.number}</>
                                                                                        : pass06 ?
                                                                                            <>{categorySelected.question07.number}</>
                                                                                            : pass05 ?
                                                                                                <>{categorySelected.question06.number}</>
                                                                                                : pass04 ?
                                                                                                    <>{categorySelected.question05.number}</>
                                                                                                    : pass03 ?
                                                                                                        <>{categorySelected.question04.number}</>
                                                                                                        : pass02 ?
                                                                                                            <>{categorySelected.question03.number}</>
                                                                                                            : pass01 ?
                                                                                                                <>{categorySelected.question02.number}</>
                                                                                                                : <>{categorySelected.question01.number}</>}
                                            </em>
                                            <em className="slash">/</em> 16</span>
                                        <div className="bottom">
                                            {pass16 ?
                                                <>
                                                    <div className="photo-wrap" onDragLeave={handleDragEnter16} >{/*DropZone */}
                                                        <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                            <div className="imgarea"><img src={camerapic[16]} alt="" /></div>
                                                            <div className="word">
                                                                <img src={Sound} onClick={onAudio16} alt="" />
                                                                <div className="p">
                                                                    <img src={Blank} alt="" />
                                                                    {/* <p>grandfather</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                pass15 ?
                                                    <>
                                                        <div className="photo-wrap" onDragLeave={handleDragEnter16} >{/*DropZone */}
                                                            <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                <div className="imgarea"><img src={camerapic[16]} alt="" /></div>
                                                                <div className="word">
                                                                    <img src={Sound} onClick={onAudio16} alt="" />
                                                                    <div className="p">
                                                                        <img src={Blank} alt="" />
                                                                        {/* <p>grandfather</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : pass14 ?
                                                        <>
                                                            <div className="photo-wrap" onDragLeave={handleDragEnter15} >{/*DropZone */}
                                                                <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                    <div className="imgarea"><img src={camerapic[15]} alt="" /></div>
                                                                    <div className="word">
                                                                        <img src={Sound} alt="" />
                                                                        <div className="p">
                                                                            <img src={Blank} onClick={onAudio15} alt="" />
                                                                            {/* <p>grandfather</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : pass13 ?
                                                            <>
                                                                <div className="photo-wrap" onDragLeave={handleDragEnter14} >{/*DropZone */}
                                                                    <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                        <div className="imgarea"><img src={camerapic[14]} alt="" /></div>
                                                                        <div className="word">
                                                                            <img src={Sound} onClick={onAudio14} alt="" />
                                                                            <div className="p">
                                                                                <img src={Blank} alt="" />
                                                                                {/* <p>grandfather</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : pass12 ?
                                                                <>
                                                                    <div className="photo-wrap" onDragLeave={handleDragEnter13} >{/*DropZone */}
                                                                        <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                            <div className="imgarea"><img src={camerapic[13]} alt="" /></div>
                                                                            <div className="word">
                                                                                <img src={Sound} onClick={onAudio13} alt="" />
                                                                                <div className="p">
                                                                                    <img src={Blank} alt="" />
                                                                                    {/* <p>grandfather</p> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : pass11 ?
                                                                    <>
                                                                        <div className="photo-wrap" onDragLeave={handleDragEnter12} >{/*DropZone */}
                                                                            <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                <div className="imgarea"><img src={camerapic[12]} alt="" /></div>
                                                                                <div className="word">
                                                                                    <img src={Sound} onClick={onAudio12} alt="" />
                                                                                    <div className="p">
                                                                                        <img src={Blank} alt="" />
                                                                                        {/* <p>grandfather</p> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : pass10 ?
                                                                        <>
                                                                            <div className="photo-wrap" onDragLeave={handleDragEnter11} >{/*DropZone */}
                                                                                <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                    <div className="imgarea"><img src={camerapic[11]} alt="" /></div>
                                                                                    <div className="word">
                                                                                        <img src={Sound} onClick={onAudio11} alt="" />
                                                                                        <div className="p">
                                                                                            <img src={Blank} alt="" />
                                                                                            {/* <p>grandfather</p> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        pass09 ?
                                                                            <>
                                                                                <div className="photo-wrap" onDragLeave={handleDragEnter10} >{/*DropZone */}
                                                                                    <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                        <div className="imgarea"><img src={camerapic[10]} alt="" /></div>
                                                                                        <div className="word">
                                                                                            <img src={Sound} onClick={onAudio10} alt="" />
                                                                                            <div className="p">
                                                                                                <img src={Blank} alt="" />
                                                                                                {/* <p>grandfather</p> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : pass08 ?
                                                                                <>
                                                                                    <div className="photo-wrap" onDragLeave={handleDragEnter09} >{/*DropZone */}
                                                                                        <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                            <div className="imgarea"><img src={camerapic[9]} alt="" /></div>
                                                                                            <div className="word">
                                                                                                <img src={Sound} onClick={onAudio9} alt="" />
                                                                                                <div className="p">
                                                                                                    <img src={Blank} alt="" />
                                                                                                    {/* <p>grandfather</p> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : pass07 ?
                                                                                    <>
                                                                                        <div className="photo-wrap" onDragLeave={handleDragEnter08} >{/*DropZone */}
                                                                                            <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                <div className="imgarea"><img src={camerapic[8]} alt="" /></div>
                                                                                                <div className="word">
                                                                                                    <img src={Sound} onClick={onAudio8} alt="" />
                                                                                                    <div className="p">
                                                                                                        <img src={Blank} alt="" />
                                                                                                        {/* <p>grandfather</p> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    : pass06 ?
                                                                                        <>
                                                                                            <div className="photo-wrap" onDragLeave={handleDragEnter07} >{/*DropZone */}
                                                                                                <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                    <div className="imgarea"><img src={camerapic[7]} alt="" /></div>
                                                                                                    <div className="word">
                                                                                                        <img src={Sound} onClick={onAudio9} alt="" />
                                                                                                        <div className="p">
                                                                                                            <img src={Blank} alt="" />
                                                                                                            {/* <p>grandfather</p> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        : pass05 ?
                                                                                            <>
                                                                                                <div className="photo-wrap" onDragLeave={handleDragEnter06} >{/*DropZone */}
                                                                                                    <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                        <div className="imgarea"><img src={camerapic[6]} alt="" /></div>
                                                                                                        <div className="word">
                                                                                                            <img src={Sound} onClick={onAudio6} alt="" />
                                                                                                            <div className="p">
                                                                                                                <img src={Blank} alt="" />
                                                                                                                {/* <p>grandfather</p> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            : pass04 ?
                                                                                                <>
                                                                                                    <div className="photo-wrap" onDragLeave={handleDragEnter05} >{/*DropZone */}
                                                                                                        <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                            <div className="imgarea"><img src={camerapic[5]} alt="" /></div>
                                                                                                            <div className="word">
                                                                                                                <img src={Sound} onClick={onAudio5} alt="" />
                                                                                                                <div className="p">
                                                                                                                    <img src={Blank} alt="" />
                                                                                                                    {/* <p>grandfather</p> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                : pass03 ?
                                                                                                    <>
                                                                                                        <div className="photo-wrap" onDragLeave={handleDragEnter04} >{/*DropZone */}
                                                                                                            <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                                <div className="imgarea"><img src={camerapic[4]} alt="" /></div>
                                                                                                                <div className="word">
                                                                                                                    <img src={Sound} onClick={onAudio4} alt="" />
                                                                                                                    <div className="p">
                                                                                                                        <img src={Blank} alt="" />
                                                                                                                        {/* <p>grandfather</p> */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    : pass02 ?
                                                                                                        <>
                                                                                                            <div className="photo-wrap" onDragLeave={handleDragEnter03} >{/*DropZone */}
                                                                                                                <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                                    <div className="imgarea"><img src={camerapic[3]} alt="" /></div>
                                                                                                                    <div className="word">
                                                                                                                        <img src={Sound} onClick={onAudio3} alt="" />
                                                                                                                        <div className="p">
                                                                                                                            <img src={Blank} alt="" />
                                                                                                                            {/* <p>grandfather</p> */}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        : pass01 ?
                                                                                                            <>
                                                                                                                <div className="photo-wrap" onDragLeave={handleDragEnter02} >{/*DropZone */}
                                                                                                                    <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                                        <div className="imgarea"><img src={camerapic[2]} alt="" /></div>
                                                                                                                        <div className="word">
                                                                                                                            <img src={Sound} onClick={onAudio2} alt="" />
                                                                                                                            <div className="p">
                                                                                                                                <img src={Blank} alt="" />
                                                                                                                                {/* <p>grandfather</p> */}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            : <>
                                                                                                                <div className="photo-wrap" onDragLeave={handleDragEnter01} >{/*DropZone */}
                                                                                                                    <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                                        <div className="imgarea"><img src={camerapic[1]} alt="" /></div>
                                                                                                                        <div className="word">
                                                                                                                            <img src={Sound} onClick={onAudio1} alt="" />
                                                                                                                            <div className="p">
                                                                                                                                <img src={Blank} alt="" />
                                                                                                                                {/* <p>grandfather</p> */}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>}
                                        </div>
                                    </div>
                                </div>
                                {/*폴라로이드*/}
                            </div>
                        </div>


                        <div className="photo-album">
                            <p className="titleW">MY Photo Album</p>
                            {pass16 ?
                                <>
                                    {/* 1 */}
                                    <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                        <div className="photo-zone-inner">
                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[1]} alt="" />
                                                </div>
                                                <p>{categorySelected.question01.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[2]} alt="" />
                                                </div>
                                                <p>{categorySelected.question02.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[3]} alt="" />
                                                </div>
                                                <p>{categorySelected.question03.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[4]} alt="" />
                                                </div>
                                                <p>{categorySelected.question04.answer}</p>
                                            </div>
                                        </div>
                                        <div className="btnarea">
                                            <i className="arrow prev"></i>
                                            {/* <i className="complet-btn">complete</i> */}
                                            <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                            <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                        </div>
                                    </div>
                                    {/* 2 */}
                                    <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                        <div className="photo-zone-inner">
                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[5]} alt="" />
                                                </div>
                                                <p>{categorySelected.question05.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[6]} alt="" />
                                                </div>
                                                <p>{categorySelected.question06.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[7]} alt="" />
                                                </div>
                                                <p>{categorySelected.question07.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[8]} alt="" />
                                                </div>
                                                <p>{categorySelected.question08.answer}</p>
                                            </div>
                                        </div>
                                        <div className="btnarea">
                                            <i className="arrow prev" onClick={onPage01}  ></i>
                                            {/* <i className="complet-btn">complete</i> */}
                                            <i className="complet-btn2">complete</i>
                                            <i className="arrow next" onClick={onPage03} ></i>
                                        </div>
                                    </div>
                                    {/* 3 */}
                                    <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                        <div className="photo-zone-inner">
                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[9]} alt="" />
                                                </div>
                                                <p>{categorySelected.question09.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[10]} alt="" />
                                                </div>
                                                <p>{categorySelected.question10.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[11]} alt="" />
                                                </div>
                                                <p>{categorySelected.question11.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[12]} alt="" />
                                                </div>
                                                <p>{categorySelected.question12.answer}</p>
                                            </div>
                                        </div>
                                        <div className="btnarea">
                                            <i className="arrow prev" onClick={onPage02} ></i>
                                            {/* <i className="complet-btn">complete</i> */}
                                            <i className="complet-btn2">complete</i>
                                            <i className="arrow next" onClick={onPage04} ></i>
                                        </div>
                                    </div>
                                    {/* 4 */}
                                    <div className={page04 ? "photo-zone now" : "photo-zone"}>
                                        <div className="photo-zone-inner">
                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[13]} alt="" />
                                                </div>
                                                <p>{categorySelected.question13.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[14]} alt="" />
                                                </div>
                                                <p>{categorySelected.question14.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[15]} alt="" />
                                                </div>
                                                <p>{categorySelected.question15.answer}</p>
                                            </div>
                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                <div className="imgarea">
                                                    <img src={camerapic[16]} alt="" />
                                                </div>
                                                <p>{categorySelected.question16.answer}</p>
                                            </div>
                                        </div>
                                        <div className="btnarea">
                                            <i className="arrow prev" onClick={onPage03} ></i>
                                            {/* <i className="complet-btn">complete</i> */}
                                            <i className="complet-btn2">complete</i>
                                            <i className="arrow next"></i>
                                        </div>
                                    </div>
                                </>
                                :
                                pass15 ?
                                    <>
                                        {/* 1 */}
                                        <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                            <div className="photo-zone-inner">
                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[1]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question01.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[2]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question02.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[3]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question03.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[4]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question04.answer}</p>
                                                </div>
                                            </div>
                                            <div className="btnarea">
                                                <i className="arrow prev"></i>
                                                {/* <i className="complet-btn">complete</i> */}
                                                <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                            </div>
                                        </div>
                                        {/* 2 */}
                                        <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                            <div className="photo-zone-inner">
                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[5]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question05.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[6]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question06.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[7]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question07.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[8]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question08.answer}</p>
                                                </div>
                                            </div>
                                            <div className="btnarea">
                                                <i className="arrow prev" onClick={onPage01}  ></i>
                                                {/* <i className="complet-btn">complete</i> */}
                                                <i className="complet-btn2">complete</i>
                                                <i className="arrow next" onClick={onPage03} ></i>
                                            </div>
                                        </div>
                                        {/* 3 */}
                                        <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                            <div className="photo-zone-inner">
                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[9]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question09.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[10]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question10.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[11]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question11.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[12]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question12.answer}</p>
                                                </div>
                                            </div>
                                            <div className="btnarea">
                                                <i className="arrow prev" onClick={onPage02} ></i>
                                                {/* <i className="complet-btn">complete</i> */}
                                                <i className="complet-btn2">complete</i>
                                                <i className="arrow next" onClick={onPage04} ></i>
                                            </div>
                                        </div>
                                        {/* 4 */}
                                        <div className={page04 ? "photo-zone now" : "photo-zone"}>
                                            <div className="photo-zone-inner">
                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[13]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question13.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[14]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question14.answer}</p>
                                                </div>
                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[15]} alt="" />
                                                    </div>
                                                    <p>{categorySelected.question15.answer}</p>
                                                </div>
                                            </div>
                                            <div className="btnarea">
                                                <i className="arrow prev" onClick={onPage03} ></i>
                                                {/* <i className="complet-btn">complete</i> */}
                                                <i className="complet-btn2">complete</i>
                                                <i className="arrow next"></i>
                                            </div>
                                        </div>
                                    </>
                                    : pass14 ?
                                        <>
                                            {/* 1 */}
                                            <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                <div className="photo-zone-inner">
                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[1]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question01.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[2]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question02.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[3]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question03.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[4]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question04.answer}</p>
                                                    </div>
                                                </div>
                                                <div className="btnarea">
                                                    <i className="arrow prev"></i>
                                                    {/* <i className="complet-btn">complete</i> */}
                                                    <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                    <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                </div>
                                            </div>
                                            {/* 2 */}
                                            <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                <div className="photo-zone-inner">
                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[5]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question05.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[6]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question06.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[7]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question07.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[8]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question08.answer}</p>
                                                    </div>
                                                </div>
                                                <div className="btnarea">
                                                    <i className="arrow prev" onClick={onPage01}  ></i>
                                                    {/* <i className="complet-btn">complete</i> */}
                                                    <i className="complet-btn2">complete</i>
                                                    <i className="arrow next" onClick={onPage03} ></i>
                                                </div>
                                            </div>
                                            {/* 3 */}
                                            <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                <div className="photo-zone-inner">
                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[9]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question09.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[10]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question10.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[11]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question11.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[12]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question12.answer}</p>
                                                    </div>
                                                </div>
                                                <div className="btnarea">
                                                    <i className="arrow prev" onClick={onPage02} ></i>
                                                    {/* <i className="complet-btn">complete</i> */}
                                                    <i className="complet-btn2">complete</i>
                                                    <i className="arrow next" onClick={onPage04} ></i>
                                                </div>
                                            </div>
                                            {/* 4 */}
                                            <div className={page04 ? "photo-zone now" : "photo-zone"}>
                                                <div className="photo-zone-inner">
                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[13]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question13.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[14]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question14.answer}</p>
                                                    </div>
                                                </div>
                                                <div className="btnarea">
                                                    <i className="arrow prev" onClick={onPage03} ></i>
                                                    {/* <i className="complet-btn">complete</i> */}
                                                    <i className="complet-btn2">complete</i>
                                                    <i className="arrow next"></i>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        pass13 ?
                                            <>
                                                {/* 1 */}
                                                <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                    <div className="photo-zone-inner">
                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[1]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question01.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[2]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question02.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[3]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question03.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[4]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question04.answer}</p>
                                                        </div>
                                                    </div>
                                                    <div className="btnarea">
                                                        <i className="arrow prev"></i>
                                                        {/* <i className="complet-btn">complete</i> */}
                                                        <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                        <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                    </div>
                                                </div>
                                                {/* 2 */}
                                                <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                    <div className="photo-zone-inner">
                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[5]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question05.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[6]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question06.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[7]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question07.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[8]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question08.answer}</p>
                                                        </div>
                                                    </div>
                                                    <div className="btnarea">
                                                        <i className="arrow prev" onClick={onPage01}  ></i>
                                                        {/* <i className="complet-btn">complete</i> */}
                                                        <i className="complet-btn2">complete</i>
                                                        <i className="arrow next" onClick={onPage03} ></i>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                    <div className="photo-zone-inner">
                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[9]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question09.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[10]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question10.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[11]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question11.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[12]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question12.answer}</p>
                                                        </div>
                                                    </div>
                                                    <div className="btnarea">
                                                        <i className="arrow prev" onClick={onPage02} ></i>
                                                        {/* <i className="complet-btn">complete</i> */}
                                                        <i className="complet-btn2">complete</i>
                                                        <i className="arrow next" onClick={onPage04} ></i>
                                                    </div>
                                                </div>
                                                {/* 4 */}
                                                <div className={page04 ? "photo-zone now" : "photo-zone"}>
                                                    <div className="photo-zone-inner">
                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[13]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question13.answer}</p>
                                                        </div>
                                                    </div>
                                                    <div className="btnarea">
                                                        <i className="arrow prev" onClick={onPage03} ></i>
                                                        {/* <i className="complet-btn">complete</i> */}
                                                        <i className="complet-btn2">complete</i>
                                                        <i className="arrow next"></i>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            pass12 ?
                                                <>
                                                    {/* 1 */}
                                                    <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                        <div className="photo-zone-inner">
                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[1]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question01.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[2]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question02.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[3]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question03.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[4]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question04.answer}</p>
                                                            </div>
                                                        </div>
                                                        <div className="btnarea">
                                                            <i className="arrow prev"></i>
                                                            {/* <i className="complet-btn">complete</i> */}
                                                            <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                            <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                        </div>
                                                    </div>
                                                    {/* 2 */}
                                                    <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                        <div className="photo-zone-inner">
                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[5]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question05.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[6]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question06.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[7]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question07.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[8]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question08.answer}</p>
                                                            </div>
                                                        </div>
                                                        <div className="btnarea">
                                                            <i className="arrow prev" onClick={onPage01}  ></i>
                                                            {/* <i className="complet-btn">complete</i> */}
                                                            <i className="complet-btn2">complete</i>
                                                            <i className="arrow next" onClick={onPage03} ></i>
                                                        </div>
                                                    </div>
                                                    {/* 3 */}
                                                    <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                        <div className="photo-zone-inner">
                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[9]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question09.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[10]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question10.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[11]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question11.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[12]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question12.answer}</p>
                                                            </div>
                                                        </div>
                                                        <div className="btnarea">
                                                            <i className="arrow prev" onClick={onPage02} ></i>
                                                            {/* <i className="complet-btn">complete</i> */}
                                                            <i className="complet-btn2">complete</i>
                                                            <i className="arrow next" onClick={onPage04} ></i>
                                                        </div>
                                                    </div>
                                                </>
                                                : pass11 ?
                                                    <>
                                                        {/* 1 */}
                                                        <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                            <div className="photo-zone-inner">
                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[1]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question01.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[2]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question02.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[3]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question03.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[4]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question04.answer}</p>
                                                                </div>
                                                            </div>
                                                            <div className="btnarea">
                                                                <i className="arrow prev"></i>
                                                                {/* <i className="complet-btn">complete</i> */}
                                                                <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                            </div>
                                                        </div>
                                                        {/* 2 */}
                                                        <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                            <div className="photo-zone-inner">
                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[5]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question05.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[6]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question06.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[7]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question07.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[8]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question08.answer}</p>
                                                                </div>
                                                            </div>
                                                            <div className="btnarea">
                                                                <i className="arrow prev" onClick={onPage01}  ></i>
                                                                {/* <i className="complet-btn">complete</i> */}
                                                                <i className="complet-btn2">complete</i>
                                                                <i className="arrow next" onClick={onPage03} ></i>
                                                            </div>
                                                        </div>
                                                        {/* 3 */}
                                                        <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                            <div className="photo-zone-inner">
                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[9]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question09.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[10]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question10.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[11]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question11.answer}</p>
                                                                </div>
                                                            </div>
                                                            <div className="btnarea">
                                                                <i className="arrow prev" onClick={onPage02} ></i>
                                                                {/* <i className="complet-btn">complete</i> */}
                                                                <i className="complet-btn2">complete</i>
                                                                <i className="arrow next" onClick={onPage04} ></i>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : pass10 ?
                                                        <>
                                                            {/* 1 */}
                                                            <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                <div className="photo-zone-inner">
                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[1]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question01.answer}</p>
                                                                    </div>
                                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[2]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question02.answer}</p>
                                                                    </div>
                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[3]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question03.answer}</p>
                                                                    </div>
                                                                    <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[4]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question04.answer}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="btnarea">
                                                                    <i className="arrow prev"></i>
                                                                    {/* <i className="complet-btn">complete</i> */}
                                                                    <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                    <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                </div>
                                                            </div>
                                                            {/* 2 */}
                                                            <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                                <div className="photo-zone-inner">
                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[5]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question05.answer}</p>
                                                                    </div>
                                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[6]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question06.answer}</p>
                                                                    </div>
                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[7]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question07.answer}</p>
                                                                    </div>
                                                                    <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[8]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question08.answer}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="btnarea">
                                                                    <i className="arrow prev" onClick={onPage01}  ></i>
                                                                    {/* <i className="complet-btn">complete</i> */}
                                                                    <i className="complet-btn2">complete</i>
                                                                    <i className="arrow next" onClick={onPage03} ></i>
                                                                </div>
                                                            </div>
                                                            {/* 3 */}
                                                            <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                                <div className="photo-zone-inner">
                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[9]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question09.answer}</p>
                                                                    </div>
                                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[10]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question10.answer}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="btnarea">
                                                                    <i className="arrow prev" onClick={onPage02} ></i>
                                                                    {/* <i className="complet-btn">complete</i> */}
                                                                    <i className="complet-btn2">complete</i>
                                                                    <i className="arrow next" onClick={onPage04} ></i>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        pass09 ?
                                                            <>
                                                                {/* 1 */}
                                                                <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                    <div className="photo-zone-inner">
                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[1]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question01.answer}</p>
                                                                        </div>
                                                                        <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[2]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question02.answer}</p>
                                                                        </div>
                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[3]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question03.answer}</p>
                                                                        </div>
                                                                        <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[4]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question04.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btnarea">
                                                                        <i className="arrow prev"></i>
                                                                        {/* <i className="complet-btn">complete</i> */}
                                                                        <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                        <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                    </div>
                                                                </div>
                                                                {/* 2 */}
                                                                <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                                    <div className="photo-zone-inner">
                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[5]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question05.answer}</p>
                                                                        </div>
                                                                        <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[6]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question06.answer}</p>
                                                                        </div>
                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[7]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question07.answer}</p>
                                                                        </div>
                                                                        <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[8]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question08.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btnarea">
                                                                        <i className="arrow prev" onClick={onPage01}  ></i>
                                                                        {/* <i className="complet-btn">complete</i> */}
                                                                        <i className="complet-btn2">complete</i>
                                                                        <i className="arrow next" onClick={onPage03} ></i>
                                                                    </div>
                                                                </div>
                                                                {/* 3 */}
                                                                <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                                    <div className="photo-zone-inner">
                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[9]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question09.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btnarea">
                                                                        <i className="arrow prev" onClick={onPage02} ></i>
                                                                        {/* <i className="complet-btn">complete</i> */}
                                                                        <i className="complet-btn2">complete</i>
                                                                        <i className="arrow next" onClick={onPage04} ></i>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : pass08 ?
                                                                <>
                                                                    {/* 1 */}
                                                                    <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                        <div className="photo-zone-inner">
                                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[1]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question01.answer}</p>
                                                                            </div>
                                                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[2]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question02.answer}</p>
                                                                            </div>
                                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[3]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question03.answer}</p>
                                                                            </div>
                                                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[4]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question04.answer}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="btnarea">
                                                                            <i className="arrow prev"></i>
                                                                            {/* <i className="complet-btn">complete</i> */}
                                                                            <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                            <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                        </div>
                                                                    </div>
                                                                    {/* 2 */}
                                                                    <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                                        <div className="photo-zone-inner">
                                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[5]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question05.answer}</p>
                                                                            </div>
                                                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[6]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question06.answer}</p>
                                                                            </div>
                                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[7]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question07.answer}</p>
                                                                            </div>
                                                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[8]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question08.answer}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="btnarea">
                                                                            <i className="arrow prev" onClick={onPage01}  ></i>
                                                                            {/* <i className="complet-btn">complete</i> */}
                                                                            <i className="complet-btn2">complete</i>
                                                                            <i className="arrow next" onClick={onPage03} ></i>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : pass07 ?
                                                                    <>
                                                                        {/* 1 */}
                                                                        <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                            <div className="photo-zone-inner">
                                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[1]} alt="" />
                                                                                    </div>
                                                                                    <p>{categorySelected.question01.answer}</p>
                                                                                </div>
                                                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[2]} alt="" />
                                                                                    </div>
                                                                                    <p>{categorySelected.question02.answer}</p>
                                                                                </div>
                                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[3]} alt="" />
                                                                                    </div>
                                                                                    <p>{categorySelected.question03.answer}</p>
                                                                                </div>
                                                                                <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[4]} alt="" />
                                                                                    </div>
                                                                                    <p>{categorySelected.question04.answer}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="btnarea">
                                                                                <i className="arrow prev"></i>
                                                                                {/* <i className="complet-btn">complete</i> */}
                                                                                <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                                <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                            </div>
                                                                        </div>
                                                                        {/* 2 */}
                                                                        <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                                            <div className="photo-zone-inner">
                                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[5]} alt="" />
                                                                                    </div>
                                                                                    <p>{categorySelected.question05.answer}</p>
                                                                                </div>
                                                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[6]} alt="" />
                                                                                    </div>
                                                                                    <p>{categorySelected.question06.answer}</p>
                                                                                </div>
                                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[7]} alt="" />
                                                                                    </div>
                                                                                    <p>{categorySelected.question07.answer}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="btnarea">
                                                                                <i className="arrow prev" onClick={onPage01}  ></i>
                                                                                {/* <i className="complet-btn">complete</i> */}
                                                                                <i className="complet-btn2">complete</i>
                                                                                <i className="arrow next" onClick={onPage03} ></i>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : pass06 ?
                                                                        <>
                                                                            {/* 1 */}
                                                                            <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                                <div className="photo-zone-inner">
                                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[1]} alt="" />
                                                                                        </div>
                                                                                        <p>{categorySelected.question01.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[2]} alt="" />
                                                                                        </div>
                                                                                        <p>{categorySelected.question02.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[3]} alt="" />
                                                                                        </div>
                                                                                        <p>{categorySelected.question03.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[4]} alt="" />
                                                                                        </div>
                                                                                        <p>{categorySelected.question04.answer}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="btnarea">
                                                                                    <i className="arrow prev"></i>
                                                                                    {/* <i className="complet-btn">complete</i> */}
                                                                                    <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                                    <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                                </div>
                                                                            </div>
                                                                            {/* 2 */}
                                                                            <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                                                <div className="photo-zone-inner">
                                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[5]} alt="" />
                                                                                        </div>
                                                                                        <p>{categorySelected.question05.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[6]} alt="" />
                                                                                        </div>
                                                                                        <p>{categorySelected.question06.answer}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="btnarea">
                                                                                    <i className="arrow prev" onClick={onPage01}  ></i>
                                                                                    {/* <i className="complet-btn">complete</i> */}
                                                                                    <i className="complet-btn2">complete</i>
                                                                                    <i className="arrow next" onClick={onPage03} ></i>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : pass05 ?
                                                                            <>
                                                                                {/* 1 */}
                                                                                <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                                    <div className="photo-zone-inner">
                                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[1]} alt="" />
                                                                                            </div>
                                                                                            <p>{categorySelected.question01.answer}</p>
                                                                                        </div>
                                                                                        <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[2]} alt="" />
                                                                                            </div>
                                                                                            <p>{categorySelected.question02.answer}</p>
                                                                                        </div>
                                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[3]} alt="" />
                                                                                            </div>
                                                                                            <p>{categorySelected.question03.answer}</p>
                                                                                        </div>
                                                                                        <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[4]} alt="" />
                                                                                            </div>
                                                                                            <p>{categorySelected.question04.answer}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="btnarea">
                                                                                        <i className="arrow prev"></i>
                                                                                        {/* <i className="complet-btn">complete</i> */}
                                                                                        <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                                        <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                                    </div>
                                                                                </div>
                                                                                {/* 2 */}
                                                                                <div className={page02 ? "photo-zone now" : "photo-zone"}>
                                                                                    <div className="photo-zone-inner">
                                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[5]} alt="" />
                                                                                            </div>
                                                                                            <p>{categorySelected.question05.answer}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="btnarea">
                                                                                        <i className="arrow prev" onClick={onPage01}  ></i>
                                                                                        {/* <i className="complet-btn">complete</i> */}
                                                                                        <i className="complet-btn2">complete</i>
                                                                                        <i className="arrow next" onClick={onPage03} ></i>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : pass04 ?
                                                                                <>
                                                                                    {/* 1 */}
                                                                                    <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                                        <div className="photo-zone-inner">
                                                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                                <div className="imgarea">
                                                                                                    <img src={camerapic[1]} alt="" />
                                                                                                </div>
                                                                                                <p>{categorySelected.question01.answer}</p>
                                                                                            </div>
                                                                                            <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                                <div className="imgarea">
                                                                                                    <img src={camerapic[2]} alt="" />
                                                                                                </div>
                                                                                                <p>{categorySelected.question02.answer}</p>
                                                                                            </div>
                                                                                            <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                                <div className="imgarea">
                                                                                                    <img src={camerapic[3]} alt="" />
                                                                                                </div>
                                                                                                <p>{categorySelected.question03.answer}</p>
                                                                                            </div>
                                                                                            <div className="photo" style={{ 'transform': 'rotate(-11deg)' }}>
                                                                                                <div className="imgarea">
                                                                                                    <img src={camerapic[4]} alt="" />
                                                                                                </div>
                                                                                                <p>{categorySelected.question04.answer}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="btnarea">
                                                                                            <i className="arrow prev"></i>
                                                                                            {/* <i className="complet-btn">complete</i> */}
                                                                                            <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                                            <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : pass03 ?
                                                                                    <>
                                                                                        {/* 1 */}
                                                                                        <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                                            <div className="photo-zone-inner">
                                                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                                    <div className="imgarea">
                                                                                                        <img src={camerapic[1]} alt="" />
                                                                                                    </div>
                                                                                                    <p>{categorySelected.question01.answer}</p>
                                                                                                </div>
                                                                                                <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                                    <div className="imgarea">
                                                                                                        <img src={camerapic[2]} alt="" />
                                                                                                    </div>
                                                                                                    <p>{categorySelected.question02.answer}</p>
                                                                                                </div>
                                                                                                <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                                    <div className="imgarea">
                                                                                                        <img src={camerapic[3]} alt="" />
                                                                                                    </div>
                                                                                                    <p>{categorySelected.question03.answer}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="btnarea">
                                                                                                <i className="arrow prev"></i>
                                                                                                {/* <i className="complet-btn">complete</i> */}
                                                                                                <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                                                <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    : pass02 ?
                                                                                        <>
                                                                                            {/* 1 */}
                                                                                            <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                                                <div className="photo-zone-inner">
                                                                                                    <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                                        <div className="imgarea">
                                                                                                            <img src={camerapic[1]} alt="" />
                                                                                                        </div>
                                                                                                        <p>{categorySelected.question01.answer}</p>
                                                                                                    </div>
                                                                                                    <div className="photo" style={{ 'transform': 'rotate(0deg)' }}>
                                                                                                        <div className="imgarea">
                                                                                                            <img src={camerapic[2]} alt="" />
                                                                                                        </div>
                                                                                                        <p>{categorySelected.question02.answer}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="btnarea">
                                                                                                    <i className="arrow prev"></i>
                                                                                                    {/* <i className="complet-btn">complete</i> */}
                                                                                                    <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                                                    <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        pass01 ?
                                                                                            <>
                                                                                                {/* 1 */}
                                                                                                <div className={page01 ? "photo-zone now" : "photo-zone"} >
                                                                                                    <div className="photo-zone-inner">
                                                                                                        <div className="photo" style={{ 'transform': 'rotate(7deg)' }}>
                                                                                                            <div className="imgarea">
                                                                                                                <img src={camerapic[1]} alt="" />
                                                                                                            </div>
                                                                                                            <p>{categorySelected.question01.answer}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="btnarea">
                                                                                                        <i className="arrow prev"></i>
                                                                                                        {/* <i className="complet-btn">complete</i> */}
                                                                                                        <i className="complet-btn2" onClick={onComplete}  >complete</i>
                                                                                                        <i className="arrow next" onClick={onPage02} ></i> {/* className="arrowactive prev"  className="arrowactive next"*/}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                            </>
                            }
                        </div>

                    </>

                )
            }
            else {

                const picture = [mediaPath + '/' + categorySelected.question01.image,
                mediaPath + '/' + categorySelected.question02.image,
                mediaPath + '/' + categorySelected.question03.image,
                mediaPath + '/' + categorySelected.question04.image,
                mediaPath + '/' + categorySelected.question05.image,
                mediaPath + '/' + categorySelected.question06.image,
                mediaPath + '/' + categorySelected.question07.image,
                mediaPath + '/' + categorySelected.question08.image,
                mediaPath + '/' + categorySelected.question09.image,
                mediaPath + '/' + categorySelected.question10.image,
                mediaPath + '/' + categorySelected.question11.image,
                mediaPath + '/' + categorySelected.question12.image,
                mediaPath + '/' + categorySelected.question13.image,
                mediaPath + '/' + categorySelected.question14.image,
                mediaPath + '/' + categorySelected.question15.image,
                mediaPath + '/' + categorySelected.question16.image];

                return (
                    <>
                        {pass ? <div className="guide-ani" ref={guideaniRef}>
                            <img src={GuideAni} alt="" />
                        </div> : null}
                        <div className="classification">
                            <p className="titleW">Drag the pictures to the correct place.</p>
                            <div className="board">
                                <div className="left">
                                    <p className="titleP">{categorySelected.answerGroup1}</p>
                                    <div className="photo-zone" onDragEnter={handleLeftDragEnter}>
                                        <div className="photo-zone-inner">
                                            {/* 1 */}
                                            {leftData.map((item, index) => (
                                                <>
                                                    <div className="photo" style={{ 'transform': item.transform }}>
                                                        <div className="imgarea">
                                                            <img src={item.src} alt="" />
                                                        </div>
                                                        <p>{item.text}</p>
                                                    </div>
                                                </>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                                <div className="center">
                                    <div className="photos">
                                        <div className="photo" style={{ 'transform': 'rotate(3deg)' }}></div>
                                        <div className="photo" style={{ 'transform': 'rotate(-5deg)' }}>
                                            {/* <div className="imgarea">
                                                        <img src={balcony} alt="" />
                                                    </div>
                                                    <p>balcony</p> */}
                                        </div>
                                        <>
                                            {drop16 ?
                                                <div className="photo" draggable="true" onDragLeave={onDragLeave16} >
                                                    <div className="imgarea">
                                                        <img src={picture[0]} alt="" />
                                                    </div>
                                                    <p></p>
                                                </div>
                                                : drop15 ?
                                                    <div className="photo" draggable="true" onDragLeave={onDragLeave16} >
                                                        <div className="imgarea">
                                                            <img src={picture[15]} alt="" />
                                                        </div>
                                                        <p>{categorySelected.question16.text}</p>
                                                    </div>
                                                    : drop14 ?
                                                        <div className="photo" draggable="true" onDragLeave={onDragLeave15} >
                                                            <div className="imgarea">
                                                                <img src={picture[14]} alt="" />
                                                            </div>
                                                            <p>{categorySelected.question15.text}</p>
                                                        </div>
                                                        : drop13 ?
                                                            <div className="photo" draggable="true" onDragLeave={onDragLeave14} >
                                                                <div className="imgarea">
                                                                    <img src={picture[13]} alt="" />
                                                                </div>
                                                                <p>{categorySelected.question14.text}</p>
                                                            </div>
                                                            : drop12 ?
                                                                <div className="photo" draggable="true" onDragLeave={onDragLeave13} >
                                                                    <div className="imgarea">
                                                                        <img src={picture[12]} alt="" />
                                                                    </div>
                                                                    <p>{categorySelected.question13.text}</p>
                                                                </div>
                                                                : drop11 ?
                                                                    <div className="photo" draggable="true" onDragLeave={onDragLeave12} >
                                                                        <div className="imgarea">
                                                                            <img src={picture[11]} alt="" />
                                                                        </div>
                                                                        <p>{categorySelected.question12.text}</p>
                                                                    </div>
                                                                    : drop10 ?
                                                                        <div className="photo" draggable="true" onDragLeave={onDragLeave11} >
                                                                            <div className="imgarea">
                                                                                <img src={picture[10]} alt="" />
                                                                            </div>
                                                                            <p>{categorySelected.question11.text}</p>
                                                                        </div>
                                                                        : drop09 ?
                                                                            <div className="photo" draggable="true" onDragLeave={onDragLeave10} >
                                                                                <div className="imgarea">
                                                                                    <img src={picture[9]} alt="" />
                                                                                </div>
                                                                                <p>{categorySelected.question10.text}</p>
                                                                            </div>
                                                                            :
                                                                            drop08 ?
                                                                                <div className="photo" draggable="true" onDragLeave={onDragLeave9} >
                                                                                    <div className="imgarea">
                                                                                        <img src={picture[8]} alt="" />
                                                                                    </div>
                                                                                    <p>{categorySelected.question09.text}</p>
                                                                                </div>
                                                                                :
                                                                                drop07 ?
                                                                                    <div className="photo" draggable="true" onDragLeave={onDragLeave8} >
                                                                                        <div className="imgarea">
                                                                                            <img src={picture[7]} alt="" />
                                                                                        </div>
                                                                                        <p>{categorySelected.question08.text}</p>
                                                                                    </div>
                                                                                    :
                                                                                    drop06 ?
                                                                                        <div className="photo" draggable="true" onDragLeave={onDragLeave7} >
                                                                                            <div className="imgarea">
                                                                                                <img src={picture[6]} alt="" />
                                                                                            </div>
                                                                                            <p>{categorySelected.question07.text}</p>
                                                                                        </div>
                                                                                        :
                                                                                        drop05 ?
                                                                                            <div className="photo" draggable="true" onDragLeave={onDragLeave6} >
                                                                                                <div className="imgarea">
                                                                                                    <img src={picture[5]} alt="" />
                                                                                                </div>
                                                                                                <p>{categorySelected.question06.text}</p>
                                                                                            </div>
                                                                                            :
                                                                                            drop04 ?
                                                                                                <div className="photo" draggable="true" onDragLeave={onDragLeave5} >
                                                                                                    <div className="imgarea">
                                                                                                        <img src={picture[4]} alt="" />
                                                                                                    </div>
                                                                                                    <p>{categorySelected.question05.text}</p>
                                                                                                </div>
                                                                                                :
                                                                                                drop03 ?
                                                                                                    <div className="photo" draggable="true" onDragLeave={onDragLeave4} >
                                                                                                        <div className="imgarea">
                                                                                                            <img src={picture[3]} alt="" />
                                                                                                        </div>
                                                                                                        <p>{categorySelected.question04.text}</p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    drop02 ?
                                                                                                        <div className="photo" draggable="true" onDragLeave={onDragLeave3} >
                                                                                                            <div className="imgarea">
                                                                                                                <img src={picture[2]} alt="" />
                                                                                                            </div>
                                                                                                            <p>{categorySelected.question03.text}</p>
                                                                                                        </div>
                                                                                                        :
                                                                                                        drop01 ?
                                                                                                            <div className="photo" draggable="true" onDragLeave={onDragLeave2} >
                                                                                                                <div className="imgarea">
                                                                                                                    <img src={picture[1]} alt="" />
                                                                                                                </div>
                                                                                                                <p>{categorySelected.question02.text}</p>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="photo" draggable="true" onDragLeave={onDragLeave1} >
                                                                                                                <div className="imgarea">
                                                                                                                    <img src={picture[0]} alt="" />
                                                                                                                </div>
                                                                                                                <p>{categorySelected.question01.text}</p>
                                                                                                            </div>
                                            }
                                        </>
                                    </div>
                                </div>
                                <div className="right">
                                    <p className="titleP">{categorySelected.answerGroup2}</p>
                                    <div className="photo-zone" onDragEnter={handleRightDragEnter}>
                                        <div className="photo-zone-inner">
                                            {rightData.map((item, index) => (
                                                <>
                                                    <div className="photo" style={{ 'transform': item.transform }}>
                                                        <div className="imgarea">
                                                            <img src={item.src} alt="" />
                                                        </div>
                                                        <p>{item.text}</p>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                )
            }



        }
    };

    return (
        <div className="word-play2">
            <div>
                <div className="contents d-flex flex-column justify-content-between">
                    <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
                    <CloseActivityModal ref={closeActivityModalRef} />
                    <AlertModal ref={alertModalRef} />
                    <div className="route_bar d-flex align-items-center justify-content-between">
                        <div className="right_icon">
                            <img className="help_icon_img" src={HelpIcon} onClick={() => handleGuide()} alt="" />
                            <img className="cancel_icon_img" src={CancelIcon} onClick={() => handleClose()} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <>
                <RenderActivityArea />
            </>
        </div>
    );
};

export default Index;
