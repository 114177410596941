import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import Guide from "../../components/guide/Guide";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import LeftButton from "../../images/activity-button/btn_pre_n.png";
import RightButton from "../../images/activity-button/btn_next_n.png";

import PlayBtn from "../../images/activity-default/btn_play_03.png";
import PlayBtn_dim from "../../images/activity-default/btn_play_d.png";
import PlayBtn_normal from "../../images/activity-default/btn_play_n.png";
import PlayBtn_pressed from "../../images/activity-default/btn_play_p.png";
import PlayBtn_highlight from "../../images/activity-default/btn_play_h.png";

import RecordBtn from "../../images/activity-default/btn_record.png";
import PlayMy from "../../images/activity-default/btn_my_d.png";

import ep01_le01_mini_img_01_bg from "../../images/activity-minisitcom/hht1_ep01_le01_mini_img_01_t1_bg.png"
import ep01_le01_mini_img_01_t1_1 from "../../images/activity-minisitcom/hht1_ep01_le01_mini_img_01_t1_t.png"
import ep01_le01_mini_img_01_t1_2 from "../../images/activity-minisitcom/hht1_ep01_le01_mini_img_01_t2_t.png"
import ep01_le01_mini_img_01_t1_3 from "../../images/activity-minisitcom/hht1_ep01_le01_mini_img_01_t3_t.png"

import ep01_le01_mini_img_01_t1_1_st from "../../images/activity-minisitcom/hht1_ep01_le01_mini_img_01_t1_st.png"
import ep01_le01_mini_img_01_t1_2_st from "../../images/activity-minisitcom/hht1_ep01_le01_mini_img_01_t2_st.png"
import ep01_le01_mini_img_01_t1_3_st from "../../images/activity-minisitcom/hht1_ep01_le01_mini_img_01_t3_st.png"


/*css*/
import "../../css/layout.css";
import "../../css/minisitcom.css";


const Index = () => {

  return (
    <div className="mini-sitcom-new">
      <div>
        <div className="contents d-flex flex-column justify-content-between">
          <div className="route_bar d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <p>Lesson 1 &gt; <span className="extra-bold">Mini Sitcom 1</span></p>
            </div>
            <div className="right_icon">
              <div className="paging">
                <span className="current_num">01</span>
                <span className="total_num">/07</span>
              </div>
              <img className="help_icon_img" src={HelpIcon} alt="" />
              <img className="cancel_icon_img" src={CancelIcon} alt="" />
            </div>
          </div>

          <div className="box_parent">
            <div className="box-wrap d-flex justify-content-center align-items-center">
              <div className="sitcom-box">
                <div className="d-flex flex-lg-column justify-content-center align-items-center box">
                  <div className="screen">
                      {/*mini-sitcom 화면*/}
                      <div className="mini-position">
                        <img src={ep01_le01_mini_img_01_bg} alt="" />
                        <div className="bubble-wrap">
                          {/*선택 전 까만 말풍선 - ★말풍선마다 위치,크기 각각 설정 필요*/}
                          <img src={ep01_le01_mini_img_01_t1_1} alt="" style={{'left':'2%', 'top':'4%', 'width':'41%'}}/>
                          <img src={ep01_le01_mini_img_01_t1_2} alt="" style={{'left':'39%', 'top':'50%', 'width':'15%'}}/>
                          <img src={ep01_le01_mini_img_01_t1_3} alt="" style={{'right':'7%', 'top':'7%', 'width':'20%'}}/>
                          {/*선택 전 까만 말풍선*/}
                          {/*선택 후 빨간 말풍선*/}
                          {/* <img src={ep01_le01_mini_img_01_t1_1_st} alt="" style={{'left':'2%', 'top':'4%', 'width':'41%'}}/>
                          <img src={ep01_le01_mini_img_01_t1_2_st} alt="" style={{'left':'39%', 'top':'50%', 'width':'15%'}}/>
                          <img src={ep01_le01_mini_img_01_t1_3_st} alt="" style={{'right':'7%', 'top':'7%', 'width':'20%'}}/> */}
                          {/*선택 후 빨간 말풍선*/}
                        </div>
                      </div>
                      {/*mini-sitcom 화면*/}

                      {/*하단 음성플레이 버튼*/}
                      <div className="btn-area">
                        <img src={PlayBtn} alt="" />{/*재생중*/}

                        {/* 
                        <img src={PlayBtn_dim} alt="" /> //dim
                        <img src={PlayBtn_normal} alt="" /> //*normal
                        <img src={PlayBtn_pressed} alt="" /> //pressed
                        <img src={PlayBtn_highlight} alt="" /> //highlight
                        */}
                      </div>
                      {/*하단 음성플레이 버튼*/}

                  </div>
                </div>
                <div className="left-button"><img src={LeftButton} alt="" /></div>
                <div className="right-button"><img src={RightButton} alt="" /></div>
              </div>
            </div>
          </div>

          {/*하단팝업 레이어 - 물음표있는 말풍선 클릭시 노출*/}
          <div className="btm_layer answer">
            <div className="answer-wrap word">
              <span>hold</span>
              <span>hold</span>
              <span>hold</span>
            </div>                  
            <div className="buttongroup">
                <button className="check-btn">Submit</button>{/*활성화 시 className on추가*/}
            </div>
            <span className="layer-close"></span>
          </div>
          {/*하단팝업 레이어 - 정답체크*/}

          {/*하단팝업 레이어 - 하단 녹음버튼 클릭시 노출*/}
          <div className="btm_layer record">
            <div className="record-wrap">
                <img src={PlayBtn} alt="" />
                <img src={RecordBtn} alt="" />
                <img src={PlayMy} alt="" />
            </div>
            <div className="text-wrap">
              <p>Harry! Can you <span className="point">help</span> me with something?</p>
              <p>Sure, Dad.</p>
            </div>
            <span className="layer-close"></span>
          </div>
          {/*하단팝업 레이어 - 녹음*/}

          {/*Are you sure .... 팝업 - 녹음 1 회 완료 후 녹음 버튼 클릭 시  딤 화면 위에 알림 팝업창 노출*/}
          {/* 
          <div className="modal_width">
            <div className="modal_overlay"></div>
            <div className="modal_wrapper exit-pop">
              <div className="modal_inner">
                <div className="title">Are you sure you want to <br/>delete and record again?</div>
                <div className="buttongroup text-btn">
                  <span className="line-btn">Yes</span>
                  <span className="full-btn">No</span>
                </div>
              </div>
            </div> 
          </div>*/}
          {/*Are you sure .... 팝업*/}
        </div>
      </div>
    </div>
  );
};

export default Index;
