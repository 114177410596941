import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";


import ClosePop from "../../images/pop-default/pop-close.png";

const CloseActivityModal = forwardRef((props, ref) => {
    let history = useHistory();

    const modalRef = useRef();
    
    const btnOk = [
        require('../../images/common/pop_btn_ok_h.png').default,
        require('../../images/common/pop_btn_ok_n.png').default,
        require('../../images/common/pop_btn_ok_p.png').default,
    ];
    const btnCancel = [
        require('../../images/common/pop_btn_cancel_h.png').default,
        require('../../images/common/pop_btn_cancel_n.png').default,
        require('../../images/common/pop_btn_cancel_p.png').default,
    ];

    useImperativeHandle(ref, () => ({
        openModal() {
            modalRef.current.style.display = 'block';
        },
    }));

    const btnCancelEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.src = btnCancel[0];
        } else if (e.type === "mouseleave") {
            e.target.src = btnCancel[1];
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            e.target.src = btnCancel[2];
        } else if (e.type === "mouseup" || e.type === "touchend") {
            e.target.src = btnCancel[1];
            modalRef.current.style.display = 'none';
        }
    };

    const btnOkEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.src = btnOk[0];
        } else if (e.type === "mouseleave") {
            e.target.src = btnOk[1];
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            e.target.src = btnOk[2];
        } else if (e.type === "mouseup" || e.type === "touchend") {
            e.target.src = btnOk[1];
            modalRef.current.style.display = 'none';
            history.goBack();
        }
    };

    return (
        <div className="modal_width" ref={modalRef}>
            <div className="modal_overlay" />
            <div className="modal_wrapper close-pop" tabIndex="-1">
                <div className="modal_inner">
                    <div className="title">Do you want to exit?</div>
                    <div className="buttongroup text-btn">
                        <span className="line-btn" 
                            onMouseDown={btnOkEvents}
                            onMouseUp={btnOkEvents}
                            onTouchEnd={btnOkEvents}>Yes</span>
                        <span className="full-btn"
                            onMouseDown={btnCancelEvents}
                            onMouseUp={btnCancelEvents}                            
                            onTouchEnd={btnCancelEvents}>No</span>
                    </div>
                        {/* <img src={btnCancel[1]}
                            onMouseOver={btnCancelEvents}
                            onMouseLeave={btnCancelEvents}
                            onMouseDown={btnCancelEvents}
                            onMouseUp={btnCancelEvents}                            
                            onTouchEnd={btnCancelEvents} />
                        <img src={btnOk[1]}
                            onMouseOver={btnOkEvents}
                            onMouseLeave={btnOkEvents}
                            onMouseDown={btnOkEvents}
                            onMouseUp={btnOkEvents}
                            onTouchStart={btnOkEvents}
                            onTouchEnd={btnOkEvents} />
                    </div> */}
                </div>
            </div>
        </div>
    )
});

export default CloseActivityModal;