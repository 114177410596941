import React, { forwardRef, useEffect, useRef } from 'react';
import { Group, Image, Layer, Rect, Stage, Text } from 'react-konva';
import { TaskTimer } from 'tasktimer';
import useImage from 'use-image';

const EggStudioShadowing = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath;
    const activityProps = props.activityProps;
    const answerOpen = props.answerOpen;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const openAlertModal = props.openAlertModal;

    const audioRef = [];
    const stageRef = useRef();

    const containerRef = useRef();

    useEffect(() => {
        let containerWidth = containerRef.current.offsetWidth
        let containerHeight = containerRef.current.offsetHeight;
        const scaleX = containerWidth / activityProps.defaultWidth;
        const scaleY = containerHeight / activityProps.defaultHeight;
        if (scaleX > scaleY) {
            containerWidth = activityProps.defaultWidth * scaleY;
        } else if (scaleX < scaleY) {
            containerHeight = activityProps.defaultHeight * scaleX;
        }
        const scale = Math.min(scaleX, scaleY);
        stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
    }, []);
    useEffect(() => {
        const resizeListener = () => {
            let containerWidth = containerRef.current.offsetWidth;
            let containerHeight = containerRef.current.offsetHeight;
            const scaleX = containerWidth / activityProps.defaultWidth;
            const scaleY = containerHeight / activityProps.defaultHeight;
            if (scaleX > scaleY) {
                containerWidth = activityProps.defaultWidth * scaleY;
            } else if (scaleX < scaleY) {
                containerHeight = activityProps.defaultHeight * scaleX;
            }
            const scale = Math.min(scaleX, scaleY);
            stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    });

    const LoadImage = (props) => {
        const [image, status] = useImage(props.imagePath);
        if (status !== "loaded") return null;
        let imageWidth = (props.width ? props.width : image.width);
        let imageHeight = (props.height ? props.height : image.height);
        let x = props.x;
        let y = props.y;
        if (props.center) {
            x = (activityProps.defaultWidth - imageWidth) / 2;
            y = (activityProps.defaultHeight - imageHeight) / 2;
        }
        if (props.x === 'center') {
            x = (activityProps.defaultWidth - imageWidth) / 2;
        }
        return <Image
            id={props.id || ''}
            name={props.name || ''}
            image={image}
            x={x}
            y={y}
            width={props.width}
            height={props.height}
            stroke={props.stroke}
            strokeWidth={props.strokeWidth}
            cornerRadius={props.cornerRadius}
            originalWidth={image.width}
            originalHeight={image.height}
            onMouseOver={props.events}
            onMouseLeave={props.events}
            onMouseDown={props.events}
            onMouseUp={props.events}
            onTouchStart={props.events}
            onTouchEnd={props.events}
            visible={props.visible}
        />;
    };

    /**
     * 오디오 파일 관련
     * 주료 이벤트: 재생 가능 할때, 자동 재생 시작
     *             재생이 끝났을때, 다음 파일 재생
     */
    const onCanPlayThrough = (index) => {
        audioRef[index].current.volume = activityProps.defaultVolume;
        if (index === 0) {
            startPlaySentence();
        }
    };
    const onEnded = (index) => {
        const nextIndex = index + 1;
        if (nextIndex < categorySelected.sentence.length && !isPlaySentenceStopped) {
            playSentence(nextIndex);

            if (audioRef[nextIndex] &&
                audioRef[nextIndex].current.error !== null &&
                audioRef[nextIndex].current.error.message === 'MEDIA_ELEMENT_ERROR: Empty src attribute') {
                stopPlaySentence();
            }
        } else {
            stopPlaySentence();
        }
    };
    const LoadAudios = () => {
        categorySelected.sentence.forEach(() => {
            audioRef.push(React.createRef());
        });
        return (
            <>
                {categorySelected.sentence.map((item, index) => (
                    <audio
                        key={index}
                        ref={audioRef[index]}
                        src={(item.audio ? mediaPath + item.audio : '')}
                        onCanPlayThrough={() => onCanPlayThrough(index)}
                        onEnded={() => onEnded(index)}
                    />
                ))}
            </>
        );
    };
    
    /**
     * 말풍선 표시 관련
     */
    let isPlaySentenceStopped = false;
    const startPlaySentence = () => {
        isPlaySentenceStopped = false;

        playSentence(0);

        playIconEventsTimer.start();
    };
    let playSentenceIndex = -1;
    const playSentence = (index) => {
        playSentenceIndex = index;
        if (audioRef[playSentenceIndex].current.error === null) {
            audioRef[playSentenceIndex].current.play();
        }

        stageRef.current.find(node => node.name() === 'sentenceGroup').each((node, index) => {
            if (index <= playSentenceIndex) node.visible(true);
        });

        const sentenceTotalHeight = stageRef.current.findOne('#sentenceParentLayer').getClientRect().height + scrollBarPaddingTop;
        if (sentenceTotalHeight > stageRef.current.height()) {
            stageRef.current.findOne('#scrollBarParentLayer').visible(true);
            stageRef.current.findOne('#sentenceParentLayer').on('wheel', handleWheelDrag);
            stageRef.current.findOne('#sentenceParentLayer').on('touchstart', handleTouch);
            stageRef.current.findOne('#sentenceParentLayer').on('touchmove', handleTouch);

            stageRef.current.findOne('#sentenceParentLayer').y(stageRef.current.height() - sentenceTotalHeight);

            const availableHeight = stageRef.current.height() - scrollBarHeight;
            const vy = (stageRef.current.findOne('#sentenceParentLayer').y() / (-sentenceTotalHeight + stageRef.current.height())) * availableHeight + scrollBarPaddingTop;
            stageRef.current.findOne('#scrollBarParentLayer').findOne('Rect').y(vy);
            stageRef.current.batchDraw();
        } else {
            stageRef.current.findOne('#scrollBarParentLayer').visible(false);
            stageRef.current.findOne('#sentenceParentLayer').on('wheel', () => { });
            stageRef.current.findOne('#sentenceParentLayer').on('touchstart', () => { });
            stageRef.current.findOne('#sentenceParentLayer').on('touchmove', () => { });

            stageRef.current.findOne('#sentenceParentLayer').y(0);
            stageRef.current.findOne('#scrollBarParentLayer').findOne('Rect').y(50);
            stageRef.current.batchDraw();
        }

        stageRef.current.batchDraw();
    };
    let firstTime = true;
    const stopPlaySentence = () => {
        isPlaySentenceStopped = true;

        stageRef.current.find(node => { return node.name() === 'sentenceGroup' }).each((node, index) => {
            var sentence = categorySelected.sentence[index];
            if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                node.findOne('Rect').fill('#fff').strokeWidth(boxBorder);
            } else {
                node.findOne('Rect').fill('#eee').strokeWidth(0);
            }
        });

        stageRef.current.find('#playIcon').image('#playIconNormalImage');
        stageRef.current.batchDraw();

        stageRef.current.find(node => node.name() === 'sentenceGroup').each(node => {
            if (node.getAttr('record')) {
                node.on('mouseover', sentenceEvents)
                    .on('mouseleave', sentenceEvents)
                    .on('mouseup', sentenceEvents)
                    .on('touchend', sentenceEvents);
            }
        });

        playIconEventsTimer.stop();

        setNextButtonEnabled();

        const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
        const major = version && version[1] ? version[1] : "";

        if (firstTime && !(major == '14')){
            openAlertModal({ content: "Click the speech bubble and record the sentences." });
            firstTime = false;
        }
    };
    let recordingIndex = -1;
    const boxBorder = 5;
    const sentenceEvents = (e) => {
        let group = e.target.getParent();
        let rect = group.findOne('Rect');

        if (e.type === "mouseover") {
            stageRef.current.container().style.cursor = "pointer";
            rect.fill('#fff').strokeWidth(boxBorder);
            stageRef.current.batchDraw();
        } else if (e.type === "mouseleave") {
            stageRef.current.container().style.cursor = "default";

            stageRef.current.find(node => { return node.name() === 'sentenceGroup' }).each((node, index) => {
                if (node === group) {
                    if (recordingIndex !== index) {
                        var sentence = categorySelected.sentence[index];
                        if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                        } else {
                            rect.fill('#eee').strokeWidth(0);
                            stageRef.current.batchDraw();
                        }
                    }
                }
            });
        } else if (e.type === "mouseup" || e.type === "touchend") {
            stageRef.current.find(node => { return node.name() === 'sentenceGroup' }).each((node, index) => {
                if (node === group) {
                    recordingIndex = index;
                    node.findOne('Rect').fill('#fff').strokeWidth(boxBorder);
                } else {
                    var sentence = categorySelected.sentence[index];
                    if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                        node.findOne('Rect').fill('#fff').strokeWidth(boxBorder);
                    } else {
                        node.findOne('Rect').fill('#eee').strokeWidth(0);
                    }
                }

            });
            stageRef.current.batchDraw();
            
            /**
             * 하단 모달 오픈
             * 현재 인덱스 값은 @param {recordingIndex}
             */
            answerOpen(recordingIndex);
        }
    };

    const onDragMove = (e) => {
        const scale = stageRef.current.getScaleX();
        const sentenceTotalHeight = stageRef.current.findOne('#sentenceParentLayer').getClientRect().height + scrollBarPaddingTop;
        const availableHeight = (stageRef.current.height()) - scrollBarPaddingTop - scrollBarHeight;
        const delta = (e.target.y() - scrollBarPaddingTop) / availableHeight;
        stageRef.current.findOne('#sentenceParentLayer').y(-(sentenceTotalHeight - stageRef.current.height()) * delta);
        stageRef.current.batchDraw();
    };
    const handleWheelDrag = (e) => {
        e.evt.preventDefault();

        const sentenceTotalHeight = stageRef.current.findOne('#sentenceParentLayer').getClientRect().height + scrollBarPaddingTop;
        const dy = e.evt.deltaY / 5;
        const minY = -(sentenceTotalHeight - stageRef.current.height() + scrollBarPaddingTop);
        const maxY = 0;
        const y = Math.max(minY, Math.min(stageRef.current.findOne('#sentenceParentLayer').y() - dy, maxY));
        stageRef.current.findOne('#sentenceParentLayer').y(y);

        const availableHeight = stageRef.current.height() - scrollBarHeight;
        const vy = (stageRef.current.findOne('#sentenceParentLayer').y() / (-sentenceTotalHeight + stageRef.current.height())) * availableHeight + scrollBarPaddingTop;
        stageRef.current.findOne('#scrollBarParentLayer').findOne('Rect').y(vy);
        stageRef.current.batchDraw();
    };
    let touchStartPageY = 0;
    const handleTouch = (e) => {
        e.evt.preventDefault();

        if (e.evt.type === 'touchstart') {
            touchStartPageY = e.evt.changedTouches[0].pageY;
        } else if (e.evt.type === 'touchmove') {
            const touchEndPageY = e.evt.changedTouches[0].pageY;

            const sentenceTotalHeight = stageRef.current.findOne('#sentenceParentLayer').getClientRect().height + scrollBarPaddingTop;
            const dy = (touchStartPageY - touchEndPageY) / 10;
            const minY = -(sentenceTotalHeight - stageRef.current.height());
            const maxY = 0;
            const y = Math.max(minY, Math.min(stageRef.current.findOne('#sentenceParentLayer').y() - dy, maxY));
            stageRef.current.findOne('#sentenceParentLayer').y(y);

            const availableHeight = stageRef.current.height() - scrollBarHeight;
            const vy = (stageRef.current.findOne('#sentenceParentLayer').y() / (-sentenceTotalHeight + stageRef.current.height())) * availableHeight + scrollBarPaddingTop;
            stageRef.current.findOne('#scrollBarParentLayer').findOne('Rect').y(vy);
            stageRef.current.batchDraw();
        }
    };

    /**
     * 하단 확대 아이콘 관련
     */
    const scaleIcon = require('../../images/egg-studio/btn_scale.png').default;
    const scaleMinusIcon = require('../../images/egg-studio/contract.png').default;
    const scaleIconEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";
        } else if (e.type === "mousedown" || e.type === "touchstart") {
        } else if (e.type === "mouseup" || e.type === "touchend") {
            const mainLeftImg = stageRef.current.findOne('#mainLeftImg');
            const originalWidth = stageRef.current.width() / stageRef.current.scaleX() - 100;
            const originalHeight = originalWidth / mainLeftImgRatio;
            mainLeftImg.width(originalWidth).height(originalHeight).y(0).on('click', mainLeftImgClick).on('wheel', mainLeftImgWheel);

            stageRef.current.findOne('#mainLeftImgScrollBarRect').y(0).visible(true);

            stageRef.current.findOne('#scaleIcon').visible(false);
            stageRef.current.findOne('#scaleMinusIcon').visible(true);

            stageRef.current.batchDraw();
        }
    };
    const scaleMinusIconEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";
        } else if (e.type === "mousedown" || e.type === "touchstart") {
        } else if (e.type === "mouseup" || e.type === "touchend") {
            resotreMainLeftImg();
        }
    };
    const mainLeftImgClick = (e) => { resotreMainLeftImg(); };
    const resotreMainLeftImg = () => {
        const mainLeftImg = stageRef.current.findOne('#mainLeftImg');
        const originalWidth = mainLeftImgHeight * mainLeftImgRatio;
        const originalHeight = mainLeftImgHeight;
        mainLeftImg.width(originalWidth).height(originalHeight).y(50).off('click').off('wheel');

        stageRef.current.findOne('#mainLeftImgScrollBarRect').y(50).visible(false);

        stageRef.current.findOne('#scaleIcon').visible(true);
        stageRef.current.findOne('#scaleMinusIcon').visible(false);

        stageRef.current.batchDraw();
    };
    const mainLeftImgScrollBarRectDragMove = (e) => {
        const sentenceTotalHeight = stageRef.current.findOne('#mainLeftImg').getClientRect().height + scrollBarPaddingTop;
        const availableHeight = stageRef.current.height() - scrollBarPaddingTop - scrollBarHeight;
        const delta = (e.target.y() - scrollBarPaddingTop) / availableHeight;
        stageRef.current.findOne('#mainLeftImg').y(-(sentenceTotalHeight - stageRef.current.height()) * delta);
        stageRef.current.batchDraw();
    };
    const mainLeftImgWheel = (e) => {
        e.evt.preventDefault();

        const sentenceTotalHeight = stageRef.current.findOne('#mainLeftImg').getClientRect().height + scrollBarPaddingTop;
        const dy = e.evt.deltaY / 5;
        const minY = -(sentenceTotalHeight - stageRef.current.height() + scrollBarPaddingTop);
        const maxY = 0;
        const y = Math.max(minY, Math.min(stageRef.current.findOne('#mainLeftImg').y() - dy, maxY));
        stageRef.current.findOne('#mainLeftImg').y(y);

        const availableHeight = stageRef.current.height() - scrollBarHeight;
        const vy = (stageRef.current.findOne('#mainLeftImg').y() / (-sentenceTotalHeight + stageRef.current.height())) * availableHeight + scrollBarPaddingTop;
        stageRef.current.findOne('#mainLeftImgScrollBarRect').y(vy);
        stageRef.current.batchDraw();
    };

    /**
     * 하단 재생 아이콘 관련
     * 주요 이벤트: 재생중이 아닐때, 아이콘을 누르면, 타이머 작동 및 오디오 재생 그리고 말풍선 표시
     */
    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];
    // const playIconImage = [useImage(playIcon[0][0])[0], useImage(playIcon[0][1])[0], useImage(playIcon[0][2])[0]];
    // const [playIconHoverImage, playIconHoverStatus] = useImage(playIcon[1]);
    // const [playIconNormalImage, playIconNormalStatus] = useImage(playIcon[2]);
    // const [playIconPushImage, playIconPushStatus] = useImage(playIcon[3]);

    const aniImageIndex = useRef(0);

    const playIconEventsTimer = new TaskTimer(500);
    playIconEventsTimer.add([
        {
            id: 'playIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                switch (aniImageIndex.current) {
                    case 0 :
                        stageRef.current.findOne('#playIcon0').visible(true);
                        stageRef.current.findOne('#playIcon1').visible(false);
                        stageRef.current.findOne('#playIcon2').visible(false);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                    case 1 :
                        stageRef.current.findOne('#playIcon0').visible(false);
                        stageRef.current.findOne('#playIcon1').visible(true);
                        stageRef.current.findOne('#playIcon2').visible(false);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                    case 2:
                        stageRef.current.findOne('#playIcon0').visible(false);
                        stageRef.current.findOne('#playIcon1').visible(false);
                        stageRef.current.findOne('#playIcon2').visible(true);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                }
                stageRef.current.batchDraw();
                aniImageIndex.current = aniImageIndex.current + 1;
                if(aniImageIndex.current > 2) aniImageIndex.current = 0;
            }
        }
    ]).stop();


    const drawPlayStopImage = () => {

        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconHoverImage').visible(false);
        stageRef.current.findOne('#playIconPushImage').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(true);
        stageRef.current.batchDraw();

    }

    const drawPlayIconHoverImage = () => {
        stageRef.current.findOne('#playIconHoverImage').visible(true);
        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(false);
        stageRef.current.findOne('#playIconPushImage').visible(false);
        stageRef.current.batchDraw();

    }

    const drawPlayIconPushImage = () =>{
        stageRef.current.findOne('#playIconPushImage').visible(true);
        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(false);
        stageRef.current.findOne('#playIconHoverImage').visible(false);
        stageRef.current.batchDraw();

    }


    const playIconEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";
            if (isPlaySentenceStopped) {
                drawPlayIconHoverImage();
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (isPlaySentenceStopped) {
                drawPlayStopImage();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (isPlaySentenceStopped) {
                drawPlayIconPushImage()
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isPlaySentenceStopped) {
                startPlaySentence();
            } else {
                const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
                const major = version && version[1] ? version[1] : "";

                if (major == '14') {
                    startPlaySentence();
                } else {
                    stopPlaySentence();
                }

            }
        }
    }
    let beforeLineNum = 0;
    const mainLeftImgHeight = 400;
    const mainLeftImgRatio = 400 / 510;
    const textHeight = 85;
    const scrollBarPaddingTop = 0;
    const scrollBarHeight = 200;

    return (
        <div style={{ width: '100%', height:'calc(100% - 40px)', margin:'20px 0' }} ref={containerRef}>
            <LoadAudios />
            <Stage
                width={activityProps.defaultWidth}
                height={activityProps.defaultHeight}
                ref={stageRef}
            >
                <Layer id={'sentenceParentLayer'}>
                    <Group x={400} y={0}>
                        {categorySelected.sentence.map((item, index) => {
                            if (index > 0) {
                                beforeLineNum += (categorySelected.sentence[index - 1].text.match(/\n/g) || []).length + 1;
                            }
                            let lineNum = (item.text.match(/\n/g) || []).length + 1
                            return (
                                <Group
                                    key={index}
                                    name={'sentenceGroup'}
                                    x={0}
                                    y={textHeight * beforeLineNum * 1.1}
                                    record={item.record}
                                    visible={false}
                                >
                                    <Rect
                                        x={0}
                                        y={0}
                                        width={740}
                                        height={textHeight * lineNum}
                                        stroke={'#ff8484'}
                                        strokeWidth={0}
                                        fill={'#eee'}
                                        cornerRadius={10}
                                    />
                                    <LoadImage imagePath={activityProps.characterMediaPath + item.icon} x={20} y={20} />
                                    <Text
                                        x={90}
                                        y={15}
                                        text={item.text}
                                        fontSize={activityProps.textFontSize}
                                        fill={activityProps.textColor}
                                        fontFamily={activityProps.textFontFamily}
                                        lineHeight={activityProps.textLineHeight}
                                    />
                                    {item.record && <LoadImage imagePath={require('../../images/egg-studio/icon_studio_record_on.png').default} x={740 - 40} y={textHeight * lineNum - 60} />}
                                </Group>
                            );
                        })}
                    </Group>
                </Layer>
                <Layer id={'scrollBarParentLayer'} visible={false}>
                    <Rect
                        x={1150} y={0}
                        width={15}
                        height={scrollBarHeight}
                        fill={'grey'}
                        draggable={true}
                        dragBoundFunc={(pos) => {
                            pos.x = stageRef.current.findOne('#scrollBarParentLayer').findOne('Rect').getAbsolutePosition().x;
                            pos.y = Math.max(
                                Math.min(
                                    pos.y,
                                    stageRef.current.height() - scrollBarHeight
                                ),
                                scrollBarPaddingTop
                            );
                            return pos;
                        }}
                        onDragMove={onDragMove}
                    />
                </Layer>
                <Layer>
                    {/*<LoadImage imagePath={playIcon[2]} x={160} y={480} id={'playIcon'} events={playIconEvents} />*/}
                    <LoadImage imagePath={playIcon[0][0]} x={160} y={480} id={'playIcon0'} visible={true} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[0][1]} x={160} y={480} id={'playIcon1'} visible={true} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[0][2]} x={160} y={480} id={'playIcon2'} visible={true} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[1]} x={160} y={480} id={'playIconHoverImage'} visible={false} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[3]} x={160} y={480} id={'playIconPushImage'} visible={false} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[2]} x={160} y={480} id={'playIconNormalImage'} visible={false} events={playIconEvents}></LoadImage>


                    <LoadImage
                        id={'mainLeftImg'}
                        imagePath={mediaPath + categorySelected.image}
                        x={50}
                        y={50}
                        width={mainLeftImgHeight * mainLeftImgRatio}
                        height={mainLeftImgHeight}
                    />
                    <LoadImage imagePath={scaleMinusIcon} x={1100} y={0} id={'scaleMinusIcon'} visible={false} events={scaleMinusIconEvents} />
                    <LoadImage imagePath={scaleIcon} x={mainLeftImgHeight * mainLeftImgRatio - 20} y={mainLeftImgHeight - 20} id={'scaleIcon'} events={scaleIconEvents} />
                    <Group x={1170} y={0}>
                        <Rect
                            id={'mainLeftImgScrollBarRect'}
                            width={15}
                            height={scrollBarHeight}
                            fill={'grey'}
                            visible={false}
                            draggable={true}
                            dragBoundFunc={(pos) => {
                                pos.x = stageRef.current.findOne('#mainLeftImgScrollBarRect').getAbsolutePosition().x;
                                pos.y = Math.max(
                                    Math.min(pos.y, stageRef.current.height() - scrollBarHeight),
                                    scrollBarPaddingTop
                                );
                                return pos;
                            }}
                            onDragMove={mainLeftImgScrollBarRectDragMove}
                        />
                    </Group>
                </Layer>
            </Stage>
        </div>
    );
});

export default EggStudioShadowing;