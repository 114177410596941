import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import styled from "styled-components";

const RecordBar = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = window.location.origin + props.mediaPath + '/';
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const recordRef = useRef();

    const [recordState, setRecordState] = useState({
        // percent : 100,
        // recordingText: null,

        recordingNum: -1,
        // recordingButton: false
    });

    useImperativeHandle(ref, () => ({
        recordbarOpen(recordingNum) {
            recordRef.current.style.display = 'block';
            setRecordState({
                // percent : 100,
                recordingNum: recordingNum,
                // recordingText: recordState.recordingText,
                // recordingButton: recordState.recordingButton
            });
        },
        recordbarClose() {
            recordRef.current.style.display = 'none';
            setRecordState({
                // percent : 100,
                recordingNum: -1,
                // recordingButton: false
            });
        }
    }));
    // console.log('recordState.recordingText',  recordState.recordingText);


    return (
        // <div className="subtitle record" ref={recordRef} >
        <BarWrapper onClick={props.handleClickBar} isSelected={props.isSelected} ref={recordRef} >
            <BarInfo
            // onClick={props.handleClickBar} 
            // isSelected={props.isSelected} 
            >
                {/* <Percent>{recordState.percent}</Percent>   */}
                {recordState.recordingNum > -1 ? <ItemValue className='text'>{categorySelected.sentence[recordState.recordingNum].text}</ItemValue> : null}
                {/* <Count>{props.count}</Count>  */}
            </BarInfo>
            <BarGraph
                second={recordState.recordingNum > -1 ? categorySelected.sentence[recordState.recordingNum].recordBarTime : 0}
            // isSelected={props.isSelected} 
            ></BarGraph>
        </BarWrapper>
    )
});

export default RecordBar;

const BarWrapper = styled.div`
        background: rgba(255, 255, 255, 0.7);
        padding: 20px 35px;
        position: absolute;
        min-height: 6.9vw;
        width: 60%;
        z-index: 1;
        display: none;
        top: 33.3vw;
        background: ${({ isSelected }) => isSelected ? '#dddddd' : '#f3f3f3'};
`

const BarInfo = styled.div`
width: 100%;
display: flex;
z-Index: 2;
//1228_hhj 수정
position: absolute;
left: 0;
top: 0;
height: 100%;
//1228_hhj 수정
`

const Percent = styled.span`
text-align: right;
min-width: 70px;
flex: 0 0 auto;
`

const ItemValue = styled.span`
padding-left: 60px;
flex: 1 1 0%;
`

const Count = styled.span`
padding-right: 20px;
flex: 0 0  auto;
`

const BarGraph = styled.div`
positon: absolute;
left: 0;
top: 0;
width: 100%;
transform: 1;
transform-origin: center left;
transition: all 1s ease; //transition: transform  1s ease;
height: 100%;
z-index: 1;
`