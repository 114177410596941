import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import styled from "styled-components";
import Answer from "../../components/activity-answer-minisitcom/Answer";
import MiniSitcom from "../../components/activity/MiniSitcom";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import AlertModal from "../../components/modal/AlertModal";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import MiniSitcomResult from "../../components/result/MiniSitcomResult";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import RouteBarIcon from "../../images/activity-sitcom/route-bar-icon.png";
import BackgroundPattern from "../../images/background-pattern.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";


import PopReply from "../../images/pop-default/restart-btn.png";
import PopHome from "../../images/pop-default/home-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const MiniSitcomBlock = styled.div`
  .konvajs-content {
    margin: auto;
  }
`;

const Index = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(1);
  console.log('page_orgActList', orgActList);
  console.log('page_actList', actList);
  console.log('page_categoryList', categoryList);
  console.log('page_categorySelected', categorySelected);
  console.log('page_setNum', setNum);
  console.log('page_maxSetNum', maxSetNum);
  console.log('page_minCategoryNum', minCategoryNum);
  console.log('page_maxCategoryNum', maxCategoryNum);
  let minRecordCategoryNum = 2;

  const errorModalRef = useRef();
  const routeLearningHome = () => {
    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
      case 'HHT1':
        routerName = '/hh1/learning-home';
        break;
      case 'HHT2':
        routerName = '/hh2/learning-home';
        break;
      case 'CULTURE':
        routerName = '/culture/learning-home';
        break;
      default:
        break;
    }
    history.push(routerName);
  };
  useEffect(() => {
    const fetchData = async (actContentData) => {
      let filePathReplaced = actInfo.file_path.replace(`${apiHost}`, '');
      console.log('filePathReplaced', filePathReplaced);
      await fetch(filePathReplaced)
        .then(res => res.json())
        .then(res => {
          console.log("data_minisitcomindex.jsx_res >>>", res);
          setOrgActList(JSON.parse(JSON.stringify(res)));
          console.log("data_minisitcomindex.jsx_setOrgActList(JSON.parse(JSON.stringify(res)))", JSON.parse(JSON.stringify(res)));

          try {
            const stdAnswer = JSON.parse(actContentData.stdAnswer);
            console.log('data_stdAnswer1', stdAnswer);

            res.map((res_item, res_index) => {
              res_item.category.map((category_item, category_index) => {
                if (category_item.name === 'Speak Sentence') {
                  category_item.sentence.map((sentence_item, sentence_index) => {
                    if (sentence_item.quiz) {
                      sentence_item.quiz.list.map((quizList_item, quizList_index) => {
                        if (stdAnswer.length > res_index &&
                          stdAnswer[res_index].category.length > category_index &&
                          stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                          stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list.length > quizList_index) {
                          var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list[quizList_index].choiceSelected;
                          if (choiceSelected) {
                            quizList_item.choiceSelected = choiceSelected;
                          }
                        }
                      });
                    }
                    if (stdAnswer.length > res_index &&
                      stdAnswer[res_index].category.length > category_index &&
                      stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                      var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                      console.log('data_fileUrlPath', fileUrlPath);
                      console.log('data_res1', res);

                      if (fileUrlPath) {
                        sentence_item.fileUrlPath = fileUrlPath;
                        console.log('data_res2', res);
                        console.log('data_sentence_item.fileUrlPath', sentence_item.fileUrlPath);

                        var player = new Audio(fileUrlPath);
                        if (player) {
                          sentence_item.player = player;
                          console.log('data_res3', res);
                          console.log('data_stdAnswer2', stdAnswer);
                          console.log('data_player', sentence_item.player);
                        }
                      }
                    }
                  });
                }
              });
            });
          } catch (error) {
            console.log('Incorrect student answer data, ', error);
          }

          setActList(res);
          console.log('data_useEffect_res', res);

          const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
          console.log('data_useEffect_maxSetNum', maxSetNum);
          setMaxSetNum(maxSetNum);

          const categoryList = res.find(item => item.set === setNum).category;
          console.log('data_useEffect_categoryList', categoryList);
          setCategoryList(categoryList);

          categoryList.sort((a, b) => a.order - b.order).forEach((value, index) => {
            if (index === 0) {
              setMinCategoryNum(value.order);
              setCategorySelected(categoryList[value.order]);
            } else if (index === categoryList.length - 1) {
              setMaxCategoryNum(value.order);
            }
          });
        })
        .catch(error => {
          console.error(error);
          errorModalRef.current.openModal();
        });
    };

    console.log('lessonId', lessonInfo.lessonId);

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);
  console.log('data_useEffectOUT_categoryList', categoryList);

  const [guideFinished, setGuideFinished] = useState(false);

  useEffect(() => {
    if (actList.length > 0) {
      const categoryList = actList.find(item => item.set === setNum).category;
      setCategoryList(categoryList);

      categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
        if (index === 0) {
          setMinCategoryNum(item.order);
          setCategorySelected(categoryList[item.order]);
        } else if (index === categoryList.length - 1) {
          setMaxCategoryNum(item.order);
        }
      });
    }
  }, [setNum]);

  const miniSitcomRef = useRef();

  const closeActivityModalRef = useRef();
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  };

  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  };

  const recordTimeOverModalRef = useRef();
  const alertModalRef = useRef();
  const openAlertModal = (text) => {
    if (setNum === 1 && categorySelected.order === minRecordCategoryNum) {
      alertModalRef.current.openModal(text);
      setTimeout(() => { alertModalRef.current.closeModal(); }, 4000);
    }
  };

  const answerRef = useRef();
  const answerOpen = (recordingIndex) => {
    answerRef.current.answerOpen(recordingIndex);
  };
  const answerClose = () => {
    answerRef.current.answerClose();
  };


  const prevCategory = () => {
    answerClose();
    if (categorySelected.order > minCategoryNum) {
      setCategorySelected(categoryList[categorySelected.order - 1]);
    }
  };
  const nextCategory = () => {
    answerClose();
    if (categorySelected.name === "Main Sentence" || categorySelected.name === "Key Expression" || categorySelected.name === "Video") {
      if (categorySelected.order < maxCategoryNum) {
        setCategorySelected(categoryList[categorySelected.order + 1]);
      }
    } else {
      console.log('data_categorySelected.sentence', categorySelected.sentence);

      let newRecordExists = false;
      categorySelected.sentence.forEach(item => {
        if (item.file && item.file.size && item.file.size > 0) {
          newRecordExists = true;
          return false;
        }
      });
      console.log('data_newRecordExists', newRecordExists);

      if (newRecordExists) {
        /**
         * 학생 Act 학습 정보 저장 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
         */
        async function callApiAct() {
          console.log('data_callApiAct()INSIDE_JSON.stringify(orgActList)', JSON.stringify(orgActList));
          console.log('data_callApiAct()INSIDE_JSON.stringify(actList)', JSON.stringify(actList));
          const data = await API('post', 'act', {}, {
            oCode: userSelector.info.oCode,
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            level: actInfo.level,
            episodeId: episodeInfo.episodeId,
            episodeCode: episodeInfo.episodeCode,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actStatus: (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus),
            actPoint: 0,
            actCurrentPage: setNum,
            question: JSON.stringify(orgActList),
            answer: JSON.stringify(actList)
          }).catch((error) => {
            console.error(error);
          });

          console.log(data);

          userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .actStatus = (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus);

          dispatch(userStore.save(userSelector.info));

          console.log('data_actInfo.actStatus', actInfo.actStatus);


          let nextCategory;
          if (categorySelected.order < maxCategoryNum) {
            nextCategory = categoryList[categorySelected.order + 1];
          } else {
            nextCategory = {
              ...categoryList[2],
              name: 'result',
              first: false
            };
          }
          setCategorySelected(nextCategory);
        }

        /**
         * 학생 파일 파일 업로드 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
         */
        async function callApiActFile() {
          let index = 0;
          for (const item of categorySelected.sentence) {
            if (item.file && item.file.size && item.file.size > 0) {
              let form = new FormData();
              form.append('file', item.file);
              form.append('data', JSON.stringify({
                productId: userSelector.info.productId,
                studentId: userSelector.info.studentId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                episodeId: episodeInfo.episodeId,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId,
                actType: actInfo.actType,
                actPage: setNum,
                studentSoundNo: index + 1
              }));

              console.log('data_actInfo.actStatus', actInfo.actStatus);

              const data = await API('post', 'act/file', {}, form).catch((error) => {
                console.error(error);
              });

              console.log("==========================");
              console.log("학생 파일 파일 업로드이 완료 되었습니다.");
              console.log(data);
              console.log("==========================");

              if (!data.data || !data.data.fileUrlPath) {
                console.error("==========================");
                console.error("녹음 파일 경로 반환이 되지 않았습니다.");
                console.error("==========================");
              }

              categorySelected.sentence[index].fileUrlPath = data.data.fileUrlPath;
            }
            index++;
          }
          callApiAct();
        }
        callApiActFile();
      } else {
        let nextCategory;
        if (categorySelected.order < maxCategoryNum) {
          nextCategory = categoryList[categorySelected.order + 1];
        } else {
          nextCategory = {
            ...categoryList[2],
            name: 'result',
            first: true
          };
        }
        setCategorySelected(nextCategory);
      }
    }
  };

  const btnPrevRef = useRef();
  const btnNextRef = useRef();
  const nextButtonRef = useRef();
  const setNextButtonEnabled = () => {
    if (btnNextRef.current) return;

    if (categorySelected.name === "Key Expression" || categorySelected.name === "Video") {
    } else {
      if (categorySelected.sentence) {
        let errorIndex = -1;
        for (var index = 0; index < categorySelected.sentence.length; index++) {
          let sentence = categorySelected.sentence[index];
          if (sentence.audio && !sentence.noRecord && ((!sentence.file || !sentence.file.size || sentence.file.size < 0) && !sentence.fileUrlPath)) {
            errorIndex = index;
            break;
          }
        }
        if (errorIndex > -1) {


        } else {
          nextButtonRef.current.children[0].src = btnNext[1];
          nextButtonRef.current.className = 'right-button';
          nextButtonRef.current.onmouseenter = () => { nextButtonRef.current.src = btnNext[0]; };
          nextButtonRef.current.onmouseleave = () => { nextButtonRef.current.src = btnNext[1]; };
          nextButtonRef.current.onmousedown = () => { nextButtonRef.current.src = btnNext[2]; };
          nextButtonRef.current.ontouchstart = () => { nextButtonRef.current.src = btnNext[2]; };
          nextButtonRef.current.onmouseup = () => { nextButtonRef.current.src = btnNext[1]; nextCategory(); };
          nextButtonRef.current.ontouchend = () => { nextButtonRef.current.src = btnNext[1]; nextCategory(); };
        }
      } else {

      }
    }
  };
  const onVideoEnded = () => {
    if (btnNextRef.current) return;

    nextButtonRef.current.children[0].src = btnNext[1];
    nextButtonRef.current.className = 'right-button';
    nextButtonRef.current.onmouseenter = () => { nextButtonRef.current.src = btnNext[0]; };
    nextButtonRef.current.onmouseleave = () => { nextButtonRef.current.src = btnNext[1]; };
    nextButtonRef.current.onmousedown = () => { nextButtonRef.current.src = btnNext[2]; };
    nextButtonRef.current.ontouchstart = () => { nextButtonRef.current.src = btnNext[2]; };
    nextButtonRef.current.onmouseup = () => { nextButtonRef.current.src = btnNext[1]; nextCategory(); };
    nextButtonRef.current.ontouchend = () => { nextButtonRef.current.src = btnNext[1]; nextCategory(); };
  };
  const btnPre = [
    require('../../images/activity-button/btn_pre_h.png').default,
    require('../../images/activity-button/btn_pre_n.png').default,
    require('../../images/activity-button/btn_pre_p.png').default,
    require('../../images/activity-button/btn_pre_d.png').default,
  ];
  const btnNext = [
    require('../../images/activity-button/btn_next_h.png').default,
    require('../../images/activity-button/btn_next_n.png').default,
    require('../../images/activity-button/btn_next_p.png').default,
    require('../../images/activity-button/btn_next_d.png').default,
  ];

  const RenderActivityArea = () => {
    if (!guideFinished) {
      return <Guide setGuideFinished={setGuideFinished} />
    } else {
      if (categorySelected.name === 'Video') {
        return (
          <div className="box-wrap d-flex justify-content-center align-items-center" onContextMenu={(e) => e.preventDefault()}>
            <video
              autoPlay
              controls
              width={'80%'}
              height={'80%'}
              src={actInfo.media_path.replace(`${apiHost}`, '') + '/' + categorySelected.video}
              controlsList={'nodownload'}
              disablePictureInPicture
              onEnded={onVideoEnded}
            />

            <>
              <BrowserView>
                <div className="left-button"
                  onMouseEnter={() => { btnPrevRef.current.src = btnPre[0]; }}
                  onMouseLeave={() => { btnPrevRef.current.src = btnPre[1]; }}
                  onMouseDown={() => { btnPrevRef.current.src = btnPre[2]; }}
                  onMouseUp={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
                >
                  <img src={btnPre[1]} alt="" ref={btnPrevRef} />
                </div>
              </BrowserView>
              <MobileView>
                <div className="left-button"
                  onTouchStart={() => { btnPrevRef.current.src = btnPre[2]; }}
                  onTouchEnd={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
                >
                  <img src={btnPre[1]} alt="" ref={btnPrevRef} />
                </div>
              </MobileView>
            </>
            {
              (actInfo.actStatus === 'Progress' || actInfo.actStatus === 'Completed') ?
                <>
                  <BrowserView>
                    <div className="right-button"
                      onMouseEnter={() => { btnNextRef.current.src = btnNext[0]; }}
                      onMouseLeave={() => { btnNextRef.current.src = btnNext[1]; }}
                      onMouseDown={() => { btnNextRef.current.src = btnNext[2]; }}
                      onMouseUp={() => { btnNextRef.current.src = btnNext[1]; nextCategory(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </BrowserView>
                  <MobileView>
                    <div className="right-button"
                      onTouchStart={() => { btnNextRef.current.src = btnNext[2]; }}
                      onTouchEnd={() => { btnNextRef.current.src = btnNext[1]; nextCategory(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </MobileView>
                </>
                :
                <div className="right-button disabled" ref={nextButtonRef}>
                  <img src={btnNext[3]} alt="" />
                </div>
            }
          </div>
        );
      } else if (categorySelected.name === 'finished') {
        return (
          <div className="box-wrap d-flex justify-content-center align-items-center">
            <Congratulations categorySelected={categorySelected} />
          </div>
        );
      } else if (categorySelected.name === 'result') {
        const btnAgain = [
          require('../../images/common/btn_again_h.png').default,
          require('../../images/common/btn_again_n.png').default,
          require('../../images/common/btn_again_p.png').default,
        ];
        const btnNext = [
          require('../../images/common/btn_next_h.png').default,
          require('../../images/common/btn_next_n.png').default,
          require('../../images/common/btn_next_p.png').default,
        ];

        const LoadBtn = (props) => {
          const btnEvents = (e) => {
            let btns;
            if (btnAgain.findIndex(item => item === e.target.src) > -1) {
              btns = btnAgain;
            } else if (btnNext.findIndex(item => item === e.target.src) > -1) {
              btns = btnNext;
            } else {
              return;
            }

            if (e.type === "mouseover") {
              e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
              e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
              e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
              e.target.src = btns[0];

              if (btns === btnAgain) {
                setCategorySelected(categoryList[categorySelected.order]);
              } else if (btns === btnNext) {
                console.log('result');
                if (categorySelected.order < maxCategoryNum) {
                  let nextCategory = {
                    ...categoryList[categorySelected.order + 1],
                    name: 'result',
                  };
                  setCategorySelected(nextCategory);
                } else {
                  if (setNum === maxSetNum) {
                    /**
                     * 학생 Act 학습 정보 저장 API
                     * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
                     */
                    async function callApiAct() {
                      if (actInfo.actStatus !== 'Completed') {
                        const data = await API('post', 'act', {}, {
                          oCode: userSelector.info.oCode,
                          productId: userSelector.info.productId,
                          studentId: userSelector.info.studentId,
                          courseId: userSelector.info.courseId,
                          bookId: userSelector.info.bookId,
                          level: actInfo.level,
                          episodeId: episodeInfo.episodeId,
                          episodeCode: episodeInfo.episodeCode,
                          lessonId: lessonInfo.lessonId,
                          actId: actInfo.actId,
                          actType: actInfo.actType,
                          actStatus: "Completed",
                          actPoint: actInfo.actCompletPoint,
                          actCurrentPage: setNum,
                          question: JSON.stringify(orgActList),
                          answer: JSON.stringify(actList)
                        }).catch((error) => {
                          console.error(error);
                        });

                        console.log(data);

                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .actStatus = "Completed";
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .file = categorySelected.file;
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .fileUrl = categorySelected.fileUrl;
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .player = categorySelected.player;

                        dispatch(userStore.save(userSelector.info));
                      }

                      let nextCategory = {
                        name: 'finished',
                        order: categorySelected.order,
                        retry: () => {
                          setSetNum(1);
                          if (actList.length > 0) {
                            const categoryList = actList.find(item => item.set === 1).category;
                            setCategoryList(categoryList);

                            categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                              if (index === 0) {
                                setMinCategoryNum(item.order);
                                setCategorySelected(categoryList[item.order]);
                              } else if (index === categoryList.length - 1) {
                                setMaxCategoryNum(item.order);
                              }
                            });
                          }
                        },
                      };
                      setCategorySelected(nextCategory);
                    }
                    callApiAct();
                  } else {
                    setSetNum(setNum + 1);
                    if (actList.length > 0) {
                      const categoryList = actList.find(item => item.set === setNum + 1).category;
                      setCategoryList(categoryList);

                      categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                        if (index === 0) {
                          setMinCategoryNum(item.order);
                          setCategorySelected(categoryList[item.order]);
                        } else if (index === categoryList.length - 1) {
                          setMaxCategoryNum(item.order);
                        }
                      });
                    }
                  }
                }
              }
            }
          };

          return (
            <>
              {!isTablet && <BrowserView>
                <img src={props.src} alt=""
                  onMouseOver={btnEvents}
                  onMouseLeave={btnEvents}
                  onMouseDown={btnEvents}
                  onMouseUp={btnEvents} />
              </BrowserView>}
              <MobileView>
                <img src={props.src} alt=""
                  onTouchStart={btnEvents}
                  onTouchEnd={btnEvents} />
              </MobileView>
            </>
          )
        };

        const nextCategoryAtResult = () => {
          if (categorySelected.order < maxCategoryNum) {
            let nextCategory = {
              ...categoryList[categorySelected.order + 1],
              name: 'result',
            };
            setCategorySelected(nextCategory);
          }
        };

        return (
          <>
            <div className="modal_overlay"></div>
            <div className="result-wrap">
              <div className="imgArea">
                <MiniSitcomResult
                  categorySelected={categorySelected}
                  nextCategoryAtResult={nextCategoryAtResult}
                  mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                />
              </div>
              <div className="buttongroup img-btn">
                <span><img src={PopReply} alt="" /></span>
                <span><img src={PopHome} alt="" /></span>
                <span><img src={PopNext} alt="" /></span>
                {/* <LoadBtn src={btnAgain[1]} />
                  <LoadBtn src={btnNext[1]} /> */}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="box-wrap d-flex justify-content-center align-items-center">
            <div className="screen">
              <MiniSitcom
                ref={miniSitcomRef}
                categorySelected={categorySelected}
                setCategorySelected={setCategorySelected}
                mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                setNextButtonEnabled={setNextButtonEnabled}
                openAlertModal={openAlertModal}
                answerOpen={answerOpen}
              />
            </div>
            {
              categorySelected.order > minCategoryNum &&
              <>
                <BrowserView>
                  <div className="left-button"
                    onMouseEnter={() => { btnPrevRef.current.src = btnPre[0]; }}
                    onMouseLeave={() => { btnPrevRef.current.src = btnPre[1]; }}
                    onMouseDown={() => { btnPrevRef.current.src = btnPre[2]; }}
                    onMouseUp={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
                  >
                    <img src={btnPre[1]} alt="" ref={btnPrevRef} />
                  </div>
                </BrowserView>
                <MobileView>
                  <div className="left-button"
                    onTouchStart={() => { btnPrevRef.current.src = btnPre[2]; }}
                    onTouchEnd={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
                  >
                    <img src={btnPre[1]} alt="" ref={btnPrevRef} />
                  </div>
                </MobileView>
              </>
            }
            {
              categorySelected.name === "Key Expression" || actInfo.actStatus === 'Completed' ?
                <>
                  <BrowserView>
                    <div className="right-button"
                      onMouseEnter={() => { btnNextRef.current.src = btnNext[0]; }}
                      onMouseLeave={() => { btnNextRef.current.src = btnNext[1]; }}
                      onMouseDown={() => { btnNextRef.current.src = btnNext[2]; }}
                      onMouseUp={() => { btnNextRef.current.src = btnNext[1]; nextCategory(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </BrowserView>
                  <MobileView>
                    <div className="right-button"
                      onTouchStart={() => { btnNextRef.current.src = btnNext[2]; }}
                      onTouchEnd={() => { btnNextRef.current.src = btnNext[1]; nextCategory(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </MobileView>
                </>
                :
                <div className="right-button disabled" ref={nextButtonRef}>
                  <img src={btnNext[3]} alt="" />
                </div>
            }
          </div>
        );
      }
    }
  };

  const checkUpRef = useRef();
  let idleSeconds = 0;
  let isIntervalRunning = true;

  const resetCheckUp = () => {
    idleSeconds = 0;
    isIntervalRunning = true;
  };

  const goToHome = () => {
    history.goBack();
  };

  return (
    <div className="mini-sitcom-new">
      {!isTablet && <BrowserView>
        <MiniSitcomBlock>
          <div
            onMouseDown={(e) => {
              if (isIntervalRunning === true) idleSeconds = 0;
            }}
            onTouchStart={(e) => {
              if (isIntervalRunning === true) idleSeconds = 0;
            }}
            onWheel={(e) => {
              if (isIntervalRunning === true) idleSeconds = 0;
            }}
          >
            <div className="contents  d-flex flex-column justify-content-between">
              <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
              <CloseActivityModal ref={closeActivityModalRef} />
              <Feedback ref={feedbackRef} />
              <RecordTimeOverModal ref={recordTimeOverModalRef} />
              <AlertModal ref={alertModalRef} />
              <CheckUp ref={checkUpRef} resetCheckUp={resetCheckUp} />
              <Answer
                ref={answerRef}
                miniSitcomRef={miniSitcomRef}
                categorySelected={categorySelected}
                setNextButtonEnabled={setNextButtonEnabled}
                mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                feedback={feedback}
                recordTimeOverModalRef={recordTimeOverModalRef}
              />

              <div className="route_bar d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <p>
                    {lessonInfo.lessonTitle} &gt; <span
                      className="extra-bold">{actInfo.actTitle}</span>
                  </p>
                </div>
                <div className="right_icon">
                  <div className="paging">
                    <span className="current_num">01</span>
                    <span className="total_num">/07</span>
                  </div>
                  <img className="help_icon_img" src={HelpIcon} alt="" />
                  <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
                </div>
              </div>

              <div className="box_parent">
                <RenderActivityArea />
              </div>
            </div>
          </div>
        </MiniSitcomBlock>
      </BrowserView>}
      <MobileView>
        <MiniSitcomBlock>
          <div
            onMouseDown={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
            onTouchStart={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
            onWheel={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
          >
            <div className="contents  d-flex flex-column justify-content-between">
              <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
              <CloseActivityModal ref={closeActivityModalRef} />
              <Feedback ref={feedbackRef} />
              <RecordTimeOverModal ref={recordTimeOverModalRef} />
              <AlertModal ref={alertModalRef} />
              <CheckUp ref={checkUpRef} resetCheckUp={resetCheckUp} />
              <Answer
                ref={answerRef}
                miniSitcomRef={miniSitcomRef}
                categorySelected={categorySelected}
                setNextButtonEnabled={setNextButtonEnabled}
                mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                feedback={feedback}
                recordTimeOverModalRef={recordTimeOverModalRef}
              />
              <div className="route_bar d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <p>
                    {lessonInfo.lessonTitle} &gt; <span
                      className="extra-bold">{actInfo.actTitle}</span>
                  </p>
                </div>
                <div className="right_icon">
                  <div className="paging">
                    <span className="current_num">01</span>
                    <span className="total_num">/07</span>
                  </div>
                  <img className="help_icon_img" src={HelpIcon} alt="" />
                  <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
                </div>
              </div>

              <div className="box_parent">
                <RenderActivityArea />
              </div>
            </div>
          </div>
        </MiniSitcomBlock>
      </MobileView>
    </div>
  );
};

export default Index;
