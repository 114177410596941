import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
//import AudioPlayer from '../components/audio-player';
import AfterPlayIcon from "../images/audio/after-play-icon.png";
import BeforePlayIcon from "../images/audio/before-play-icon.png";
import CurrentlyPlayIcon from "../images/audio/currently-play-icon.png";
import PausePlayIcon from "../images/audio/pause-play-icon.png";
import PersonIcon from "../images/audio/person-icon.png";
import PersonIcon2 from "../images/audio/person-icon2.png";
import PlayBar from "../images/audio/play-bar.png";
import PlayListIcon from "../images/audio/play-list-icon.png";
import PlaySample from "../images/audio/play-sample.png";
import RandomPlayIcon from "../images/audio/random-play-icon.png";
import RepeatPlayIcon from "../images/audio/repeat-play-icon.png";
import SoundIcon from "../images/audio/sound-icon.png";
import BannerColor from "../images/banner-color-common.png";

import { useSelector, useDispatch } from "react-redux";
import BoxSampleImg2 from "../images/video/box-sample-img2.png";
import { Link } from "react-router-dom";
import PlayIcon from "../images/video/play-icon.png";

//import useAudio from '../components/audio-player_b';
import AudioPlayer from 'react-h5-audio-player';
import '../css/audio_style.css'
import HomeBtnClose from "../images/home-new/close-btn.png";


const AudioBlock = styled.div`
& {
  height: 100vh;
  overflow-y: auto;
  background-color: #f9f7e8;

  & > div {
    //min-width: 1280px;
    //min-height: 768px;
  }
}

.close-btn {
  position: absolute;
  top: 4vh;
  right: 70px;
  z-index: 10;
  width: 4.218vw;
  height: 4.218vw;
  min-width: 50px;
  min-height: 50px;
}

.main-banner {
  background-image: url(${BannerColor});
  width: calc(100% - 5%);
  //min-width: 1250px;
  height: 390px;
  border-right: 6px solid #e7e6d8;
  border-bottom: 6px solid #e7e6d8;
  border-radius: 0 0 250px 0;

  h1 {
    text-align: center;
    font-size: 80px;
    color: #fff;
    margin: 55px 0 30px 5%;
  }

  .choice-box {
    position: relative;
    width: 530px;
    height: 65px;
    background-color: #fff;
    border-radius: 100px;
    margin-left: 5%;
    box-shadow: 3px 3px 3px rgba(50, 50, 50, 0.28);

    .list p {
      margin: 0;
      font-size: 24px;
      line-height: 65px;
      cursor: pointer;
      color: #cbcbcb;

      &.select-menu {
        color: #000;
      }
    }

    img {
      position: absolute;
      top: 6px;
      right: 30px;
      transform: translate(0, -100%);
    }
  }
}

.play-box-parent {
  display: flex;
  justify-content: center;
  height:100%;
}

.contents {
  height:calc(100vh - 390px);
  .play-box-wrap {
    position: relative;
    // top: 310px;
    // left: 50%;
    // transform: translate(-50%, 0);
    margin: -90px auto 0 auto;
    padding-bottom:65px;
    width: 100%;
    max-width: 1166px;
    padding-right:200px;
    left:100px;
    @media (max-width: 1400px){
      padding-right:10vw;
      left:5vw;
      max-width:88vw;
    }
    @media (max-width: 1200px){
      padding-right:0;
      left:0;
      max-width:inherit;
      padding: 0 50px 50px 50px;
    }
  }

  .play-box{
    width:100%;
    box-shadow: 6px 6px 3px rgba(50, 50, 50, 0.1);
    height:100%;
  }
  .person-icon {
    position: absolute;
    //transform: translate(100%, 0);
    right: 0;
    bottom: 0;
    width:248px;
    img{
      display:block;
      width:100%;
    }
    @media (max-width: 1400px){
      width:230px;
    }
    @media (max-width: 1200px){
      width:17vw;
    }
  }

  .play-screen {
    background-color: #2c2d32;
    width: 380px;
    //min-width: 380px;
    padding: 20px 25px;
    position:relative;

    .song-text {
      text-align: center;
      position:absolute;
      width:100%;
      left:0;
      top:25px;
    }

    .title-of-song p {
      margin: 0;
      font-size: 22px;
      color: #fff;
    }

    .singer-name p {
      margin: 0;
      color: #fff;
    }

    .play-image {
      margin: 0 auto;
      max-width: 330px;
      width: 100%;
      height: 100%;
      padding:30px 0 95px 0;
      max-height:490px;

      img {
        min-width: 0%;
        max-width: 100%;
        height: auto;
        max-height: 100%;
      }
      @media (max-width: 1400px){
        padding-top:0;
      }
    }

    .rhap_container {
      position:absolute;
      bottom:20px;
      left:50%;
      transform:translateX(-50%);
      max-width:330px;
    }

    .play-bar {
      max-width: 100%;

      img {
        min-width: 0%;
        max-width: 100%;
        height: auto;
        max-height: 100%;
      }
    }

    .play-time {
      max-width: 400px;
      //min-width: 330px;
      margin: 0 auto;

      .current-playing-time p {
        color: #fff;
        font-size: 14px;
      }

      .total-playing-time p {
        color: #6b6c70;
        font-size: 14px;
      }
    }

    .play-icon {
      margin-bottom: 30px;

      > div > img {
        cursor: pointer;
      }
    }

    .rhap_volume-bar {
      background: none;
    }
  }

  .play-list {
    width: calc(100% - 380px);
    background-color: #fff;
    padding: 20px 25px;

    .list {
      font-size: 18px;
      text-align: center;
      padding: 10px 0 20px;
      

      span {
        color: #c1c1c1;
      }
    }
    

    ul {
      list-style: none;
      border-top: 1px solid #a6afb6;
      padding: 0;
      height: calc(100% - 57px);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        width: 12px;
        background-color: gray;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      li {
        padding: 8px 15px;
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;
        font-size: 18px;

        span {
          color: #c1c1c1;
        }
      }

      .pick {
        color: #0096ff;
        font-weight: 800 !important;

        img {
          margin-right: 15px;
        }
      }
    }
  }
}
@media (max-width: 1024px), (max-height:768px){
  .close-btn{
    right: 1.5vw;;
  }
  .main-banner{
    h1{
      font-size:65px;
      margin-top:80px;
    }
    .choice-box{
      height:60px;
      .list p{
        font-size:20px;
        line-height:60px;
      }
    }
  }
}
  
/*태블릿 7인치 이하*/
@media (max-height:650px) {
  .contents{
    height:calc(100vh - 300px);
    .play-box-wrap{
      padding-bottom:30px;
    }
    .play-screen{
      width:280px;
      .play-image{
        padding-bottom:75px;
      }
      .rhap_container{
        bottom:9px;
        padding:0;
        transform:scale(0.8);
        margin-left:-140px;
      }
    }
    .play-list{
      width:calc(100% - 280px);
      .list{
        padding:3px 0 10px 0;
      }
      ul{
        height:calc(100% - 40px);
        li{
        font-size:17px;
        padding:8px 10px;
      }

      } 
    }
    .person-icon{
      width:14vw;
    }
  }
  .main-banner{
    height:300px;
    h1{
      font-size:47px;
      margin-top:35px;
    }
    .choice-box{
      width: 60%;
      height:55px;
      padding:0 25px;
      .list p{
        line-height:55px;
      }
      img{
        width:75px;
      }
    }
  }
}
`;

const Audio = () => {
  const audioPlayerRef = useRef();

  const audioListStore = useSelector(state => state.audioListStore);
  const studentStore = useSelector(state => state.userStore);
  const audioList = audioListStore.list;

  let menuList = [];
  audioList.map((item, index) => {
    menuList[index] = item.category;
  });
  menuList = new Set(menuList);
  menuList = [...menuList];

  const [menuSelected, setMenuSelected] = useState('ALL');
  let audio_List = audioListStore.list;

  if (menuSelected !== 'ALL') {
    audio_List = audioListStore.list.filter(temp => temp.category === menuSelected);
  }

  const [itemSelected, setItemSelected] = useState(audio_List[0]);
  const [itemIndex, setItemIndex] = useState(0);
  const [songName, setSongName] = useState(audio_List[0].title);

  const onItemClick = (itemId, index) => {
    setItemSelected(itemId);
    setItemIndex(index);
    setSongName(itemId.title)
  };

  const onPrevButton = () => {
    if (itemIndex === 0) {
      setItemSelected(audio_List[(audio_List.length - 1)]);
      setItemIndex((audio_List.length - 1));
      setSongName(audio_List[(audio_List.length - 1)].title)
    } else if (itemIndex === 'change') {
      if (itemSelected === audio_List[0]) {
        setItemSelected(audio_List[(audio_List.length - 1)]);
        setItemIndex((audio_List.length - 1));
        setSongName(audio_List[(audio_List.length - 1)].title)
      } else {
        setItemSelected(audio_List[0]);
        setItemIndex(0);
        setSongName(audio_List[0].title)
      }
    }
    else {
      setItemSelected(audio_List[(itemIndex - 1)]);
      setItemIndex((itemIndex - 1));
      setSongName(audio_List[(itemIndex - 1)].title)
    }
  }

  const onNextButton = () => {
    if (itemIndex === (audio_List.length - 1)) {
      setItemSelected(audio_List[0]);
      setItemIndex(0);
      setSongName(audio_List[0].title)
    } else if (itemIndex === 'change') {
      if (itemSelected === audio_List[0]) {
        setItemSelected(audio_List[1]);
        setItemIndex(1);
        setSongName(audio_List[1].title)
      } else {
        setItemSelected(audio_List[0]);
        setItemIndex(0);
        setSongName(audio_List[0].title)
      }
    } else {
      setItemSelected(audio_List[(itemIndex + 1)]);
      setItemIndex((itemIndex + 1));
      setSongName(audio_List[(itemIndex + 1)].title)
    }
  }

  const onEnded = () => {
    if (audioPlayerRef.current.props.loop === false) {
      onNextButton();
    }
  };

  let routerName = '';
  switch (studentStore.info.productName) {
    case 'HHT1':
      routerName = 'hh1/learning-home';
      break;
    case 'HHT2':
      routerName = 'hh2/learning-home';
      break;
    case 'CULTURE':
      routerName = 'culture/learning-home';
      break;
    default:
      break;
  }

  return (
    <AudioBlock>
      <div>
        <Link to={"/" + routerName}>
          <img className="close-btn" src={HomeBtnClose} alt="" />
        </Link>
        <div className="main-banner d-flex flex-column align-items-center">
          <h1 className="extra-bold">Audio</h1>
          <div className="choice-box">
            <div className="list d-flex justify-content-around h-100">
              <p
                className={`extra-bold ${menuSelected && menuSelected === 'ALL' ? "select-menu" : ""
                  }`}
                onClick={() => (setMenuSelected('ALL'), setItemIndex('change'))}
              >
                ALL
              </p>
              {menuList.map((item, index) => (
                <p
                  key={index}
                  className={`extra-bold ${menuSelected && menuSelected === item ? "select-menu" : ""
                    }`}
                  onClick={() => (
                    setMenuSelected(item), setItemIndex('change')
                  )}
                >{item}</p>
              ))}
            </div>
            <img src={PersonIcon} alt="" />
          </div>
        </div>
        <div className="contents">
          <div className="play-box-parent">
            <div className="play-box-wrap">
              <div className="play-box d-flex">
                <div className="play-screen">
                  {/* <div className="song-text">
                    <div className="title-of-song">
                      <p className="bold">{itemSelected.title}</p>
                    </div>
                    <div className="singer-name">
                      <p>{itemSelected.category}</p>
                    </div>
                  </div> */}

                  <div className="play-image d-flex justify-content-center">
                    <img src={itemSelected.img_path} alt="" />
                  </div>

                  <AudioPlayer
                    ref={audioPlayerRef}
                    autoPlay={false}
                    src={itemSelected.file_path}
                    showSkipControls={true}
                    onClickNext={() => onNextButton()}
                    onClickPrevious={() => onPrevButton()}
                    showJumpControls={true}
                    showFilledVolume={true}
                    onEnded={() => onEnded()}
                  />
                </div>

                <div className="play-list">
                  <div className="list">
                    <p>
                      List <span>({audio_List.length})</span>
                    </p>
                  </div>
                  <ul>
                    {audio_List.map((item, index) => (
                      <li key={index} className={item.id === itemSelected.id ? 'pick' : ''} onClick={() => onItemClick(item, index)}>
                        {/* <img src={CurrentlyPlayIcon} alt=""/>*/}
                        {/*&#91;EP2&#93; Hello,Tony 2 <br/>*/}
                        { item.title} <br />
                        <span>{item.category}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>              
              <div className="person-icon">
                  <img src={PersonIcon2} alt="" />
                </div>
            </div>
          </div>
        </div>
      </div>
    </AudioBlock>
  );
};

export default Audio;
