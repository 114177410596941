import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';

/**
 * For V/G Controller
 * https://support.catenoid.net/pages/viewpage.action?pageId=1081499
 */
const useScript = (url, name) => {
  const [lib, setLib] = useState({});

  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = () => setLib({ [name]: window[name] });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);

  return lib;
}

const DrmVideoPlayer = (props) => {
  let filePath = props.filePath;
  const samplePath = 'https://v.kr.kollus.com/s?s=0&jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHB0IjoxNjI2NTc5MDQ3LCJjdWlkIjoiNDgiLCJtYyI6W3sibWNrZXkiOiJIbEl3a2JzZyIsImRybV9wb2xpY3kiOnsia2luZCI6Imlua2EiLCJzdHJlYW1pbmdfdHlwZSI6ImRhc2giLCJkYXRhIjp7ImxpY2Vuc2VfdXJsIjoiaHR0cHM6XC9cL2xpY2Vuc2UucGFsbHljb24uY29tXC9yaVwvbGljZW5zZU1hbmFnZXIuZG8iLCJjZXJ0aWZpY2F0ZV91cmwiOiJodHRwczpcL1wvbGljZW5zZS5wYWxseWNvbi5jb21cL3JpXC9mcHNLZXlNYW5hZ2VyLmRvP3NpdGVJZD1HN09MIiwiY3VzdG9tX2hlYWRlciI6eyJrZXkiOiJwYWxseWNvbi1jdXN0b21kYXRhLXYyIiwidmFsdWUiOiJleUprY20xZmRIbHdaU0k2SWxkcFpHVjJhVzVsSWl3aWMybDBaVjlwWkNJNklrYzNUMHdpTENKMWMyVnlYMmxrSWpvaU5EZ2lMQ0pqYVdRaU9pSXlNREl4TURNeE1TMWlNVEo1YW5ZelpTSXNJblJ2YTJWdUlqb2lXVTVJTnl0VllqWkhXRFJIY1d3NWRVOTFSRXhtUW05S2EwRTNSRGRCUTBsd1EyczBURmhjTDBVeFoyeGpPRmxUZVRsMWNVOVZPRkJyY1ZZd1luQklaVkp0SzNweWQzSmpVMUl5U0RGc01WVnZabkJ6UzJSbmRFRmtkVXBhUkVSRmFFODBTVFUxYkhOdlQybHVXalZoUldSQmVrbENXamxhYVc5cU5IWkRXbEowVG5STWFXbFBXVk4zYTJWSE16UlhVSE5pZUVReU1GZ3pVMmw1UkVwTU5FUTVNbGsxY25salVERmtSRGhFYTNsV1VFWnVOR0ZDVlhJeWNGZEdaRzVNYWlJc0luUnBiV1Z6ZEdGdGNDSTZJakl3TWpFdE1EUXRNRGxVTURNNk16QTZORGRhSWl3aWFHRnphQ0k2SW5WWk5FUnhSMjlHYlZGY0wyOWhaRXhqVlhCTWIzaHFNMGxIWVc1WlVFbFVlRzh3YlZkcU4ycERiRmswUFNKOSJ9fX19XX0.wQMpPhWrQB57DCs5HFISUBfS61Fxsmg7L31cq5dsbx8&custom_key=3598f160b5eb503f847456fff22fe81ffc9adbfff4da486ad0aced0afce3660f&player_version=html5';
  if (window.location.hostname === "localhost") {
    filePath = samplePath;
  }

  /**
   * V/G Controller
   * https://support.catenoid.net/pages/viewpage.action?pageId=1081499
   */
  const vgLib = useScript('https://file.kollus.com/vgcontroller/vg-controller-client.latest.min.js', 'VgControllerClient');
  let vgLibIsEmpty = true;
  for (var prop in vgLib) {
    if (vgLib.hasOwnProperty(prop)) {
      vgLibIsEmpty = false;
      break;
    }
  }
  if (!vgLibIsEmpty) {
    try {
      var controller = new vgLib.VgControllerClient({
        target_window: document.getElementById('drmVideo').contentWindow
      });
      // dev005 애니메이션 비디오 시작버튼과 Play버튼을 누를 때마다 화면전환 
      controller.on('play', function () {
        controller.set_screen();        
        });
      controller.on('done', function () {
        if (props.setNextButtonEnabled) {
          props.setNextButtonEnabled();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div id="drmVideoPlayer"
      style={{
        width: '100%',
        height: '100%'
      }}>
      <Iframe url={filePath}
        width="100%"
        height="100%"
        id="drmVideo"
        allow="encrypted-media; fullscreen"
        className="myClassname"
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default DrmVideoPlayer;