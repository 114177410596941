import React, { forwardRef, useRef } from "react";
import styled from "styled-components";

const EggStudioResult = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = window.location.origin + props.mediaPath + '/';

    const audioRef = [];
    var i = 0;
    categorySelected.categoryList.forEach((category) => {
        category.sentence.filter((item) => item.record).forEach(() => {
            audioRef.push(React.createRef());
        });
    });
    console.log('result_audio', audioRef);
    console.log('result_categorySelected.categoryList', categorySelected.categoryList);

    const videoRef = useRef();

    let currentTime = -1;
    const onTimeUpdate = () => {
        const cds = new Date(Math.floor(videoRef.current.currentTime * 1000)).toISOString().substr(11, 8);
        if (cds === currentTime) {
            return;
        } else {
            currentTime = cds;
        }
        console.log(currentTime);
        audioRef.forEach((item, index) => {
            if (item.current && item.current.dataset && currentTime === item.current.dataset.recordstarttime) {
                if (item.current.paused) {
                    item.current.play();
                }
            }
        });
    };

    const infoRef = useRef();
    const onCanPlayThrough = () => {
        setTimeout(() => {
            infoRef.current.style.display = 'none';
            videoRef.current.play();
        }, 2000);
    };

    const EggStudioResultBlock = styled.div`
        & {
            text-align: center;
            margin-top: 2vh;

            video {
                width: 95%;
                max-height: 42vh;
            }

            .info-parent {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                padding:158px 30px 81px 30px;
                z-index:10;
    
                .info-child {
                    background: rgb(51, 51, 51, 0.98);
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    z-index: 999;
                    margin:0 auto;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                }
                .info-img {
                    margin: 0 auto;
                    width: fit-content;
                }
                .info-content {
                    font-size: 2rem;
                    color: #fff;
                    margin: 5.9vh auto 0 auto;
                    width: fit-content;
                }
            }
        }
        @media (max-width: 1024px), (max-height:768px) {
            .info-parent{
                padding: 112px 22px 65px 22px;
            } 
        }
        /*태블릿 7인치 이하*/
        @media (max-height:650px) {
            & {
                .info-parent {
                    padding:93px 20px 48px 20px;
                    .info-child{
                        border-radius: 25px;
                    }
                    .info-img {
                        img{
                            width:147px;
                        }
                    }
                    .info-content {
                        font-size: 19px;
                    }
                }
            }
        }
    `;

    let audioRefIndex = 0;
    return (
        <EggStudioResultBlock onContextMenu={(e) => e.preventDefault()}>
            <div ref={infoRef} className="info-parent">
                <div className="info-child">
                    <div>
                        <div className="info-img"><img src={require('../../images/common/layer_pop_01.png').default} /></div>
                        <div className="info-content">Now enjoy your movie!</div>
                    </div>
                </div>
            </div>
            {categorySelected.categoryList.map((category) => (
                category.sentence.filter((item) => item.record).map((item, index) => (
                    <audio
                        key={index}
                        ref={audioRef[audioRefIndex++]}
                        src={(item.audio ? (item.fileUrlPath ? item.fileUrlPath : item.fileUrl) : '')}
                        data-recordstarttime={item.recordStartTime}
                    />
                ))
            ))}
            <video
                ref={videoRef}
                controls
                src={mediaPath + '/' + categorySelected.video}
                controlsList={'nodownload'}
                disablePictureInPicture
                onTimeUpdate={onTimeUpdate}
                onCanPlayThrough={onCanPlayThrough}
            />
        </EggStudioResultBlock>
    );
});

export default EggStudioResult;