/**
 * HH1 에피소드별 썸네일 이미지
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const AnimationThumbnail = (currentEpisodeCode) => {
    const thumnail = {};

    thumnail['EP1'] = require('../../images/animationThumbnail/hht1_ani_01.png').default;
    thumnail['EP2'] = require('../../images/animationThumbnail/hht1_ani_02.png').default;
    thumnail['EP3'] = require('../../images/animationThumbnail/hht1_ani_03.png').default;
    thumnail['EP4'] = require('../../images/animationThumbnail/hht1_ani_04.png').default;
    thumnail['EP5'] = require('../../images/animationThumbnail/hht1_ani_05.png').default;
    thumnail['EP6'] = require('../../images/animationThumbnail/hht1_ani_06.png').default;
    thumnail['EP7'] = require('../../images/animationThumbnail/hht1_ani_07.png').default;
    thumnail['EP8'] = require('../../images/animationThumbnail/hht1_ani_08.png').default;
    thumnail['EP9'] = require('../../images/animationThumbnail/hht1_ani_09.png').default;
    thumnail['EP10'] = require('../../images/animationThumbnail/hht1_ani_10.png').default;
    thumnail['EP11'] = require('../../images/animationThumbnail/hht1_ani_11.png').default;
    thumnail['EP12'] = require('../../images/animationThumbnail/hht1_ani_12.png').default;
    thumnail['EP13'] = require('../../images/animationThumbnail/hht1_ani_13.png').default;
    thumnail['EP14'] = require('../../images/animationThumbnail/hht1_ani_14.png').default;
    thumnail['EP15'] = require('../../images/animationThumbnail/hht1_ani_15.png').default;
    thumnail['EP16'] = require('../../images/animationThumbnail/hht1_ani_16.png').default;
    thumnail['EP17'] = require('../../images/animationThumbnail/hht1_ani_17.png').default;
    thumnail['EP18'] = require('../../images/animationThumbnail/hht1_ani_18.png').default;
    thumnail['EP19'] = require('../../images/animationThumbnail/hht1_ani_19.png').default;
    thumnail['EP20'] = require('../../images/animationThumbnail/hht1_ani_20.png').default;

    return thumnail[currentEpisodeCode];
}

export default AnimationThumbnail;
