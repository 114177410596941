import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Image, Stage } from 'react-konva';
import useImage from 'use-image';
import LoadScene from './LoadScene';

const LoadStage = forwardRef((props, ref) => {
    const activityProps = props.activityProps;
    const audioRef = props.audioRef;
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = props.mediaPath;
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const recordStartAudioRef = props.recordStartAudioRef;
    const countBbiAudioRef = props.countBbiAudioRef;
    const openAlertModal = props.openAlertModal;
    const stageRef = useRef();

    const containerRef = props.containerRef;
    let containerWidth = activityProps.defaultWidth;
    let containerHeight = activityProps.defaultHeight;
    let scaleX, scaleY, scale;

    useEffect(() => {
        containerWidth = containerRef.current.offsetWidth
        containerHeight = containerRef.current.offsetHeight;
        scaleX = containerWidth / activityProps.defaultWidth;
        scaleY = containerHeight / activityProps.defaultHeight;
        if (scaleX > scaleY) {
            containerWidth = activityProps.defaultWidth * scaleY;
        } else if (scaleX < scaleY) {
            containerHeight = activityProps.defaultHeight * scaleX;
        }
        scale = Math.min(scaleX, scaleY);
        stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
    }, []);
    useEffect(() => {
        const resizeListener = () => {
            containerWidth = containerRef.current.offsetWidth;
            containerHeight = containerRef.current.offsetHeight;
            scaleX = containerWidth / activityProps.defaultWidth;
            scaleY = containerHeight / activityProps.defaultHeight;
            if (scaleX > scaleY) {
                containerWidth = activityProps.defaultWidth * scaleY;
            } else if (scaleX < scaleY) {
                containerHeight = activityProps.defaultHeight * scaleX;
            }
            scale = Math.min(scaleX, scaleY);
            stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    });

    const LoadImage = (props) => {
        const [image, status] = useImage(props.imagePath);
        if (status !== "loaded") return null;
        let imageWidth = (props.width ? props.width : image.width);
        let imageHeight = (props.height ? props.height : image.height);
        let x = props.x;
        let y = props.y;
        if (props.center) {
            x = (activityProps.defaultWidth - imageWidth) / 2;
            y = (activityProps.defaultHeight - imageHeight) / 2;
        }
        if (props.x === 'center') {
            x = (activityProps.defaultWidth - imageWidth) / 2;
        }
        return <Image
            id={props.id || ''}
            name={props.name || ''}
            image={image}
            x={x}
            y={y}
            width={props.width}
            height={props.height}
            stroke={props.stroke}
            strokeWidth={props.strokeWidth}
            cornerRadius={props.cornerRadius}
            onMouseOver={props.events}
            onMouseLeave={props.events}
            onMouseDown={props.events}
            onMouseUp={props.events}
            onTouchStart={props.events}
            onTouchEnd={props.events}
        />;
    };

    const sceneRef = useRef();
    useImperativeHandle(ref, () => ({
        onCanPlayThrough(index) {
            sceneRef.current.onCanPlayThrough(index);
        },
        onEnded(index) {
            sceneRef.current.onEnded(index);
        }
    }));

    return (
        <Stage width={activityProps.defaultWidth} height={activityProps.defaultHeight} ref={stageRef}>
            <LoadScene
                ref={sceneRef}
                stageRef={stageRef}
                LoadImage={LoadImage}
                audioRef={audioRef}
                categorySelected={categorySelected}
                setNextButtonEnabled={setNextButtonEnabled}
                mediaPath={mediaPath}
                activityProps={activityProps}
                feedback={feedback}
                recordTimeOverModalRef={recordTimeOverModalRef}
                recordStartAudioRef={recordStartAudioRef}
                countBbiAudioRef={countBbiAudioRef}
                openAlertModal={openAlertModal}
            />
        </Stage>
    );
});

export default LoadStage;