import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";

import CenterLigth from "../../images/word-play/center-light.png";
import UserFaceDefault from "../../images/word-play/user-face.png";
import UserBody from "../../images/word-play/user-body.png";

import UserImg from "../../images/word-play/user-img.png";
import Quizimg from "../../images/word-play/word-quiz-1.jpg";

import countNum1 from "../../images/word-play/count-1.png";
import countNum2 from "../../images/word-play/count-2.png";
import countNum3 from "../../images/word-play/count-3.png";
import resultCorrect from "../../images/word-play/correct.png";
import resultOops from "../../images/word-play/oops.png";

import thropy from "../../images/word-play/thropy.png";
import WinnerImg from "../../images/word-play/winner-bg.png";

import PopReply from "../../images/pop-default/restart-btn.png";
import PopHome from "../../images/pop-default/home-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";

//캐릭터
import UserTony from "../../images/word-play/user-tony.png";
import UserHolly from "../../images/word-play/user-holly.png";
import UserMike from "../../images/word-play/user-mike.png";
import UserThomas from "../../images/word-play/user-thomas.png";
import UserHarry from "../../images/word-play/user-harry.png";
import UserEmma from "../../images/word-play/user-emma.png";
import UserCharles from "../../images/word-play/user-charles.png";

import Particle from "../../images/word-play/particle.png";
import Booth from "../../images/word-play/score-board.png";
import BoothOn from "../../images/word-play/score-board-on.png";
/*css*/
import "../../css/wordplay.css";


const Index = () => {
  return (
    <div className="word-play">
      <div>
          <div className="contents d-flex flex-column justify-content-between">
              <div className="route_bar d-flex align-items-center justify-content-between">
                  <div className="right_icon">
                      <img className="help_icon_img" src={HelpIcon} alt="" />
                      <img className="cancel_icon_img" src={CancelIcon} alt="" />
                  </div>
              </div>
          </div>
      </div>
      <div className="top-light">
        <span className="light left"></span>
        <span className="light right"></span>
      </div>
      {/*단상 - 구조는 index와 동일함*/}
      <div className="score-line" style={{'z-index':'10'}}>
        {/*캐릭터 있는 왼쪽 단상*/}
        <div className="score-box left">
          {/*캐릭터*/}
          <div className="character">
            {/*캐릭터 들어가는 태그 - 캐릭터 총 7명*/}
            <span>
              <img src={UserCharles} alt="" />
            </span>
            {/*캐릭터 들어가는 태그 - 캐릭터 총 7명*/}
          </div>
          {/*캐릭터*/}

          {/*단상 내용*/}
          <div className="score-table">
            <span className="character-name">Thomas</span>{/*이름*/}
            <span className="score-num">20</span>{/*점수*/}
            <div className="btm">
              {/*1. 부스 배경 변경*/}
              <div className="bootharea">
               <img src={Booth} alt="" /> {/*부스 기본*/}
              </div>
            </div>
          </div>
          {/*단상 내용*/}
        </div>
        {/*캐릭터 있는 왼쪽 단상*/}

        {/*유저 캐릭터 있는 오른쪽 단상*/}
        <div className="score-box right">
          {/*캐릭터*/}
          <div className="character">         
            {/*승자일경우 추가되는 부분*/}
            <div className="particle"><img src={Particle} alt="" /></div>
            {/*승자일경우 추가되는 부분*/}
            {/*user 이미지 들어가는 태그*/}
            {/* <span className="face"><img src={UserFaceDefault} alt="" /></span>user 이미지 없을 경우 기본 이미지 */}
            <span className="face"><img src={UserImg} alt="" /></span>
            {/*user 이미지 들어가는 태그*/}
            <span className="body"><img src={UserBody} alt="" /></span>
          </div>
          {/*캐릭터*/}

          {/*단상 내용*/}
          <div className="score-table">
            <span className="character-name">Christine</span>{/*이름*/}
            <span className="score-num">000</span>{/*점수*/}
            <div className="btm">
              {/*1. 부스 배경 변경*/}
              <div className="bootharea">
               <img src={BoothOn} alt="" /> {/*부스 활성화*/}
              </div>
              {/*4.결과화면에서 승자 트로피 표시*/}
              <span className="thropy">{/*트로피*/}
                <img src={thropy} alt="" />
              </span>
            </div>
          </div>
          {/*단상 내용*/}
        </div>
        {/*유저 캐릭터 있는 오른쪽 단상*/}
      </div>
      
      {/*word play 결과 팝업*/}
      <div className="modal_width result_word" style={{'display':'block'}}>
        <div className="modal_overlay"></div>
        <div className="modal_wrapper">
          <div className="top">
              <img className="winner_img" src={WinnerImg} alt="" />
              <p className="winner">Christine!</p>{/*승자 이름*/}
            </div>
            <div className="buttongroup img-btn">
              <span><img src={PopReply} alt="" /></span>
              <span><img src={PopHome} alt="" /></span>
              <span><img src={PopNext} alt="" /></span>
            </div>
        </div>
      </div>  
      {/*word play 결과 팝업*/}   
    </div>
  );
};

export default Index;
