/**
 * HH2 에피소드별 썸네일 이미지
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const Thumbnailspeakingpractice = (currentEpisodeCode) => {
    const thumnail = {};

    thumnail['EP1'] = require('../../images/thumbnailSpeakingPractice/hht2_sp_01.png').default;
    thumnail['EP2'] = require('../../images/thumbnailSpeakingPractice/hht2_sp_01.png').default;
    thumnail['EP3'] = require('../../images/thumbnailSpeakingPractice/hht2_sp_01.png').default;
    thumnail['EP4'] = require('../../images/thumbnailSpeakingPractice/hht2_sp_01.png').default;

    return thumnail[currentEpisodeCode];
}

export default Thumbnailspeakingpractice;
