import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import * as userStore from '../modules/userStore';


import LogoHht1 from "../images/home-new/logo-hh1.png";
import LogoHht2 from "../images/home-new/logo-hh2.png";
import ProfileDefault from "../images/home-new/character_d2.png";
import SoundIcon_on from "../images/home-new/sound_on.png";
import SoundIcon_off from "../images/home-new/sound_off.png";
import ProfileIcon from "../images/home-new/profile.png";
import CloseIcon from "../images/home-new/close-btn.png";

import HomeImg from "../images/home-new/person-icon.png";

import AniThumb011 from "../images/animationThumbnail/hht1_ani_01.png";
import SitcomThumb011 from "../images/animationThumbnail/hht1_ani_02.png";
import EggThumb011 from "../images/animationThumbnail/hht1_ani_03.png";
import Check from "../images/home/item-box-icon1.png";
import Progress from "../images/home/item-box-icon2.png";

import VideoIcon from "../images/home/side-menu-icon1.png";
import AudioIcon from "../images/home/side-menu-icon2.png";
import BookIcon from "../images/home/side-menu-icon3.png";

import Char01 from "../images/home-new/character_01.png"
import Char02 from "../images/home-new/character_02.png"
import Char03 from "../images/home-new/character_03.png"
import Char04 from "../images/home-new/character_04.png"
import Char05 from "../images/home-new/character_05.png"
import Char06 from "../images/home-new/character_06.png"
import Char07 from "../images/home-new/character_07.png"
import Char08 from "../images/home-new/character_08.png"
import Char09 from "../images/home-new/character_09.png"
import Char10 from "../images/home-new/character_10.png"
import Char11 from "../images/home-new/character_11.png"
import Char12 from "../images/home-new/character_d.png"

import PhotoSample from "../images/home-new/photo-sample.jpg"


/*hht2 이미지*/
import HomeImgHht021 from "../images/home-new/person-icon-hht2-01.png";
import HomeImgHht022 from "../images/home-new/person-icon-hht2-02.png";
import HomeImgHht023 from "../images/home-new/person-icon-hht2-03.png";
import AniThumb021 from "../images/sitcomThumbnail/hht2_sitcom_01.png";
import SitcomThumb021 from "../images/animationThumbnail/hht1_ani_02.png";
import EggThumb021 from "../images/animationThumbnail/hht1_ani_03.png";
/*css*/
import "../css/learninghome.css";


const LearningHomeNew = () => {
    return (
        <div className="learning-home">
            {/*러닝홈 hht1*/}
            <div className="hht1-home">
                <div className="home-header">
                    <p className="logo">
                        <img src={LogoHht1} alt="" />
                    </p>
                    <div className="home-menu">
                        <span className="sound-menu">
                            <img src={SoundIcon_on} alt="" />
                            {/* <img src={SoundIcon_off} alt="" /> */}{/*뮤트*/}
                        </span>
                        <span className="profile-menu">
                            <img src={ProfileIcon} alt="" />
                            <div className="my-message">
                                <div className="arrow"></div>
                                <div className="top d-flex">
                                    <div className="message-icon d-flex align-items-center justify-content-center">
                                        <img src={ProfileDefault} alt="" />
                                    </div>
                                    <div className="about-me"><p className="extra-bold">병일자녀닉네임</p><span>HHT1</span></div>
                                </div>
                            </div>
                        </span>
                        <span className="close-menu"><img src={CloseIcon} alt="" /></span>
                    </div>
                </div>

                <div className="home-contents">
                    <div className="home-box">
                        <div className="home-img">
                            <img src={HomeImg} alt="" />
                        </div>
                        <div className="box-wrap">
                            <div className="epi-step">
                                <span className="epi-arrow prev"></span>
                                <p className="epi-step-p">Episode 1</p>
                                <span className="epi-arrow next"></span>
                            </div>
                            <div className="epi-lesson">
                                <ul className="lesson-bar" data-menu-selected="2">
                                    <li className="list-1"><span>Lesson 1</span></li>
                                    <li className="list-2 now"><span>Lesson 2</span></li>
                                    <li className="list-3"><span>Lesson 3</span></li>
                                    <li className="list-4"><span>Lesson 4</span></li>
                                </ul>
                            </div>
                            <div className="epi-category">
                                <div className="item_box_parent first">
                                    <div className="item_box first-box">
                                        <img src={AniThumb011} alt="" />
                                    </div>
                                    <div className="top_text">
                                        <p className="extra-bold">Animation</p>
                                    </div>
                                    <div className="btm_text">
                                        <p className="extra-bold">Ready</p>
                                        <div className="icon ready">
                                            <img className="icon-3" src={Check} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item_box_parent second">
                                    <div className="item_box second-box">
                                        <img src={SitcomThumb011} alt="" />
                                    </div>
                                    <div className="top_text">
                                        <p className="extra-bold">Mini Sitcom 1</p>
                                    </div>
                                    <div className="btm_text">
                                        <p className="extra-bold">Completed</p>
                                        <div className="icon completed">
                                            <img className="icon-3" src={Check} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item_box_parent third">
                                    <div className="item_box second-box">
                                        <img src={EggThumb011} alt="" />
                                    </div>
                                    <div className="top_text">
                                        <p className="extra-bold">EGG Studio</p>
                                    </div>
                                    <div className="btm_text">
                                        <p className="extra-bold">Progress</p>
                                        <div className="icon progress">
                                            <img className="icon-2" src={Progress} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="side-menu">
                            <div className="menu-box d-flex flex-column justify-content-center align-items-center">
                                <img className="side-menu-1" src={VideoIcon} alt="" />
                                <p className="bold">Video</p>
                            </div>
                            <div className="menu-box d-flex flex-column justify-content-center align-items-center">
                                <img className="side-menu-2" src={AudioIcon} alt="" />
                                <p className="bold">Audio</p>
                            </div>
                            <div className="menu-box d-flex flex-column justify-content-center align-items-center">
                                <img className="side-menu-3" src={BookIcon} alt="" />
                                <p className="bold">Book</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*러닝홈 hht1*/}

            {/*러닝홈2 hht2*/}
            <div className="hht2-home" style={{ 'display': 'none' }}>
                <div className="home-header">
                    <p className="logo">
                        <img src={LogoHht2} alt="" />
                    </p>
                    <div className="home-menu">
                        <span className="sound-menu">
                            <img src={SoundIcon_on} alt="" />
                            {/* <img src={SoundIcon_off} alt="" />*/}
                        </span>
                        <span className="profile-menu">
                            <img src={ProfileIcon} alt="" />
                            <div className="my-message">
                                <div className="arrow"></div>
                                <div className="top d-flex">
                                    <div className="message-icon d-flex align-items-center justify-content-center">
                                        <img src={ProfileDefault} alt="" />
                                    </div>
                                    <div className="about-me"><p className="extra-bold">병일자녀닉네임</p><span>HHT1</span></div>
                                </div>
                            </div>
                        </span>
                        <span className="close-menu"><img src={CloseIcon} alt="" /></span>
                    </div>
                </div>

                <div className="home-contents">
                    <div className="home-box-top">
                        <div className="epi-step">
                            <span className="epi-arrow prev"></span>
                            <p className="epi-step-p">Episode 1</p>
                            <span className="epi-arrow next"></span>
                        </div>
                        <div className="lesson-box">
                            <div className="epi-lesson">
                                <ul className="lesson-bar" data-menu-selected="2">
                                    <li className="list-1"><span>Lesson 1</span></li>
                                    <li className="list-2 now"><span>Lesson 2</span></li>
                                    <li className="list-3"><span>Lesson 3</span></li>
                                    <li className="list-4"><span>Lesson 4</span></li>
                                </ul>
                            </div>
                            <div className="home-img">
                                <img src={HomeImgHht023} alt="" />{/*이미지 3개있음 -> HomeImgHhht021, HomeImgHhht022, HomeImgHhht023*/}
                            </div>
                        </div>
                    </div>
                    <div className="home-box">
                        <div className="box-wrap">
                            <div className="epi-category">
                                <div className="item_box_parent first">
                                    <div className="item_box first-box">
                                        <img src={AniThumb021} alt="" />
                                    </div>
                                    <div className="top_text">
                                        <p className="extra-bold">Animation</p>
                                    </div>
                                    <div className="btm_text">
                                        <p className="extra-bold">Ready</p>
                                        <div className="icon ready">
                                            <img className="icon-3" src={Check} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item_box_parent second">
                                    <div className="item_box second-box">
                                        <img src={SitcomThumb021} alt="" />
                                    </div>
                                    <div className="top_text">
                                        <p className="extra-bold">Speaking Booster</p>
                                    </div>
                                    <div className="btm_text">
                                        <p className="extra-bold">Completed</p>
                                        <div className="icon completed">
                                            <img className="icon-3" src={Check} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="item_box_parent third">
                                    <div className="item_box second-box">
                                        <img src={EggThumb021} alt="" />
                                    </div>
                                    <div className="top_text">
                                        <p className="extra-bold">EGG Studio</p>
                                    </div>
                                    <div className="btm_text">
                                        <p className="extra-bold">Progress</p>
                                        <div className="icon progress">
                                            <img className="icon-2" src={Progress} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="side-menu">
                            <div className="menu-box d-flex flex-column justify-content-center align-items-center">
                                <img className="side-menu-1" src={VideoIcon} alt="" />
                                <p className="bold">Video</p>
                            </div>
                            <div className="menu-box d-flex flex-column justify-content-center align-items-center">
                                <img className="side-menu-2" src={AudioIcon} alt="" />
                                <p className="bold">Audio</p>
                            </div>
                            <div className="menu-box d-flex flex-column justify-content-center align-items-center">
                                <img className="side-menu-3" src={BookIcon} alt="" />
                                <p className="bold">Book</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*러닝홈2*/}


            {/*close 팝업 - 오른쪽 상단 X버튼 클릭시 노출*/}
            {/* 
            <div className="modal_width">
                <div className="modal_overlay"></div>
                <div className="modal_wrapper close-pop">
                    <div className="modal_inner">
                        <div className="title">Do you want to exit?</div>
                        <div className="buttongroup text-btn">
                            <span className="line-btn">Yes</span>
                            <span className="full-btn">No</span>
                        </div>
                    </div>
                </div> 
            </div>*/}
            {/*close 팝업*/}

            {/*Character 팝업1 - 오른쪽 상단 프로필 클릭시 노출*/}
            {/* 
            <div className="modal_width">
                <div className="modal_overlay"></div>
                <div className="modal_wrapper character-pop">
                    <div className="modal_inner">
                        <div className="title">Choose your character.</div>
                        <div className="character-list">
                            <span><img src={Char01} alt="" /></span>
                            <span><img src={Char02} alt="" /></span>
                            <span><img src={Char03} alt="" /></span>
                            <span><img src={Char04} alt="" /></span>
                            <span><img src={Char05} alt="" /></span>
                            <span><img src={Char06} alt="" /></span>
                            <span><img src={Char07} alt="" /></span>
                            <span><img src={Char08} alt="" /></span>
                            <span><img src={Char09} alt="" /></span>
                            <span><img src={Char10} alt="" /></span>
                            <span><img src={Char11} alt="" /></span>
                            <span className="my-photo"><img src={Char12} alt="" /></span>
                        </div>
                        <button className="pop-close"></button>
                    </div>
                </div> 
            </div>*/}
            {/*Character 팝업1*/}

            {/*Character 팝업2 - Character 팝업 가장 마지막에 있는 my-photo 클릭시 노출*/}
            {/* 
            <div className="modal_width">
                <div className="modal_overlay"></div>
                <div className="modal_wrapper upload-pop">
                    <div className="modal_inner">
                        <div className="title">Upload your photo.</div>
                        <div className="photo-area">
                            <div className="defalut">
                                <p>Drag the photo <br/>or</p>
                                <button className="full-btn">Select the image</button>
                            </div>
                        </div>
                        <button className="pop-close"></button>
                    </div>
                </div> 
            </div>*/}
            {/*Character 팝업2*/}

            {/*Character 팝업3 - 사진 저장팝업*/}
            {/* 
            <div className="modal_width">
                <div className="modal_overlay"></div>
                <div className="modal_wrapper photo-pop">
                    <div className="modal_inner">
                        <img src={PhotoSample} alt="" />
                        <button className="full-btn">Save</button>
                    </div>
                </div> 
            </div>*/}
            {/*Character 팝업3*/}
        </div>
    );
};

export default LearningHomeNew;