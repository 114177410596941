import axios from 'axios';
// const apiHost = 'https://devdcon-api.eggschool.net/';
const apiHost = `${process.env.REACT_APP_BASE_URL}`;
console.log('apiHost', apiHost);

export default (async function API(type, method, query = {}, opts = {}) {
	let url = `${apiHost}${method}`;

	for (const key in query){
		url += `/${query[key]}`;
	}

	if (typeof type === 'string' && type.toUpperCase() === 'GET') {
		const params = {
			params: opts
		};

		return axios
			.get(url, params)
			.then(({ data }) => {
				let returnData;
				if (method === 'main' || method === 'act') {
					if (data.error) { throw data.error; }
					returnData = data.data;
					console.log('data_api.js_main_act_params', params);
					console.log('data_api.js_main_act', returnData);
				} else if (method === 'point') {
					returnData = data;
					console.log('data_api.js_point_params', params);
					console.log('data_api.js_point', returnData);
				} else {
					returnData = data;
					console.log('data_api.js_else_params', params);
					console.log('data_api.js_else', returnData);
				}
				return returnData;
			})
			.catch(error => {
				throw error;
			});

	} else if (typeof type === 'string' && type.toUpperCase() === 'POST') {
		return axios
			.post(url, opts)
			.then(({ data }) => {
				let returnData;
				returnData = data;
				console.log('data_api.js_string_POST', returnData);
				return returnData;
			})
			.catch(error => {
				throw error;
			});
	}
});
