import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import { TaskTimer } from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const PlayRecord = forwardRef((props, ref) => {
    const modalRef = useRef();
    const feedback = props.feedback;
    const categorySelected = props.categorySelected;
    const mediaPath = window.location.origin + props.mediaPath + '/';
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const setNextButtonEnabledR = props.setNextButtonEnabledR;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;

    const [modal, setModal] = useState(false);
    const [yesmodal, setYesmodal] = useState(false);

    // const [buttonState, setButtonState] = useState({
    //     audioPlayRef : null
    // });

    const RerecordModalRef = useRef();
    const RerecordModalOpen = () => {
        RerecordModalRef.current.style.display = 'block';
    };
    const RerecordModalClose = () => {
        RerecordModalRef.current.style.display = 'none';

    };

    const callCloseModal = () => {
        RerecordModalClose();
        console.log('callCloseModal')
        setModal(true);
    }

    const onTwoevent = (e) => {
        setYesmodal(true);
        setTimeout(() => {
            recordIconEvents(e);
        }, 300);

        console.log('yesmodal', yesmodal);
    }

    useImperativeHandle(ref, () => ({
        playrecordOpen() {
            modalRef.current.style.display = 'block';
            // setButtonState({
            //     // showNextButton: true
            // });
        },
        playrecordClose() {
            modalRef.current.style.display = 'none';
            replayIconTimer.stop();
            recordIconTimer.stop();
            recordReadyIconTimer.stop();
            Mp3Recorder.stop();
            mysoundIconTimer.stop();
        }
    }));

    const onCancel = () => {
        RerecordModalClose();
    }

    /**
     * 재생 버튼 오디오 관련
     */

    const audioPlayRef = useRef();

    const onEnded = (e) => {
        isReplaying = false;

        replayIconTimer.stop();
        playIconRef.current.src = playIcon[2];
        recordIconRef.current.src = recordIcon[2];
        if (
            (!categorySelected.sentence.file ||
                !categorySelected.sentence.file.size ||
                categorySelected.sentence.file.size < 0) &&
            !categorySelected.sentence.fileUrlPath) {
            mysoundIconRef.current.src = mysoundIcon[4];
        } else {
            mysoundIconRef.current.src = mysoundIcon[2];
        }
    };


    /**
    * 재생 버튼 관련
    */
    const playIconRef = useRef();
    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];
    let isReplaying = false;
    const playIconEvents = (e) => {
        if (categorySelected.sentence.fileUrlPath) {
            setNextButtonEnabledR();
        }
        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[3];
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isRecordingReady || isRecording || isMySoundPlaying) return;
            if (!isReplaying) {
                isReplaying = true;

                playIconRef.current.src = playIcon[0][0];
                recordIconRef.current.src = recordIcon[4];
                mysoundIconRef.current.src = mysoundIcon[4];
                replayIconTimer.start();
                console.log('1');

                //   audioRef.current.play();
                console.log('audioPlayRef', audioPlayRef);


                audioPlayRef.current.play();
                // audio.play();
                console.log('2');

            } else {
                isReplaying = false;

                replayIconTimer.stop();
                playIconRef.current.src = playIcon[2];
                recordIconRef.current.src = recordIcon[2];
                if (
                    (!categorySelected.sentence.file ||
                        !categorySelected.sentence.file.size ||
                        categorySelected.sentence.file.size < 0) &&
                    !categorySelected.sentence.fileUrlPath) {
                    mysoundIconRef.current.src = mysoundIcon[4];
                } else {
                    mysoundIconRef.current.src = mysoundIcon[2];
                }

                audioPlayRef.current.pause();
                audioPlayRef.current.currentTime = 0;

            }
        }
    };
    const replayIconTimer = new TaskTimer(500);
    replayIconTimer.add([
        {
            id: 'replayIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = playIcon[0].findIndex(item => item === playIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 2) nextIndex = 0;
                playIconRef.current.src = playIcon[0][nextIndex];
            }
        }
    ]).stop();


    /**
     * 녹음 버튼 관련
     */
    const recordStartAudioRef = useRef();
    const countBbiAudioRef = useRef();
    const recordIconRef = useRef();
    const recordIcon = [
        [
            require('../../images/activity-default/btn_record_stop_01.png').default,
            require('../../images/activity-default/btn_record_stop_02.png').default
        ],
        require('../../images/activity-default/btn_record_h.png').default,
        require('../../images/activity-default/btn_record_n.png').default,
        require('../../images/activity-default/btn_record_p.png').default,
        require('../../images/activity-default/btn_record_d.png').default,
        [
            require('../../images/activity-default/btn_record_ready_01.png').default,
            require('../../images/activity-default/btn_record_ready_02.png').default,
            require('../../images/activity-default/btn_record_ready_03.png').default
        ],
    ];
    let isRecording = false;
    let isRecordingReady = false;

    const recordIconEvents = (e) => {
        console.log('CLICK1', e.type);
        if (categorySelected.sentence.fileUrlPath) {
            setNextButtonEnabledR();
        }
        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[3];
            }
        } else if (e.type === "click" || e.type === "mouseup" || e.type === "touchend") {
            console.log('CLICK2', e);
            if (isReplaying || isRecordingReady || isMySoundPlaying) return console.log('state_recordIconEvents1_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            if (!isRecording && !categorySelected.sentence.fileUrlPath) {
                console.log('state_mouseup_record_!isRecording&& !modal');
                console.log('state_recordIconEvents2_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                isRecordingReady = true;
                console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                playIconRef.current.src = playIcon[4];
                recordIconRef.current.src = recordIcon[5][2];
                mysoundIconRef.current.src = mysoundIcon[4];

                countBbiAudioRef.current.play();
                setTimeout(() => {
                    countBbiAudioRef.current.pause();
                    countBbiAudioRef.current.currentTime = 0;

                    recordReadyIconTimer.start();
                }, 300);
            }
            // else if (!isRecording && categorySelected.sentence.fileUrlPath) {
            else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && !yesmodal) {
                console.log('!modal');
                console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'yesmodal', yesmodal, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                RerecordModalOpen();

            } else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && yesmodal) {
                RerecordModalClose();
                console.log('else');
                isRecordingReady = true;
                console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'yesmodal', yesmodal, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                playIconRef.current.src = playIcon[4];
                recordIconRef.current.src = recordIcon[5][2];
                mysoundIconRef.current.src = mysoundIcon[4];

                countBbiAudioRef.current.play();
                setTimeout(() => {
                    countBbiAudioRef.current.pause();
                    countBbiAudioRef.current.currentTime = 0;

                    recordReadyIconTimer.start();
                }, 300);
                setModal(false);

            }
            else {
                console.log('state_mouseup_record_!isRecordingElse');
                console.log('state_recordIconEvents4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                Mp3Recorder
                    .stop()
                    .getMp3().then(([buffer, blob]) => {
                        isRecording = false;

                        recordIconTimer.stop();
                        playIconRef.current.src = playIcon[2];
                        recordIconRef.current.src = recordIcon[2];
                        mysoundIconRef.current.src = mysoundIcon[2];

                        feedback(true);

                        const file = new File(buffer, 'mySound.mp3', {
                            type: blob.type,
                            lastModified: Date.now()
                        });
                        const player = new Audio(URL.createObjectURL(file));
                        console.log('data_player1', player);

                        console.log('data_categorySelected.order', categorySelected.order);

                        categorySelected.sentence.file = file;
                        categorySelected.sentence.fileUrl = URL.createObjectURL(file);
                        categorySelected.sentence.player = player;

                        console.log('state_data_file', file);
                        console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
                        console.log('data_player2', player);
                        setModal(false);
                        setYesmodal(false);

                        setNextButtonEnabled();
                        console.log('state_recordIconEvents5_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                    }).catch((e) => {
                        // TODO 녹음 오류시 처리 필요
                    });
            }
        }
    };
    const recordReadyIconTimer = new TaskTimer(700);
    recordReadyIconTimer.add([
        {
            id: 'recordReadyIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                console.log('state_recordReadyIconTimer1_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                let nowIndex = recordIcon[5].findIndex(item => item === recordIconRef.current.src);
                console.log('nowIndex', nowIndex);
                let nextIndex = nowIndex - 1;
                recordIconRef.current.src = recordIcon[5][nextIndex];

                if (nextIndex < 0) {
                    console.log('state_recordReadyIconTimer2_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                    recordReadyIconTimer.stop();

                    playIconRef.current.src = playIcon[4];
                    recordIconRef.current.src = recordIcon[0][0];
                    mysoundIconRef.current.src = mysoundIcon[4];
                    recordIconTimer.start();

                    Mp3Recorder.start().then(() => {
                        console.log('state_recordReadyIconTimer3_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                        isRecordingReady = false;
                        isRecording = true;
                        console.log('state_recordReadyIconTimer4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);


                        let recordSeconds = 1;
                        let recordLimitTimer = setInterval(() => {
                            if (isRecording) {
                                console.log('state_recordReadyIconTimer5_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                                recordSeconds++;
                                if (recordSeconds > 30) {
                                    clearInterval(recordLimitTimer);

                                    isRecording = false;

                                    recordIconTimer.stop();
                                    console.log('state_recordReadyIconTimer6_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                                    if (playIconRef.current !== null) {
                                        playIconRef.current.src = playIcon[2];
                                    }
                                    if (recordIconRef.current !== null) {
                                        recordIconRef.current.src = recordIcon[2];
                                    }
                                    if (mysoundIconRef.current !== null) {
                                        if (
                                            (!categorySelected.sentence.file ||
                                                !categorySelected.sentence.file.size ||
                                                categorySelected.sentence.file.size < 0) &&
                                            !categorySelected.sentence.fileUrlPath) {
                                            mysoundIconRef.current.src = mysoundIcon[4];
                                        } else {
                                            mysoundIconRef.current.src = mysoundIcon[2];
                                        }
                                    }


                                    Mp3Recorder.stop();

                                    recordTimeOverModalRef.current.openModal();
                                }
                            } else {
                                console.log('state_recordReadyIconTimer7_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                                clearInterval(recordLimitTimer);
                            }
                        }, 1000);
                    }).catch((e) => {
                        console.error(e);
                    });


                    recordReadyIconTimer.stop();

                    playIconRef.current.src = playIcon[4];
                    recordIconRef.current.src = recordIcon[0][0];
                    mysoundIconRef.current.src = mysoundIcon[4];
                    recordIconTimer.start();
                    // }, 500);
                } else {
                    console.log('state_recordReadyIconTimer8_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                    console.log('countBbiAudioRef', countBbiAudioRef);
                    countBbiAudioRef.current.play();
                    setTimeout(() => {
                        countBbiAudioRef.current.pause();
                        countBbiAudioRef.current.currentTime = 0;
                    }, 300);
                }
            }
        }
    ]).stop();
    const recordIconTimer = new TaskTimer(500);
    recordIconTimer.add([
        {
            id: 'recordIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                console.log('state_recordIconTimer1_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                let nowIndex = recordIcon[0].findIndex(item => item === recordIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 1) nextIndex = 0;
                recordIconRef.current.src = recordIcon[0][nextIndex];
                console.log('state_recordIconTimer2_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

            }
        }
    ]).stop();

    /**
     * 마이사운드 버튼 관련
     */
    const mysoundIconRef = useRef();
    const mysoundIcon = [
        [
            require('../../images/activity-default/btn_my_00.png').default,
            require('../../images/activity-default/btn_my_01.png').default,
            require('../../images/activity-default/btn_my_02.png').default,
            require('../../images/activity-default/btn_my_03.png').default
        ],
        require('../../images/activity-default/btn_my_h.png').default,
        require('../../images/activity-default/btn_my_n.png').default,
        require('../../images/activity-default/btn_my_p.png').default,
        require('../../images/activity-default/btn_my_d.png').default,
    ];
    let isMySoundPlaying = false;

    const mysoundIconEvents = (e) => {

        if (categorySelected.sentence.fileUrlPath) {
            setNextButtonEnabledR();
        }

        if (
            (!categorySelected.sentence.file ||
                !categorySelected.sentence.file.size ||
                categorySelected.sentence.file.size < 0) &&
            !categorySelected.sentence.fileUrlPath) return mysoundIconRef.current.src = mysoundIcon[4];;


        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[1];
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (!isMySoundPlaying) {
                isMySoundPlaying = true;

                mysoundIconTimer.start();

                playIconRef.current.src = playIcon[4];
                recordIconRef.current.src = recordIcon[4];
                mysoundIconRef.current.src = mysoundIcon[0][0];

                mysoundIconTimer.start();
                if (categorySelected.sentence.fileUrlPath && !categorySelected.sentence.player.play) {
                    categorySelected.sentence.player = new Audio(categorySelected.sentence.fileUrlPath);
                }
                categorySelected.sentence.player.addEventListener('ended', () => {
                    isMySoundPlaying = false;

                    playIconRef.current.src = playIcon[2];
                    recordIconRef.current.src = recordIcon[2];
                    playIconRef.current.src = playIcon[2];
                    recordIconRef.current.src = recordIcon[2];
                    if (
                        (!categorySelected.sentence.file ||
                            !categorySelected.sentence.file.size ||
                            categorySelected.sentence.file.size < 0) &&
                        !categorySelected.sentence.fileUrlPath) {
                        mysoundIconRef.current.src = mysoundIcon[4];
                    } else {
                        mysoundIconRef.current.src = mysoundIcon[2];
                    }

                    mysoundIconTimer.stop();
                });
                let playPromise = categorySelected.sentence.player.play();
                console.log('data_playPromise', playPromise);
                if (playPromise !== undefined) {
                    playPromise.then(function () {
                    }).catch(function (error) {
                    });
                }
            } else {
                isMySoundPlaying = false;

                playIconRef.current.src = playIcon[2];
                recordIconRef.current.src = recordIcon[2];
                if (
                    (!categorySelected.sentence.file ||
                        !categorySelected.sentence.file.size ||
                        categorySelected.sentence.file.size < 0) &&
                    !categorySelected.sentence.fileUrlPath) {
                    mysoundIconRef.current.src = mysoundIcon[4];
                } else {
                    mysoundIconRef.current.src = mysoundIcon[2];
                }

                mysoundIconTimer.stop();

                categorySelected.sentence.player.pause();
            }
        }
    };
    const mysoundIconTimer = new TaskTimer(500);
    mysoundIconTimer.add([
        {
            id: 'mysoundIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = mysoundIcon[0].findIndex(item => item === mysoundIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 3) nextIndex = 0;
                mysoundIconRef.current.src = mysoundIcon[0][nextIndex];
            }
        }
    ]).stop();


    return (
        <>
            {console.log('state_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isReplaying', isReplaying, 'yesmodal', yesmodal, 'isMySoundPlaying', isMySoundPlaying)}
            {/* { modal? null : <RerecordModal 
            RerecordModalRef={RerecordModalRef} 
            RerecordModalOpen={RerecordModalOpen}
            RerecordModalClose={RerecordModalClose}
            handleOn={onRecord} 
            handleOff={onCancel} 
        /> } */}
            {modal ? null :
                <>
                    <div className="modal_width" ref={RerecordModalRef} >
                        <div className="modal_overlay" ></div>
                        <div className="modal_wrapper exit-pop">
                            <div className="modal_inner">
                                <div className="title">Do you want to delete <br />your recording and try again?</div>
                                <div className="buttongroup text-btn">
                                    <span className="line-btn"
                                        onClick={(e) => {

                                            recordIconEvents(e);
                                            RerecordModalClose();
                                            setYesmodal(true);

                                            // recordIconRef.current.click();
                                        }}>Yes</span>
                                    <span className="full-btn" onClick={onCancel}>No</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="btm_layer record" ref={modalRef}>
                {/* <LoadBtn src={btnClose[1]} /> */}
                <audio ref={recordStartAudioRef} src={recordStartAudio} />
                <audio ref={countBbiAudioRef} src={countBbiAudio} />
                <audio ref={audioPlayRef} src={mediaPath + categorySelected.answerAudioSentence} onEnded={onEnded} />

                <div className="record-wrap">
                    <img
                        ref={playIconRef}
                        className="play-icon"
                        src={playIcon[2]}
                        alt=""
                        onMouseOver={playIconEvents}
                        onMouseLeave={playIconEvents}
                        onMouseDown={playIconEvents}
                        onMouseUp={playIconEvents}
                    />
                    <img
                        ref={recordIconRef}
                        className="record-icon"
                        src={recordIcon[2]}
                        alt=""
                        onMouseOver={recordIconEvents}
                        onMouseLeave={recordIconEvents}
                        onMouseDown={recordIconEvents}
                        onMouseUp={recordIconEvents}
                        click={recordIconEvents}
                    />
                    <img
                        ref={mysoundIconRef}
                        className="mysound-icon"
                        src={mysoundIcon[2]}
                        alt=""
                        onMouseOver={mysoundIconEvents}
                        onMouseLeave={mysoundIconEvents}
                        onMouseDown={mysoundIconEvents}
                        onMouseUp={mysoundIconEvents}
                    />
                </div>
                <div className="text-wrap">
                    {/* <p>{categorySelected.answerText}</p> */}

                    <p>{categorySelected.answerTextNormal0}<em className='point'>{categorySelected.answerTextHighlight0}</em>{categorySelected.answerTextNormal1}<em className='point'>{categorySelected.answerTextHighlight1}</em>{categorySelected.answerTextNormal2}</p>
                </div>
                {/* <span className="layer-close" onClick={modalClose}></span> */}
            </div>
        </>
    )
});

export default PlayRecord;