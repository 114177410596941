import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import styled from "styled-components";
import Answer from "../../components/activity-answer-eggstudio/Answer";
import EggStudio from "../../components/activity/EggStudio";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import EggStudioResult from "../../components/result/EggStudioResult";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import Background from "../../images/egg-studio/background.png";
import filmPattern from "../../images/egg-studio/film-pattern.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);


const EggStudioBlock = styled.div`
& {
  height: 100%;
  overflow: hidden;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  & > div {
    position: relative;
    height: 100%;
  }
}

.contents {
  min-height: 100%;

  .route-bar {
    width: calc(100% - 5%);
    height: 90px;
    border-radius: 0 0 50px 50px;
    background-color: #96c93d;
    box-shadow: 0px 11px 3px rgba(50, 50, 50, 0.1);
    margin: 0 auto 30px;
    padding: 20px 0 20px 15%;
    position:absolute;
    left:50%;
    top:0;
    transform: translateX(-50%);
    background:none;
    box-shadow:none;
    z-index:5;
    p{
      text-align: center;
      font-size: 38px;
      color: #fffffe;
      span{
        color: #fff;
      }
    }
    .cancel-icon {
      cursor: pointer;
  }
    .route-icons {
      width: 48px;
      height: 48px;
      background-color: rgba(50, 50, 50, 0.1);
      border-radius: 50%;
      margin-right: 15px;
      .route-icons-img {
        width: 24px;
    }
  }
  }

  .box-parent {
    padding:3% 0;   
    width: 100%;
    height: 100vh;
    padding:30px 0;
    display:flex;
    align-items:center;
    justify-content:center;

    .box {
      max-width: 1235px;
      background-color: #fff;
      border-radius: 80px;
      border: 1px solid rgba(50, 50, 50, 0.2);
      overflow: visible;
      margin: 0 auto;
      flex-wrap:wrap;
      height: 100%;
      background-color: #282220;
      border-radius: 32px;
      padding: 20px 32px;
      width:calc(100% - 290px);
      margin:0 auto;

      .order-bar-div{
        width: 100%;
        .now-circle{
          margin-right: 0.8%;
          width: 24px;
          height: 24px;
          border-radius:50%;
        }
        .next-circle{
            margin-right: 0.8%;
            width: 24px;
            height: 24px;
            border-radius:50%;
            background-color: #ededed;
            cursor: pointer;
        }
      }
      
      .screen-parent {
        width: 100%;
        height: 100%;
        position:relative;
                  
        .film-top {
          width: 100%;
          min-width: 100%;
          background-color: #282220;
          background-image: url(${filmPattern});
          background-repeat: repeat-x;
          min-height: 21px;
          background-size: 50px 21px;
        }
        .film-bottom{
          position:absolute;
          left:0;
          bottom:1px;
        }
        
        .title-bar {
          background-color: #282220;
          width: 100%;
          min-width: 100%;
          min-height: 107px;
          padding-top: 23px;
          padding-left: 62px;
          padding-right: 16px;
          p {
            text-align: center;
            font-size: 40px;
            color: #fffffe;
            &.page-number {
              font-size: 26px;
              @media (max-height: 768px) {
                font-size: 20px;
              }
            }
          
            span {
              color: #fff;
            }
          }
        }

        .screen {
          width: 100%;
          height: calc(100% - 177px);
          border-radius: 16px;
          background-color: #fff;
          overflow: visible;
          & >div{
            display:flex;
            align-items:center;
            justify-content:center;
            height:100%;
          }
        }
      }

      .left-button {
        position: absolute;
        top: 50%;
        left: -58px;
        transform: translate(-100%, -50%);
        cursor: pointer;
        height:auto !important;
        &.disabled{
          opacity:0.5;
        }
        @media (max-width: 1200px) {
          img{
            width:75px;
            height:75px;
          }
        }
      }

      .right-button {
        position: absolute;
        top: 50%;
        right: -58px;
        transform: translate(100%, -50%);
        cursor: pointer;
        height:auto !important;
        &.disabled{
          opacity:0.5;
        }
        @media (max-width: 1200px) {
          img{
            width:75px;
            height:75px;
          }
        }
      }

      .buttonGroup {
        text-align: center;
        margin: 1vh 0;

      }
    }
  }

  .order-bar {
    width: calc(100% - 5%);
    height: 90px;
    border-radius: 50px 50px 0 0;
    background-color: #96c93d;
    margin: 30px auto 0;
    padding: 20px 40px;
    position:absolute;
    left:50%;
    bottom:0;
    transform: translateX(-50%);
    background:none;
    display:none;
    & > div > div > div {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      border: 5px solid #fff;
      margin-left: 15px;
      cursor: pointer;
      p{
        font-size: 20px;
        margin: 2px 1px 0 0;
    }
  }
 }
}

@media (max-width: 1024px), (max-height:768px) {
  & {
    .contents{
      
      .box-parent {
        .box{
          border-radius: 50px;
          min-width: auto;
          width:75.09vw;
          .order-bar-div{
            .now-circle{
              width: 18px;
              height: 18px;
            }
            .next-circle{
              width: 18px;
              height: 18px;
            }
          }
          .screen-parent {
            .title-bar{
              min-height: 70px;
              padding-top: 20px;
              padding-left: 40px;
              padding-right: 20px;
              p{
                font-size: 26.441px;
              }
            }
            .screen{
              height:calc(100% - 125px);
            }
            .film-top{
              min-height:16px;
              background-size:40px 16px;
            }
          }
        }
      }
      .screenContainer .imgArea{
        max-width:67vh;
      }
      .route-bar{
        height: 70px;
        border-radius: 0 0 35px 35px;
        p{
          font-size: 26px;
        }
        .route-icons {
          width: 33px;
          height: 33px;
          .route-icons-img {
            width: 16px;
          }
        }
        .cancel-icon-img {
          width: 27px
        }
      }
      .order-bar {
        height: 70px;
        border-radius: 35px 35px 0 0;
        & > div > div > div {
          width: 35px;
          height: 35px;            
          p{
            font-size:18px;
          }
        }
      }
    }
  }
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
  & {    
    .contents{
      
      .box-parent {
        padding:20px 0;
        .box{
          margin:0 8%;
          width:calc(100% - 16%);
          min-width:auto;
          min-height:auto;
          border-radius:23px;
          padding:12px 20px;
          .order-bar-div{
            .now-circle{
              width: 12px;
              height: 12px;
            }
            .next-circle{
              width: 12px;
              height: 12px;
            }
          }
          .screen-parent .film-top{
            min-height:12px;
            background-size:34px 12px;
          }
          .screen-parent .title-bar p{
            font-size:24px;
          }
          .screen-parent .title-bar{
            padding:14px 2% 8px 4%;
            min-height:auto;
          }
          .screen-parent .screen{
            height:calc(100% - 97px);
          }
          .right-button{
            right:-30px;
            img{
              width:52px;
              height:52px;
            }
          }
          .left-button{
            left:-30px;
            img{
              width:52px;
              height:52px;
            }
          }
        }
      }
      .route-bar{
        .cancel-icon-img {
          width:22px;
        }
      }
    }
  }
}

  .konvajs-content {
    margin: auto;
  }
`;

const Index = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(0);
  let minRecordCategoryNum = 0;
  console.log('page_orgActList', orgActList);
  console.log('page_actList', actList);
  console.log('page_categoryList', categoryList);
  console.log('page_categorySelected', categorySelected);
  console.log('page_setNum', setNum);
  console.log('page_maxSetNum', maxSetNum);
  console.log('page_minCategoryNum', minCategoryNum);
  console.log('page_maxCategoryNum', maxCategoryNum);

  const errorModalRef = useRef();
  const routeLearningHome = () => {
    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
      case 'HHT1':
        routerName = '/hh1/learning-home';
        break;
      case 'HHT2':
        routerName = '/hh2/learning-home';
        break;
      case 'CULTURE':
        routerName = '/culture/learning-home';
        break;
      default:
        break;
    }
    history.push(routerName);
  };
  useEffect(() => {
    const fetchData = async (actContentData) => {
      let filePathReplaced = actInfo.file_path.replace(`${apiHost}`, '');
      await fetch(filePathReplaced)
        .then(res => res.json())
        .then(res => {
          console.log("data_eggstudioindex.jsx_res >>>", res);
          setOrgActList(JSON.parse(JSON.stringify(res)));
          console.log("data_eggstudioindex.jsx_JSON.parse(JSON.stringify(res)>", JSON.parse(JSON.stringify(res)));

          try {
            const stdAnswer = JSON.parse(actContentData.stdAnswer);

            res.map((res_item, res_index) => {
              res_item.category.map((category_item, category_index) => {
                if (category_item.name === 'Shadowing') {
                  category_item.sentence.map((sentence_item, sentence_index) => {
                    if (sentence_item.quiz) {
                      sentence_item.quiz.list.map((quizList_item, quizList_index) => {
                        if (stdAnswer.length > res_index &&
                          stdAnswer[res_index].category.length > category_index &&
                          stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                          stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list.length > quizList_index) {
                          var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list[quizList_index].choiceSelected;
                          if (choiceSelected) {
                            quizList_item.choiceSelected = choiceSelected;
                          }
                        }
                      });
                    }
                    if (stdAnswer.length > res_index &&
                      stdAnswer[res_index].category.length > category_index &&
                      stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                      var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                      if (fileUrlPath) {
                        sentence_item.fileUrlPath = fileUrlPath;

                        var player = new Audio(fileUrlPath);
                        if (player) {
                          sentence_item.player = player;
                        }
                      }
                    }
                  });
                }
              });
            });
          } catch (error) {
            console.log('Incorrect student answer data, ', error);
          }

          setActList(res);

          const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
          setMaxSetNum(maxSetNum);

          const categoryList = res.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
          setCategoryList(categoryList);

          categoryList.forEach((value, index) => {
            if (index === 0) {
              setMinCategoryNum(value.order);
              setCategorySelected(categoryList[value.order]);
            } else if (index === categoryList.length - 1) {
              setMaxCategoryNum(value.order);
            }
          });
        })
        .catch(error => {
          console.error(error);
          errorModalRef.current.openModal();
        });
    };

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);

  const [guideFinished, setGuideFinished] = useState(false);

  useEffect(() => {
    if (actList.length > 0) {
      const categoryList = actList.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
      setCategoryList(categoryList);

      categoryList.forEach((item, index) => {
        if (index === 0) {
          setMinCategoryNum(item.order);
          setCategorySelected(categoryList[item.order]);
        } else if (index === categoryList.length - 1) {
          setMaxCategoryNum(item.order);
        }
      });
    }
  }, [setNum]);

  const eggStudioRef = useRef();

  const closeActivityModalRef = useRef();
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  };

  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  };

  const recordTimeOverModalRef = useRef();
  const alertModalRef = useRef();
  const openAlertModal = (text) => {
    if (setNum === 1 && categorySelected.order === minRecordCategoryNum) {
      alertModalRef.current.openModal(text);
      setTimeout(() => { alertModalRef.current.closeModal(); }, 4000);
    }
  };

  const answerRef = useRef();
  const answerOpen = (recordingIndex) => {
    answerRef.current.answerOpen(recordingIndex);
  };
  const answerClose = () => {
    answerRef.current.answerClose();
  };

  const prevCategory = () => {
    answerClose();
    if (categorySelected.order > minCategoryNum) {
      setCategorySelected(categoryList[categorySelected.order - 1]);
    }
  };
  const nextCategory = () => {
    answerClose();
    if (categorySelected.name === "Main Sentence" || categorySelected.name === "Key Expression" || categorySelected.name === "Video") {
      if (categorySelected.order < maxCategoryNum) {
        setCategorySelected(categoryList[categorySelected.order + 1]);
      }
    } else {
      console.log('data_categorySelected.sentence', categorySelected.sentence);

      let newRecordExists = false;
      categorySelected.sentence.forEach(item => {
        if (item.file && item.file.size && item.file.size > 0) {
          newRecordExists = true;
          return false;
        }
      });
      console.log('data_newRecordExists', newRecordExists);
      console.log('data_item.file', categorySelected.sentence.file);
      if (newRecordExists) {
        /**
         * 학생 Act 학습 정보 저장 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
         */
        async function callApiAct() {
          const data = await API('post', 'act', {}, {
            oCode: userSelector.info.oCode,
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            level: actInfo.level,
            episodeId: episodeInfo.episodeId,
            episodeCode: episodeInfo.episodeCode,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actStatus: (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus),
            actPoint: 0,
            actCurrentPage: setNum,
            question: JSON.stringify(orgActList),
            answer: JSON.stringify(actList)
          }).catch((error) => {
            console.error(error);
          });

          console.log(data);

          userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .actStatus = (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus);

          dispatch(userStore.save(userSelector.info));

          console.log('data_actInfo.actStatus', actInfo.actStatus);


          let nextCategory;
          if (categorySelected.order < maxCategoryNum) {
            nextCategory = categoryList[categorySelected.order + 1];
          } else {
            nextCategory = {
              ...categoryList[0],
              name: 'result',
              video: actList.find(item => item.set === setNum).video,
              categoryList: categoryList
            };
          }
          setCategorySelected(nextCategory);
        }

        /**
         * 학생 파일 파일 업로드 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
         */
        async function callApiActFile() {
          let index = 0;
          for (const item of categorySelected.sentence) {
            if (item.file && item.file.size && item.file.size > 0) {
              let form = new FormData();
              form.append('file', item.file);
              form.append('data', JSON.stringify({
                productId: userSelector.info.productId,
                studentId: userSelector.info.studentId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                episodeId: episodeInfo.episodeId,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId,
                actType: actInfo.actType,
                actPage: setNum,
                studentSoundNo: index + 1
              }));

              console.log('data_actInfo.actStatus', actInfo.actStatus);


              const data = await API('post', 'act/file', {}, form).catch((error) => {
                console.error(error);
              });

              console.log("==========================");
              console.log("학생 파일 파일 업로드이 완료 되었습니다.");
              console.log(data);
              console.log("==========================");

              if (!data.data || !data.data.fileUrlPath) {
                console.error("==========================");
                console.error("녹음 파일 경로 반환이 되지 않았습니다.");
                console.error("==========================");
              }

              categorySelected.sentence[index].fileUrlPath = data.data.fileUrlPath;
            }
            index++;
          }
          callApiAct();
        }
        console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');

        callApiActFile();
      } else {
        let nextCategory;
        if (categorySelected.order < maxCategoryNum) {
          nextCategory = categoryList[categorySelected.order + 1];
        } else {
          nextCategory = {
            ...categoryList[0],
            name: 'result',
            video: actList.find(item => item.set === setNum).video,
            categoryList: categoryList
          };
        }
        setCategorySelected(nextCategory);
      }
    }
  };

  const btnPrevRef = useRef();
  const btnNextRef = useRef();
  const nextButtonRef = useRef();
  const setNextButtonEnabled = () => {
    if (btnNextRef.current) return;

    if (categorySelected.name === "Key Expression" || categorySelected.name === "Video") {
    } else {
      if (categorySelected.sentence) {
        let errorIndex = -1;
        for (var index = 0; index < categorySelected.sentence.length; index++) {
          let sentence = categorySelected.sentence[index];
          if (sentence.audio && sentence.record && ((!sentence.file || !sentence.file.size || sentence.file.size < 0) && !sentence.fileUrlPath)) {
            errorIndex = index;
            break;
          }
        }
        if (errorIndex > -1) {

        } else {
          nextButtonRef.current.children[0].src = btnNext[1];
          nextButtonRef.current.className = 'right-button';
          nextButtonRef.current.onmouseenter = () => {
            nextButtonRef.current.src = btnNext[0];
          };
          nextButtonRef.current.onmouseleave = () => {
            nextButtonRef.current.src = btnNext[1];
          };
          nextButtonRef.current.onmousedown = () => {
            nextButtonRef.current.src = btnNext[2];
          };
          nextButtonRef.current.ontouchstart = () => {
            nextButtonRef.current.src = btnNext[2];
          };
          nextButtonRef.current.onmouseup = () => {
            nextButtonRef.current.src = btnNext[1]; nextCategory();
          };
          nextButtonRef.current.ontouchend = () => {
            nextButtonRef.current.src = btnNext[1]; nextCategory();
          };
        }
      } else {

      }
    }
  };
  const btnPre = [
    require('../../images/activity-button/left-button.png').default,
    require('../../images/activity-button/left-button.png').default,
    require('../../images/activity-button/left-button.png').default,
    require('../../images/activity-button/left-button.png').default,
  ];
  const btnNext = [
    require('../../images/activity-button/right-button.png').default,
    require('../../images/activity-button/right-button.png').default,
    require('../../images/activity-button/right-button.png').default,
    require('../../images/activity-button/right-button.png').default,
  ];

  const RenderActivityArea = () => {
    if (!guideFinished) {
      return <Guide setGuideFinished={setGuideFinished} />
    } else {
      if (categorySelected.name === 'finished') {
        return (
          <Congratulations categorySelected={categorySelected} />
        );
      } else if (categorySelected.name === 'result') {
        const btnAgain = [
          require('../../images/common/btn_again_h.png').default,
          require('../../images/common/btn_again_n.png').default,
          require('../../images/common/btn_again_p.png').default,
        ];
        const btnNext = [
          require('../../images/common/btn_next_h.png').default,
          require('../../images/common/btn_next_n.png').default,
          require('../../images/common/btn_next_p.png').default,
        ];

        const LoadBtn = (props) => {
          const btnEvents = (e) => {
            let btns;
            if (btnAgain.findIndex(item => item === e.target.src) > -1) {
              btns = btnAgain;
            } else if (btnNext.findIndex(item => item === e.target.src) > -1) {
              btns = btnNext;
            } else {
              return;
            }

            if (e.type === "mouseover") {
              e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
              e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
              e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
              e.target.src = btns[0];

              if (btns === btnAgain) {
                setCategorySelected(categoryList[categorySelected.order]);
              } else if (btns === btnNext) {
                if (setNum === maxSetNum) {
                  /**
                   * 학생 Act 학습 정보 저장 API
                   * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
                   */
                  async function callApiAct() {
                    if (actInfo.actStatus !== 'Completed') {
                      const data = await API('post', 'act', {}, {
                        oCode: userSelector.info.oCode,
                        productId: userSelector.info.productId,
                        studentId: userSelector.info.studentId,
                        courseId: userSelector.info.courseId,
                        bookId: userSelector.info.bookId,
                        level: actInfo.level,
                        episodeId: episodeInfo.episodeId,
                        episodeCode: episodeInfo.episodeCode,
                        lessonId: lessonInfo.lessonId,
                        actId: actInfo.actId,
                        actType: actInfo.actType,
                        actStatus: "Completed",
                        actPoint: actInfo.actCompletPoint,
                        actCurrentPage: setNum,
                        question: JSON.stringify(orgActList),
                        answer: JSON.stringify(actList)
                      }).catch((error) => {
                        console.error(error);
                      });

                      console.log(data);

                      userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .actStatus = "Completed";
                      userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .file = categorySelected.file;
                      userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .fileUrl = categorySelected.fileUrl;
                      userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .player = categorySelected.player;

                      dispatch(userStore.save(userSelector.info));
                    }

                    let nextCategory = {
                      name: 'finished',
                      order: categorySelected.order,
                      retry: () => {
                        setSetNum(1);
                        if (actList.length > 0) {
                          const categoryList = actList.find(item => item.set === 1).category.sort((a, b) => a.order - b.order);
                          setCategoryList(categoryList);

                          categoryList.forEach((item, index) => {
                            if (index === 0) {
                              setMinCategoryNum(item.order);
                              setCategorySelected(categoryList[item.order]);
                            } else if (index === categoryList.length - 1) {
                              setMaxCategoryNum(item.order);
                            }
                          });
                        }
                      },
                    };
                    setCategorySelected(nextCategory);
                  }
                  callApiAct();
                } else {
                  setSetNum(setNum + 1);
                  if (actList.length > 0) {
                    const categoryList = actList.find(item => item.set === setNum + 1).category.sort((a, b) => a.order - b.order);
                    setCategoryList(categoryList);

                    categoryList.forEach((item, index) => {
                      if (index === 0) {
                        setMinCategoryNum(item.order);
                        setCategorySelected(categoryList[item.order]);
                      } else if (index === categoryList.length - 1) {
                        setMaxCategoryNum(item.order);
                      }
                    });
                  }
                }
              }
            }
          };

          return (
            <>
              <BrowserView>
                <img src={props.src} alt=""
                  onMouseOver={btnEvents}
                  onMouseLeave={btnEvents}
                  onMouseDown={btnEvents}
                  onMouseUp={btnEvents} />
              </BrowserView>
              <MobileView>
                <img src={props.src} alt=""
                  onTouchStart={btnEvents}
                  onTouchEnd={btnEvents} />
              </MobileView>
            </>
          )
        };

        return (
          <>
            <EggStudioResult categorySelected={categorySelected} mediaPath={actInfo.media_path.replace(`${apiHost}`, '')} />
            <div className="buttonGroup">
              <LoadBtn src={btnAgain[1]} />
              <LoadBtn src={btnNext[1]} />
            </div>
          </>
        );
      } else {
        return (
          <>
            <EggStudio
              ref={eggStudioRef}
              categorySelected={categorySelected}
              setCategorySelected={setCategorySelected}
              mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
              setNextButtonEnabled={setNextButtonEnabled}
              openAlertModal={openAlertModal}
              answerOpen={answerOpen}
            />
            {
              categorySelected.order > minCategoryNum &&
              <>
                <BrowserView>
                  <div className="left-button"
                    onMouseEnter={() => {
                      btnPrevRef.current.src = btnPre[0];
                    }}
                    onMouseLeave={() => {
                      btnPrevRef.current.src = btnPre[1];
                    }}
                    onMouseDown={() => {
                      btnPrevRef.current.src = btnPre[2];
                    }}
                    onMouseUp={() => {
                      btnPrevRef.current.src = btnPre[1];
                      prevCategory();
                    }}
                  >
                    <img src={btnPre[1]} alt="" ref={btnPrevRef} />
                  </div>
                </BrowserView>
                <MobileView>
                  <div className="left-button"
                    onTouchStart={() => { btnPrevRef.current.src = btnPre[2]; }}
                    onTouchEnd={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
                  >
                    <img src={btnPre[1]} alt="" ref={btnPrevRef} />
                  </div>
                </MobileView>
              </>
            }
            {
              actInfo.actStatus === 'Completed' ?
                <>
                  <BrowserView>
                    <div className="right-button"
                      onMouseEnter={() => {
                        btnNextRef.current.src = btnNext[0];
                      }}
                      onMouseLeave={() => {
                        btnNextRef.current.src = btnNext[1];
                      }}
                      onMouseDown={() => {
                        btnNextRef.current.src = btnNext[2];
                      }}
                      onMouseUp={() => {
                        btnNextRef.current.src = btnNext[1];
                        nextCategory();
                      }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </BrowserView>
                  <MobileView>
                    <div className="right-button"
                      onTouchStart={() => {
                        btnNextRef.current.src = btnNext[2];
                      }}
                      onTouchEnd={() => {
                        btnNextRef.current.src = btnNext[1]; nextCategory();
                      }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </MobileView>
                </>
                :
                <div className="right-button disabled" ref={nextButtonRef}>
                  <img src={btnNext[3]} alt="" />
                </div>
            }
          </>
        );
      }
    }
  };

  const checkUpRef = useRef();
  let idleSeconds = 0;
  let isIntervalRunning = true;
  // const useInterval = (callback, delay) => {
  //   const savedCallback = useRef();
  //   useEffect(() => {
  //     savedCallback.current = callback;
  //   }, [callback]);
  //   useEffect(() => {
  //     function tick() {
  //       savedCallback.current();
  //     }
  //     if (delay !== null) {
  //       let id = setInterval(tick, delay);
  //       return () => clearInterval(id);
  //     }
  //   }, [delay]);
  // };
  // useInterval(async () => {
  //   if (!isIntervalRunning) return;
  //   idleSeconds++;
  //   if (idleSeconds > (60 * 3)) {
  //     idleSeconds = 0;
  //     isIntervalRunning = false;
  //     checkUpRef.current.checkUp();
  //   }
  // }, 1000);
  const resetCheckUp = () => {
    idleSeconds = 0;
    isIntervalRunning = true;
  };

  return (
    <>
      {!isTablet && <BrowserView>
        <EggStudioBlock>
          <div
            onMouseDown={(e) => {
              if (isIntervalRunning === true) idleSeconds = 0;
            }}
            onWheel={(e) => {
              if (isIntervalRunning === true) idleSeconds = 0;
            }}
          >
            <div className="contents d-flex flex-column justify-content-between">
              <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
              <CloseActivityModal ref={closeActivityModalRef} />
              <Feedback ref={feedbackRef} />
              <RecordTimeOverModal ref={recordTimeOverModalRef} />
              <CheckUp ref={checkUpRef} resetCheckUp={resetCheckUp} />
              <AlertModal ref={alertModalRef} />
              <Answer
                ref={answerRef}
                eggStudioRef={eggStudioRef}
                categorySelected={categorySelected}
                setNextButtonEnabled={setNextButtonEnabled}
                mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                feedback={feedback}
                recordTimeOverModalRef={recordTimeOverModalRef}
              />

              <div className="route-bar d-flex align-items-center justify-content-end">
                <div className="cancel-icon" onClick={() => handleClose()}>
                  <img className="cancel-icon-img" src={CancelIcon} alt="" />
                </div>
              </div>

              <div className="box-parent">
                <div className="box d-flex justify-content-center align-items-center">
                  <div className="screen-parent d-flex flex-column">
                    <div className="film-top"></div>

                    <div className="title-bar d-flex align-items-center justify-content-between">
                      <div className="d-flex ">
                        <p>
                          {episodeInfo.episodeName} &gt; {lessonInfo.lessonTitle} &gt; <span
                            className="extra-bold">{actInfo.actTitle}</span>
                        </p>
                      </div>
                      <div className="d-flex ">
                        {guideFinished ?
                          <p className="page-number">
                            <span
                              className="extra-bold">{(categorySelected.order + 1) + ''}</span> / {categoryList.length + ''}
                          </p>
                          :
                          <p className="page-number">&nbsp;</p>
                        }
                      </div>
                    </div>
                    <div className="screen">
                      <RenderActivityArea />
                    </div>

                    <div className="film-top film-bottom"></div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </EggStudioBlock>
      </BrowserView>}
      <MobileView>
        <EggStudioBlock>
          <div
            onTouchStart={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
          >
            <div className="contents d-flex flex-column justify-content-between">
              <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
              <CloseActivityModal ref={closeActivityModalRef} />
              <Feedback ref={feedbackRef} />
              <RecordTimeOverModal ref={recordTimeOverModalRef} />
              <CheckUp ref={checkUpRef} resetCheckUp={resetCheckUp} />
              <AlertModal ref={alertModalRef} />
              <Answer
                ref={answerRef}
                eggStudioRef={eggStudioRef}
                categorySelected={categorySelected}
                setNextButtonEnabled={setNextButtonEnabled}
                mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                feedback={feedback}
                recordTimeOverModalRef={recordTimeOverModalRef}
              />

              <div className="route-bar d-flex align-items-center justify-content-end">
                <div className="cancel-icon" onClick={() => handleClose()}>
                  <img className="cancel-icon-img" src={CancelIcon} alt="" />
                </div>
              </div>

              <div className="box-parent">
                <div className="box d-flex justify-content-center align-items-center">
                  <div className="screen-parent d-flex flex-column">
                    <div className="film-top"></div>

                    <div className="title-bar d-flex align-items-center justify-content-between">
                      <div className="d-flex ">
                        <p>
                          {episodeInfo.episodeName} &gt; {lessonInfo.lessonTitle} &gt; <span className="extra-bold">{actInfo.actTitle}</span>
                        </p>
                      </div>
                      <div className="d-flex ">
                        {guideFinished ?
                          <p className="page-number">
                            <span className="extra-bold">{(categorySelected.order + 1) + ''}</span> / {categoryList.length + ''}
                          </p>
                          :
                          <p className="page-number">&nbsp;</p>
                        }
                      </div>
                    </div>
                    <div className="screen">
                      <RenderActivityArea />
                    </div>

                    <div className="film-top film-bottom"></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </EggStudioBlock>
      </MobileView>
    </>
  );
};

export default Index;
