import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Group, Image, Layer, Stage } from 'react-konva';
import styled from "styled-components";
import useImage from 'use-image';

const activityProps = {
    defaultVolume: 0.5,
    defaultWidth: 1240,
    defaultHeight: 840,
    characterMediaPath: window.location.origin + "/character/",
    textFontSize: 32,
    textColor: '#242424',
    textFontFamily: 'Nanum Barun Gothic',
    textLineHeight: 1.8,
};

const LoadImageSentenceLayer = forwardRef((props, ref) => {
    const stageRef = props.stageRef;
    const audioRef = props.audioRef;
    const categorySelected = props.categorySelected;
    const nextCategoryAtResult = props.nextCategoryAtResult;
    const mediaPath = props.mediaPath;

    let playSentenceIndex = -1;
    const playSentence = (index) => {
        playSentenceIndex = index;

        if (audioRef[playSentenceIndex].current.error === null) {
            audioRef[playSentenceIndex].current.play();
        }

        stageRef.current.find(node => node.name() === 'sentenceImage').each((node, index) => {
            if (index <= playSentenceIndex) node.visible(true);
        });
        stageRef.current.batchDraw();
    };

    let autoPlayEnded = false;
    const autoPlayOver = () => {
        autoPlayEnded = true;

        nextCategoryAtResult();
    };

    useImperativeHandle(ref, () => ({
        onCanPlayThrough(index) {
            if (index === 0) {
                playSentence(index);
            }
        },
        onEnded(index) {
            const nextIndex = index + 1;
            if (nextIndex < categorySelected.sentence.length && !autoPlayEnded) {
                playSentence(nextIndex);

                if (audioRef[nextIndex] &&
                    audioRef[nextIndex].current.error !== null &&
                    audioRef[nextIndex].current.error.message === 'MEDIA_ELEMENT_ERROR: Empty src attribute') {
                    autoPlayOver();
                }
            } else {
                autoPlayOver();
            }
        }
    }));

    const LoadSentenceImage = (props) => {
        const [normalImage, normalStatus] = useImage(props.imagePath[0]);
        const [clickImage, clickStatus] = useImage(props.imagePath[1]);

        if (!props.imagePath[0] || !props.imagePath[1]) {
            return <Image name={'sentenceImage'} visible={false} />
        }
        if (normalStatus !== "loaded" || clickStatus !== "loaded") return null;

        return <Image
            image={normalImage}
            name={'sentenceImage'}
            images={[normalImage, clickImage]}
            x={props.x}
            y={props.y}
            visible={false}
            noAudio={props.noAudio}
            index={props.index}
            quiz={props.quiz}
        />;
    };

    return (
        <Group>
            {categorySelected.sentence.map((item, index) => (
                <LoadSentenceImage
                    key={index}
                    imagePath={[(item.image ? mediaPath + item.image : undefined), (item.recordImage ? mediaPath + item.recordImage : undefined)]}
                    x={item.x}
                    y={item.y}
                    noAudio={(item.audio === undefined ? true : false)}
                    index={index}
                    quiz={item.quiz}
                />
            ))}
        </Group>
    )
});

const LoadScene = forwardRef((props, ref) => {
    const stageRef = props.stageRef;
    const LoadImage = props.LoadImage;
    const audioRef = props.audioRef;
    const categorySelected = props.categorySelected;
    const nextCategoryAtResult = props.nextCategoryAtResult;
    const mediaPath = props.mediaPath;

    const imageSentenceLayerRef = useRef();
    useImperativeHandle(ref, () => ({
        onCanPlayThrough(index) {
            if (imageSentenceLayerRef.current && imageSentenceLayerRef.current.onCanPlayThrough) {
                imageSentenceLayerRef.current.onCanPlayThrough(index);
            }
        },
        onEnded(index) {
            if (imageSentenceLayerRef.current && imageSentenceLayerRef.current.onEnded) {
                imageSentenceLayerRef.current.onEnded(index);
            }
        }
    }));

    return (
        <Layer>
            <LoadImage imagePath={mediaPath + categorySelected.image} x={'center'} y={100} />
            {
                categorySelected.subImage &&
                <LoadImage imagePath={mediaPath + categorySelected.subImage.image} x={categorySelected.subImage.x} y={categorySelected.subImage.y} />
            }
            <LoadImageSentenceLayer
                ref={imageSentenceLayerRef}
                stageRef={stageRef}
                LoadImage={LoadImage}
                audioRef={audioRef}
                categorySelected={categorySelected}
                nextCategoryAtResult={nextCategoryAtResult}
                mediaPath={mediaPath}
            />
        </Layer>
    );
});

const LoadStage = forwardRef((props, ref) => {
    const audioRef = props.audioRef;
    const categorySelected = props.categorySelected;
    const nextCategoryAtResult = props.nextCategoryAtResult;
    const containerRef = props.containerRef;
    const mediaPath = props.mediaPath;

    const stageRef = useRef();

    const LoadImage = (props) => {
        const [image, status] = useImage(props.imagePath);
        if (status !== "loaded") return null;
        let x = (props.x === 'center' ? (activityProps.defaultWidth - image.width) / 2 : props.x);
        let y = props.y;
        if (props.center) {
            x = (activityProps.defaultWidth - image.width) / 2;
            y = (activityProps.defaultHeight - image.height) / 2;
        }
        return <Image
            id={props.id || ''}
            name={props.name || ''}
            image={image}
            x={x}
            y={y}
            stroke={props.stroke}
            strokeWidth={props.strokeWidth}
            cornerRadius={props.cornerRadius}
            onMouseOver={props.events}
            onMouseLeave={props.events}
            onMouseDown={props.events}
            onMouseUp={props.events}
            onTouchStart={props.events}
            onTouchEnd={props.events}
        />;
    };

    let containerWidth = activityProps.defaultWidth;
    let containerHeight = activityProps.defaultHeight;
    let scaleX, scaleY, scale;
    useEffect(() => {
        containerWidth = containerRef.current.offsetWidth
        containerHeight = containerRef.current.offsetHeight;
        scaleX = containerWidth / activityProps.defaultWidth;
        scaleY = containerHeight / activityProps.defaultHeight;
        if (scaleX > scaleY) {
            containerWidth = activityProps.defaultWidth * scaleY;
        } else if (scaleX < scaleY) {
            containerHeight = activityProps.defaultHeight * scaleX;
        }
        scale = Math.min(scaleX, scaleY);
        stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
    }, []);
    useEffect(() => {
        const resizeListener = () => {
            containerWidth = containerRef.current.offsetWidth;
            containerHeight = containerRef.current.offsetHeight;
            scaleX = containerWidth / activityProps.defaultWidth;
            scaleY = containerHeight / activityProps.defaultHeight;
            if (scaleX > scaleY) {
                containerWidth = activityProps.defaultWidth * scaleY;
            } else if (scaleX < scaleY) {
                containerHeight = activityProps.defaultHeight * scaleX;
            }
            scale = Math.min(scaleX, scaleY);
            stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    });

    const sceneRef = useRef();
    useImperativeHandle(ref, () => ({
        onCanPlayThrough(index) {
            sceneRef.current.onCanPlayThrough(index);
        },
        onEnded(index) {
            sceneRef.current.onEnded(index);
        }
    }));

    return (
        <Stage width={activityProps.defaultWidth} height={activityProps.defaultHeight} ref={stageRef}>
            <LoadScene
                ref={sceneRef}
                stageRef={stageRef}
                LoadImage={LoadImage}
                audioRef={audioRef}
                categorySelected={categorySelected}
                nextCategoryAtResult={nextCategoryAtResult}
                mediaPath={mediaPath}
            />
        </Stage>
    );
});

const MiniSitcomResult = (props) => {
    const infoRef = useRef();

    const categorySelected = props.categorySelected;
    const nextCategoryAtResult = props.nextCategoryAtResult;
    const mediaPath = window.location.origin + props.mediaPath + '/';

    const containerRef = useRef();
    const audioRef = [];
    const loadStageRef = useRef();

    const onCanPlayThrough = (index) => {
        if (index === 0) {
            if (categorySelected.first) {
                setTimeout(() => {
                    infoRef.current.style.display = 'none';
                    audioRef[index].current.volume = activityProps.defaultVolume;
                    loadStageRef.current.onCanPlayThrough(index);
                }, 2000);
            } else {
                audioRef[index].current.volume = activityProps.defaultVolume;
                loadStageRef.current.onCanPlayThrough(index);
            }
        }
    }

    const onEnded = (index) => {
        loadStageRef.current.onEnded(index);
    }

    const LoadAudios = () => {
        categorySelected.sentence.forEach(() => {
            audioRef.push(React.createRef());
        });
        console.log('result_audio', audioRef);
        console.log('result_categorySelected.sentence', categorySelected.sentence);
        return (
            <>
                {categorySelected.sentence.map((item, index) => (
                    <audio
                        key={index}
                        ref={audioRef[index]}
                        src={(item.audio ? (item.noRecord ? item.audio : (item.fileUrlPath ? item.fileUrlPath : item.fileUrl)) : '')}
                        onCanPlayThrough={() => onCanPlayThrough(index)}
                        onEnded={() => onEnded(index)}
                    />
                ))}
            </>
        );
    };


    const MiniSitcomResultBlock = styled.div`
        width:100%;
        height:100%;
        & {
            .info-parent {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                padding:30px;
                display: ${categorySelected.first ? 'flex' : 'none'};
                z-index:10;
    
                .info-child {
                    background: rgb(51, 51, 51, 0.98);
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    z-index: 999;
                    margin:0 auto;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                }
                .info-img {
                    margin: 0 auto;
                    width: fit-content;
                }
                .info-content {
                    font-size: 2rem;
                    color: #fff;
                    margin: 5.9vh auto 0 auto;
                    width: fit-content;
                }
            }
        }
        /*태블릿 7인치 이하*/
        @media (max-height:650px) {
            & {
                .info-parent {
                    padding:20px 20px;
                    .info-child{
                        border-radius: 25px;
                    }
                    .info-img {
                        img{
                            width:147px;
                        }
                    }
                    .info-content {
                        font-size: 19px;
                    }
                }
            }
        }
    `;

    return (
        <MiniSitcomResultBlock ref={containerRef}>
            <div ref={infoRef} className="info-parent">
                <div className="info-child">
                    <div>
                        <div className="info-img"><img src={require('../../images/common/layer_pop_01.png').default} /></div>
                        <div className="info-content">Now enjoy your movie!</div>
                    </div>
                </div>
            </div>
            <LoadAudios />
            <LoadStage
                ref={loadStageRef}
                audioRef={audioRef}
                categorySelected={categorySelected}
                nextCategoryAtResult={nextCategoryAtResult}
                containerRef={containerRef}
                mediaPath={mediaPath}
            />
        </MiniSitcomResultBlock>
    );
};

export default MiniSitcomResult;