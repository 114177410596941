import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";

/*타임라인 조각 이미지 - 기본*/
import Time01 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_01.png";
import Time02 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_02.png";
import Time03 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_03.png";
import Time04 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_04.png";
import Time05 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_05.png";
import Time06 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_06.png";
import Time07 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_07.png";
import Time08 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_08.png";
import Time09 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_09.png";
import Time10 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_10.png";
import Time11 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_11.png";
import Time12 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_12.png";
import Time13 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_13.png";
import Time14 from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_14.png";

/*타임라인 조각 이미지 - 질문*/
import Time02_q from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_02_blank.png";
import Time04_q from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_04_blank.png";
import Time06_q from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_06_blank.png";
import Time09_q from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_09_blank.png";
import Time13_q from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_13_blank.png";

/*타임라인 문제 하단 이미지*/
import question_01_A from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q01_a.png"
import question_01_B from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q01_b.png"
import question_02_A from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q02_a.png"
import question_02_B from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q02_b.png"
import question_03_A from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q03_a.png"
import question_03_B from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q03_b.png"
import question_04_A from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q04_a.png"
import question_04_B from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q04_b.png"
import question_05_A from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q05_a.png"
import question_05_B from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_q05_b.png"

import PopReply from "../../images/pop-default/restart-btn.png";
import PopHome from "../../images/pop-default/home-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";

/*css*/
import "../../css/timeline.css";


const Index = ({ type }) => {

    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive); 
    };

    return (
        <div className="time-line">
            <div>
                <div className="contents d-flex flex-column justify-content-between">
                    <div className="route_bar d-flex align-items-center justify-content-between route-bar-background-hh1">
                        <div className="d-flex">
                            <p>
                                Lesson 3 &gt; <span className="extra-bold">Time Line</span>
                            </p>
                        </div>
                        <div className="right_icon">
                            <img className="help_icon_img" src={HelpIcon} alt="" />
                            <img className="cancel_icon_img" src={CancelIcon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/*
            타임라인 개발 참고사항
                1. 학습 진입시 첫번째칸 오디오 자동송출 -> 오디오 송출될 동안 클래스 show와 on 추가되도록 개발 필요, 송출 끝나면 on클래스만 제거 후 다음 칸 show와 on클래스 추가
                2. 각 조각 클릭시 클래스 on 추가되도록 개발 필요
                3. 문제 푸는 조각의 경우 태그 안에 img 변경되야 합니다.
                4. 문제푸는 조각 클릭시 문제 선택지 팝업노출 -> 144번째줄 .time-pop-inner 기본으로 'display:none' 상태임 노출시 'display:block'으로 변경
            */}
            <div className="box_parent hht1-timeline-ep01">{/*배경 다르게 변경시 에피소드별로 클래스 추가 hht1-timeline-ep01, hht1-timeline-ep02, hht1-timeline-ep03...*/}
                <div className="box-wrap d-flex justify-content-center align-items-center">
                    <div className="timeline-bg-area"> 
                        <div className='bg line01 left top'
                            style={{'width': '32.5%','height':'28%','left':'0','top':'2%'}}>
                            <img src={Time01} alt="" />
                        </div>
                        <div className='bg line02'
                            style={{'width': '34.5%','height':'30%','left':'10%','top':'21%'}}>
                            <img src={Time02_q} alt="" />
                        </div>
                        <div className="bg line03"
                            style={{'width': '26.5%','height':'21%','left':'19.2%','top':'43%'}}>
                            <img src={Time03} alt="" />
                        </div>
                        <div className="bg line04 left"
                            style={{'width': '18.8%','height':'42.5%','left':'1%','top':'35%'}}>
                            <img src={Time04_q} alt="" />
                        </div>
                        <div className="bg line05 left"
                            style={{'width': '32%','height':'26%','left':'1%','top':'65.5%'}}>
                            <img src={Time05} alt="" />
                        </div>
                        <div className="bg line06 bottom"
                            style={{'width': '27.3%','height':'29.5%','left':'23.5%','top':'70%'}}>
                            <img src={Time06_q} alt="" />
                        </div>
                        <div className="bg line07 bottom"
                            style={{'width': '24%','height':'33%','left':'50.2%','top':'68.5%'}}>
                            <img src={Time07} alt="" /> 
                        </div>
                        <div className="bg line08 bottom right"
                            style={{'width': '24.8%','height':'38%','left':'74%','top':'60%'}}>
                            <img src={Time08} alt="" />
                        </div>
                        <div className="bg line09 right"
                            style={{'width': '27.9%','height':'16%','left':'72%','top':'53.5%'}}>
                            <img src={Time09_q} alt="" />
                        </div>
                        <div className="bg line10"
                            style={{'width': '17.5%','height':'24.5%','left':'55.2%','top':'45%'}}>
                            <img src={Time10} alt="" />
                        </div>
                        <div className="bg line11"
                            style={{'width': '19.5%','height':'14%','left':'55%','top':'37.2%'}}>
                            <img src={Time11} alt="" />
                        </div>
                        <div className="bg line12 right"
                            style={{'width': '24.5%','height':'33%','left':'74.3%','top':'18%'}}>
                            <img src={Time12} alt="" />
                        </div>
                        <div className="bg line13 top right"
                            style={{'width': '22.5%','height':'16.5%','left':'77.5%','top':'8%'}}>
                            <img src={Time13_q} alt="" />
                        </div>
                        <div className="bg line14 top"
                            style={{'width': '22.5%','height':'32.5%','left':'55.8%','top':'0'}}>
                            <img src={Time14} alt="" />
                        </div>                                                
                    </div>
                </div>
            {/*타임라인 문제 팝업*/}
            <div className="time-pop-inner">
                <div className="time-line-pop">
                    <div className="question-layer">
                        <div className="select select01" tabIndex="0">
                            <p>Suddenly, Matt calls to Harry for help, and Stephanie calls to Holly for help</p>
                            <img src={question_01_A} alt="" />
                        </div>
                        <div className="select select02" tabIndex="0">
                            <p>Then, Holly reaches under again and picks up the glass.</p>
                            <img src={question_01_B} alt="" />
                        </div>
                    </div>
                    <div className="buttongroup">
                        <div>
                            <button className="check-btn">Submit</button>
                        </div>
                    </div>
                </div>
                <span className="shadow"></span>
            </div>
            {/*타임라인 문제 팝업*/}
            </div>

            
        </div>
    );
};

export default Index;
