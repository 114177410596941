import MicRecorder from 'mic-recorder-to-mp3';
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Answer = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = window.location.origin + props.mediaPath + '/';
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;

    const [quizState, setQuizState] = useState({
        type: '',
        recordingIndex: -1,
        showNextButton: false
    });

    useImperativeHandle(ref, () => ({
        answerOpen(recordingIndex) {
            setQuizState({
                type: 'R',
                recordingIndex: recordingIndex,
                showNextButton: quizState.showNextButton
            });
        },
        answerClose() {
            setQuizState({
                type: '',
                recordingIndex: -1,
                showNextButton: false
            });
        }
    }));

    const btnClose = [
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_h.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_n.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_p.png').default,
    ];
    const btnSubmit = [
        require('../../images/activity-minisitcom/sitcom_pop_btn_submit_h.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_submit_n.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_submit_p.png').default,
    ];
    // 브랜치명: dev002 녹음 기능 중 Submit 버튼 삭제
    const LoadBtn = (props) => {
        let btns, className;
        if (btnClose.findIndex(item => item === props.src) > -1) {
            btns = btnClose;
            className = 'home-btn';
        } else if (btnSubmit.findIndex(item => item === props.src) > -1) {
            return null;
            // btns = btnSubmit;
            // className = 'submit-btn';
        } else {
            return null;
        }
        const btnEvents = (e) => {
            if (e.type === "mouseover") {
                e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
                e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
                e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
                e.target.src = btns[0];

                if (btns === btnClose) {
                    setQuizState({
                        type: '',
                        recordingIndex: -1,
                        showNextButton: false
                    });
                } else if (btns === btnSubmit) {
                    setQuizState({
                        type: '',
                        recordingIndex: -1,
                        showNextButton: false
                    });
                }
            }
        };
        return (
            <>
                <BrowserView>
                    <img
                        className={className}
                        src={props.src}
                        alt=""
                        onMouseOver={btnEvents}
                        onMouseLeave={btnEvents}
                        onMouseDown={btnEvents}
                        onMouseUp={btnEvents} />
                </BrowserView>
                <MobileView>
                    <img
                        className={className}
                        src={props.src}
                        alt=""
                        onTouchStart={btnEvents}
                        onTouchEnd={btnEvents} />
                </MobileView>
            </>
        )
    };

    /**
     * 재생 버튼 오디오 관련
     */
    const audioRef = [];
    const LoadAudios = () => {
        if (!categorySelected.sentence || categorySelected.sentence.length < 1) return null;

        categorySelected.sentence.forEach(() => {
            audioRef.push(React.createRef());
        });
        return (
            <>
                {categorySelected.sentence.map((item, index) => (
                    <audio
                        key={index}
                        ref={audioRef[index]}
                        src={(item.audio ? mediaPath + item.audio : '')}
                        onEnded={onEnded}
                    />
                ))}
            </>
        );
    };
    const onEnded = (e) => {
        isReplaying = false;

        replayIconTimer.stop();
        playIconRef.current.src = playIcon[2];
        recordIconRef.current.src = recordIcon[2];
        if (quizState.recordingIndex === -1 ||
            (!categorySelected.sentence[quizState.recordingIndex].file ||
                !categorySelected.sentence[quizState.recordingIndex].file.size ||
                categorySelected.sentence[quizState.recordingIndex].file.size < 0) &&
            !categorySelected.sentence[quizState.recordingIndex].fileUrlPath) {
            mysoundIconRef.current.src = mysoundIcon[4];
        } else {
            mysoundIconRef.current.src = mysoundIcon[2];
        }
    };

    /**
     * 재생 버튼 관련
     */
    const playIconRef = useRef();
    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];
    let isReplaying = false;
    const playIconEvents = (e) => {
        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[3];
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isRecordingReady || isRecording || isMySoundPlaying) return console.log('state_playIconEvents1_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying );
            if (!isReplaying) {
                console.log('state_playIconEvents2_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                isReplaying = true;
                console.log('state_playIconEvents3_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                playIconRef.current.src = playIcon[0][0];
                recordIconRef.current.src = recordIcon[4];
                mysoundIconRef.current.src = mysoundIcon[4];
                replayIconTimer.start();

                audioRef[quizState.recordingIndex].current.play();
            } else {
                console.log('state_playIconEvents4_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                isReplaying = false;
                console.log('state_playIconEvents5_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                replayIconTimer.stop();
                playIconRef.current.src = playIcon[2];
                recordIconRef.current.src = recordIcon[2];
                if (quizState.recordingIndex === -1 ||
                    (!categorySelected.sentence[quizState.recordingIndex].file ||
                        !categorySelected.sentence[quizState.recordingIndex].file.size ||
                        categorySelected.sentence[quizState.recordingIndex].file.size < 0) &&
                    !categorySelected.sentence[quizState.recordingIndex].fileUrlPath) {
                        console.log('state_playIconEvents6_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                    mysoundIconRef.current.src = mysoundIcon[4];
                } else {
                    console.log('state_playIconEvents7_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                    mysoundIconRef.current.src = mysoundIcon[2];
                }
                console.log('state_playIconEvents8_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
              

                audioRef[quizState.recordingIndex].current.pause();
            }
        }
    };
    const replayIconTimer = new TaskTimer(500);
    replayIconTimer.add([
        {
            id: 'replayIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = playIcon[0].findIndex(item => item === playIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 2) nextIndex = 0;
                playIconRef.current.src = playIcon[0][nextIndex];
            }
        }
    ]).stop();

    /**
     * 녹음 버튼 관련
     */
    const recordStartAudioRef = useRef();
    const countBbiAudioRef = useRef();
    const recordIconRef = useRef();
    const recordIcon = [
        [
            require('../../images/activity-default/btn_record_stop_01.png').default,
            require('../../images/activity-default/btn_record_stop_02.png').default
        ],
        require('../../images/activity-default/btn_record_h.png').default,
        require('../../images/activity-default/btn_record_n.png').default,
        require('../../images/activity-default/btn_record_p.png').default,
        require('../../images/activity-default/btn_record_d.png').default,
        [
            require('../../images/activity-default/btn_record_ready_01.png').default,
            require('../../images/activity-default/btn_record_ready_02.png').default,
            require('../../images/activity-default/btn_record_ready_03.png').default
        ],
    ];
    let isRecording = false;
    let isRecordingReady = false;
    // 브랜치명: dev001 태블릿 녹음 수정코드
    const recordIconEvents = (e) => {
        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[3];
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isReplaying || isRecordingReady || isMySoundPlaying) return   console.log('state_recordIconEvents1_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            if (!isRecording) {
                console.log('state_mouseup_record_!isRecording');
                console.log('state_recordIconEvents2_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
              
                isRecordingReady = true;
                console.log('state_recordIconEvents3_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                playIconRef.current.src = playIcon[4];
                recordIconRef.current.src = recordIcon[5][2];
                mysoundIconRef.current.src = mysoundIcon[4];

                countBbiAudioRef.current.play();
                setTimeout(() => {
                    countBbiAudioRef.current.pause();
                    countBbiAudioRef.current.currentTime = 0;

                    recordReadyIconTimer.start();
                }, 300);
            } else {
                console.log('state_mouseup_record_!isRecordingElse');
                console.log('state_recordIconEvents4_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
           
                Mp3Recorder
                    .stop()
                    .getMp3().then(([buffer, blob]) => {
                        isRecording = false;

                        recordIconTimer.stop();
                        playIconRef.current.src = playIcon[2];
                        recordIconRef.current.src = recordIcon[2];
                        mysoundIconRef.current.src = mysoundIcon[2];

                        feedback(true);

                        const file = new File(buffer, 'mySound.mp3', {
                            type: blob.type,
                            lastModified: Date.now()
                        });
                        const player = new Audio(URL.createObjectURL(file));
                        console.log('data_player1', player);
                        console.log('data_categorySelected.sentence', categorySelected.sentence);
                        console.log('data_categorySelected.sentence[quizState.recordingIndex]', categorySelected.sentence[quizState.recordingIndex]);
                        console.log('data_quizState', quizState);
                        console.log('data_quizState.recordingIndex', quizState.recordingIndex);

                        categorySelected.sentence[quizState.recordingIndex].file = file;
                        categorySelected.sentence[quizState.recordingIndex].fileUrl = URL.createObjectURL(file);
                        categorySelected.sentence[quizState.recordingIndex].player = player;

                        console.log('state_data_file', file);
                        console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
                        console.log('data_player2', player);
                        // 브랜치명: dev002 녹음 기능 중 Submit 버튼 삭제
                        setQuizState({
                            recordingIndex: quizState.recordingIndex,
                            type: quizState.type,
                            quizSelected: quizState.quizSelected,

                        });

                        setNextButtonEnabled();
                        console.log('state_recordIconEvents5_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                    }).catch((e) => {
                        // TODO 녹음 오류시 처리 필요
                    });
            }
        }
    };
    const recordReadyIconTimer = new TaskTimer(700);
    recordReadyIconTimer.add([
        {
            id: 'recordReadyIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                console.log('state_recordReadyIconTimer1_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                let nowIndex = recordIcon[5].findIndex(item => item === recordIconRef.current.src);
                let nextIndex = nowIndex - 1;
                recordIconRef.current.src = recordIcon[5][nextIndex];

                if (nextIndex < 0) {
                    console.log('state_recordReadyIconTimer2_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            
                    recordReadyIconTimer.stop();

                    playIconRef.current.src = playIcon[4];
                    recordIconRef.current.src = recordIcon[0][0];
                    mysoundIconRef.current.src = mysoundIcon[4];
                    recordIconTimer.start();

                    // recordStartAudioRef.current.play();
                    // setTimeout(() => {
                    //     recordStartAudioRef.current.pause();
                    //     recordStartAudioRef.current.currentTime = 0;

                    Mp3Recorder.start().then(() => {
                        console.log('state_recordReadyIconTimer3_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            
                        isRecordingReady = false;
                        isRecording = true;
                        console.log('state_recordReadyIconTimer4_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            
                        let recordSeconds = 1;
                        let recordLimitTimer = setInterval(() => {
                            if (isRecording) {
                                console.log('state_recordReadyIconTimer5_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            
                                recordSeconds++;
                                if (recordSeconds > 30) {
                                    clearInterval(recordLimitTimer);

                                    isRecording = false;

                                    recordIconTimer.stop();
                                    console.log('state_recordReadyIconTimer6_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            

                                    if (playIconRef.current !== null) {
                                        playIconRef.current.src = playIcon[2];
                                    }
                                    if (recordIconRef.current !== null) {
                                        recordIconRef.current.src = recordIcon[2];
                                    }
                                    if (mysoundIconRef.current !== null) {
                                        if (quizState.recordingIndex === -1 ||
                                            (!categorySelected.sentence[quizState.recordingIndex].file ||
                                                !categorySelected.sentence[quizState.recordingIndex].file.size ||
                                                categorySelected.sentence[quizState.recordingIndex].file.size < 0) &&
                                            !categorySelected.sentence[quizState.recordingIndex].fileUrlPath) {
                                            mysoundIconRef.current.src = mysoundIcon[4];
                                        } else {
                                            mysoundIconRef.current.src = mysoundIcon[2];
                                        }
                                    }

                                    Mp3Recorder.stop();

                                    recordTimeOverModalRef.current.openModal();
                                }
                            } else {
                                console.log('state_recordReadyIconTimer7_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            
                                clearInterval(recordLimitTimer);
                            }
                        }, 1000);
                    }).catch((e) => {
                        console.error(e);
                    });
                    // }, 500);
                } else {
                    console.log('state_recordReadyIconTimer8_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            
                    countBbiAudioRef.current.play();
                    setTimeout(() => {
                        countBbiAudioRef.current.pause();
                        countBbiAudioRef.current.currentTime = 0;
                    }, 300);
                }
            }
        }
    ]).stop();
    const recordIconTimer = new TaskTimer(500);
    recordIconTimer.add([
        {
            id: 'recordIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                console.log('state_recordIconTimer1_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            
                let nowIndex = recordIcon[0].findIndex(item => item === recordIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 1) nextIndex = 0;
                recordIconRef.current.src = recordIcon[0][nextIndex];
                console.log('state_recordIconTimer2_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
            
            }
        }
    ]).stop();

    /**
     * 마이사운드 버튼 관련
     */
    const mysoundIconRef = useRef();
    const mysoundIcon = [
        [
            require('../../images/activity-default/btn_my_00.png').default,
            require('../../images/activity-default/btn_my_01.png').default,
            require('../../images/activity-default/btn_my_02.png').default,
            require('../../images/activity-default/btn_my_03.png').default
        ],
        require('../../images/activity-default/btn_my_h.png').default,
        require('../../images/activity-default/btn_my_n.png').default,
        require('../../images/activity-default/btn_my_p.png').default,
        require('../../images/activity-default/btn_my_d.png').default,
    ];
    let isMySoundPlaying = false;
    // 브랜치명: dev001 태블릿 녹음 수정코드
    const mysoundIconEvents = (e) => {
        if (quizState.recordingIndex === -1 ||
            (!categorySelected.sentence[quizState.recordingIndex].file ||
                !categorySelected.sentence[quizState.recordingIndex].file.size ||
                categorySelected.sentence[quizState.recordingIndex].file.size < 0) &&
            !categorySelected.sentence[quizState.recordingIndex].fileUrlPath) return;

        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[1];
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isReplaying || isRecordingReady || isRecording) return;
            if (!isMySoundPlaying) {
                isMySoundPlaying = true;

                mysoundIconTimer.start();

                playIconRef.current.src = playIcon[4];
                recordIconRef.current.src = recordIcon[4];
                mysoundIconRef.current.src = mysoundIcon[0][0];

                mysoundIconTimer.start();

                if (categorySelected.sentence[quizState.recordingIndex].fileUrlPath && !categorySelected.sentence[quizState.recordingIndex].player.play) {
                    categorySelected.sentence[quizState.recordingIndex].player = new Audio(categorySelected.sentence[quizState.recordingIndex].fileUrlPath);
                }
                categorySelected.sentence[quizState.recordingIndex].player.addEventListener('ended', () => {
                    isMySoundPlaying = false;

                    playIconRef.current.src = playIcon[2];
                    recordIconRef.current.src = recordIcon[2];
                    playIconRef.current.src = playIcon[2];
                    recordIconRef.current.src = recordIcon[2];
                    if (quizState.recordingIndex === -1 ||
                        (!categorySelected.sentence[quizState.recordingIndex].file ||
                            !categorySelected.sentence[quizState.recordingIndex].file.size ||
                            categorySelected.sentence[quizState.recordingIndex].file.size < 0) &&
                        !categorySelected.sentence[quizState.recordingIndex].fileUrlPath) {
                        mysoundIconRef.current.src = mysoundIcon[4];
                    } else {
                        mysoundIconRef.current.src = mysoundIcon[2];
                    }

                    mysoundIconTimer.stop();
                });
                let playPromise = categorySelected.sentence[quizState.recordingIndex].player.play();
                if (playPromise !== undefined) {
                    playPromise.then(function () {
                    }).catch(function (error) {
                    });
                }
            } else {
                isMySoundPlaying = false;

                playIconRef.current.src = playIcon[2];
                recordIconRef.current.src = recordIcon[2];
                if (quizState.recordingIndex === -1 ||
                    (!categorySelected.sentence[quizState.recordingIndex].file ||
                        !categorySelected.sentence[quizState.recordingIndex].file.size ||
                        categorySelected.sentence[quizState.recordingIndex].file.size < 0) &&
                    !categorySelected.sentence[quizState.recordingIndex].fileUrlPath) {
                    mysoundIconRef.current.src = mysoundIcon[4];
                } else {
                    mysoundIconRef.current.src = mysoundIcon[2];
                }

                mysoundIconTimer.stop();

                categorySelected.sentence[quizState.recordingIndex].player.pause();
            }
        }
    };
    const mysoundIconTimer = new TaskTimer(500);
    mysoundIconTimer.add([
        {
            id: 'mysoundIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = mysoundIcon[0].findIndex(item => item === mysoundIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 3) nextIndex = 0;
                mysoundIconRef.current.src = mysoundIcon[0][nextIndex];
            }
        }
    ]).stop();

    const AnswerBlock = styled.div`
    & {
        display: ${(quizState.type === '' ? 'none' : 'block')};
        position: fixed;
        width: 100%;
        height: 26vh;
        bottom: 0;
        z-index: 99;
        background: #fff;
        box-shadow: 0 -3px 3px 2px rgb(150 150 150 / 15%);
        .home-btn {
            height: 3vh;
            position: absolute;
            top: 4vh;
            right: 3vw;
            z-index: 10;
            cursor: pointer;
        }
        .back-btn {
            height: 6vh;
            position: absolute;
            bottom: 5vh;
            left: 16vw;
            cursor: pointer;
        }
        .submit-btn {
            height: 6vh;
            position: absolute;
            bottom: 5vh;
            right: 16vw;
            cursor: pointer;
        }
        .word-wrap {
            min-height: 5vh;
            margin-top: 3vh;
            text-align: center;
            font-size: 3vh;
            color: #242424;
            font-weight: bold;
        }
        .record-wrap {
            margin-top: 3vh;
            text-align: center;
            .play-icon {
                margin-right: 3vh;
                height: 10vh;
                cursor: pointer;
            }
            .record-icon {
                margin-right: 3vh;
                height: 10vh;
                cursor: pointer;
            }
            .mysound-icon {
                height: 10vh;
                cursor: pointer;
            }
        }
    }
    `;

    return (
        <AnswerBlock>
             { console.log('state_isRecordingReady',isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying )}
            <LoadBtn src={btnClose[1]} />
            <LoadAudios />
            <audio ref={recordStartAudioRef} src={recordStartAudio} />
            <audio ref={countBbiAudioRef} src={countBbiAudio} />
            <div className="word-wrap">
                {quizState.recordingIndex > -1 && categorySelected.sentence[quizState.recordingIndex].text}
            </div>
            <>
                {!isTablet && <BrowserView>
                    <div className="record-wrap">
                        <img
                            ref={playIconRef}
                            className="play-icon"
                            src={playIcon[2]}
                            alt=""
                            onMouseOver={playIconEvents}
                            onMouseLeave={playIconEvents}
                            onMouseDown={playIconEvents}
                            onMouseUp={playIconEvents}
                        />
                        <img
                            ref={recordIconRef}
                            className="record-icon"
                            src={recordIcon[2]}
                            alt=""
                            onMouseOver={recordIconEvents}
                            onMouseLeave={recordIconEvents}
                            onMouseDown={recordIconEvents}
                            onMouseUp={recordIconEvents}
                        />
                        <img
                            ref={mysoundIconRef}
                            className="mysound-icon"
                            src={(quizState.recordingIndex === -1 ||
                                !categorySelected.sentence[quizState.recordingIndex] ||
                                (!categorySelected.sentence[quizState.recordingIndex].file ||
                                    !categorySelected.sentence[quizState.recordingIndex].file.size ||
                                    categorySelected.sentence[quizState.recordingIndex].file.size < 0) &&
                                !categorySelected.sentence[quizState.recordingIndex].fileUrlPath) ?
                                mysoundIcon[4]
                                :
                                mysoundIcon[2]
                            }
                            alt=""
                            onMouseOver={mysoundIconEvents}
                            onMouseLeave={mysoundIconEvents}
                            onMouseDown={mysoundIconEvents}
                            onMouseUp={mysoundIconEvents}
                        />
                    </div>
                </BrowserView>}
                <MobileView>
                    <div className="record-wrap">
                        <img
                            ref={playIconRef}
                            className="play-icon"
                            src={playIcon[2]}
                            alt=""
                            onTouchStart={playIconEvents}
                            onTouchEnd={playIconEvents}
                        />
                        <img
                            ref={recordIconRef}
                            className="record-icon"
                            src={recordIcon[2]}
                            alt=""
                            onTouchStart={recordIconEvents}
                            onTouchEnd={recordIconEvents}
                        />
                        <img
                            ref={mysoundIconRef}
                            className="mysound-icon"
                            src={(quizState.recordingIndex === -1 ||
                                !categorySelected.sentence[quizState.recordingIndex] ||
                                (!categorySelected.sentence[quizState.recordingIndex].file ||
                                    !categorySelected.sentence[quizState.recordingIndex].file.size ||
                                    categorySelected.sentence[quizState.recordingIndex].file.size < 0) &&
                                !categorySelected.sentence[quizState.recordingIndex].fileUrlPath) ?
                                mysoundIcon[4]
                                :
                                mysoundIcon[2]
                            }
                            alt=""
                            onTouchStart={mysoundIconEvents}
                            onTouchEnd={mysoundIconEvents}
                        />
                    </div>
                </MobileView>
            </>
            {quizState.showSubmitButton && <LoadBtn src={btnSubmit[1]} />}
        </AnswerBlock>
    )
});

export default Answer;