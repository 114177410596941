import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import Answer from "../../components/activity-answer-eggstudio/Answer";
import EggStudio from "../../components/eggstudionew/EggStudio";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import GoodJob from "../../components/congratulations/GoodJob";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import Background from "../../images/egg-studio/background.png";
import filmPattern from "../../images/egg-studio/film-pattern.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";

import nosound from "../../audios/nosound.mp3";

/*css*/
import "../../css/timeline.css";

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Index = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
  const mediaPath = actInfo.media_path.replace(`${apiHost}`, '');

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(0);
  let minRecordCategoryNum = 0;
  console.log('page_orgActList', orgActList);
  console.log('page_actList', actList);
  console.log('page_categoryList', categoryList);
  console.log('page_categorySelected', categorySelected);
  console.log('page_setNum', setNum);
  console.log('page_maxSetNum', maxSetNum);
  console.log('page_minCategoryNum', minCategoryNum);
  console.log('page_maxCategoryNum', maxCategoryNum);

  const [modal, setModal] = useState(false);
  const [yesmodal, setYesmodal] = useState(false);
  const [record, setRecord] = useState(false);

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [answer, setAnswer] = useState(null);
  const [onClickEvent, setOnClickEvent] = useState(false);


  const errorModalRef = useRef();
  const routeLearningHome = () => {
    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
      case 'HHT1':
        routerName = '/hh1/learning-home';
        break;
      case 'HHT2':
        routerName = '/hh2/learning-home';
        break;
      case 'CULTURE':
        routerName = '/culture/learning-home';
        break;
      default:
        break;
    }
    history.push(routerName);
  };
  useEffect(() => {
    console.log('useEffect1')
    const fetchData = async (actContentData) => {
      let filePathReplaced = actInfo.file_path.replace(`${apiHost}`, '');
      await fetch(filePathReplaced)
        .then(res => res.json())
        .then(res => {
          console.log("data_eggstudioindex.jsx_res >>>", res);
          setOrgActList(JSON.parse(JSON.stringify(res)));
          console.log("data_eggstudioindex.jsx_JSON.parse(JSON.stringify(res)>", JSON.parse(JSON.stringify(res)));

          try {
            const stdAnswer = JSON.parse(actContentData.stdAnswer);

            res.map((res_item, res_index) => {
              res_item.category.map((category_item, category_index) => {
                if (category_item.name === 'record') {
                  category_item.sentence.map((sentence_item, sentence_index) => {
                    if (sentence_item) {
                      sentence_item.list.map((item, index) => {
                        if (stdAnswer.length > res_index &&
                          stdAnswer[res_index].category.length > category_index &&
                          stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                          stdAnswer[res_index].category[category_index].sentence[sentence_index].list.length > index) {
                          var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].list[index].choiceSelected;
                          if (choiceSelected) {
                            item.choiceSelected = choiceSelected;
                          }
                        }
                      });
                    }
                    if (stdAnswer.length > res_index &&
                      stdAnswer[res_index].category.length > category_index &&
                      stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                      var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                      if (fileUrlPath) {
                        sentence_item.fileUrlPath = fileUrlPath;

                        var player = new Audio(fileUrlPath);
                        if (player) {
                          sentence_item.player = player;
                        }
                      }
                    }
                  });
                }
              });
            });
          } catch (error) {
            console.log('Incorrect student answer data, ', error);
          }

          setActList(res);

          const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
          setMaxSetNum(maxSetNum);

          const categoryList = res.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
          setCategoryList(categoryList);

          categoryList.forEach((value, index) => {
            if (index === 0) {
              setMinCategoryNum(value.order);
              setCategorySelected(categoryList[value.order]);
            } else if (index === categoryList.length - 1) {
              setMaxCategoryNum(value.order);
            }
          });
        })
        .catch(error => {
          console.error(error);
          errorModalRef.current.openModal();
        });
    };

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);

  const [guideFinished, setGuideFinished] = useState(false);
  const [line01, setLine01] = useState(false);
  const [line02, setLine02] = useState(false);
  const [line03, setLine03] = useState(false);
  const [line04, setLine04] = useState(false);
  const [line05, setLine05] = useState(false);
  const [line06, setLine06] = useState(false);
  const [line07, setLine07] = useState(false);
  const [line08, setLine08] = useState(false);
  const [line09, setLine09] = useState(false);
  const [line10, setLine10] = useState(false);

  const [show01, setShow01] = useState(false);
  const [show02, setShow02] = useState(false);
  const [show03, setShow03] = useState(false);
  const [show04, setShow04] = useState(false);
  const [show05, setShow05] = useState(false);
  const [show06, setShow06] = useState(false);
  const [show07, setShow07] = useState(false);
  const [show08, setShow08] = useState(false);
  const [show09, setShow09] = useState(false);
  const [show10, setShow10] = useState(false);

  const audio00Ref = useRef();
  const audio01Ref = useRef();
  const audio02Ref = useRef();
  const audio03Ref = useRef();
  const audio04Ref = useRef();
  const audio05Ref = useRef();
  const audio06Ref = useRef();
  const audio07Ref = useRef();
  const audio08Ref = useRef();
  const audio09Ref = useRef();
  const audio10Ref = useRef();

  const question2aRef = useRef();
  const question2bRef = useRef();
  const question5aRef = useRef();
  const question5bRef = useRef();
  const question8aRef = useRef();
  const question8bRef = useRef();
  const question10aRef = useRef();
  const question10bRef = useRef();

  const question02Ref = useRef();
  const question05Ref = useRef();
  const question08Ref = useRef();
  const question10Ref = useRef();

  const Image02Ref = useRef();
  const Image05Ref = useRef();
  const Image08Ref = useRef();
  const Image10Ref = useRef();

  const questionaudio2aRef = useRef();
  const questionaudio2bRef = useRef();
  const questionaudio5aRef = useRef();
  const questionaudio5bRef = useRef();
  const questionaudio8aRef = useRef();
  const questionaudio8bRef = useRef();
  const questionaudio10aRef = useRef();
  const questionaudio10bRef = useRef();

  useEffect(() => {
    console.log('useEffect2')
    if (actList.length > 0) {
      const categoryList = actList.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
      setCategoryList(categoryList);

      categoryList.forEach((item, index) => {
        if (index === 0) {
          setMinCategoryNum(item.order);
          setCategorySelected(categoryList[item.order]);
        } else if (index === categoryList.length - 1) {
          setMaxCategoryNum(item.order);
        }
      });
    }
    // audio01Ref.current.play();
    // line01Ref.current.style.display = 'block';
  }, [setNum]);

  useEffect(() => {
    console.log('useEffect3')

    if (guideFinished) {
      audio00Ref.current.play();
    }
    // line01Ref.current.style.display = 'block';

  }, [guideFinished]);

  const onEnded00 = () => {

    audio01Ref.current.play();
    setLine01(true);
  }


  const Image02 = [mediaPath + '/' + categorySelected.Image02ap,
  mediaPath + '/' + categorySelected.Image02bl,
  mediaPath + '/' + categorySelected.Image02
    // Time02
  ];

  const image02index = useRef(0);
  const image05index = useRef(0);
  const image08index = useRef(0);
  const image10index = useRef(0);

  const onEnded01 = () => {
    setLine01(false);
    audio01Ref.current.pause();
    setShow01(true);
    // audio02Ref.current.play();
    setLine02(true);
    const Image02Timer = new TaskTimer(500);
    Image02Timer.add([
      {
        id: 'playIconTask',
        tickInterval: 1,
        totalRuns: 0,
        callback(task) {
          switch (image02index.current) {
            case 0:
              Image02Ref.current.src = Image02[0];
              break;
            case 1:
              Image02Ref.current.src = Image02[1];
              break;
          }
          image02index.current = image02index.current + 1;
          if (image02index.current > 2) image02index.current = 0;
        }
      }
    ]).stop();
    Image02Timer.start();
    setTimeout(() => {
      Image02Timer.stop();
    }, 5000);





  }


  const onClick2 = () => {

    question02Ref.current.style.display = 'block';
    // setLine02(false);

  }

  const choiceClick2A = () => {
    question2aRef.current.play();
    setAnswer(categorySelected.question2a);
    setOnClickEvent(true);
  }


  const choiceClick2B = () => {
    question2bRef.current.play();

    setAnswer(categorySelected.question2b);
    setOnClickEvent(true);
  }

  const onSubmit2Answer = () => {

    if (categorySelected.answerCorrect2 === answer) {
      question2aRef.current.pause();
      question2bRef.current.pause();
      feedback(true);
      setTimeout(() => {

        question02Ref.current.style.display = 'none';
        setLine02(false);
        setShow02(true);

      }, 1500);

      setTimeout(() => {

        Image02Ref.current.src = Image02[2];
        audio02Ref.current.play();
      }, 2000);

    } else {
      feedback(false);
      return;
    }
  };

  const onEnded02 = () => {
    audio02Ref.current.pause();
    Image02Ref.current.src = Image02[2];
    audio03Ref.current.play();
    setLine03(true);
  }

  const onEnded03 = () => {
    setLine03(false);
    audio03Ref.current.pause();
    setShow03(true);
    audio04Ref.current.play();
    setLine04(true);
  }


  const Image05 = [mediaPath + '/' + categorySelected.Image05ap,
  mediaPath + '/' + categorySelected.Image05bl,
  mediaPath + '/' + categorySelected.Image05
  ];

  const onEnded04 = () => {
    setLine04(false);
    audio04Ref.current.pause();
    setShow04(true);
    // audio05Ref.current.play();
    setLine05(true);
    const Image05Timer = new TaskTimer(500);
    Image05Timer.add([
      {
        id: 'playIconTask',
        tickInterval: 1,
        totalRuns: 0,
        callback(task) {
          switch (image05index.current) {
            case 0:
              Image05Ref.current.src = Image05[0];
              break;
            case 1:
              Image05Ref.current.src = Image05[1];
              break;
          }
          image05index.current = image05index.current + 1;
          if (image05index.current > 2) image05index.current = 0;
        }
      }
    ]).stop();
    Image05Timer.start();
    setTimeout(() => {
      Image05Timer.stop();
    }, 5000);
  }

  const onClick5 = () => {

    question05Ref.current.style.display = 'block';
    // setLine02(false);

  }

  const choiceClick5A = () => {
    question5aRef.current.play();
    setAnswer(categorySelected.question5a);
    setOnClickEvent(true);
  }


  const choiceClick5B = () => {
    question5bRef.current.play();
    setAnswer(categorySelected.question5b);
    setOnClickEvent(true);
  }

  const onSubmit5Answer = () => {

    if (categorySelected.answerCorrect5 === answer) {
      question5aRef.current.pause();
      question5bRef.current.pause();
      feedback(true);
      setTimeout(() => {
        question05Ref.current.style.display = 'none';
        setLine05(false);
        setShow05(true);

      }, 1500);

      setTimeout(() => {

        Image05Ref.current.src = Image05[2];
        audio05Ref.current.play();
      }, 2000);

    } else {
      feedback(false);
      return;
    }
  };


  const onEnded05 = () => {
    setLine05(false);
    audio05Ref.current.pause();
    setShow05(true);
    audio06Ref.current.play();
    setLine06(true);
  }

  const onEnded06 = () => {
    setLine06(false);
    audio06Ref.current.pause();
    setShow06(true);
    audio07Ref.current.play();
    setLine07(true);
  }

  const Image08 = [mediaPath + '/' + categorySelected.Image08ap,
  mediaPath + '/' + categorySelected.Image08bl,
  mediaPath + '/' + categorySelected.Image08
  ];

  const onEnded07 = () => {
    setLine07(false);
    audio07Ref.current.pause();
    setShow07(true);
    // audio08Ref.current.play();
    setLine08(true);
    const Image08Timer = new TaskTimer(500);
    Image08Timer.add([
      {
        id: 'playIconTask',
        tickInterval: 1,
        totalRuns: 0,
        callback(task) {
          switch (image08index.current) {
            case 0:
              Image08Ref.current.src = Image08[0];
              break;
            case 1:
              Image08Ref.current.src = Image08[1];
              break;
          }
          image08index.current = image08index.current + 1;
          if (image08index.current > 2) image08index.current = 0;
        }
      }
    ]).stop();
    Image08Timer.start();
    setTimeout(() => {
      Image08Timer.stop();
    }, 5000);
  }

  const onClick8 = () => {

    question08Ref.current.style.display = 'block';
    // setLine02(false);

  }

  const choiceClick8A = () => {
    question8aRef.current.play();
    setAnswer(categorySelected.question8a);
    setOnClickEvent(true);
  }


  const choiceClick8B = () => {
    question8bRef.current.play();
    setAnswer(categorySelected.question8b);
    setOnClickEvent(true);
  }

  const onSubmit8Answer = () => {

    if (categorySelected.answerCorrect8 === answer) {
      question8aRef.current.pause();
      question8bRef.current.pause();
      feedback(true);
      setTimeout(() => {
        question08Ref.current.style.display = 'none';
        setLine08(false);
        setShow08(true);

      }, 1500);

      setTimeout(() => {

        Image08Ref.current.src = Image08[2];
        audio08Ref.current.play();
      }, 2000);

    } else {
      feedback(false);
      return;
    }
  };

  const onEnded08 = () => {
    setLine08(false);
    audio08Ref.current.pause();
    setShow08(true);
    audio09Ref.current.play();
    setLine09(true);
  }

  const Image10 = [mediaPath + '/' + categorySelected.Image10ap,
  mediaPath + '/' + categorySelected.Image10bl,
  mediaPath + '/' + categorySelected.Image10
  ];

  const onEnded09 = () => {
    setLine09(false);
    audio09Ref.current.pause();
    setShow09(true);
    // audio10Ref.current.play();
    setLine10(true);
    const Image10Timer = new TaskTimer(500);
    Image10Timer.add([
      {
        id: 'playIconTask',
        tickInterval: 1,
        totalRuns: 0,
        callback(task) {
          switch (image10index.current) {
            case 0:
              Image10Ref.current.src = Image10[0];
              break;
            case 1:
              Image10Ref.current.src = Image10[1];
              break;
          }
          image10index.current = image10index.current + 1;
          if (image10index.current > 2) image10index.current = 0;
        }
      }
    ]).stop();
    Image10Timer.start();
    setTimeout(() => {
      Image10Timer.stop();
    }, 5000);
  }

  const onClick10 = () => {

    question10Ref.current.style.display = 'block';
    // setLine02(false);

  }

  const choiceClick10A = () => {
    question10aRef.current.play();
    setAnswer(categorySelected.question10a);
    setOnClickEvent(true);
  }


  const choiceClick10B = () => {
    question10bRef.current.play();
    setAnswer(categorySelected.question10b);
    setOnClickEvent(true);
  }

  const onSubmit10Answer = () => {

    if (categorySelected.answerCorrect10 === answer) {
      question10aRef.current.pause();
      question10bRef.current.pause();
      feedback(true);
      setTimeout(() => {
        question10Ref.current.style.display = 'none';
        setLine10(false);
        setShow10(true);

      }, 1500);

      setTimeout(() => {

        Image10Ref.current.src = Image10[2];
        audio10Ref.current.play();
      }, 2000);
      setTimeout(() => {

        /**
       * 학생 Act 학습 정보 저장 API
       * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
       */
        async function callApiAct() {
          if (actInfo.actStatus !== 'Completed') {
            const data = await API('post', 'act', {}, {
              oCode: userSelector.info.oCode,
              productId: userSelector.info.productId,
              studentId: userSelector.info.studentId,
              courseId: userSelector.info.courseId,
              bookId: userSelector.info.bookId,
              level: actInfo.level,
              episodeId: episodeInfo.episodeId,
              episodeCode: episodeInfo.episodeCode,
              lessonId: lessonInfo.lessonId,
              actId: actInfo.actId,
              actType: actInfo.actType,
              actStatus: "Completed",
              actPoint: actInfo.actCompletPoint,
              actCurrentPage: setNum,
              question: JSON.stringify(orgActList),
              answer: JSON.stringify(actList)
            }).catch((error) => {
              console.error(error);
            });

            console.log(data);

            userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .actStatus = "Completed";
            userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .file = categorySelected.file;
            userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .fileUrl = categorySelected.fileUrl;
            userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .player = categorySelected.player;

            dispatch(userStore.save(userSelector.info));
          }

          let nextCategory = {
            name: 'finished',
            order: categorySelected.order,
            retry: () => {
              setSetNum(1);
              if (actList.length > 0) {
                const categoryList = actList.find(item => item.set === 1).category;
                setCategoryList(categoryList);

                categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                  if (index === 0) {
                    setMinCategoryNum(item.order);
                    setCategorySelected(categoryList[item.order]);
                  } else if (index === categoryList.length - 1) {
                    setMaxCategoryNum(item.order);
                  }
                });
              }
            },
          };
          setCategorySelected(nextCategory);
        }
        callApiAct();

      }, 9000); // 마지막 오디오가 들리는지, 컨텐츠 확인 필요

    } else {
      feedback(false);
      return;
    }
  };


  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  };
  const handleGuide = () => {
    setGuideFinished(false);
  };

  const alertModalRef = useRef();
  const closeActivityModalRef = useRef();
  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  };

  const RenderActivityArea = () => {
    if (!guideFinished) {
      return (
        <Guide setGuideFinished={setGuideFinished} />
      )
    } else {
      if (categorySelected.name === 'finished') {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <GoodJob
              categorySelected={categorySelected}
              setCategorySelected={setCategorySelected}
              categoryList={categoryList}
            />
          </div>
        );
      } else {
        return (
          <div className="timeline-bg-area">
            <div className={line01 ? categorySelected.style01.line01 : show01 ? categorySelected.style01.show01 : categorySelected.style01.css01}
              style={line01 ? { 'display': 'block', width: categorySelected.style01.width01, height: categorySelected.style01.height01, left: categorySelected.style01.left01, top: categorySelected.style01.top01 }
                :
                show01 ? { 'display': 'block', width: categorySelected.style01.width01, height: categorySelected.style01.height01, left: categorySelected.style01.left01, top: categorySelected.style01.top01 }
                  :
                  { width: categorySelected.style01.width01, height: categorySelected.style01.height01, left: categorySelected.style01.left01, top: categorySelected.style01.top01 }}>
              <img src={mediaPath + '/' + categorySelected.Image01} alt="" />
            </div>
            <div className={line02 ? categorySelected.style02.line02 : show02 ? categorySelected.style02.show02 : categorySelected.style02.css02}
              style={line02 ? { 'display': 'block', width: categorySelected.style02.width02, height: categorySelected.style02.height02, left: categorySelected.style02.left02, top: categorySelected.style02.top02 }
                :
                show02 ? { 'display': 'block', width: categorySelected.style02.width02, height: categorySelected.style02.height02, left: categorySelected.style02.left02, top: categorySelected.style02.top02 }
                  :
                  { width: categorySelected.style02.width02, height: categorySelected.style02.height02, left: categorySelected.style02.left02, top: categorySelected.style02.top02 }}
              onClick={onClick2}
            >
              <img ref={Image02Ref} src={Image02[0]} alt="" />
            </div>
            <div className={line03 ? categorySelected.style03.line03 : show03 ? categorySelected.style03.show03 : categorySelected.style03.css03}
              style={line03 ? { 'display': 'block', width: categorySelected.style03.width03, height: categorySelected.style03.height03, left: categorySelected.style03.left03, top: categorySelected.style03.top03 }
                :
                show03 ? { 'display': 'block', width: categorySelected.style03.width03, height: categorySelected.style03.height03, left: categorySelected.style03.left03, top: categorySelected.style03.top03 }
                  :
                  { width: categorySelected.style03.width03, height: categorySelected.style03.height03, left: categorySelected.style03.left03, top: categorySelected.style03.top03 }
              }>
              <img src={mediaPath + '/' + categorySelected.Image03} alt="" />
            </div>
            <div className={line04 ? categorySelected.style04.line04 : show04 ? categorySelected.style04.show04 : categorySelected.style04.css04}
              style={line04 ? { 'display': 'block', width: categorySelected.style04.width04, height: categorySelected.style04.height04, left: categorySelected.style04.left04, top: categorySelected.style04.top04 }
                :
                show04 ? { 'display': 'block', width: categorySelected.style04.width04, height: categorySelected.style04.height04, left: categorySelected.style04.left04, top: categorySelected.style04.top04 }
                  :
                  { width: categorySelected.style04.width04, height: categorySelected.style04.height04, left: categorySelected.style04.left04, top: categorySelected.style04.top04 }
              }>
              <img src={mediaPath + '/' + categorySelected.Image04} alt="" />
            </div>
            <div className={line05 ? categorySelected.style05.line05 : show05 ? categorySelected.style05.show05 : categorySelected.style05.css05}
              style={line05 ? { 'display': 'block', width: categorySelected.style05.width05, height: categorySelected.style05.height05, left: categorySelected.style05.left05, top: categorySelected.style05.top05 }
                :
                show05 ? { 'display': 'block', width: categorySelected.style05.width05, height: categorySelected.style05.height05, left: categorySelected.style05.left05, top: categorySelected.style05.top05 }
                  :
                  { width: categorySelected.style05.width05, height: categorySelected.style05.height05, left: categorySelected.style05.left05, top: categorySelected.style05.top05 }}
              onClick={onClick5} >
              <img ref={Image05Ref} src={Image05[0]} alt="" />
            </div>
            <div className={line06 ? categorySelected.style06.line06 : show06 ? categorySelected.style06.show06 : categorySelected.style06.css06}
              style={line06 ? { 'display': 'block', width: categorySelected.style06.width06, height: categorySelected.style06.height06, left: categorySelected.style06.left06, top: categorySelected.style06.top06 }
                : show06 ? { 'display': 'block', width: categorySelected.style06.width06, height: categorySelected.style06.height06, left: categorySelected.style06.left06, top: categorySelected.style06.top06 }
                  : { width: categorySelected.style06.width06, height: categorySelected.style06.height06, left: categorySelected.style06.left06, top: categorySelected.style06.top06 }}>
              <img src={mediaPath + '/' + categorySelected.Image06} alt="" />
            </div>
            <div className={line07 ? categorySelected.style07.line07 : show07 ? categorySelected.style07.show07 : categorySelected.style07.css07}
              style={line07 ? { 'display': 'block', width: categorySelected.style07.width07, height: categorySelected.style07.height07, left: categorySelected.style07.left07, top: categorySelected.style07.top07 }
                :
                show07 ? { 'display': 'block', width: categorySelected.style07.width07, height: categorySelected.style07.height07, left: categorySelected.style07.left07, top: categorySelected.style07.top07 }
                  :
                  { width: categorySelected.style07.width07, height: categorySelected.style07.height07, left: categorySelected.style07.left07, top: categorySelected.style07.top07 }}>
              <img src={mediaPath + '/' + categorySelected.Image07} alt="" />
            </div>
            <div
              className={line08 ? categorySelected.style08.line08 : show08 ? categorySelected.style08.show08 : categorySelected.style08.css08}
              style={line08 ? { 'display': 'block', width: categorySelected.style08.width08, height: categorySelected.style08.height08, left: categorySelected.style08.left08, top: categorySelected.style08.top08 }
                : show08 ? { 'display': 'block', width: categorySelected.style08.width08, height: categorySelected.style08.height08, left: categorySelected.style08.left08, top: categorySelected.style08.top08 }
                  :
                  { width: categorySelected.style08.width08, height: categorySelected.style08.height08, left: categorySelected.style08.left08, top: categorySelected.style08.top08 }}
              onClick={onClick8}>
              <img ref={Image08Ref} src={Image08[0]} alt="" />
            </div>
            <div
              className={line09 ? categorySelected.style09.line09 : show09 ? categorySelected.style09.show09 : categorySelected.style09.css09}
              style={line09 ? { 'display': 'block', width: categorySelected.style09.width09, height: categorySelected.style09.height09, left: categorySelected.style09.left09, top: categorySelected.style09.top09 }
                :
                show09 ? { 'display': 'block', width: categorySelected.style09.width09, height: categorySelected.style09.height09, left: categorySelected.style09.left09, top: categorySelected.style09.top09 }
                  :
                  { width: categorySelected.style09.width09, height: categorySelected.style09.height09, left: categorySelected.style09.left09, top: categorySelected.style09.top09 }}>
              <img src={mediaPath + '/' + categorySelected.Image09} alt="" />
            </div>
            <div className={line10 ? categorySelected.style10.line10 : show10 ? categorySelected.style10.show10 : categorySelected.style10.css10}
              style={line10 ? { 'display': 'block', width: categorySelected.style10.width10, height: categorySelected.style10.height10, left: categorySelected.style10.left10, top: categorySelected.style10.top10 }
                :
                show10 ? { 'display': 'block', width: categorySelected.style10.width10, height: categorySelected.style10.height10, left: categorySelected.style10.left10, top: categorySelected.style10.top10 }
                  :
                  { width: categorySelected.style10.width10, height: categorySelected.style10.height10, left: categorySelected.style10.left10, top: categorySelected.style10.top10 }}
              onClick={onClick10}>
              <img ref={Image10Ref} src={Image10[0]} alt="" />
            </div>
          </div>



        )
      }



    }
  };

  const onClickEventButton =
    <button className="check-btn on" >Submit</button>;

  const offClickEventButton =
    <button className="check-btn" >Submit</button>;

  return (
    <div className="time-line">
      <div>
        <div className="contents d-flex flex-column justify-content-between">
          {/* 오디오 배열 및 Ref 생성 */}
          <audio ref={audio00Ref} src={nosound} onEnded={onEnded00} />
          <audio ref={audio01Ref} src={mediaPath + '/' + categorySelected.audio01} onEnded={onEnded01} />
          <audio ref={audio02Ref} src={mediaPath + '/' + categorySelected.audio02} onEnded={onEnded02} />
          <audio ref={audio03Ref} src={mediaPath + '/' + categorySelected.audio03} onEnded={onEnded03} />
          <audio ref={audio04Ref} src={mediaPath + '/' + categorySelected.audio04} onEnded={onEnded04} />
          <audio ref={audio05Ref} src={mediaPath + '/' + categorySelected.audio05} onEnded={onEnded05} />
          <audio ref={audio06Ref} src={mediaPath + '/' + categorySelected.audio06} onEnded={onEnded06} />
          <audio ref={audio07Ref} src={mediaPath + '/' + categorySelected.audio07} onEnded={onEnded07} />
          <audio ref={audio08Ref} src={mediaPath + '/' + categorySelected.audio08} onEnded={onEnded08} />
          <audio ref={audio09Ref} src={mediaPath + '/' + categorySelected.audio09} onEnded={onEnded09} />
          <audio ref={audio10Ref} src={mediaPath + '/' + categorySelected.audio10} />
          {/* 문제 오디오 배열 및 Ref 생성 */}
          <audio ref={question2aRef} src={mediaPath + '/' + categorySelected.questionaudio2a} />
          <audio ref={question2bRef} src={mediaPath + '/' + categorySelected.questionaudio2b} />
          <audio ref={question5aRef} src={mediaPath + '/' + categorySelected.questionaudio5a} />
          <audio ref={question5bRef} src={mediaPath + '/' + categorySelected.questionaudio5b} />
          <audio ref={question8aRef} src={mediaPath + '/' + categorySelected.questionaudio8a} />
          <audio ref={question8bRef} src={mediaPath + '/' + categorySelected.questionaudio8b} />
          <audio ref={question10aRef} src={mediaPath + '/' + categorySelected.questionaudio10a} />
          <audio ref={question10bRef} src={mediaPath + '/' + categorySelected.questionaudio10b} />

          <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
          <CloseActivityModal ref={closeActivityModalRef} />
          <Feedback ref={feedbackRef} />
          <AlertModal ref={alertModalRef} />

          <div className="route_bar d-flex align-items-center justify-content-between route-bar-background-hh2">
            <div className="d-flex">
              <p>
                {lessonInfo.lessonTitle} &gt; <span className="extra-bold">{actInfo.actTitle}</span>
              </p>
            </div>
            <div className="right_icon">
              <img className="help_icon_img" src={HelpIcon} onClick={() => handleGuide()} alt="" />
              <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
            </div>
          </div>
        </div>

        <div className="box_parent hht2-timeline-ep01">{/*배경 다르게 변경시 에피소드별로 클래스 추가 hht2-timeline-ep01, hht2-timeline-ep02, hht2-timeline-ep03...*/}
          <div className="box-wrap d-flex justify-content-center align-items-center">
            <RenderActivityArea />
          </div>
        </div>
        <div className="time-pop-inner" ref={question02Ref} >
          <div className="time-line-pop">
            <div className="question-layer">
              <div className="select select01" tabIndex="0" onClick={choiceClick2A}>
                <audio ref={questionaudio2aRef} src={mediaPath + '/' + categorySelected.questionaudio2a} />
                <p>{categorySelected.question2a}</p>
                <img src={mediaPath + '/' + categorySelected.questionImage2a} alt="" />
              </div>
              <div className="select select02" tabIndex="0" onClick={choiceClick2B}>
                <audio ref={questionaudio2bRef} src={mediaPath + '/' + categorySelected.questionaudio2b} />
                <p>{categorySelected.question2b}</p>
                <img src={mediaPath + '/' + categorySelected.questionImage2b} alt="" />
              </div>
            </div>
            <div className="buttongroup" value={answer} onClick={onSubmit2Answer}>
              {onClickEvent ? onClickEventButton : offClickEventButton}
            </div>
          </div>
          <span className="shadow"></span>
        </div>
        <div className="time-pop-inner" ref={question05Ref} >
          <div className="time-line-pop">
            <div className="question-layer">
              <div className="select select01" tabIndex="0" onClick={choiceClick5A}>
                <audio ref={questionaudio5aRef} src={mediaPath + '/' + categorySelected.questionaudio5a} />
                <p>{categorySelected.question5a}</p>
                <img src={mediaPath + '/' + categorySelected.questionImage5a} alt="" />
              </div>
              <div className="select select02" tabIndex="0" onClick={choiceClick5B}>
                <audio ref={questionaudio5bRef} src={mediaPath + '/' + categorySelected.questionaudio5b} />
                <p>{categorySelected.question5b}</p>
                <img src={mediaPath + '/' + categorySelected.questionImage5b} alt="" />
              </div>
            </div>
            <div className="buttongroup" value={answer} onClick={onSubmit5Answer}>
              {onClickEvent ? onClickEventButton : offClickEventButton}
            </div>
          </div>
          <span className="shadow"></span>
        </div>
        <div className="time-pop-inner" ref={question08Ref} >
          <div className="time-line-pop">
            <div className="question-layer">
              <div className="select select01" tabIndex="0" onClick={choiceClick8A}>
                <audio ref={questionaudio8aRef} src={mediaPath + '/' + categorySelected.questionaudio8a} />
                <p>{categorySelected.question8a}</p>
                <img src={mediaPath + '/' + categorySelected.questionImage8a} alt="" />
              </div>
              <div className="select select02" tabIndex="0" onClick={choiceClick8B}>
                <audio ref={questionaudio8bRef} src={mediaPath + '/' + categorySelected.questionaudio8b} />
                <p>{categorySelected.question8b}</p>
                <img src={mediaPath + '/' + categorySelected.questionImage8b} alt="" />
              </div>
            </div>
            <div className="buttongroup" value={answer} onClick={onSubmit8Answer}>
              {onClickEvent ? onClickEventButton : offClickEventButton}
            </div>
          </div>
          <span className="shadow"></span>
        </div>
        <div className="time-pop-inner" ref={question10Ref} >
          <div className="time-line-pop">
            <div className="question-layer">
              <div className="select select01" tabIndex="0" onClick={choiceClick10A}>
                <audio ref={questionaudio10aRef} src={mediaPath + '/' + categorySelected.questionaudio10a} />
                <p>{categorySelected.question10a}</p>
                <img src={mediaPath + '/' + categorySelected.questionImage10a} alt="" />
              </div>
              <div className="select select02" tabIndex="0" onClick={choiceClick10B}>
                <audio ref={questionaudio10bRef} src={mediaPath + '/' + categorySelected.questionaudio10b} />
                <p>{categorySelected.question10b}</p>
                <img src={mediaPath + '/' + categorySelected.questionImage10b} alt="" />
              </div>
            </div>
            <div className="buttongroup" value={answer} onClick={onSubmit10Answer}>
              {onClickEvent ? onClickEventButton : offClickEventButton}
            </div>
          </div>
          <span className="shadow"></span>
        </div>
      </div>
    </div>
  );
};

export default Index;
