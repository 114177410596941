/**
 * HH2 에피소드별 썸네일 이미지
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const TimeLineThumbnail = (currentEpisodeCode) => {
    const thumnail = {};

    thumnail['EP1'] = require('../../images/thumbnailTimeLine/hht2_quiztime_01.png').default;

    return thumnail[currentEpisodeCode];
}

export default TimeLineThumbnail;
