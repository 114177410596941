import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
//hhj
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";

import CenterLigth from "../../images/word-play/center-light.png";
import UserFaceDefault from "../../images/word-play/user-face.png";
import UserBody from "../../images/word-play/user-body.png";

import UserImg from "../../images/word-play/user-img.png";
import Quizimg from "../../images/word-play/word-quiz-1.jpg";

import countNum1 from "../../images/word-play/count-1.png";
import countNum2 from "../../images/word-play/count-2.png";
import countNum3 from "../../images/word-play/count-3.png";
import resultCorrect from "../../images/word-play/correct.png";
import resultOops from "../../images/word-play/oops.png";
import thropy from "../../images/word-play/thropy.png";



//캐릭터
import UserTony from "../../images/word-play/user-tony.png";
import UserHolly from "../../images/word-play/user-holly.png";
import UserMike from "../../images/word-play/user-mike.png";
import UserThomas from "../../images/word-play/user-thomas.png";
import UserHarry from "../../images/word-play/user-harry.png";
import UserEmma from "../../images/word-play/user-emma.png";
import UserCharles from "../../images/word-play/user-charles.png";

import Particle from "../../images/word-play/particle.png";
import Booth from "../../images/word-play/score-board.png";
import BoothOn from "../../images/word-play/score-board-on.png";
/*css*/
import "../../css/wordplay.css";


const Index = () => {
  return (
    <div className="word-play">
      <div>
          <div className="contents d-flex flex-column justify-content-between">
              <div className="route_bar d-flex align-items-center justify-content-between">
                  <div className="right_icon">
                      <img className="help_icon_img" src={HelpIcon} alt="" />
                      <img className="cancel_icon_img" src={CancelIcon} alt="" />
                  </div>
              </div>
          </div>
      </div>
      <div className="top-light">
        <span className="light left"></span>
        <span className="light right"></span>
      </div>
      <div className="box">
        <div className="billboard">{/*전광판*/}
          <span className="billboard-bar left"></span>
          <span className="billboard-bar right"></span>
          <span className="billboard-line"></span>
          <div className="billboard-wrap">
            <div className="center-light">
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
            </div>
            <p className="word-contest">Word Contest</p>
            
            <div className="billboard-box">{/*전광판 내용*/}
              {/*1. 문제선택 화면*/}
              <div className="word-select inner">
                <p className="question">Choose a box below.</p>
                <div className="num-line">
                  <div className="word-box on">{/*문제풀이 완료된 경우 클래스 on 추가*/}
                    <div className="wrap">
                      <span className="num">01</span>
                      <span className="answer">pick up</span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">02</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">03</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">04</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">05</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">06</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">07</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">08</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">09</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">10</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">11</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">12</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">13</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">14</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box">
                    <div className="wrap">
                      <span className="num">15</span>
                      <span className="answer"></span>
                    </div>                  
                  </div>
                  <div className="word-box none">{/*문제 없는 경우 클래스 none 추가되도록 개발 필요*/}             
                  </div>
                </div>
              </div>
              {/*1. 문제선택 화면*/}

              {/*2. 문제풀이 화면*/}
              <div className="word-select-quiz inner" style={{'display':'inline-block'}}>
                <p className="question">Choose the answer.</p>
                <div className="wordplay-quiz">
                  <div className="quiz-layer">
                    <img src={Quizimg} alt="" />
                  </div>
                  <div className="quiz-word">
                    <span className="word-pick">meet</span>
                    <Link className="word-pick" to="/hh1/word-play/index03">pick up</Link>
                  </div>
                </div>
              </div>
              {/*2. 문제풀이 화면*/}

            </div>
          </div>
        </div>
      </div>

      {/*단상*/}
      <div className="score-line">

        {/*캐릭터 있는 왼쪽 단상*/}
        <div className="score-box left">
          {/*캐릭터*/}
          <div className="character">
            {/*승자일경우 추가되는 부분*/}
            <div className="particle"><img src={Particle} alt="" /></div>
            {/*승자일경우 추가되는 부분*/}
            {/*문제풀이 결과 correct, oops*/}
            <div className="answer-result">
              <img className="correct-img" src={resultCorrect} alt="" />{/*정답시 상단에 correct! 노출*/}
              {/* <img className="oops-img" src={resultOops} alt="" /> */}{/*오답시 상단에 oops! 노출*/}
            </div>
            {/*문제풀이 결과 correct, oops*/}
            {/*캐릭터 들어가는 태그 - 캐릭터 총 7명*/}
            <span>
              <img src={UserCharles} alt="" />
            </span>
            {/*캐릭터 들어가는 태그 - 캐릭터 총 7명*/}
          </div>
          {/*캐릭터*/}

          {/*단상 내용*/}
          <div className="score-table">
            <span className="character-name">Thomas</span>{/*이름*/}
            <span className="score-num">20</span>{/*점수*/}
            <div className="btm">{/*상태 3가지 1. 부스 배경 변경 2. 카운트(1,2,3) 3. 정답단어노출 4.결과화면에서 승자 트로피 표시*/}
              {/*1. 부스 배경 변경*/}
              <div className="bootharea">
               <img src={Booth} alt="" /> {/*부스 기본*/}
               <img src={BoothOn} alt="" /> {/*부스 활성화*/}
              </div>

              {/*2. 카운트(1,2,3)*/}
              <span className="count">
                <img src={countNum1} alt="" />
                <img src={countNum2} alt="" />
                <img src={countNum3} alt="" />
              </span>
              {/*3. 정답단어노출*/}
              <span className="answer-word">pick up</span>
              {/*4.결과화면에서 승자 트로피 표시*/}
              <span className="thropy">{/*트로피*/}
                <img src={thropy} alt="" />
              </span>
            </div>
          </div>
          {/*단상 내용*/}
        </div>
        {/*캐릭터 있는 왼쪽 단상*/}

        {/*유저 캐릭터 있는 오른쪽 단상*/}
        <div className="score-box right">
          {/*캐릭터*/}
          <div className="character">         
            {/*승자일경우 추가되는 부분*/}
            <div className="particle"><img src={Particle} alt="" /></div>
            {/*승자일경우 추가되는 부분*/}
            {/*문제풀이 결과 correct, oops*/}
            <div className="answer-result">
              {/* <img className="correct-img" src={resultCorrect} alt="" /> */}
              <img className="oops-img" src={resultOops} alt="" />
            </div>
            {/*문제풀이 결과 correct, oops*/}
            {/*user 이미지 들어가는 태그*/}
            {/* <span className="face"><img src={UserFaceDefault} alt="" /></span>user 이미지 없을 경우 기본 이미지 */}
            <span className="face"><img src={UserImg} alt="" /></span>
            {/*user 이미지 들어가는 태그*/}
            <span className="body"><img src={UserBody} alt="" /></span>
          </div>
          {/*캐릭터*/}

          {/*단상 내용*/}
          <div className="score-table">
            <span className="character-name">Christine</span>{/*이름*/}
            <span className="score-num">000</span>{/*점수*/}
            <div className="btm">{/*상태 3가지 1. 부스 배경 변경 2. 카운트(1,2,3) 3. 정답단어노출 4.결과화면에서 승자 트로피 표시*/}
              {/*1. 부스 배경 변경*/}
              <div className="bootharea">
               <img src={Booth} alt="" /> {/*부스 기본*/}
               <img src={BoothOn} alt="" /> {/*부스 활성화*/}
              </div>

              {/*2. 카운트(1,2,3)*/}
              <span className="count">
                <img src={countNum1} alt="" />
                <img src={countNum2} alt="" />
                <img src={countNum3} alt="" />
              </span>
              {/*3. 정답단어노출*/}
              <span className="answer-word">pick up</span>
              {/*4.결과화면에서 승자 트로피 표시*/}
              <span className="thropy">{/*트로피*/}
                <img src={thropy} alt="" />
              </span>
            </div>
          </div>
          {/*단상 내용*/}
        </div>
        {/*유저 캐릭터 있는 오른쪽 단상*/}

      </div>
      {/*단상*/}
    </div>
  );
};

export default Index;
