import MicRecorder from 'mic-recorder-to-mp3';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Group, Image, Layer } from 'react-konva';
import { TaskTimer } from 'tasktimer';
import useImage from 'use-image';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const LoadScene = forwardRef((props, ref) => {
    const stageRef = props.stageRef;
    const LoadImage = props.LoadImage;
    const audioRef = props.audioRef;
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = props.mediaPath;
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const recordStartAudioRef = props.recordStartAudioRef;
    const countBbiAudioRef = props.countBbiAudioRef;
    const openAlertModal = props.openAlertModal;

    useImperativeHandle(ref, () => ({
        onCanPlayThrough(index) {
            if (index === 0) {
                startPlaySentence();
            }
        },
        onEnded(index) {
            const nextIndex = index + 1;
            if (nextIndex < categorySelected.sentence.length) { //  && !isPlaySentenceStopped ipad관련 코드 삭제
                playSentence(nextIndex);

                if (audioRef[nextIndex] &&
                    audioRef[nextIndex].current.error !== null &&
                    audioRef[nextIndex].current.error.message === 'MEDIA_ELEMENT_ERROR: Empty src attribute') {
                    stopPlaySentence();
                }
            } else {
                stopPlaySentence();
            }

            // playStopped();
        }
    }));


    let isPlaySentenceStopped = false;
    const startPlaySentence = () => {
        isPlaySentenceStopped = false;

        playSentence(0);

        replayIconTimer.start();
    };
    let playSentenceIndex = -1;
    const playSentence = (index) => {
        playSentenceIndex = index;

        if (audioRef[playSentenceIndex].current.error === null) {
            audioRef[playSentenceIndex].current.play();
        }

        stageRef.current.find(node => node.name() === 'sentenceImage').each((node, index) => {
            if (index <= playSentenceIndex) node.visible(true);
        });
        stageRef.current.batchDraw();
    };
    let firstTime = true;
    const stopPlaySentence = () => {
        isPlaySentenceStopped = true;

        stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {
            if (node.getAttr('images')) {
                var sentence = categorySelected.sentence[index];
                if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                    node.image(node.getAttr('images')[1]);
                } else {
                    node.image(node.getAttr('images')[0]);
                }
            }
        });

        stageRef.current.find('#playIcon').image('#playIconNormalImage');
        stageRef.current.batchDraw();

        stageRef.current.find(node => node.name() === 'sentenceImage').each(node => {
            if (!node.getAttr('noAudio')) {
                node.on('mouseover', sentenceEvents)
                    .on('mouseleave', sentenceEvents)
                    .on('mouseup', sentenceEvents)
                    .on('touchend', sentenceEvents);
            }
        });

        replayIconTimer.stop();

        setNextButtonEnabled();

        const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
        const major = version && version[1] ? version[1] : "";

        if (firstTime && !(major == '14')) {
            openAlertModal({ content: "Click the speech bubble and record the sentences." });
            firstTime = false;
        }
    };

    let recordingIndex = -1;
    const sentenceEvents = (e) => {
        if (e.type === "mouseover") {
            stageRef.current.container().style.cursor = "pointer";
        } else if (e.type === "mouseleave") {
            stageRef.current.container().style.cursor = "default";
        } else if (e.type === "mouseup" || e.type === "touchend") {
            stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {
                if (node.getAttr('images')) {
                    if (node === e.target) {
                        node.image(node.getAttr('images')[1]);
                        recordingIndex = categorySelected.sentence.findIndex(category => {
                            return mediaPath + category.image === node.getAttr('images')[0].src
                        });
                    } else {
                        var sentence = categorySelected.sentence[index];
                        if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                            node.image(node.getAttr('images')[1]);
                        } else {
                            node.image(node.getAttr('images')[0]);
                        }
                    }
                }
            });

            stageRef.current.findOne('#recordGroup').visible(true);
            stageRef.current.find(node => node.name() === 'quizGroup').each(node => {
                node.visible(false);
            });
            stageRef.current.batchDraw();

            // recordReady(recordingIndex);
        }
    };

    const LoadSentenceImage = (props) => {
        const [normalImage, normalStatus] = useImage(props.imagePath[0]);
        const [clickImage, clickStatus] = useImage(props.imagePath[1]);

        if (!props.imagePath[0] || !props.imagePath[1]) {
            return <Image name={'sentenceImage'} visible={false} />
        }
        if (normalStatus !== "loaded" || clickStatus !== "loaded") return null;

        return <Image
            image={normalImage}
            name={'sentenceImage'}
            images={[normalImage, clickImage]}
            x={props.x}
            y={props.y}
            visible={false}
            noAudio={props.noAudio}
            index={props.index}
        />;
    };

    /**
     * 좌하단 재생아이콘 관련
     * 주요 이벤트: 재생중이 아닐때, 아이콘을 누르면, 타이머 작동 및 오디오 재생 그리고 말풍선 표시
     */
    const replayIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_replay_h.png').default,
        require('../../images/activity-default/btn_replay_n.png').default,
        require('../../images/activity-default/btn_replay_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];


    const aniImageIndex = useRef(0);

    const replayIconTimer = new TaskTimer(500);
    replayIconTimer.add([
        {
            id: 'replayIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                switch (aniImageIndex.current) {
                    case 0:
                        stageRef.current.findOne('#playIcon0').visible(true);
                        stageRef.current.findOne('#playIcon1').visible(false);
                        stageRef.current.findOne('#playIcon2').visible(false);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                    case 1:
                        stageRef.current.findOne('#playIcon0').visible(false);
                        stageRef.current.findOne('#playIcon1').visible(true);
                        stageRef.current.findOne('#playIcon2').visible(false);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                    case 2:
                        stageRef.current.findOne('#playIcon0').visible(false);
                        stageRef.current.findOne('#playIcon1').visible(false);
                        stageRef.current.findOne('#playIcon2').visible(true);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                }
                stageRef.current.batchDraw();
                aniImageIndex.current = aniImageIndex.current + 1;
                if (aniImageIndex.current > 2) aniImageIndex.current = 0;
            }
        }
    ]).stop();


    const drawPlayStopImage = () => {

        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconHoverImage').visible(false);
        stageRef.current.findOne('#playIconPushImage').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(true);
        stageRef.current.batchDraw();

    }

    const drawPlayIconHoverImage = () => {
        stageRef.current.findOne('#playIconHoverImage').visible(true);
        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(false);
        stageRef.current.findOne('#playIconPushImage').visible(false);
        stageRef.current.batchDraw();

    }

    const drawPlayIconPushImage = () => {
        stageRef.current.findOne('#playIconPushImage').visible(true);
        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(false);
        stageRef.current.findOne('#playIconHoverImage').visible(false);
        stageRef.current.batchDraw();

    }


    const replayIconEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";
            if (isPlaySentenceStopped) {
                drawPlayIconHoverImage();
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (isPlaySentenceStopped) {
                drawPlayStopImage();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (isPlaySentenceStopped) {
                drawPlayIconPushImage()
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isPlaySentenceStopped) {
                playSentence(0);
            } else {
                const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
                const major = version && version[1] ? version[1] : "";

                if (major == '14') {
                    playSentence(0);
                } else {
                    stopPlaySentence();
                }

            }
        }
    }


    return (
        <Layer>
            <LoadImage imagePath={mediaPath + categorySelected.image} x={'center'} y={100} />
            {
                categorySelected.subImage &&
                <LoadImage imagePath={mediaPath + categorySelected.subImage.image} x={categorySelected.subImage.x} y={categorySelected.subImage.y} />
            }
            <Group>
                <Group>
                    {categorySelected.sentence.map((item, index) => (
                        <LoadSentenceImage
                            key={index}
                            imagePath={[(item.image ? mediaPath + item.image : undefined), (item.recordImage ? mediaPath + item.recordImage : undefined)]}
                            x={item.x}
                            y={item.y}
                            noAudio={(item.audio === undefined ? true : false)}
                            index={index}
                            quiz={item.quiz}
                        />
                    ))}
                </Group>
                <Group x={150} y={650}>
                    {/*<LoadImage imagePath={replayIcon[2]} id={'replayIcon'} events={replayIconEvents} />*/}
                    <LoadImage imagePath={replayIcon[0][0]} id={'playIcon0'} visible={true} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[0][1]} id={'playIcon1'} visible={true} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[0][2]} id={'playIcon2'} visible={true} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[1]} id={'playIconHoverImage'} visible={false} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[3]} id={'playIconPushImage'} visible={false} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[2]} id={'playIconNormalImage'} visible={false} events={replayIconEvents}></LoadImage>

                </Group>
            </Group>
            {/* <Group id={'recordGroup'} x={0} y={650} visible={false}>
                <LoadPlayIcon />
                <LoadRecordingIcon />
                <LoadMySoundIcon />
            </Group> */}
        </Layer>
    );
});

export default LoadScene;