import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import styled from "styled-components";
import { Group, Image, Layer, Stage } from 'react-konva';
import { TaskTimer } from 'tasktimer';
import useImage from 'use-image';
import LoadScene from './LoadScene';
import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';

import PlayBtn from "../../images/activity-default/btn_play_03.png";
import PlayBtn_dim from "../../images/activity-default/btn_play_d.png";
import PlayBtn_normal from "../../images/activity-default/btn_play_n.png";
import PlayBtn_pressed from "../../images/activity-default/btn_play_p.png";
import PlayBtn_highlight from "../../images/activity-default/btn_play_h.png";

const BoxInner = styled.div`
    width:100%;
    height:100%;
`;

const SpeakingPracticeExpended = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath;
    const activityProps = props.activityProps;
    const answerOpen = props.answerOpen;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const setNextButtonEnabledR = props.setNextButtonEnabledR;
    const openAlertModal = props.openAlertModal;
    const countBbiAudioRef = props.countBbiAudioRef;
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const recordStartAudioRef = props.recordStartAudioRef;


    const audioRef = [];
    const stageRef = useRef();

    const containerRef = useRef();
    let containerWidth = activityProps.defaultWidth;
    let containerHeight = activityProps.defaultHeight;
    let scaleX, scaleY, scale;

    useEffect(() => {
        containerWidth = containerRef.current.offsetWidth
        containerHeight = containerRef.current.offsetHeight;
        scaleX = containerWidth / activityProps.defaultWidth;
        scaleY = containerHeight / activityProps.defaultHeight;
        if (scaleX > scaleY) {
            containerWidth = activityProps.defaultWidth * scaleY;
        } else if (scaleX < scaleY) {
            containerHeight = activityProps.defaultHeight * scaleX;
        }
        scale = Math.min(scaleX, scaleY);
        stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
    }, []);
    useEffect(() => {
        const resizeListener = () => {
            containerWidth = containerRef.current.offsetWidth;
            containerHeight = containerRef.current.offsetHeight;
            scaleX = containerWidth / activityProps.defaultWidth;
            scaleY = containerHeight / activityProps.defaultHeight;
            if (scaleX > scaleY) {
                containerWidth = activityProps.defaultWidth * scaleY;
            } else if (scaleX < scaleY) {
                containerHeight = activityProps.defaultHeight * scaleX;
            }
            scale = Math.min(scaleX, scaleY);
            stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    });

    useImperativeHandle(ref, () => ({
        setSelectedSubImage(imagePath) {
            console.log(imagePath);
        }
    }));
    const sceneRef = useRef();

    useImperativeHandle(ref, () => ({
        onCanPlayThrough(index) {
            sceneRef.current.onCanPlayThrough(index);
        },
        onEnded(index) {
            sceneRef.current.onEnded(index);
        }
    }));

    const LoadImage = (props) => {
        const [image, status] = useImage(props.imagePath);
        if (status !== "loaded") return null;
        let imageWidth = (props.width ? props.width : image.width);
        let imageHeight = (props.height ? props.height : image.height);
        let x = props.x;
        let y = props.y;
        if (props.center) {
            x = (activityProps.defaultWidth - imageWidth) / 2;
            y = (activityProps.defaultHeight - imageHeight) / 2;
        }
        if (props.x === 'center') {
            x = (activityProps.defaultWidth - imageWidth) / 2;
        }
        if (props.x === 'right') {
            x = (activityProps.defaultWidth - imageWidth) - 2;
        }
        return <Image
            id={props.id || ''}
            name={props.name || ''}
            image={image}
            x={x}
            y={y}
            width={props.width}
            height={props.height}
            stroke={props.stroke}
            strokeWidth={props.strokeWidth}
            cornerRadius={props.cornerRadius}
            onMouseOver={props.events}
            onMouseLeave={props.events}
            onMouseDown={props.events}
            onMouseUp={props.events}
            onTouchStart={props.events}
            onTouchEnd={props.events}
        />;
    };

    /**
     * 오디오 파일 관련
     * 주료 이벤트: 재생 가능 할때, 자동 재생 시작
     *             재생이 끝났을때, 다음 파일 재생
     */
    const onCanPlayThrough = (index) => {
        audioRef[index].current.volume = activityProps.defaultVolume;
        if (index === 0) {
            startPlaySentence();
        }
    };
    const onEnded = (index) => {
        const nextIndex = index + 1;
        if (nextIndex < categorySelected.sentence.length && !isPlaySentenceStopped) {
            playSentence(nextIndex);

            if (audioRef[nextIndex] &&
                audioRef[nextIndex].current.error !== null &&
                audioRef[nextIndex].current.error.message === 'MEDIA_ELEMENT_ERROR: Empty src attribute') {
                console.log("1");
                stopPlaySentence();
            }
        } else {
            console.log("2");
            stopPlaySentence();
        }
    };
    const LoadAudios = () => {
        categorySelected.sentence.forEach(() => {
            audioRef.push(React.createRef());
        });
        return (
            <>
                {categorySelected.sentence.map((item, index) => (
                    <audio
                        key={index}
                        ref={audioRef[index]}
                        src={(item.audio ? mediaPath + item.audio : '')}
                        onCanPlayThrough={() => onCanPlayThrough(index)}
                        onEnded={() => onEnded(index)}
                    />
                ))}
            </>
        );
    };

    /**
     * 말풍선 표시 관련
     */
    let isPlaySentenceStopped = false;
    const startPlaySentence = () => {
        isPlaySentenceStopped = false;

        playSentence(0);

        playIconEventsTimer.start();
    };
    let playSentenceIndex = -1;
    const aniSentenceImageIndex = useRef(0);
    const playSentence = (index) => {
        playSentenceIndex = index;

        if (audioRef[playSentenceIndex].current.error === null) {
            audioRef[playSentenceIndex].current.play();
        }

        stageRef.current.find(node => node.name() === 'sentenceImage').each((node, index) => {
            if (index <= playSentenceIndex) node.visible(true);
        });
        stageRef.current.batchDraw();
    };
    let firstTime = true;
    const stopPlaySentenceExceptAffordance = () => {
        console.log('stopPlaySentenceExceptAffordance');
        isPlaySentenceStopped = true;

        stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {
            if (node.getAttr('images')) {
                var sentence = categorySelected.sentence[index];
                node.setAttr('time', 1000);
                if (sentence.record === "Y") {
                    if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                        node.image(node.getAttr('images')[1]);
                    } else {
                        node.image(node.getAttr('images')[2]);

                    }
                } else {
                    node.image(node.getAttr('images')[0]);
                }
            }
        });

        drawPlayStopImage();

        stageRef.current.find(node => node.name() === 'sentenceImage').each(node => {
            if (!node.getAttr('noRecord')) {
                node.on('mouseover', sentenceEvents)
                    .on('mouseleave', sentenceEvents)
                    .on('mouseup', sentenceEvents)
                    .on('touchend', sentenceEvents);
            }
        });

        playIconEventsTimer.stop();

        setNextButtonEnabled();

        const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
        const major = version && version[1] ? version[1] : "";

        if (firstTime && !(major == '14')) {
            openAlertModal({ content: "Click the speech bubble and record the sentences." });
            firstTime = false;
        }
    };

    const stopPlaySentence = () => {
        console.log('stopPlaySentence');
        isPlaySentenceStopped = true;

        stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {
            if (node.getAttr('images')) {
                var sentence = categorySelected.sentence[index];
                node.setAttr('time', 1000);
                if (sentence.record === "Y") {
                    if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                        node.image(node.getAttr('images')[1]);
                    } else {

                        const playSentenceIconEventsTimer = new TaskTimer(500);
                        playSentenceIconEventsTimer.add([
                            {
                                id: 'playSentenceIconTask',
                                tickInterval: 1,
                                totalRuns: 0,
                                callback(task) {
                                    switch (aniSentenceImageIndex.current) {
                                        case 0:
                                            node.image(node.getAttr('images')[0]);
                                            break;
                                        case 1:
                                            node.image(node.getAttr('images')[2]);
                                            break;
                                    }
                                    stageRef.current.batchDraw();
                                    aniSentenceImageIndex.current = aniSentenceImageIndex.current + 1;
                                    if (aniSentenceImageIndex.current > 2) aniSentenceImageIndex.current = 0;
                                }
                            }
                        ]).stop();
                        playSentenceIconEventsTimer.start();
                        setTimeout(() => {
                            playSentenceIconEventsTimer.stop();
                        }, 10000);
                        // playSentenceIconEventsTimer.start();
                        // playSentenceIconEventsTimer.stop();

                    }
                } else {
                    node.image(node.getAttr('images')[0]);
                }
            }
        });

        drawPlayStopImage();

        stageRef.current.find(node => node.name() === 'sentenceImage').each(node => {
            if (!node.getAttr('noRecord')) {
                node.on('mouseover', sentenceEvents)
                    .on('mouseleave', sentenceEvents)
                    .on('mouseup', sentenceEvents)
                    .on('touchend', sentenceEvents);
            }
        });

        playIconEventsTimer.stop();

        setNextButtonEnabled();

        // const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
        // const major = version && version[1] ? version[1] : "";

        // if (firstTime && !(major == '14')){
        //     openAlertModal({ content: "Click the speech bubble and record the sentences." });
        //     firstTime = false;
        // }
    };
    let recordingIndex = -1;
    const sentenceEvents = (e) => {
        if (e.type === "mouseover") {
            stageRef.current.container().style.cursor = "pointer";
        } else if (e.type === "mouseleave") {
            stageRef.current.container().style.cursor = "default";
        } else if (e.type === "mouseup" || e.type === "touchend") {
            console.log('mouseup_e', e);
            stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {
                console.log('node', node);
                console.log('e.target', e.target);
                console.log('index', index);
                if (node.getAttr('images')) {
                    if (node === e.target) {
                        node.image(node.getAttr('images')[1]);
                        recordingIndex = categorySelected.sentence.findIndex(category => {
                            return mediaPath + category.image === node.getAttr('images')[0].src
                        });
                    } else {
                        var sentence = categorySelected.sentence[index];
                        if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                            if (sentence.record === "Y") { node.image(node.getAttr('images')[1]); }
                            else { node.image(node.getAttr('images')[0]); }
                        } else {
                            node.image(node.getAttr('images')[0]);
                        }
                    }
                }
            });
            stageRef.current.batchDraw();
            console.log('recordingIndex', recordingIndex);

            /**
             * 하단 모달 오픈
             * 현재 인덱스 값은 @param {recordingIndex}
             */
            if (categorySelected.sentence[recordingIndex].record === "Y") {
                answerOpen(recordingIndex);
            } else {
                const audio = new Audio(mediaPath + categorySelected.sentence[recordingIndex].audio);
                audio.play();
                /**
            * 클릭하면 음원이 나오도록 
            * 현재 인덱스 값은 @param {recordingIndex}
            */

            }


        }
    };
    const LoadSentenceImage = (props) => {
        const [normalImage, normalStatus] = useImage(props.imagePath[0]);
        const [clickImage, clickStatus] = useImage(props.imagePath[1]);
        const [affordanceImage, affordanceStatus] = useImage(props.imagePath[2]);

        if (!props.imagePath[0] || !props.imagePath[1] || !props.imagePath[2]) {
            return <Image name={'sentenceImage'} visible={false} />
        }
        if (normalStatus !== "loaded" || clickStatus !== "loaded" || affordanceStatus !== "loaded") return null;
        console.log('');

        return <Image
            image={normalImage}
            name={'sentenceImage'}

            id={'LoadSentenceImage' + props.id}
            images={[normalImage, clickImage, affordanceImage]}
            x={props.x}
            y={props.y}
            visible={false}
            noRecord={props.noRecord}
            index={props.index}
        // quiz={props.quiz}
        />;
    };


    /**
     * 재생 아이콘 관련
     * 주요 이벤트: 재생중이 아닐때, 아이콘을 누르면, 타이머 작동 및 오디오 재생 그리고 말풍선 표시
     */
    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];
    // const playIconImage = [useImage(playIcon[0][0])[0], useImage(playIcon[0][1])[0], useImage(playIcon[0][2])[0]];
    // const [playIconHoverImage, playIconHoverStatus] = useImage(playIcon[1]);
    // const [playIconNormalImage, playIconNormalStatus] = playIcon[2];
    // const [playIconPushImage, playIconPushStatus] = useImage(playIcon[3]);
    const aniImageIndex = useRef(0);

    const playIconEventsTimer = new TaskTimer(500);
    playIconEventsTimer.add([
        {
            id: 'playIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                switch (aniImageIndex.current) {
                    case 0:
                        stageRef.current.findOne('#playIcon0').visible(true);
                        stageRef.current.findOne('#playIcon1').visible(false);
                        stageRef.current.findOne('#playIcon2').visible(false);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                    case 1:
                        stageRef.current.findOne('#playIcon0').visible(false);
                        stageRef.current.findOne('#playIcon1').visible(true);
                        stageRef.current.findOne('#playIcon2').visible(false);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                    case 2:
                        stageRef.current.findOne('#playIcon0').visible(false);
                        stageRef.current.findOne('#playIcon1').visible(false);
                        stageRef.current.findOne('#playIcon2').visible(true);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                }
                stageRef.current.batchDraw();
                aniImageIndex.current = aniImageIndex.current + 1;
                if (aniImageIndex.current > 2) aniImageIndex.current = 0;
            }
        }
    ]).stop();


    const drawPlayStopImage = () => {

        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconHoverImage').visible(false);
        stageRef.current.findOne('#playIconPushImage').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(true);
        stageRef.current.batchDraw();

    }

    const drawPlayIconHoverImage = () => {
        stageRef.current.findOne('#playIconHoverImage').visible(true);
        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(false);
        stageRef.current.findOne('#playIconPushImage').visible(false);
        stageRef.current.batchDraw();

    }

    const drawPlayIconPushImage = () => {
        stageRef.current.findOne('#playIconPushImage').visible(true);
        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(false);
        stageRef.current.findOne('#playIconHoverImage').visible(false);
        stageRef.current.batchDraw();

    }


    const playIconEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";
            if (isPlaySentenceStopped) {
                drawPlayIconHoverImage();
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (isPlaySentenceStopped) {
                drawPlayStopImage();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (isPlaySentenceStopped) {
                drawPlayIconPushImage()
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isPlaySentenceStopped) {
                startPlaySentence();
            } else {
                const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
                const major = version && version[1] ? version[1] : "";

                if (major == '14') {
                    startPlaySentence();
                } else {
                    stopPlaySentenceExceptAffordance();
                }

            }
        }
    }


    // useImperativeHandle(ref, () => ({
    //     onCanPlayThrough(index) {
    //         if (index === 0) {
    //             startPlaySentence();
    //         }
    //     },
    //     onEnded(index) {
    //         const nextIndex = index + 1;
    //         if (nextIndex < categorySelected.sentence.length) { //  && !isPlaySentenceStopped ipad관련 코드 삭제
    //             playSentence(nextIndex);

    //             if (audioRef[nextIndex] &&
    //                 audioRef[nextIndex].current.error !== null &&
    //                 audioRef[nextIndex].current.error.message === 'MEDIA_ELEMENT_ERROR: Empty src attribute') {
    //                 stopPlaySentence();
    //             }
    //         } else {
    //             stopPlaySentence();
    //         }

    //         // playStopped();
    //     }
    // }));




    return (
        <BoxInner ref={containerRef}>
            <LoadAudios />
            <Stage width={activityProps.defaultWidth} height={activityProps.defaultHeight} ref={stageRef}>
                {/* <LoadScene
                ref={sceneRef}
                stageRef={stageRef}
                LoadImage={LoadImage}
                audioRef={audioRef}
                categorySelected={categorySelected}
                setNextButtonEnabled={setNextButtonEnabled}
                mediaPath={mediaPath}
                activityProps={activityProps}
                feedback={feedback}
                recordTimeOverModalRef={recordTimeOverModalRef}
                recordStartAudioRef={recordStartAudioRef}
                countBbiAudioRef={countBbiAudioRef}
                openAlertModal={openAlertModal}
            /> */}
                <Layer>
                    <LoadImage imagePath={mediaPath + categorySelected.image} x={'center'} y={100} />
                    {
                        categorySelected.subImage &&
                        <LoadImage imagePath={mediaPath + categorySelected.subImage.image} x={categorySelected.subImage.x} y={categorySelected.subImage.y} />
                    }
                    <Group>
                        {categorySelected.sentence.map((item, index) => (
                            <>
                                <LoadSentenceImage
                                    key={index}
                                    imagePath={[(item.image ? mediaPath + item.image : undefined),
                                    (item.recordImage ? mediaPath + item.recordImage : undefined),
                                    (item.affordanceImage ? mediaPath + item.affordanceImage : undefined)]}
                                    x={item.x}
                                    y={item.y}
                                    noAudio={(item.audio === undefined ? true : false)}
                                    index={index}
                                    id={index}
                                />
                            </>
                        ))}

                    </Group>
                </Layer>
                <Layer className="btn-area right">
                    <LoadImage imagePath={playIcon[0][0]} x={'center'} id={'playIcon0'} visible={true} x={'right'} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[0][1]} x={'center'} id={'playIcon1'} visible={true} x={'right'} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[0][2]} x={'center'} id={'playIcon2'} visible={true} x={'right'} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[1]} x={'center'} id={'playIconHoverImage'} visible={false} x={'right'} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[3]} x={'center'} id={'playIconPushImage'} visible={false} x={'right'} events={playIconEvents}></LoadImage>
                    <LoadImage imagePath={playIcon[2]} x={'center'} id={'playIconNormalImage'} visible={false} x={'right'} events={playIconEvents}></LoadImage>


                </Layer>
            </Stage>
        </BoxInner>
    );
});

export default SpeakingPracticeExpended;