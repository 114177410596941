import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ItemBoxAnimation from "../images/direction/animation.png";
import ItemBoxMiniSitcom from "../images/direction/minisitcom.png";
import ItemBoxEggStudio1 from "../images/direction/eggstudio_1.png";
import ItemBoxCulture from "../images/direction/video_culture.png";

import ItemBoxSitcom from "../images/direction/sitcom.png";
import ItemBoxEggStudio2 from "../images/direction/eggstudio_2.png";
import ItemBoxSpeakingBooster from "../images/direction/speakingbooster.png";

import BannerText from "../images/home/banner-text.png";
import BoxBackgroundColorCulture from "../images/home/box-background-color-culture.png";
import BoxBackgroundColorHH1 from "../images/home/box-background-color-hh1.png";
import BoxBackgroundColorHH2 from "../images/home/box-background-color-hh2.png";
import { default as ItemBoxIcon1, default as ItemBoxIcon3 } from "../images/home/item-box-icon1.png";
import ItemBoxIcon2 from "../images/home/item-box-icon2.png";
import ItemBoxSample1 from "../images/home/item-box-sample1.png";
import MessageIcon from "../images/home/message-icon.png";
import MyPageIcon from "../images/home/my-page-icon.png";
import PersonIconHHT1_01 from "../images/home-new/person-icon.png";
import PersonIconHHT2_01 from "../images/home-new/person-icon-hht2-01.png";
import PersonIcon from "../images/home/person-icon.png";
import PointIcon2 from "../images/home/point-icon2.png";
import SideMenuIcon1 from "../images/home/side-menu-icon1.png";
import SideMenuIcon2 from "../images/home/side-menu-icon2.png";
import SideMenuIcon3 from "../images/home/side-menu-icon3.png";
import CultureTitleImg from "../images/home/culture_title.png";

import EpisodeThumb from "../components/culture/EpisodeThumb";
import AnimationThumbnail from "../components/animation/AnimationThumbnail";
import SitcomThumbnail from "../components/sitcom/SitcomThumbnail";
import QuiztimeThumbnail from "../components/quiztime/QuiztimeThumbnail";
import Thumbnailspeakingpractice from "../components/speakingpractice/Thumbnailspeakingpractice"
import EggStudioThumbnail from "../components/eggstudionew/EggStudioThumbnail";
import SongTimeThumbnail from "../components/songtime/SongTimeThumbnail";
import TimeLineThumbnail from "../components/timeline/TimeLineThumbnail";
import WordPlayThumbnail from "../components/wordplay/WordPlayThumbnail"

import * as userStore from '../modules/userStore';

import LogoHht1 from "../images/home-new/logo-hh1.png";
import LogoHht2 from "../images/home-new/logo-hh2.png";
import ProfileDefault from "../images/home-new/character_d2.png";
import SoundIcon_on from "../images/home-new/sound_on.png";
import SoundIcon_off from "../images/home-new/sound_off.png";
import ProfileIcon from "../images/home-new/profile.png";
import CloseIcon from "../images/home-new/close-btn.png";

import Char01 from "../images/home-new/character_01.png"
import Char02 from "../images/home-new/character_02.png"
import Char03 from "../images/home-new/character_03.png"
import Char04 from "../images/home-new/character_04.png"
import Char05 from "../images/home-new/character_05.png"
import Char06 from "../images/home-new/character_06.png"
import Char07 from "../images/home-new/character_07.png"
import Char08 from "../images/home-new/character_08.png"
import Char09 from "../images/home-new/character_09.png"
import Char10 from "../images/home-new/character_10.png"
import Char11 from "../images/home-new/character_11.png"
import Char12 from "../images/home-new/character_d.png"

import PhotoSample from "../images/home-new/photo-sample.jpg"

import "../css/learninghome.css";

// click_sound
import click_sound from "../audios/click_sound.mp3";

const LearningHome = () => {
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();
  const [episodeSelected, setEpisodeSelected] = useState(userSelector.learningHome.episodeId);
  const [lessonSelected, setLessonSelected] = useState(userSelector.learningHome.lessonId);
  const [studentHover, setStudentHover] = useState(false);

  const [currentEpisodeCode, setCurrentEpisodeCode] = useState(userSelector.info.episodes[userSelector.info.episodes.findIndex(item => item.episodeId === episodeSelected)].episodeCode);

  let clickAudio = new Audio(click_sound);

  const [style, setStyle] = useState(currentEpisodeCode.toLowerCase());
  const onLeftClick = () => {
    if (episodeSelected > userSelector.info.episodes[0].episodeId) {

      const episode = userSelector.info.episodes[userSelector.info.episodes.findIndex(item => item.episodeId === episodeSelected) - 1];

      let nextEpisodeId = episode.episodeId;
      let nextLessonId = userSelector.info.episodes.find(item => item.episodeId === nextEpisodeId).lessons[0].lessonId;
      let learningHome = {
        episodeId: nextEpisodeId,
        lessonId: nextLessonId
      }
      dispatch(userStore.lhSave(learningHome));
      setEpisodeSelected(nextEpisodeId);
      setLessonSelected(nextLessonId);
      setCurrentEpisodeCode(episode.episodeCode);

      setStyle(episode.episodeCode.toLowerCase());
    }
  };

  const onRightClick = () => {
    if (episodeSelected < userSelector.info.episodes[userSelector.info.episodes.length - 1].episodeId) {
      const episode = userSelector.info.episodes[userSelector.info.episodes.findIndex(item => item.episodeId === episodeSelected) + 1];

      let nextEpisodeId = episode.episodeId;
      let nextLessonId = userSelector.info.episodes.find(item => item.episodeId === nextEpisodeId).lessons[0].lessonId;
      let learningHome = {
        episodeId: nextEpisodeId,
        lessonId: nextLessonId
      }
      dispatch(userStore.lhSave(learningHome));
      setEpisodeSelected(nextEpisodeId);
      setLessonSelected(nextLessonId);
      setCurrentEpisodeCode(episode.episodeCode);

      setStyle(episode.episodeCode.toLowerCase());
    }
  };

  const onLessonChange = (lessonId) => {
    let learningHome = {
      episodeId: episodeSelected,
      lessonId: lessonId
    }
    dispatch(userStore.lhSave(learningHome));
    setLessonSelected(lessonId);

  };

  /**
   * HH1 에피소드별 썸네일 이미지
   * @returns imagePath
   */
  const animationThumbnail = () => {
    return AnimationThumbnail(currentEpisodeCode);
  };

  /**
  * HH1 에그 스튜디오 썸네일 이미지
  */
  const eggStudioThumbnail = () => {
    return EggStudioThumbnail(currentEpisodeCode);
  };

  /**
   * HH2 시트콤 썸네일 이미지
   */
  const sitcomThumbnail = () => {
    return SitcomThumbnail(currentEpisodeCode);
  };

  /**
   * HH2 퀴즈타임 썸네일 이미지
   */
  const quiztimeThumbnail = () => {
    return QuiztimeThumbnail(currentEpisodeCode);
  };

  /**
   * HH2 스피킹 프랙티스 썸네일 이미지
   */
  const thumbnailspeakingpractice = () => {
    return Thumbnailspeakingpractice(currentEpisodeCode);
  };

  /**
   * HH1 HH2 송타임 썸네일 이미지
   */
  const songTimeThumbnail = () => {
    return SongTimeThumbnail(currentEpisodeCode);
  };

  /**
   * HH1 HH2 타임라인 썸네일 이미지
   */
  const timeLineThumbnail = () => {
    return TimeLineThumbnail(currentEpisodeCode);
  };
  /**
   * HH1 HH2 워드플레이 썸네일 이미지
   */
  const wordplayThumbnail = () => {
    return WordPlayThumbnail(currentEpisodeCode);
  };

  const onClickAudio = () => {
    clickAudio.play();
  }

  const HH1Menus = () => {
    if (userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected)) {
      return (
        <div className={'ls' + lessonSelected}>
          <div className="sub-box d-flex justify-content-center">
            {
              userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected).acts.map((values, index) => {
                let iconBox = undefined;
                let iconClassName = '';
                let iconContainerName = '';
                switch (values.actStatus) {
                  case 'Ready':
                    iconBox = ItemBoxIcon3;
                    iconClassName = "icon-3";
                    iconContainerName = 'ready';
                    break;
                  case 'Progress':
                    iconBox = ItemBoxIcon2;
                    iconClassName = "icon-2";
                    iconContainerName = 'progress';
                    break;
                  case 'Completed':
                    iconBox = ItemBoxIcon1;
                    iconClassName = "icon-1";
                    iconContainerName = 'completed';
                    break;
                  default:
                    break;
                }
                return <Link className={"item-box-parent " + (values.actTypeGroup === 'Animation' ? 'first' : values.actTypeGroup === 'QuizTime' ? 'third' : values.actTypeGroup === 'EGGStudioNew' ? 'third' : values.actTypeGroup === 'TimeLine' ? 'third' : values.actTypeGroup === 'WordPlay' ? 'first' : values.actTypeGroup === 'SongTime' ? 'third' : 'second')}
                  key={index}
                  to={{
                    pathname: "/hh1/" + (values.actTypeGroup === 'Animation' ? 'animation'
                      :
                      values.actTypeGroup === 'QuizTime' ? 'quiz-time'
                        :
                        values.actTypeGroup === 'SongTime' ? 'song-time'
                          :
                          values.actTypeGroup === 'TimeLine' ? 'time-line'
                            :
                            values.actTypeGroup === 'EGGStudioNew' ? 'egg-studio-new'
                              :
                              'mini-sitcom'),
                    state: {
                      actInfo: values,
                      episodeId: episodeSelected,
                      lessonId: lessonSelected,
                      actId: values.actId
                    }
                  }}>
                  <div className={"item-box " + (values.actTypeGroup === 'Animation' ? 'first-box'
                    :
                    values.actTypeGroup === 'QuizTime' ? 'third-box'
                      :
                      values.actTypeGroup === 'SongTime' ? 'third-box'
                        :
                        values.actTypeGroup === 'TimeLine' ? 'third-box'
                          :
                          values.actTypeGroup === 'EGGStudioNew' ? 'third-box'
                            : 'second-box')} onClick={onClickAudio}>
                    <img src={
                      values.actTypeGroup === 'Animation' ? animationThumbnail() :
                        values.actTypeGroup === 'SongTime' ? songTimeThumbnail() :
                          values.actTypeGroup === 'EGGStudioNew' ? eggStudioThumbnail() :
                            values.actTypeGroup === 'TimeLine' ? timeLineThumbnail() :
                              values.actTypeGroup === 'QuizTime' ? quiztimeThumbnail() :
                                ItemBoxMiniSitcom
                    } alt="" />
                    {/* <div className="point-icon d-flex justify-content-center">
                      <p className="extra-bold">{values.actPoint}</p>
                    </div> */}
                  </div>
                  <div className="top-text">
                    <p className="extra-bold">{values.actTitle}</p>
                  </div>
                  <div className="bot-text">
                    <p className="extra-bold">{values.actStatus}</p>
                    <div className={"icon " + iconContainerName + " d-flex justify-content-center align-items-center"}>
                      <img className={iconClassName} src={iconBox} alt="" />
                    </div>
                  </div>
                </Link>
              })
            }
          </div>
        </div>
      );
    } else {
      return '';
    }
  };

  const HH2Menus = () => {
    if (userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected)) {
      return (
        <div className="epi-category">
          {
            userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected).acts.map((values, index) => {
              let iconBox = undefined;
              let iconClassName = '';
              let iconContainerName = '';
              switch (values.actStatus) {
                case 'Ready':
                  iconBox = ItemBoxIcon3;
                  iconClassName = "icon-3";
                  iconContainerName = 'ready';
                  break;
                case 'Progress':
                  iconBox = ItemBoxIcon2;
                  iconClassName = "icon-2";
                  iconContainerName = 'progress';
                  break;
                case 'Completed':
                  iconBox = ItemBoxIcon1;
                  iconClassName = "icon-1";
                  iconContainerName = 'completed';
                  break;
                default:
                  break;
              }
              return <Link
                key={index}
                to={{
                  pathname: "/hh2/" + (values.actTypeGroup === 'Animation' ? 'sitcom'
                    : values.actTypeGroup === 'QuizTime' ? 'quiz-time'
                      : values.actTypeGroup === 'TimeLine' ? 'time-line2'
                        : values.actTypeGroup === 'WordPlay' ? 'word-play2'
                          : 'speaking-practice'),
                  state: {
                    episodeId: episodeSelected,
                    lessonId: lessonSelected,
                    actId: values.actId
                  }
                }} className={"item-box-parent " + (values.actTypeGroup === 'Animation' ? 'first'
                  : values.actTypeGroup === 'WordPlay' ? 'first'
                    : values.actTypeGroup === 'QuizTime' ? 'third'
                      : values.actTypeGroup === 'TimeLine' ? 'third'
                        : values.actTypeGroup === 'SongTime' ? 'third'
                          : values.actTypeGroup === 'EGGStudioNew' ? 'third'
                            : 'second')}>
                <div className={"item-box " + (values.actTypeGroup === 'Animation' ? 'first-box'
                  : values.actTypeGroup === 'WordPlay' ? 'first-box'
                    : values.actTypeGroup === 'QuizTime' ? 'third-box'
                      : values.actTypeGroup === 'TimeLine' ? 'third'
                        : 'second-box')}
                  onClick={onClickAudio}
                >
                  <img src={
                    values.actTypeGroup === 'Animation' ? sitcomThumbnail() :
                      values.actTypeGroup === 'WordPlay' ? wordplayThumbnail() :
                        values.actTypeGroup === 'QuizTime' ? quiztimeThumbnail() :
                          values.actTypeGroup === 'TimeLine' ? timeLineThumbnail() :
                            thumbnailspeakingpractice()
                    // ItemBoxSpeakingBooster
                  } alt="" />
                </div>
                <div className="top-text">
                  <p className="extra-bold">{values.actTitle}</p>
                </div>
                <div className="bot-text">
                  <p className="extra-bold">{values.actStatus}</p>
                  <div className={"icon " + iconContainerName + " d-flex justify-content-center align-items-center"}>
                    <img className={iconClassName} src={iconBox} alt="" />
                  </div>
                </div>
              </Link>
            })
          }
        </div>
      );
    } else {
      return '';
    }
  };

  const CultureMenus = () => {
    if (userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected)) {
      return (
        <div className="epi-category">
          {
            userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected).acts.map((values, index) => {
              let iconBox = undefined;
              let iconClassName = '';
              let iconContainerName = '';
              switch (values.actStatus) {
                case 'Ready':
                  iconBox = ItemBoxIcon3;
                  iconClassName = "icon-3";
                  iconContainerName = 'ready';
                  break;
                case 'Progress':
                  iconBox = ItemBoxIcon2;
                  iconClassName = "icon-2";
                  iconContainerName = 'progress';
                  break;
                case 'Completed':
                  iconBox = ItemBoxIcon1;
                  iconClassName = "icon-1";
                  iconContainerName = 'completed';
                  break;
                default:
                  break;
              }
              return <Link className="item-box-parent first"
                key={index}
                to={{
                  pathname: "/culture/video",
                  state: {
                    episodeId: episodeSelected,
                    lessonId: lessonSelected,
                    actId: values.actId
                  }
                }}>
                <div className="item-box first-box"
                  onClick={onClickAudio}
                >
                  <EpisodeThumb episodeCode={currentEpisodeCode} />
                  {/* <img src={require('../images/direction/culture_EP1.png')} alt="" /> */}
                  {/* <div className="point-icon d-flex justify-content-center">
                      <p className="extra-bold">{values.actPoint}</p>
                    </div> */}
                </div>
                <div className="top-text">
                  <p className="extra-bold">Video</p>
                </div>
                <div className="bot-text">
                  <p className="extra-bold">{values.actStatus}</p>
                  <div
                    className={"icon " + iconContainerName + " d-flex justify-content-center align-items-center"}>
                    <img className={iconClassName} src={iconBox} alt="" />
                  </div>
                </div>
              </Link>
            })
          }
        </div>
      );
    } else {
      return '';
    }
  };

  const MyMessage = () => {
    return (
      <div className="my-message">
        <div className="arrow" />
        <div className="top d-flex">
          <div className="message-icon d-flex align-items-center justify-content-center">
            <img src={MessageIcon} alt="" />
          </div>
          <div className="about-me">
            <p className="extra-bold">{userSelector.info.studentName}</p>
            <span>{userSelector.info.productName}</span>
          </div>
        </div>

        {/* <Link to="/my-point">
          <div className="point d-flex align-items-center">
            <div className="my-point">
              <p className="extra-bold">My Point</p>
            </div>
            <div className="current-point d-flex align-items-center">
              <img src={userSelector.point.iconURL} alt="" />
              <span className="extra-bold">{userSelector.point.total}</span>
            </div>
          </div>
        </Link> */}
      </div>
    );
  };


  return (
    <>
      <div className="learning-home">
        {/*CULTURE learning-home 시작*/}
        {
          userSelector.info.productName.toUpperCase() === 'CULTURE' &&
          <>
            <div className="home-header">
              <p className="logo">
                <img src={LogoHht1} alt="" />
              </p>
              <div className="home-menu">
                <span className="sound-menu">
                  <img src={SoundIcon_on} alt="" />
                  {/* <img src={SoundIcon_off} alt="" /> */}{/*뮤트*/}
                </span>
                <span className="profile-menu">
                  <img src={ProfileIcon} alt="" onClick={() => setStudentHover(!studentHover)} />
                  {studentHover ? <MyMessage /> : <div className="my-message-hide"></div>}
                  <em className="photo-upload"></em>
                </span>
                <span className="close-menu"><img src={CloseIcon} alt="" /></span>
              </div>
            </div>
            <div className="home-contents box-background-culture">
              <div className="home-box">
                <div className="home-img">

                </div>
                <div className="episode_wrap">
                  <div className="epi-step">
                    <span onClick={() => onLeftClick()} className="epi-arrow prev"></span>
                    <p className="epi-step-p">{userSelector.info.episodes.find(item => item.episodeId === episodeSelected).episodeName}</p>
                    <span onClick={() => onRightClick()} className="epi-arrow next"></span>
                  </div>
                  <div className="epi-lesson" data-menu-selected={lessonSelected}>
                    <ul className="lesson-bar"></ul>
                  </div>
                  <CultureMenus />
                </div>
              </div>
            </div>
          </>

        }
        {/*CULTURE learning-home 끝*/}

        {/*HHT1 learning-home 시작*/}
        {
          userSelector.info.productName.toUpperCase() == 'HHT1' &&
          <div className={style}>
            <div className="home-header">
              <p className="logo">
                <img src={LogoHht1} alt="" />
              </p>
              <div className="home-menu">
                <span className="sound-menu">
                  <img src={SoundIcon_on} alt="" />
                  {/* <img src={SoundIcon_off} alt="" /> */}{/*뮤트*/}
                </span>
                <span className="profile-menu">
                  <img src={ProfileIcon} alt="" onClick={() => setStudentHover(!studentHover)} />
                  {studentHover ? <MyMessage /> : <div className="my-message-hide"></div>}
                  <em className="photo-upload"></em>
                </span>
                <span className="close-menu"><img src={CloseIcon} alt="" /></span>
              </div>
            </div>
            <div className={"home-contents box-background-hh1"}>
              <div className="home-box">
                <div className="home-img">
                  <img src={PersonIconHHT1_01} alt="" />
                  {/* <img src={PersonIconHHT1_02} alt="" />
                    <img src={PersonIconHHT1_03} alt="" />
                    <img src={PersonIconHHT1_04} alt="" /> */}
                </div>
                <div className="box-wrap">
                  <div className="episode_wrap">
                    <div className="epi-step">
                      <span onClick={() => onLeftClick()} className="epi-arrow prev"></span>
                      <p className="epi-step-p">{userSelector.info.episodes.find(item => item.episodeId === episodeSelected).episodeName}</p>
                      <span onClick={() => onRightClick()} className="epi-arrow next"></span>
                    </div>
                    <div className="epi-lesson" data-menu-selected={lessonSelected}>
                      <ul className="lesson-bar">
                        {
                          userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.map((item, index) => (
                            <li
                              key={index}
                              className={`list-${index + 1} bold ${item.lessonId === lessonSelected ? "now" : "next"}`}
                              onClick={() => onLessonChange(item.lessonId)}
                            >
                              <span>{item.lessonTitle}</span>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    <HH1Menus />
                  </div>
                </div>
                <div className="side-menu">
                  <Link to="/video" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-1" src={SideMenuIcon1} alt="" />
                      <p className="bold">Video</p>
                    </div>
                  </Link>
                  <Link to="/audio" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-2" src={SideMenuIcon2} alt="" />
                      <p className="bold">Audio</p>
                    </div>
                  </Link>
                  <Link to="/book" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-3" src={SideMenuIcon3} alt="" />
                      <p className="bold">Book</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
        {/*HHT1 learning-home 끝*/}
        {/*HHT2 learning-home 시작*/}
        {
          userSelector.info.productName.toUpperCase() == 'HHT2' &&
          <div className={style}>
            <div className="home-header">
              <p className="logo">
                <img src={LogoHht2} alt="" />
              </p>
              <div className="home-menu">
                <span className="sound-menu">
                  <img src={SoundIcon_on} alt="" />
                  {/* <img src={SoundIcon_off} alt="" /> */}{/*뮤트*/}
                </span>
                <span className="profile-menu">
                  <img src={ProfileIcon} alt="" onClick={() => setStudentHover(!studentHover)} />
                  {studentHover ? <MyMessage /> : <div className="my-message-hide"></div>}
                  <em className="photo-upload"></em>
                </span>
                <span className="close-menu"><img src={CloseIcon} alt="" /></span>
              </div>
            </div>
            <div className="home-contents box-background-hh2">
              <div className="home-box-top">
                <div className="epi-step">
                  <span onClick={() => onLeftClick()} className="epi-arrow prev"></span>
                  <p className="epi-step-p">{userSelector.info.episodes.find(item => item.episodeId === episodeSelected).episodeName}</p>
                  <span onClick={() => onRightClick()} className="epi-arrow next"></span>
                </div>
                <div className="lesson-box">
                  <div className="epi-lesson" data-menu-selected={lessonSelected}>
                    <ul className="lesson-bar">
                      {
                        userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.map((item, index) => (
                          <li
                            key={index}
                            className={`list-${index + 1} bold ${item.lessonId === lessonSelected ? "now" : "next"}`}
                            onClick={() => onLessonChange(item.lessonId)}
                          >
                            <span>{item.lessonTitle}</span>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                  <div className="home-img">
                    <img src={PersonIconHHT2_01} alt="" />
                    {/* <img src={PersonIconHHT2_02} alt="" />
                      <img src={PersonIconHHT2_03} alt="" />
                      <img src={PersonIconHHT2_04} alt="" /> */}
                  </div>
                </div>
              </div>
              <div className="home-box">
                <div className={'ls' + lessonSelected}>
                  <HH2Menus />
                </div>
                <div className="side-menu">
                  <Link to="/video" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-1" src={SideMenuIcon1} alt="" />
                      <p className="bold">Video</p>
                    </div>
                  </Link>
                  <Link to="/audio" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-2" src={SideMenuIcon2} alt="" />
                      <p className="bold">Audio</p>
                    </div>
                  </Link>
                  <Link to="/book" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-3" src={SideMenuIcon3} alt="" />
                      <p className="bold">Book</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
        {/*HHT2 learning-home 끝*/}

        {/*learning-home 모달팝업*/}
        {/*Character 팝업1 - 프로필 카메라모양 아이콘 클릭시 노출*/}
        {/*각 이미지 아이콘 클릭시 border 추가되도록 개발 필요*/}
        <div className="modal_width">
          <div className="modal_overlay"></div>
          <div className="modal_wrapper character-pop">
            <div className="modal_inner">
              <div className="title">Choose your character.</div>
              <div className="character-list">
                <span style={{ 'border': '2px solid #45b6c9' }}><img src={Char01} alt="" /></span>{/*기본으로 맨처음 이미지아이콘에 border스타일있음*/}
                <span><img src={Char02} alt="" /></span>
                <span><img src={Char03} alt="" /></span>
                <span><img src={Char04} alt="" /></span>
                <span><img src={Char05} alt="" /></span>
                <span><img src={Char06} alt="" /></span>
                <span><img src={Char07} alt="" /></span>
                <span><img src={Char08} alt="" /></span>
                <span><img src={Char09} alt="" /></span>
                <span><img src={Char10} alt="" /></span>
                <span><img src={Char11} alt="" /></span>
                <span className="my-photo"><img src={Char12} alt="" /></span>
              </div>
              <button className="pop-close"></button>
            </div>
          </div>
        </div>
        {/*Character 팝업1 - 프로필 카메라모양 아이콘 클릭시 노출*/}

        {/*Character 팝업2 - Character 팝업1 가장 마지막에 있는 my-photo 클릭시 노출*/}
        <div className="modal_width">
          <div className="modal_overlay"></div>
          <div className="modal_wrapper upload-pop">
            <div className="modal_inner">
              <div className="title">Upload your photo.</div>
              <div className="photo-area">
                <div className="defalut">
                  <p>Drag the photo <br />or</p>
                  <button className="full-btn">Select the image</button>
                </div>
              </div>
              <button className="pop-close"></button>
            </div>
          </div>
        </div>
        {/*Character 팝업2*/}

        {/*Character 팝업3 - Select the image버튼 클릭시 사진편집하는 팝업*/}
        <div className="modal_width">
          <div className="modal_overlay"></div>
          <div className="modal_wrapper photo-pop">
            <div className="modal_inner">
              <img src={PhotoSample} alt="" />
              <button className="full-btn">Save</button>
            </div>
          </div>
        </div>
        {/*Character 팝업3*/}

        {/*learning-home 모달팝업*/}

      </div>
    </>
  );
};

export default LearningHome;