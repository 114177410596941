import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import { TaskTimer } from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const PlayRecordSP = forwardRef((props, ref) => {
    const modalRef = useRef();
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const setNextButtonEnabledR = props.setNextButtonEnabledR;
    // const mediaPath = props.mediaPath;
    const mediaPath = window.location.origin + props.mediaPath + '/';

    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const [modal, setModal] = useState(false);
    const [yesmodal, setYesmodal] = useState(false);

    const onCancel = () => {
        RerecordModalClose();
    }

    const [recordState, setRecordState] = useState({
        recordingIndex: -1,
    });
    console.log('recordState.recordingIndex', recordState.recordingIndex);

    const RerecordModalRef = useRef();
    const RerecordModalOpen = () => {
        RerecordModalRef.current.style.display = 'block';
    };
    const RerecordModalClose = () => {
        RerecordModalRef.current.style.display = 'none';

    };

    useImperativeHandle(ref, () => ({
        answerOpen(recordingIndex) {
            modalRef.current.style.display = 'block';
            setRecordState({
                recordingIndex: recordingIndex,
            });
            replayIconTimer.stop();
            recordIconTimer.stop();
            recordReadyIconTimer.stop();
            Mp3Recorder.stop();
            mysoundIconTimer.stop();
        },
        answerClose() {
            modalRef.current.style.display = 'none';
            setRecordState({
                recordingIndex: -1,

            });
            replayIconTimer.stop();
            recordIconTimer.stop();
            recordReadyIconTimer.stop();
            Mp3Recorder.stop();
            mysoundIconTimer.stop();
        }
    }));

    const modalClose = () => {
        modalRef.current.style.display = 'none';
        setRecordState({
            recordingIndex: -1
        });
    }

    const btnBack = [
        require('../../images/activity-minisitcom/sitcom_pop_btn_back_h.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_back_n.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_back_p.png').default,
    ];
    const btnSubmit = [
        require('../../images/activity-minisitcom/sitcom_pop_btn_submit_h.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_submit_n.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_submit_p.png').default,
    ];
    // 브랜치명: dev002 녹음 기능 중 Submit 버튼 삭제
    const LoadBtn = (props) => {
        let btns, className;
        if (btnBack.findIndex(item => item === props.src) > -1) {
            btns = btnBack;
            className = 'back-btn';
        } else if (btnSubmit.findIndex(item => item === props.src) > -1) {
            return null;
            // btns = btnSubmit;
            // className = 'submit-btn';
        } else {
            return null;
        }
        const btnEvents = (e) => {
            if (e.type === "mouseover") {
                e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
                e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
                e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
                e.target.src = btns[0];

                if (btns === btnBack) {
                    let type;


                } else if (btns === btnSubmit) {


                }
            }
        };
        return (
            <>
                <BrowserView>
                    <img
                        className={className}
                        src={props.src}
                        alt=""
                        onMouseOver={btnEvents}
                        onMouseLeave={btnEvents}
                        onMouseDown={btnEvents}
                        onMouseUp={btnEvents} />
                </BrowserView>
                <MobileView>
                    <img
                        className={className}
                        src={props.src}
                        alt=""
                        onTouchStart={btnEvents}
                        onTouchEnd={btnEvents} />
                </MobileView>
            </>
        )
    };

    /**
     * 재생 버튼 오디오 관련
     */
    const audioRef = [];
    // const onCanPlayThrough = (index) => {
    //     audioRef[index].current.volume = activityProps.defaultVolume;
    //     loadStageRef.current.onCanPlayThrough(index);
    // }

    const LoadAudios = () => {
        if (!categorySelected.sentence || categorySelected.sentence.length < 1) return null;
        console.log('audioRef', audioRef);

        categorySelected.sentence.forEach(() => {
            audioRef.push(React.createRef());
        });
        console.log('audioRef', audioRef);
        return (
            <>
                {categorySelected.sentence.map((item, index) => (
                    <audio
                        key={index}
                        ref={audioRef[index]}
                        src={(item.audio ? mediaPath + item.audio : '')}
                        // onCanPlayThrough={() => onCanPlayThrough(index)}
                        onEnded={() => onEnded(index)}
                    />
                ))}
            </>
        );
    };
    const onEnded = (e) => {
        isReplaying = false;

        replayIconTimer.stop();
        playIconRef.current.src = playIcon[2];
        recordIconRef.current.src = recordIcon[2];
        if (recordState.recordingIndex === -1 ||
            (!categorySelected.sentence[recordState.recordingIndex].file ||
                !categorySelected.sentence[recordState.recordingIndex].file.size ||
                categorySelected.sentence[recordState.recordingIndex].file.size < 0) &&
            !categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
            mysoundIconRef.current.src = mysoundIcon[4];
        } else {
            mysoundIconRef.current.src = mysoundIcon[2];
        }
    };

    /**
     * 재생 버튼 관련
     */
    const playIconRef = useRef();
    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];
    let isReplaying = false;
    const playIconEvents = (e) => {
        if (categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
            setNextButtonEnabledR();
        }
        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = playIcon[3];
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isRecordingReady || isRecording || isMySoundPlaying) return;
            if (!isReplaying) {
                isReplaying = true;

                playIconRef.current.src = playIcon[0][0];
                recordIconRef.current.src = recordIcon[4];
                mysoundIconRef.current.src = mysoundIcon[4];
                replayIconTimer.start();
                console.log('recordState.recordingIndex', recordState.recordingIndex);

                audioRef[recordState.recordingIndex].current.play();
            } else {
                isReplaying = false;

                replayIconTimer.stop();
                playIconRef.current.src = playIcon[2];
                recordIconRef.current.src = recordIcon[2];
                if (recordState.recordingIndex === -1 ||
                    (!categorySelected.sentence[recordState.recordingIndex].file ||
                        !categorySelected.sentence[recordState.recordingIndex].file.size ||
                        categorySelected.sentence[recordState.recordingIndex].file.size < 0) &&
                    !categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
                    mysoundIconRef.current.src = mysoundIcon[4];
                } else {
                    mysoundIconRef.current.src = mysoundIcon[2];
                }

                audioRef[recordState.recordingIndex].current.pause();
                audioRef[recordState.recordingIndex].current.currentTime = 0;
            }
        }
    };
    const replayIconTimer = new TaskTimer(500);
    replayIconTimer.add([
        {
            id: 'replayIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = playIcon[0].findIndex(item => item === playIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 2) nextIndex = 0;
                playIconRef.current.src = playIcon[0][nextIndex];
            }
        }
    ]).stop();

    /**
     * 녹음 버튼 관련
     */
    const recordStartAudioRef = useRef();
    const countBbiAudioRef = useRef();
    const recordIconRef = useRef();
    const recordIcon = [
        [
            require('../../images/activity-default/btn_record_stop_01.png').default,
            require('../../images/activity-default/btn_record_stop_02.png').default
        ],
        require('../../images/activity-default/btn_record_h.png').default,
        require('../../images/activity-default/btn_record_n.png').default,
        require('../../images/activity-default/btn_record_p.png').default,
        require('../../images/activity-default/btn_record_d.png').default,
        [
            require('../../images/activity-default/btn_record_ready_01.png').default,
            require('../../images/activity-default/btn_record_ready_02.png').default,
            require('../../images/activity-default/btn_record_ready_03.png').default
        ],
    ];
    let isRecording = false;
    let isRecordingReady = false;
    // 브랜치명: dev001 태블릿 녹음 수정코드
    const recordIconEvents = (e) => {
        if (categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
            setNextButtonEnabledR();
        }
        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = recordIcon[3];
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isReplaying || isRecordingReady || isMySoundPlaying) return;

            if (!isRecording && !categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
                console.log('state_mouseup_record_!isRecording&& !modal');
                console.log('state_recordIconEvents2_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                isRecordingReady = true;
                console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                playIconRef.current.src = playIcon[4];
                recordIconRef.current.src = recordIcon[5][2];
                mysoundIconRef.current.src = mysoundIcon[4];

                countBbiAudioRef.current.play();
                setTimeout(() => {
                    countBbiAudioRef.current.pause();
                    countBbiAudioRef.current.currentTime = 0;

                    recordReadyIconTimer.start();
                }, 300);
            }
            // else if (!isRecording && categorySelected.sentence.fileUrlPath) {
            else if (!isRecording && categorySelected.sentence[recordState.recordingIndex].fileUrlPath && !modal && !yesmodal) {
                console.log('!modal');
                console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'yesmodal', yesmodal, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                RerecordModalOpen();

            } else if (!isRecording && categorySelected.sentence[recordState.recordingIndex].fileUrlPath && !modal && yesmodal) {
                RerecordModalClose();
                console.log('else');
                isRecordingReady = true;
                console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'yesmodal', yesmodal, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                playIconRef.current.src = playIcon[4];
                recordIconRef.current.src = recordIcon[5][2];
                mysoundIconRef.current.src = mysoundIcon[4];

                countBbiAudioRef.current.play();
                setTimeout(() => {
                    countBbiAudioRef.current.pause();
                    countBbiAudioRef.current.currentTime = 0;

                    recordReadyIconTimer.start();
                }, 300);
                setModal(false);

            }
            else {
                console.log('state_mouseup_record_!isRecordingElse');
                console.log('state_recordIconEvents4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);

                Mp3Recorder
                    .stop()
                    .getMp3().then(([buffer, blob]) => {
                        isRecording = false;

                        recordIconTimer.stop();
                        playIconRef.current.src = playIcon[2];
                        recordIconRef.current.src = recordIcon[2];
                        mysoundIconRef.current.src = mysoundIcon[2];

                        feedback(true);

                        const file = new File(buffer, 'mySound.mp3', {
                            type: blob.type,
                            lastModified: Date.now()
                        });
                        const player = new Audio(URL.createObjectURL(file));
                        console.log('data_player1', player);

                        console.log('data_categorySelected.order', categorySelected.order);
                        categorySelected.sentence[recordState.recordingIndex].file = file;
                        categorySelected.sentence[recordState.recordingIndex].fileUrl = URL.createObjectURL(file);
                        categorySelected.sentence[recordState.recordingIndex].player = player;
                        console.log('data_file', file);
                        console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
                        console.log('data_player2', player);

                        setRecordState({
                            recordingIndex: recordState.recordingIndex,
                        });

                        setNextButtonEnabled();
                        console.log('state_recordIconEvents5_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isReplaying', isReplaying, 'isMySoundPlaying', isMySoundPlaying);
                    }).catch((e) => {
                        // TODO 녹음 오류시 처리 필요
                    });
            }
        }
    };
    const recordReadyIconTimer = new TaskTimer(700);
    recordReadyIconTimer.add([
        {
            id: 'recordReadyIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = recordIcon[5].findIndex(item => item === recordIconRef.current.src);
                let nextIndex = nowIndex - 1;
                recordIconRef.current.src = recordIcon[5][nextIndex];

                if (nextIndex < 0) {
                    // recordStartAudioRef.current.play();
                    // setTimeout(() => {
                    //     recordStartAudioRef.current.pause();
                    //     recordStartAudioRef.current.currentTime = 0;

                    Mp3Recorder.start().then(() => {
                        isRecordingReady = false;
                        isRecording = true;

                        let recordSeconds = 1;
                        let recordLimitTimer = setInterval(() => {
                            if (isRecording) {
                                recordSeconds++;
                                if (recordSeconds > 30) {
                                    clearInterval(recordLimitTimer);

                                    isRecording = false;

                                    recordIconTimer.stop();
                                    if (playIconRef.current !== null) {
                                        playIconRef.current.src = playIcon[2];
                                    }
                                    if (recordIconRef.current !== null) {
                                        recordIconRef.current.src = recordIcon[2];
                                    }
                                    if (mysoundIconRef.current !== null) {
                                        if (recordState.recordingIndex === -1 ||
                                            (!categorySelected.sentence[recordState.recordingIndex].file ||
                                                !categorySelected.sentence[recordState.recordingIndex].file.size ||
                                                categorySelected.sentence[recordState.recordingIndex].file.size < 0) &&
                                            !categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
                                            mysoundIconRef.current.src = mysoundIcon[4];
                                        } else {
                                            mysoundIconRef.current.src = mysoundIcon[2];
                                        }
                                    }

                                    Mp3Recorder.stop();

                                    recordTimeOverModalRef.current.openModal();
                                }
                            } else {
                                clearInterval(recordLimitTimer);
                            }
                        }, 1000);
                    }).catch((e) => {
                        console.error(e);
                    });

                    recordReadyIconTimer.stop();

                    playIconRef.current.src = playIcon[4];
                    recordIconRef.current.src = recordIcon[0][0];
                    mysoundIconRef.current.src = mysoundIcon[4];
                    recordIconTimer.start();
                    // }, 500);
                } else {
                    countBbiAudioRef.current.play();
                    setTimeout(() => {
                        countBbiAudioRef.current.pause();
                        countBbiAudioRef.current.currentTime = 0;
                    }, 300);
                }
            }
        }
    ]).stop();
    const recordIconTimer = new TaskTimer(500);
    recordIconTimer.add([
        {
            id: 'recordIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = recordIcon[0].findIndex(item => item === recordIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 1) nextIndex = 0;
                recordIconRef.current.src = recordIcon[0][nextIndex];
            }
        }
    ]).stop();

    /**
     * 마이사운드 버튼 관련
     */
    const mysoundIconRef = useRef();
    const mysoundIcon = [
        [
            require('../../images/activity-default/btn_my_00.png').default,
            require('../../images/activity-default/btn_my_01.png').default,
            require('../../images/activity-default/btn_my_02.png').default,
            require('../../images/activity-default/btn_my_03.png').default
        ],
        require('../../images/activity-default/btn_my_h.png').default,
        require('../../images/activity-default/btn_my_n.png').default,
        require('../../images/activity-default/btn_my_p.png').default,
        require('../../images/activity-default/btn_my_d.png').default,
    ];
    let isMySoundPlaying = false;
    // 브랜치명: dev001 태블릿 녹음 수정코드
    const mysoundIconEvents = (e) => {
        if (categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
            setNextButtonEnabledR();
        }

        if (recordState.recordingIndex === -1 ||
            (!categorySelected.sentence[recordState.recordingIndex].file ||
                !categorySelected.sentence[recordState.recordingIndex].file.size ||
                categorySelected.sentence[recordState.recordingIndex].file.size < 0) &&
            !categorySelected.sentence[recordState.recordingIndex].fileUrlPath) return;

        if (e.type === "mouseover") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.src = mysoundIcon[1];
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isReplaying || isRecordingReady || isRecording) return;
            if (!isMySoundPlaying) {
                isMySoundPlaying = true;

                playIconRef.current.src = playIcon[4];
                recordIconRef.current.src = recordIcon[4];
                mysoundIconRef.current.src = mysoundIcon[0][0];

                mysoundIconTimer.start();

                if (categorySelected.sentence[recordState.recordingIndex].fileUrlPath && !categorySelected.sentence[recordState.recordingIndex].player.play) {
                    categorySelected.sentence[recordState.recordingIndex].player = new Audio(categorySelected.sentence[recordState.recordingIndex].fileUrlPath);
                }
                categorySelected.sentence[recordState.recordingIndex].player.addEventListener('ended', () => {
                    isMySoundPlaying = false;

                    recordIconRef.current.src = recordIcon[2];
                    playIconRef.current.src = playIcon[2];
                    if (recordState.recordingIndex === -1 ||
                        (!categorySelected.sentence[recordState.recordingIndex].file ||
                            !categorySelected.sentence[recordState.recordingIndex].file.size ||
                            categorySelected.sentence[recordState.recordingIndex].file.size < 0) &&
                        !categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
                        mysoundIconRef.current.src = mysoundIcon[4];
                    } else {
                        mysoundIconRef.current.src = mysoundIcon[2];
                    }

                    mysoundIconTimer.stop();
                });
                let playPromise = categorySelected.sentence[recordState.recordingIndex].player.play();
                if (playPromise !== undefined) {
                    playPromise.then(function () {
                    }).catch(function (error) {
                    });
                }
            } else {
                isMySoundPlaying = false;

                playIconRef.current.src = playIcon[2];
                recordIconRef.current.src = recordIcon[2];
                if (recordState.recordingIndex === -1 ||
                    (!categorySelected.sentence[recordState.recordingIndex].file ||
                        !categorySelected.sentence[recordState.recordingIndex].file.size ||
                        categorySelected.sentence[recordState.recordingIndex].file.size < 0) &&
                    !categorySelected.sentence[recordState.recordingIndex].fileUrlPath) {
                    mysoundIconRef.current.src = mysoundIcon[4];
                } else {
                    mysoundIconRef.current.src = mysoundIcon[2];
                }

                mysoundIconTimer.stop();

                categorySelected.sentence[recordState.recordingIndex].player.pause();
            }
        }
    };
    const mysoundIconTimer = new TaskTimer(500);
    mysoundIconTimer.add([
        {
            id: 'mysoundIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = mysoundIcon[0].findIndex(item => item === mysoundIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 3) nextIndex = 0;
                mysoundIconRef.current.src = mysoundIcon[0][nextIndex];
            }
        }
    ]).stop();

    /**
  * 문장 띄어쓰기 처리 제거 작업
  * @param wordArray 문장
  * @return string
  */
    const wordSpaceWork = (wordArray) => {
        let str, returnWord = '';

        wordArray.map((item, index) => {
            str = '';
            const reg = new RegExp('%.*%');
            if (reg.exec(item) !== null) {
                returnWord = returnWord.substr(0, (returnWord.length - 1));
                str = item.replace(/%/gi, '');
            } else {
                str = item + ' ';
            }

            // if (index < quizSelected.list.length) {
            //     str += quizSelected.lisentence.choiceSelected + ' ';
            // } else {
            //     str += '';
            // }

            returnWord += str;
        });

        return returnWord;
    };


    return (
        <>
            {console.log('state_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isReplaying', isReplaying, 'yesmodal', yesmodal, 'isMySoundPlaying', isMySoundPlaying)}
            {/* { modal? null : <RerecordModal 
            RerecordModalRef={RerecordModalRef} 
            RerecordModalOpen={RerecordModalOpen}
            RerecordModalClose={RerecordModalClose}
            handleOn={onRecord} 
            handleOff={onCancel} 
        /> } */}
            {modal ? null :
                <>
                    <div className="modal_width" ref={RerecordModalRef}>
                        <div className="modal_overlay" ></div>
                        <div className="modal_wrapper exit-pop">
                            <div className="modal_inner">
                                <div className="title">Do you want to delete <br />your recording and try again?</div>
                                <div className="buttongroup text-btn">
                                    <span className="line-btn"
                                        onClick={(e) => {

                                            recordIconEvents(e);
                                            RerecordModalClose();
                                            setYesmodal(true);
                                        }}>Yes</span>
                                    <span className="full-btn" onClick={onCancel}>No</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="btm_layer record" ref={modalRef}>
                <LoadAudios />
                <audio ref={recordStartAudioRef} src={recordStartAudio} />
                <audio ref={countBbiAudioRef} src={countBbiAudio} />
                {/* 문장 표시 부분 */}
                {/* <div className="word-wrap">
                { recordState.recordingIndex> -1 ?
                    categorySelected.sentence[recordState.recordingIndex].text
                   : null
                }
            </div> */}

                <div className="record-wrap">
                    <img
                        ref={playIconRef}
                        className="play-icon"
                        src={playIcon[2]}
                        alt=""
                        onMouseOver={playIconEvents}
                        onMouseLeave={playIconEvents}
                        onMouseDown={playIconEvents}
                        onMouseUp={playIconEvents}
                    />
                    <img
                        ref={recordIconRef}
                        className="record-icon"
                        src={recordIcon[2]}
                        alt=""
                        onMouseOver={recordIconEvents}
                        onMouseLeave={recordIconEvents}
                        onMouseDown={recordIconEvents}
                        onMouseUp={recordIconEvents}
                    />
                    <img
                        ref={mysoundIconRef}
                        className="mysound-icon"
                        src={(recordState.recordingIndex === -1 ||
                            (!categorySelected.sentence[recordState.recordingIndex].file ||
                                !categorySelected.sentence[recordState.recordingIndex].file.size ||
                                categorySelected.sentence[recordState.recordingIndex].file.size < 0) &&
                            !categorySelected.sentence[recordState.recordingIndex].fileUrlPath) ?
                            mysoundIcon[4]
                            :
                            mysoundIcon[2]
                        }
                        alt=""
                        onMouseOver={mysoundIconEvents}
                        onMouseLeave={mysoundIconEvents}
                        onMouseDown={mysoundIconEvents}
                        onMouseUp={mysoundIconEvents}
                    />
                </div>
                <div className="text-wrap">
                    {/* <p>{recordState.recordingIndex> -1 ? 
            categorySelected.sentence[recordState.recordingIndex].textnormal0 
            : null}</p> */}
                    {recordState.recordingIndex > -1 ? <p>{categorySelected.sentence[recordState.recordingIndex].textnormal0}
                        <em className='point'>{categorySelected.sentence[recordState.recordingIndex].texthighlight0}</em>
                        {categorySelected.sentence[recordState.recordingIndex].textnormal1}
                        <em className='point'>{categorySelected.sentence[recordState.recordingIndex].texthighlight1}</em>
                        {categorySelected.sentence[recordState.recordingIndex].textnormal2}</p> : null}
                </div>
                <div className="close-button"><span className="layer-close" onClick={modalClose}></span></div>
            </div>
        </>
    )
});

export default PlayRecordSP;