import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import SpeakingBooster from "../../components/activity/SpeakingBooster";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import SpeakingBoosterResult from "../../components/result/SpeakingBoosterResult";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import RouteBarIcon from "../../images/activity-sitcom/route-bar-icon.png";
import BackgroundPattern from "../../images/background-pattern.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";
// TODO 임시 이미지 변경하기
import HomeIcon from "../../images/home/message-icon.png";

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const SpeakingBoosterBlock = styled.div`
& {
  height: 100%;
  overflow: hidden;
  background-color: #f3f7d9;
  background-image: url(${BackgroundPattern});
  background-repeat: repeat;
  background-size: auto;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  & > div {
    position: relative;
    height: 100%;
  }
}

.contents {
  min-height: 100%;

  .route-bar {
    background-color: #08bbcb;
    width: calc(100% - 5%);
    height: 90px;
    border-radius: 0 0 50px 50px;
    box-shadow: 0px 11px 3px rgba(50, 50, 50, 0.1);
    margin: 0 auto 30px;
    padding: 20px 40px 20px 15%;
    position:absolute;
    left:50%;
    top:0;
    transform: translateX(-50%);
    z-index:5;
    .route-icons {
      width: 48px;
      height: 48px;
      background-color: rgba(50, 50, 50, 0.1);
      border-radius: 50%;
      margin-right: 15px;
      .route-icons-img {
        width: 24px;
      }
    }

    p {
      text-align: center;
      font-size: 38px;
      color: #fffffe;

      span {
        color: #fff;
      }
    }

    .cancel-icon {
      cursor: pointer;
      .home-icon-img {
        margin-right: 10px;
      }
    }
  }

  .box-parent {
    width: 100%;
    height: 100vh;
    padding:120px 0 50px 0;
    display:flex;
    align-items:center;
    .box {
      max-width: 1235px;
      height: 100%;    
      width: 80vw;
      background-color: #fff;
      border-radius: 80px;
      border: 1px solid rgba(50, 50, 50, 0.2);
      overflow: visible;
      margin: 0 auto;
      flex-wrap:wrap;

      .screen {
        background-color: transparent;
        width: 100%;
        height: 100%;
      }

      .volume-icon {
        margin-bottom: 30px;
        cursor: pointer;

        img {
          min-width: 0%;
          max-width: 100%;
          height: auto;
          max-height: 100%;
          
        }
      }

      .order-bar-div{
        width: 100%;
        .now-circle{
          margin-right: 0.8%;
          width: 24px;
          height: 24px;
          border-radius:50%;
        }
        .next-circle{
            margin-right: 0.8%;
            width: 24px;
            height: 24px;
            border-radius:50%;
            background-color: #ededed;
            cursor: pointer;
        }
      }

      .left-button {
        position: absolute;
        top: 50%;
        left: -25px;
        transform: translate(-100%, -50%);
        cursor: pointer;

        img {
          width: 95px;
        }
        @media (max-width: 1200px) {
          img{
            width:75px;
            height:75px;
          }
        }
      }
    }

    .right-button {
      position: absolute;
      top: 50%;
      right: -25px;
      transform: translate(100%, -50%);
      cursor: pointer;

      img {
        width: 95px;
      }
      @media (max-width: 1200px) {
        img{
          width:75px;
          height:75px;
        }
      }
    }

    .right-button.disabled {
      cursor: default !important;
    }

    .buttonGroup {
      position: absolute;
      bottom: 5vh;

      img:last-child {
        margin-right: 0;  
      }
      img {
        cursor: pointer;
        margin-right: 16px;
        width: 248px;
        height: 96px;
      }
    }
  }
}

.order-bar {
  background-color: #08bbcb;
  width: calc(100% - 5%);
  height: 90px;
  margin: 30px auto 0;
  padding: 20px 40px;
  border-radius: 50px 50px 0 0;
  position:absolute;
  left:50%;
  bottom:0;
  transform: translateX(-50%);
  
  
  .recodeClear {
    background-color: #fff;
  }

  & > div > div > div {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    margin-left: 15px;
    cursor: pointer;

    p {
      font-size: 20px;
      margin: 2px 1px 0 0;
    }
  }

  .previous-circle {
    background-color: #f9f7e8;
    border: 5px solid #f9f7e8;
  }

  .now-circle {
    background-color: #fdfa00;
    border: 5px solid #fdfa00;
  }
}


@media (max-width: 1024px), (max-height:768px) {
  & {
    .contents{
      
      .box-parent {
        padding:110px 0 30px 0;
        .box{
          min-height: auto;
          border-radius: 50px;
          width: 75.09vw;
          //min-width: 784px;
          .order-bar-div{
            .now-circle{
              width: 18px;
              height: 18px;
            }
            .next-circle{
              width: 18px;
              height: 18px;
            }
          }
          .right-button{
            img{
              width:75px;
              height:75px;
            }
          }
          .left-button{
            img{
              width:75px;
              height:75px;
            }
          }
        }
        .buttonGroup img{
          width: 200px;
          height: 77px;
        }
      }
      .route-bar{
        height: 70px;
        box-shadow: 0px 7px 2px rgba(50, 50, 50, 0.1);
        border-radius: 0 0 35px 35px;
        p{
          font-size: 26px;
        }
        .route-icons {
          width: 33px;
          height: 33px;
          .route-icons-img {
            width: 16px;
          }
        }
        .cancel-icon-img {
          width: 27px
        }
      }
      .order-bar {
        height: 70px;
        border-radius: 35px 35px 0 0;
        & > div > div > div {
          width: 35px;
          height: 35px;            
          p{
            font-size:18px;
          }
        }
      }
    }
  }
}

/*태블릿 7인치 이하*/
@media (max-height:650px) {
  & {
    .contents{      
      .box-parent {
        padding:40px 0 20px 0;
        align-items:flex-start;
        .box{
          margin:0 8%;
          width:calc(100% - 16%);
          min-width:auto;
          max-height:inherit;
          height:100%;
          border-radius:0 0 50px 50px;
          .order-bar-div{
            .now-circle{
              width: 12px;
              height: 12px;
            }
            .next-circle{
              width: 12px;
              height: 12px;
            }
          }
          .right-button{
            right:-10px;
            img{
              width:52px;
              height:52px;
            }
          }
          .left-button{
            left:-10px;
            img{
              width:52px;
              height:52px;
            }
          }
        }
        .buttonGroup img{
          width: 160px;
          height: 64px;
        }
      }
      .route-bar{
        height: 60px;
        padding-left:80px;
        p{
          font-size: 24px;
        }
        .cancel-icon-img {
          width: 22px
        }
      }
      .order-bar {
        height: 60px;
      }
    }
  }
}

.konvajs-content {
  margin: auto;
}
`;

const Index = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const nextButtonRef = useRef();
  const setNextButtonEnabled = () => {
    if (btnNextRef.current) return;

    if (categorySelected.name === "Main Sentence" || categorySelected.name === "Video") {
    } else {
      if (categorySelected.sentence) {
        let errorIndex = -1;
        for (var index = 0; index < categorySelected.sentence.length; index++) {
          let sentence = categorySelected.sentence[index];
          if (sentence.audio && ((!sentence.file || !sentence.file.size || sentence.file.size < 0) && !sentence.fileUrlPath)) {
            errorIndex = index;
            break;
          }
        }
        if (errorIndex > -1) {

        } else {
          nextButtonRef.current.children[0].src = btnNext[1];
          nextButtonRef.current.className = 'right-button';
          nextButtonRef.current.onmouseenter = () => { nextButtonRef.current.src = btnNext[0]; };
          nextButtonRef.current.onmouseleave = () => { nextButtonRef.current.src = btnNext[1]; };
          nextButtonRef.current.onmousedown = () => { nextButtonRef.current.src = btnNext[2]; };
          nextButtonRef.current.ontouchstart = () => { nextButtonRef.current.src = btnNext[2]; };
          nextButtonRef.current.onmouseup = () => { nextButtonRef.current.src = btnNext[1]; nextCategory(); };
          nextButtonRef.current.ontouchend = () => { nextButtonRef.current.src = btnNext[1]; nextCategory(); };
        }
      } else {

      }
    }
  };

  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(1);
  let minRecordCategoryNum = 1;
  console.log('actInfo', actInfo);
  useEffect(() => {

    const fetchData = async (actContentData) => {
      console.log('actContentData', actContentData);
      let filePathReplaced = actInfo.file_path.replace(`${apiHost}`, '');
      console.log('filePathReplaced', filePathReplaced);
      await fetch(filePathReplaced)
        .then(res => res.json())
        .then(res => {
          console.log("data_speakingBoosterindex.jsx_res >>>", res);
          setOrgActList(JSON.parse(JSON.stringify(res)));
          console.log("data_speakingBoosterindex.jsx_setOrgActList(JSON.parse(JSON.stringify(res)))", JSON.parse(JSON.stringify(res)));

          try {
            const stdAnswer = JSON.parse(actContentData.stdAnswer);

            res.map((res_item, res_index) => {
              res_item.category.map((category_item, category_index) => {
                if (category_item.name === 'Expended Sentence') {
                  category_item.sentence.map((sentence_item, sentence_index) => {
                    if (sentence_item.quiz) {
                      sentence_item.quiz.list.map((quizList_item, quizList_index) => {
                        if (stdAnswer && stdAnswer.length > res_index &&
                          stdAnswer[res_index].category && stdAnswer[res_index].category.length > category_index &&
                          stdAnswer[res_index].category[category_index].sentence && stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                          stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list && stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list.length > quizList_index) {
                          var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list[quizList_index].choiceSelected;
                          if (choiceSelected) {
                            quizList_item.choiceSelected = choiceSelected;
                          }
                        }
                      });
                    }
                    if (stdAnswer && stdAnswer.length > res_index &&
                      stdAnswer[res_index].category && stdAnswer[res_index].category.length > category_index &&
                      stdAnswer[res_index].category[category_index].sentence && stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                      var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                      if (fileUrlPath) {
                        sentence_item.fileUrlPath = fileUrlPath;

                        var player = new Audio(fileUrlPath);
                        if (player) {
                          sentence_item.player = player;
                        }
                      }
                    }
                  });
                }
              });
            });
          } catch (error) {
            console.log('Incorrect student answer data, ', error);
          }

          setActList(res);

          const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
          setMaxSetNum(maxSetNum);

          const categoryList = res.find(item => item.set === setNum).category;
          setCategoryList(categoryList);

          categoryList.sort((a, b) => a.order - b.order).forEach((value, index) => {
            if (index === 0) {
              setMinCategoryNum(value.order);
              setCategorySelected(categoryList[value.order]);
            } else if (index === categoryList.length - 1) {
              setMaxCategoryNum(value.order);
            }
          });

          // if (actContentData && actContentData.actCurrentPage) {
          //   setSetNum(actContentData.actCurrentPage);
          // }
        });
    };

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);

  const [guideFinished, setGuideFinished] = useState(false);

  const closeActivityModalRef = useRef();
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  }

  const recordTimeOverModalRef = useRef();
  const alertModalRef = useRef();
  const openAlertModal = (text) => {
    if (setNum === 1 && categorySelected.order === minRecordCategoryNum) {
      alertModalRef.current.openModal(text);
      setTimeout(() => { alertModalRef.current.closeModal(); }, 4000);
    }
  };

  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  }

  const prevCategory = () => {
    if (categorySelected.order > minCategoryNum) {
      setCategorySelected(categoryList[categorySelected.order - 1]);
    }
  };
  const nextCategory = () => {
    if (categorySelected.name === "Main Sentence") {
      if (categorySelected.order < maxCategoryNum) {
        setCategorySelected(categoryList[categorySelected.order + 1]);
      }
    } else {
      let newRecordExists = false;
      categorySelected.sentence.forEach(item => {
        if (item.file && item.file.size && item.file.size > 0) {
          newRecordExists = true;
          return false;
        }
      });
      if (newRecordExists) {
        /**
         * 학생 Act 학습 정보 저장 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
         */
        async function callApiAct() {
          const data = await API('post', 'act', {}, {
            oCode: userSelector.info.oCode,
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            level: actInfo.level,
            episodeId: episodeInfo.episodeId,
            episodeCode: episodeInfo.episodeCode,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actStatus: (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus),
            actPoint: 0,
            actCurrentPage: setNum,
            question: JSON.stringify(orgActList),
            answer: JSON.stringify(actList)
          }).catch((error) => {
            console.error(error);
          });

          console.log(data);

          userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .actStatus = (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus);

          dispatch(userStore.save(userSelector.info));

          let nextCategory;
          if (categorySelected.order < maxCategoryNum) {
            nextCategory = categoryList[categorySelected.order + 1];
          } else {
            nextCategory = {
              ...categoryList[1],
              name: 'result',
              first: true
            };
          }
          setCategorySelected(nextCategory);
        }

        /**
         * 학생 파일 파일 업로드 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
         */
        async function callApiActFile() {
          let index = 0;
          for (const item of categorySelected.sentence) {
            if (item.file && item.file.size && item.file.size > 0) {
              let form = new FormData();
              form.append('file', item.file);
              form.append('data', JSON.stringify({
                productId: userSelector.info.productId,
                studentId: userSelector.info.studentId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                episodeId: episodeInfo.episodeId,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId,
                actType: actInfo.actType,
                actPage: setNum,
                studentSoundNo: index + 1
              }));

              const data = await API('post', 'act/file', {}, form).catch((error) => {
                console.error(error);
              });

              console.log("==========================");
              console.log("학생 파일 파일 업로드이 완료 되었습니다.");
              console.log(data);
              console.log("==========================");

              if (!data.data || !data.data.fileUrlPath) {
                console.error("==========================");
                console.error("녹음 파일 경로 반환이 되지 않았습니다.");
                console.error("==========================");
              }

              categorySelected.sentence[index].fileUrlPath = data.data.fileUrlPath;
            }
            index++;
          }
          callApiAct();
        }
        callApiActFile();
      } else {
        let nextCategory;
        if (categorySelected.order < maxCategoryNum) {
          nextCategory = categoryList[categorySelected.order + 1];
        } else {
          nextCategory = {
            ...categoryList[1],
            name: 'result',
            first: false
          };
        }
        setCategorySelected(nextCategory);
      }
    }
  };

  const btnPrevRef = useRef();
  const btnNextRef = useRef();
  const btnPre = [
    require('../../images/activity-button/btn_pre_h.png').default,
    require('../../images/activity-button/btn_pre_n.png').default,
    require('../../images/activity-button/btn_pre_p.png').default,
    require('../../images/activity-button/btn_pre_d.png').default,
  ];
  const btnNext = [
    require('../../images/activity-button/btn_next_h.png').default,
    require('../../images/activity-button/btn_next_n.png').default,
    require('../../images/activity-button/btn_next_p.png').default,
    require('../../images/activity-button/btn_next_d.png').default,
  ];

  const RenderActivityArea = () => {
    if (!guideFinished) {
      return <Guide setGuideFinished={setGuideFinished} />
    } else {
      if (categorySelected.name === 'finished') {
        return (
          <div className="box d-flex justify-content-center align-items-center">
            <Congratulations categorySelected={categorySelected} />
          </div>
        );
      } else if (categorySelected.name === 'result') {
        const btnAgain = [
          require('../../images/common/btn_again_h.png').default,
          require('../../images/common/btn_again_n.png').default,
          require('../../images/common/btn_again_p.png').default,
        ];
        const btnNext = [
          require('../../images/common/btn_next_h.png').default,
          require('../../images/common/btn_next_n.png').default,
          require('../../images/common/btn_next_p.png').default,
        ];

        const LoadBtn = (props) => {
          const btnEvents = (e) => {
            let btns;
            if (btnAgain.findIndex(item => item === e.target.src) > -1) {
              btns = btnAgain;
            } else if (btnNext.findIndex(item => item === e.target.src) > -1) {
              btns = btnNext;
            } else {
              return;
            }

            if (e.type === "mouseover") {
              e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
              e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
              e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
              e.target.src = btns[0];

              if (btns === btnAgain) {
                setCategorySelected(categoryList[categorySelected.order]);
              } else if (btns === btnNext) {
                if (categorySelected.order < maxCategoryNum) {
                  let nextCategory = {
                    ...categoryList[categorySelected.order + 1],
                    name: 'result',
                  };
                  setCategorySelected(nextCategory);
                } else {
                  if (setNum === maxSetNum) {
                    /**
                     * 학생 Act 학습 정보 저장 API
                     * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
                     */
                    async function callApiAct() {
                      if (actInfo.actStatus !== 'Completed') {
                        const data = await API('post', 'act', {}, {
                          oCode: userSelector.info.oCode,
                          productId: userSelector.info.productId,
                          studentId: userSelector.info.studentId,
                          courseId: userSelector.info.courseId,
                          bookId: userSelector.info.bookId,
                          level: actInfo.level,
                          episodeId: episodeInfo.episodeId,
                          episodeCode: episodeInfo.episodeCode,
                          lessonId: lessonInfo.lessonId,
                          actId: actInfo.actId,
                          actType: actInfo.actType,
                          actStatus: "Completed",
                          actPoint: actInfo.actCompletPoint,
                          actCurrentPage: setNum,
                          question: JSON.stringify(orgActList),
                          answer: JSON.stringify(actList)
                        }).catch((error) => {
                          console.error(error);
                        });

                        console.log(data);

                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .actStatus = "Completed";
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .file = categorySelected.file;
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .fileUrl = categorySelected.fileUrl;
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .player = categorySelected.player;

                        dispatch(userStore.save(userSelector.info));
                      }

                      let nextCategory = {
                        name: 'finished',
                        order: categorySelected.order,
                        retry: () => {
                          setSetNum(1);
                          if (actList.length > 0) {
                            const categoryList = actList.find(item => item.set === 1).category;
                            setCategoryList(categoryList);

                            categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                              if (index === 0) {
                                setMinCategoryNum(item.order);
                                setCategorySelected(categoryList[item.order]);
                              } else if (index === categoryList.length - 1) {
                                setMaxCategoryNum(item.order);
                              }
                            });
                          }
                        },
                      };
                      setCategorySelected(nextCategory);
                    }
                    callApiAct();
                  } else {
                    setSetNum(setNum + 1);
                    if (actList.length > 0) {
                      const categoryList = actList.find(item => item.set === setNum + 1).category;
                      setCategoryList(categoryList);

                      categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                        if (index === 0) {
                          setMinCategoryNum(item.order);
                          setCategorySelected(categoryList[item.order]);
                        } else if (index === categoryList.length - 1) {
                          setMaxCategoryNum(item.order);
                        }
                      });
                    }
                  }
                }
              }
            }
          };

          return (
            <img src={props.src} alt=""
              onMouseOver={btnEvents}
              onMouseLeave={btnEvents}
              onMouseDown={btnEvents}
              onTouchStart={btnEvents}
              onMouseUp={btnEvents}
              onTouchEnd={btnEvents} />
          )
        };

        const nextCategoryAtResult = () => {
          if (categorySelected.order < maxCategoryNum) {
            let nextCategory = {
              ...categoryList[categorySelected.order + 1],
              name: 'result',
            };
            setCategorySelected(nextCategory);
          }
        };

        return (
          <div className="box d-flex justify-content-center align-items-center">
            <div className="screen">
              <SpeakingBoosterResult
                categorySelected={categorySelected}
                nextCategoryAtResult={nextCategoryAtResult}
                mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
              />
            </div>
            <div className="buttonGroup">
              <LoadBtn src={btnAgain[1]} />
              <LoadBtn src={btnNext[1]} />
            </div>
          </div>
        );
      } else {
        return (
          <div className="box d-flex justify-content-center align-items-center">
            <div className="screen">
              <SpeakingBooster
                categorySelected={categorySelected}
                setNextButtonEnabled={setNextButtonEnabled}
                mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                feedback={feedback}
                setNextButtonEnabled={setNextButtonEnabled}
                openAlertModal={openAlertModal}
                recordTimeOverModalRef={recordTimeOverModalRef}
              />
            </div>
            {
              categorySelected.order > minCategoryNum &&
              <div className="left-button"
                onMouseEnter={() => { btnPrevRef.current.src = btnPre[0]; }}
                onMouseLeave={() => { btnPrevRef.current.src = btnPre[1]; }}
                onMouseDown={() => { btnPrevRef.current.src = btnPre[2]; }}
                onTouchStart={() => { btnPrevRef.current.src = btnPre[2]; }}
                onMouseUp={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
                onTouchEnd={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
              >
                <img src={btnPre[1]} alt="" ref={btnPrevRef} />
              </div>
            }
            {
              categorySelected.name === "Main Sentence" || actInfo.actStatus === 'Completed' ?
                <>
                  <BrowserView>
                    <div className="right-button"
                      onMouseEnter={() => { btnNextRef.current.src = btnNext[0]; }}
                      onMouseLeave={() => { btnNextRef.current.src = btnNext[1]; }}
                      onMouseDown={() => { btnNextRef.current.src = btnNext[2]; }}
                      onMouseUp={() => { btnNextRef.current.src = btnNext[1]; nextCategory(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </BrowserView>
                  <MobileView>
                    <div className="right-button"
                      onTouchStart={() => { btnNextRef.current.src = btnNext[2]; }}
                      onTouchEnd={() => { btnNextRef.current.src = btnNext[1]; nextCategory(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </MobileView>
                </>
                :
                <div className="right-button disabled" ref={nextButtonRef}>
                  <img src={btnNext[3]} alt="" />
                </div>
            }
          </div >
        );
      }
    }
  };

  const checkUpRef = useRef();
  let idleSeconds = 0;
  let isIntervalRunning = true;
  // const useInterval = (callback, delay) => {
  //   const savedCallback = useRef();
  //   useEffect(() => {
  //     savedCallback.current = callback;
  //   }, [callback]);
  //   useEffect(() => {
  //     function tick() {
  //       savedCallback.current();
  //     }
  //     if (delay !== null) {
  //       let id = setInterval(tick, delay);
  //       return () => clearInterval(id);
  //     }
  //   }, [delay]);
  // };
  // useInterval(async () => {
  //   if (!isIntervalRunning) return;
  //   idleSeconds++;
  //   if (idleSeconds > (60 * 3)) {
  //     idleSeconds = 0;
  //     isIntervalRunning = false;
  //     checkUpRef.current.checkUp();
  //   }
  // }, 1000);
  const resetCheckUp = () => {
    idleSeconds = 0;
    isIntervalRunning = true;
  };

  const goToHome = () => {
    history.goBack();
  };

  /**
   * 녹은 완료된 문항 & 녹은 미완료된 문항 분리
   * @param setNum
   * @param actListRow
   * @returns {string}
   */
  const actNumBtnClass = (setNum, actListRow) => {
    let returnClass = "next-circle";

    if (setNum && setNum === actListRow.set) {
      returnClass = "now-circle";

    } else {
      let recodeClear = true; // 녹음 완료 여부

      actListRow.category.map((category) => {
        if (category.sentence !== undefined) {
          category.sentence.map((sentence) => {
            if (!sentence.player) {
              recodeClear = false;
            }
          });
        }
      });

      returnClass += recodeClear ? ' recodeClear' : '';
    }

    return returnClass;
  };

  return (
    <>
      {!isTablet && <BrowserView>
        <SpeakingBoosterBlock>
          <div
            onMouseDown={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
            onTouchStart={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
            onWheel={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
          >
            <div className="contents d-flex flex-column justify-content-between">
              <CloseActivityModal ref={closeActivityModalRef} />
              <Feedback ref={feedbackRef} />
              <RecordTimeOverModal ref={recordTimeOverModalRef} />
              <CheckUp ref={checkUpRef} resetCheckUp={resetCheckUp} />
              <AlertModal ref={alertModalRef} />

              <div className="route-bar d-flex align-items-center justify-content-between">
                <div className="d-flex ">
                  <div className="route-icons d-flex justify-content-center align-items-center">
                    <img className="route-icons-img" src={RouteBarIcon} alt="" />
                  </div>
                  <p>
                    {episodeInfo.episodeName} &gt; {lessonInfo.lessonTitle} &gt; <span className="extra-bold">{actInfo.actTitle}</span>
                  </p>
                </div>
                <div className="cancel-icon">
                  {/*<img className="home-icon-img" src={HomeIcon} alt="" onClick={() => goToHome()} />*/}
                  <img className="cancel-icon-img" src={CancelIcon} alt="" onClick={() => handleClose()} />
                </div>
              </div>

              <div className="box-parent">
                <RenderActivityArea />
              </div>
            </div>
          </div>
        </SpeakingBoosterBlock>
      </BrowserView>}
      <MobileView>
        <SpeakingBoosterBlock>
          <div
            onMouseDown={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
            onTouchStart={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
            onWheel={(e) => { if (isIntervalRunning === true) idleSeconds = 0; }}
          >
            <div className="contents d-flex flex-column justify-content-between">
              <CloseActivityModal ref={closeActivityModalRef} />
              <Feedback ref={feedbackRef} />
              <RecordTimeOverModal ref={recordTimeOverModalRef} />
              <CheckUp ref={checkUpRef} resetCheckUp={resetCheckUp} />
              <AlertModal ref={alertModalRef} />

              <div className="route-bar d-flex align-items-center justify-content-between">
                <div className="d-flex ">
                  <div className="route-icons d-flex justify-content-center align-items-center">
                    <img className="route-icons-img" src={RouteBarIcon} alt="" />
                  </div>
                  <p>
                    {episodeInfo.episodeName} &gt; {lessonInfo.lessonTitle} &gt; <span className="extra-bold">{actInfo.actTitle}</span>
                  </p>
                </div>
                <div className="cancel-icon">
                  {/*<img className="home-icon-img" src={HomeIcon} alt="" onClick={() => goToHome()} />*/}
                  <img className="cancel-icon-img" src={CancelIcon} alt="" onClick={() => handleClose()} />
                </div>
              </div>

              <div className="box-parent">
                <RenderActivityArea />
              </div>
            </div>
          </div>
        </SpeakingBoosterBlock>
      </MobileView>
    </>
  );
};

export default Index;
