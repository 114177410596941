import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import MiniSitcomKeyExpression from './MiniSitcomKeyExpression';
import MiniSitcomSpeakSentence from './MiniSitcomSpeakSentence';

const activityProps = {
    defaultVolume: 0.5,
    defaultWidth: 1240,
    defaultHeight: 840,
    characterMediaPath: window.location.origin + "/character/",
    textFontSize: 32,
    textColor: '#242424',
    textFontFamily: 'Nanum Barun Gothic',
    textLineHeight: 1.8,
};

const MiniSitcom = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = window.location.origin + props.mediaPath + '/';
    const answerOpen = props.answerOpen;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const openAlertModal = props.openAlertModal;

    const miniSitcomSpeakSentenceRef = useRef();
    useImperativeHandle(ref, () => ({
        setSelectedSubImage(imagePath) {
            miniSitcomSpeakSentenceRef.current.setSelectedSubImage(imagePath);
        }
    }));

    if (categorySelected.name === "Key Expression") {
        return <MiniSitcomKeyExpression
            categorySelected={categorySelected}
            mediaPath={mediaPath}
            activityProps={activityProps}
        />
    } else if (categorySelected.name === "Speak Sentence") {
        return <MiniSitcomSpeakSentence
            ref={miniSitcomSpeakSentenceRef}
            categorySelected={categorySelected}
            mediaPath={mediaPath}
            activityProps={activityProps}
            setNextButtonEnabled={setNextButtonEnabled}
            openAlertModal={openAlertModal}
            answerOpen={answerOpen}
        />
    } else {
        return (
            <div>Error</div>
        )
    }
});

export default MiniSitcom;