import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import MicRecorder from 'mic-recorder-to-mp3';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import ReplayPlay from "../../components/eggstudionew/ReplayPlay";
import EggStudio from "../../components/eggstudionew/EggStudio";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import EggStudioResult from "../../components/eggstudionew/EggStudioResult";
import RecordBar from '../../components/eggstudionew/RecordBar';
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import Background from "../../images/egg-studio/background.png";
import filmPattern from "../../images/egg-studio/film-pattern.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";


import OnAir_On from "../../images/egg-studio-new/onair_on.png"
import OnAir from "../../images/egg-studio-new/onair.png"
import NowLoading from "../../images/egg-studio-new/now-loading.png"

import Mic from "../../images/egg-studio-new/mic.png"
import MicOn from "../../images/egg-studio-new/mic_on.png"
import MicRec from "../../images/egg-studio-new/mic_rec.png"
import Speaker from "../../images/egg-studio-new/speaker.png"

import Countnum1 from "../../images/egg-studio-new/count1.png";
import Countnum2 from "../../images/egg-studio-new/count2.png";
import Countnum3 from "../../images/egg-studio-new/count3.png";

/*css*/
import "../../css/layout.css";
import "../../css/eggstudio.css";

import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Index = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
  const mediaPath = actInfo.media_path.replace(`${apiHost}`, '');

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(0);
  let minRecordCategoryNum = 0;
  console.log('page_orgActList', orgActList);
  console.log('page_actList', actList);
  console.log('page_categoryList', categoryList);
  console.log('page_categorySelected', categorySelected);
  console.log('page_setNum', setNum);
  console.log('page_maxSetNum', maxSetNum);
  console.log('page_minCategoryNum', minCategoryNum);
  console.log('page_maxCategoryNum', maxCategoryNum);

  const errorModalRef = useRef();
  const routeLearningHome = () => {
    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
      case 'HHT1':
        routerName = '/hh1/learning-home';
        break;
      case 'HHT2':
        routerName = '/hh2/learning-home';
        break;
      case 'CULTURE':
        routerName = '/culture/learning-home';
        break;
      default:
        break;
    }
    history.push(routerName);
  };
  useEffect(() => {
    const fetchData = async (actContentData) => {
      let filePathReplaced = actInfo.file_path.replace(`${apiHost}`, '');
      await fetch(filePathReplaced)
        .then(res => res.json())
        .then(res => {
          console.log("data_eggstudioindex.jsx_res >>>", res);
          setOrgActList(JSON.parse(JSON.stringify(res)));
          console.log("data_eggstudioindex.jsx_JSON.parse(JSON.stringify(res)>", JSON.parse(JSON.stringify(res)));

          try {
            const stdAnswer = JSON.parse(actContentData.stdAnswer);

            res.map((res_item, res_index) => {
              res_item.category.map((category_item, category_index) => {
                if (category_item.name === 'Shadowing') {
                  category_item.sentence.map((sentence_item, sentence_index) => {
                    if (sentence_item.quiz) {
                      sentence_item.quiz.list.map((quizList_item, quizList_index) => {
                        if (stdAnswer.length > res_index &&
                          stdAnswer[res_index].category.length > category_index &&
                          stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                          stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list.length > quizList_index) {
                          var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].quiz.list[quizList_index].choiceSelected;
                          if (choiceSelected) {
                            quizList_item.choiceSelected = choiceSelected;
                          }
                        }
                      });
                    }
                    if (stdAnswer.length > res_index &&
                      stdAnswer[res_index].category.length > category_index &&
                      stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                      var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                      if (fileUrlPath) {
                        sentence_item.fileUrlPath = fileUrlPath;

                        var player = new Audio(fileUrlPath);
                        if (player) {
                          sentence_item.player = player;
                        }
                      }
                    }
                  });
                }
              });
            });
          } catch (error) {
            console.log('Incorrect student answer data, ', error);
          }

          setActList(res);

          const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
          setMaxSetNum(maxSetNum);

          const categoryList = res.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
          setCategoryList(categoryList);

          categoryList.forEach((value, index) => {
            if (index === 0) {
              setMinCategoryNum(value.order);
              setCategorySelected(categoryList[value.order]);
            } else if (index === categoryList.length - 1) {
              setMaxCategoryNum(value.order);
            }
          });
        })
        .catch(error => {
          console.error(error);
          errorModalRef.current.openModal();
        });
    };

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);

  const [guideFinished, setGuideFinished] = useState(false);

  useEffect(() => {
    if (actList.length > 0) {
      const categoryList = actList.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
      setCategoryList(categoryList);

      categoryList.forEach((item, index) => {
        if (index === 0) {
          setMinCategoryNum(item.order);
          setCategorySelected(categoryList[item.order]);
        } else if (index === categoryList.length - 1) {
          setMaxCategoryNum(item.order);
        }
      });
    }
  }, [setNum]);
  const [modal, setModal] = useState(false);
  const [yesmodal, setYesmodal] = useState(false);
  const [record, setRecord] = useState(false);

  const eggStudioRef = useRef();
  const onairRef = useRef();
  const onairSign = [OnAir_On, OnAir, NowLoading];

  const closeActivityModalRef = useRef();
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  };

  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  };

  const recordTimeOverModalRef = useRef();
  const alertModalRef = useRef();
  const openAlertModal = (text) => {
    if (setNum === 1 && categorySelected.order === minRecordCategoryNum) {
      alertModalRef.current.openModal(text);
      setTimeout(() => { alertModalRef.current.closeModal(); }, 4000);
    }
  };
  const replayPlayRef = useRef();
  const replayPlayOpen = () => {
    replayPlayRef.current.replayPlayOpen();
  };
  const replayPlayClose = () => {
    replayPlayRef.current.replayPlayClose();
  };
  console.log('replayPlayRef.current', replayPlayRef.current);

  const prevCategory = () => {
    // answerClose();
    if (categorySelected.order > minCategoryNum) {
      setCategorySelected(categoryList[categorySelected.order - 1]);
    }
  };
  const nextCategory = () => {
    // answerClose();
    if (categorySelected.name === "Video") {
      if (categorySelected.order < maxCategoryNum) {
        setCategorySelected(categoryList[categorySelected.order + 1]);
      }
    } else {
      console.log('data_categorySelected.sentence', categorySelected.sentence);

      let newRecordExists = false;
      categorySelected.sentence.forEach(item => {
        if (item.file && item.file.size && item.file.size > 0) {
          newRecordExists = true;
          return false;
        }
      });
      console.log('data_newRecordExists', newRecordExists);
      console.log('data_item.file', categorySelected.sentence.file);
      if (newRecordExists) {
        /**
         * 학생 Act 학습 정보 저장 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
         */
        async function callApiAct() {
          const data = await API('post', 'act', {}, {
            oCode: userSelector.info.oCode,
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            level: actInfo.level,
            episodeId: episodeInfo.episodeId,
            episodeCode: episodeInfo.episodeCode,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actStatus: (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus),
            actPoint: 0,
            actCurrentPage: setNum,
            question: JSON.stringify(orgActList),
            answer: JSON.stringify(actList)
          }).catch((error) => {
            console.error(error);
          });

          console.log(data);

          userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .actStatus = (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus);

          dispatch(userStore.save(userSelector.info));

          console.log('data_actInfo.actStatus', actInfo.actStatus);


          let nextCategory;
          if (categorySelected.order < maxCategoryNum) {
            nextCategory = categoryList[categorySelected.order + 1];
          } else {
            nextCategory = {
              ...categoryList[0],
              name: 'result',
              video: actList.find(item => item.set === setNum).video,
              categoryList: categoryList
            };
          }
          setCategorySelected(nextCategory);
        }

        /**
         * 학생 파일 파일 업로드 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
         */
        async function callApiActFile() {
          let index = 0;
          for (const item of categorySelected.sentence) {
            if (item.file && item.file.size && item.file.size > 0) {
              let form = new FormData();
              form.append('file', item.file);
              form.append('data', JSON.stringify({
                productId: userSelector.info.productId,
                studentId: userSelector.info.studentId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                episodeId: episodeInfo.episodeId,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId,
                actType: actInfo.actType,
                actPage: setNum,
                studentSoundNo: index + 1
              }));

              console.log('data_actInfo.actStatus', actInfo.actStatus);


              const data = await API('post', 'act/file', {}, form).catch((error) => {
                console.error(error);
              });

              console.log("==========================");
              console.log("학생 파일 파일 업로드이 완료 되었습니다.");
              console.log(data);
              console.log("==========================");

              if (!data.data || !data.data.fileUrlPath) {
                console.error("==========================");
                console.error("녹음 파일 경로 반환이 되지 않았습니다.");
                console.error("==========================");
              }

              categorySelected.sentence[index].fileUrlPath = data.data.fileUrlPath;
            }
            index++;
          }
          callApiAct();
        }
        console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');

        callApiActFile();
      } else {
        let nextCategory;
        if (categorySelected.order < maxCategoryNum) {
          nextCategory = categoryList[categorySelected.order + 1];
        } else {
          nextCategory = {
            ...categoryList[0],
            name: 'result',
            video: actList.find(item => item.set === setNum).video,
            categoryList: categoryList
          };
        }
        setCategorySelected(nextCategory);
      }
    }
  };

  const btnPrevRef = useRef();
  const btnNextRef = useRef();
  const nextButtonRef = useRef();
  const setNextButtonEnabled = () => {
    if (btnNextRef.current) return;

    if (categorySelected.name === "Video") {
    } else {
      if (categorySelected.sentence) {
        let errorIndex = -1;
        for (var index = 0; index < categorySelected.sentence.length; index++) {
          let sentence = categorySelected.sentence[index];
          if (sentence.audio && sentence.record && ((!sentence.file || !sentence.file.size || sentence.file.size < 0) && !sentence.fileUrlPath)) {
            errorIndex = index;
            break;
          }
        }
        if (errorIndex > -1) {

        } else {
          nextButtonRef.current.children[0].src = btnNext[1];
          nextButtonRef.current.className = 'right-button';
          nextButtonRef.current.onmouseenter = () => {
            nextButtonRef.current.src = btnNext[0];
          };
          nextButtonRef.current.onmouseleave = () => {
            nextButtonRef.current.src = btnNext[1];
          };
          nextButtonRef.current.onmousedown = () => {
            nextButtonRef.current.src = btnNext[2];
          };
          nextButtonRef.current.ontouchstart = () => {
            nextButtonRef.current.src = btnNext[2];
          };
          nextButtonRef.current.onmouseup = () => {
            nextButtonRef.current.src = btnNext[1]; nextCategory();
          };
          nextButtonRef.current.ontouchend = () => {
            nextButtonRef.current.src = btnNext[1]; nextCategory();
          };
        }
      } else {

      }
    }
  };
  const onVideoEnded = () => {
    nextCategory();
  };

  // EggStudioRecord
  // const [recordstate, setRecordstate] = useState({ prerecord:false, record:false })
  // const [prerecord, setPrerecord] = useState(false);
  // const [record, setRecord] = useState(false);
  const recordBarRef = useRef();

  const chracterRef = useRef();
  const chracterRef01 = useRef();
  const chracterRef02 = useRef();
  const chracterRef03 = useRef();
  const chracterRef04 = useRef();
  const chracterRef05 = useRef();
  const micRef = useRef();

  const videoRef = useRef();
  const startRef = useRef();
  const countRef = useRef();
  const countPlayRef = useRef();


  const aniImageIndex = useRef(0);

  const recordbarOpen = (recordingNum) => {
    recordBarRef.current.recordbarOpen(recordingNum);
  };

  const recordbarClose = () => {
    recordBarRef.current.recordbarClose();
  };
  console.log('recordBarRef.current', recordBarRef.current);

  const recordStartAudioRef = useRef();
  const countBbiAudioRef = useRef();


  const RerecordModalRef = useRef();
  const RerecordModalOpen = () => {
    RerecordModalRef.current.style.display = 'block';
  };
  const RerecordModalClose = () => {
    RerecordModalRef.current.style.display = 'none';

  };

  const recordIcon = [Mic, MicOn, MicRec];

  const aniSentenceImageIndex = useRef(0);
  const aniRecordImageIndex = useRef(0);
  const recordIconEvents = (e) => {
    console.log('CLICK1', e.type);
    if (categorySelected.sentence.fileUrlPath) {
      //  setNextButtonEnabledR();  
    }
    if (e.type === "click" || e.type === "mouseup" || e.type === "touchend") {
      console.log('CLICK2', e);
      if (isRecordingReady || isMySoundPlaying) return console.log('state_recordIconEvents1_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);
      if (!isRecording && !categorySelected.sentence.fileUrlPath) {
        console.log('state_mouseup_record_!isRecording&& !modal');

        isRecordingReady = true;

        playIconEventsTimer.stop();
        recordEventsTimer.start();
        onairRef.current.src = onairSign[0];
        countPlayRef.current.style.display = 'block';
        countRef.current.src = recordReadyIcon[0][2];
        countBbiAudioRef.current.play();

        setTimeout(() => {
          countBbiAudioRef.current.pause();
          countBbiAudioRef.current.currentTime = 0;

          recordReadyIconTimer.start();



        }, 300);

        let s = parseInt(categorySelected.sentence[recordingNum].recordBarTime, 10) * 1000;
        console.log('categorySelected.sentence[recordingNum].recordBarTime', categorySelected.sentence[recordingNum].recordBarTime)
        console.log('second', s)
        setTimeout(() => {
          micRef.current.click(); //s초 후 자동 녹음 종료
        }, s);

      }

      else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && !yesmodal) {
        console.log('!modal');
        console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'yesmodal', yesmodal, 'modal', modal, 'isMySoundPlaying', isMySoundPlaying);

        RerecordModalOpen();

      } else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && yesmodal) {
        RerecordModalClose();
        console.log('else');
        isRecordingReady = true;
        onairRef.current.src = onairSign[0];
        countPlayRef.current.style.display = 'block';
        countRef.current.src = recordReadyIcon[0][2];

        countBbiAudioRef.current.play();
        setTimeout(() => {
          countBbiAudioRef.current.pause();
          countBbiAudioRef.current.currentTime = 0;

          recordReadyIconTimer.start();


        }, 300);
        let s = parseInt(categorySelected.sentence[recordingNum].recordBarTime, 10) * 1000;
        console.log('categorySelected.sentence[recordingNum].recordBarTime', categorySelected.sentence[recordingNum].recordBarTime)
        console.log('second', s)
        setTimeout(() => {
          micRef.current.click(); //s초 후 자동 녹음 종료
        }, s);
        setModal(false);

      }
      else {
        console.log('state_mouseup_record_!isRecordingElse');
        console.log('state_recordIconEvents4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isMySoundPlaying', isMySoundPlaying);

        Mp3Recorder
          .stop()
          .getMp3().then(([buffer, blob]) => {
            isRecording = false;

            //  recordIconTimer.stop();
            onairRef.current.src = onairSign[0];

            feedback(true);

            const file = new File(buffer, 'mySound.mp3', {
              type: blob.type,
              lastModified: Date.now()
            });
            const player = new Audio(URL.createObjectURL(file));
            console.log('data_player1', player);

            console.log('data_categorySelected.order', categorySelected.order);

            categorySelected.sentence.file = file;
            categorySelected.sentence.fileUrl = URL.createObjectURL(file);
            categorySelected.sentence.player = player;

            console.log('state_data_file', file);
            console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
            console.log('data_player2', player);
            setModal(false);
            setYesmodal(false);

            //  setNextButtonEnabled();
            /**
* 학생 파일 파일 업로드 API
* https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
*/
            async function callApiActFile() {
              if (categorySelected.sentence.file.size > 0) {
                console.log('data_POSTINSIDE');
                let form = new FormData();
                form.append('file', categorySelected.sentence.file);
                form.append('data', JSON.stringify({
                  productId: userSelector.info.productId,
                  studentId: userSelector.info.studentId,
                  courseId: userSelector.info.courseId,
                  bookId: userSelector.info.bookId,
                  episodeId: episodeInfo.episodeId,
                  lessonId: lessonInfo.lessonId,
                  actId: actInfo.actId,
                  actType: actInfo.actType,
                  actPage: setNum,
                  // studentSoundNo: index + 1
                }));

                const data = await API('post', 'act/file', {}, form).catch((error) => {
                  console.error(error);
                });

                console.log("==========================");
                console.log("학생 파일 파일 업로드이 완료 되었습니다.");
                console.log('data', data);
                console.log("==========================");

                if (!data.data || !data.data.fileUrlPath) {
                  console.error("==========================");
                  console.error("data 녹음 파일 경로 반환이 되지 않았습니다.");
                  console.error("==========================");
                }

                categorySelected.sentence.fileUrlPath = data.data.fileUrlPath;
                console.log('data_categorySelected.sentence.fileUrlPath', categorySelected.sentence.fileUrlPath);
                console.log('data_data.data.fileUrlPath', data.data.fileUrlPath);
                recordEventsTimer.stop();
                // ReplayPlay 모달
                replayPlayOpen();

              }

            }
            console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');
            callApiActFile();
            /**
* 학생 Act 학습 정보 저장 API
* https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
*/
            async function callApiAct() {
              if (actInfo.actStatus !== 'Completed') {
                const data = await API('post', 'act', {}, {
                  oCode: userSelector.info.oCode,
                  productId: userSelector.info.productId,
                  studentId: userSelector.info.studentId,
                  courseId: userSelector.info.courseId,
                  bookId: userSelector.info.bookId,
                  level: actInfo.level,
                  episodeId: episodeInfo.episodeId,
                  episodeCode: episodeInfo.episodeCode,
                  lessonId: lessonInfo.lessonId,
                  actId: actInfo.actId,
                  actType: actInfo.actType,
                  actStatus: "Completed",
                  actPoint: actInfo.actCompletPoint,
                  actCurrentPage: setNum,
                  question: JSON.stringify(orgActList),
                  answer: JSON.stringify(actList)
                }).catch((error) => {
                  console.error(error);
                });

                console.log(data);

                userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .actStatus = "Completed";
                userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .file = categorySelected.file;
                userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .fileUrl = categorySelected.fileUrl;
                userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .player = categorySelected.player;

                dispatch(userStore.save(userSelector.info));
              }
            }
            // callApiAct();
            setTimeout(() => {
              callApiAct();
            }, 1000);
            // 녹음 후 리렌더링
            //  setRecord(true);

          }).catch((e) => {
            // TODO 녹음 오류시 처리 필요
          });


      }
    }
  };
  const playIconEventsTimer = new TaskTimer(500);
  playIconEventsTimer.add([
    {
      id: 'playIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        switch (aniSentenceImageIndex.current) {
          case 0:
            micRef.current.src = recordIcon[0];
            break;
          case 1:
            micRef.current.src = recordIcon[1];
            break;
        }
        console.log('playIconEventsTimer1');

        aniSentenceImageIndex.current = aniSentenceImageIndex.current + 1;
        if (aniSentenceImageIndex.current > 2) aniSentenceImageIndex.current = 0;
      }
    }
  ]).stop();

  const recordEventsTimer = new TaskTimer(500);
  recordEventsTimer.add([
    {
      id: 'playIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        switch (aniRecordImageIndex.current) {
          case 0:
            micRef.current.src = recordIcon[0];
            break;
          case 1:
            micRef.current.src = recordIcon[2];
            break;
        }

        aniRecordImageIndex.current = aniRecordImageIndex.current + 1;
        if (aniRecordImageIndex.current > 2) aniRecordImageIndex.current = 0;
      }
    }
  ]).stop();

  const audioRef = [];
  var i = 0;
  // categorySelected.sentence.forEach(() => {
  //     audioRef.push(React.createRef());
  // });

  const LoadAudios = () => {
    if (!categorySelected.sentence || categorySelected.sentence.length < 1) return null;

    categorySelected.sentence.forEach(() => {
      audioRef.push(React.createRef());
    });
    return (
      <>
        {categorySelected.sentence.map((item, index) => (
          <>
            <audio
              key={index}
              ref={audioRef[audioRefIndex++]}
              src={item.audio}
              data-record={item.record}
              data-recordstarttime={item.recordStartTime}
              data-recordEndTime={item.recordEndTime}
              data-recordBarTime={item.recordBarTime}
              data-recordingIndex={item.recordingIndex}
              data-recordingCharacter={item.recordingCharacter}
              data-text={item.text}
            />
          </>
        ))
        }
      </>
    );
  };
  // console.log('result_audio', audioRef);
  console.log('result_categorySelected.sentence', categorySelected.sentence);
  console.log('result_categorySelected.video', categorySelected.video);
  let isRecording = false;
  let isRecordingReady = false;
  let isMySoundPlaying = false;

  //  const audioRef = [];
  var i = 0;
  let currentTime = -1;
  let recordingNum = -1;
  let percent = 1; // recordBar 100% transition

  const onTimeUpdate = () => {
    const cds = new Date(Math.floor(videoRef.current.currentTime * 1000)).toISOString().substr(11, 8);
    if (cds === currentTime) {
      return;
    } else {
      currentTime = cds;
    }
    console.log('currentTime', currentTime);
    console.log('audioRef', audioRef);
    audioRef.forEach((item, index) => {
      console.log('2');


      if (item.current.dataset.record === 'true' && item.current && item.current.dataset && currentTime === item.current.dataset.recordstarttime) {
        videoRef.current.pause();
        // if (item.current.paused) {
        console.log('3');
        console.log('item.current', item.current);
        console.log('item.current.dataset', item.current.dataset);
        console.log('item.current.dataset.text', item.current.dataset.text);

        recordingNum = item.current.dataset.recordingindex;
        const recordingText = item.current.dataset.text;
        console.log('recordingText', recordingText);

        recordbarOpen(recordingNum);

        //  videoRef.current.pause();

        console.log('item.current.dataset.record', item.current.dataset.record);
        console.log('item.current.dataset.recordingIndex', item.current.dataset.recordingindex);
        console.log('recordingNum', recordingNum);
        console.log('item.current.dataset.recordingcharacter', item.current.dataset.recordingcharacter);
        console.log('categorySelected.character01', categorySelected.character01);
        { item.current.dataset.recordingcharacter === categorySelected.character01 ? (chracterRef01.current.src = mediaPath + '/' + categorySelected.recordingCharacter01On) : (chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter01) }
        { item.current.dataset.recordingcharacter === categorySelected.character02 ? (chracterRef02.current.src = mediaPath + '/' + categorySelected.recordingCharacter02On) : (chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter02) }
        { item.current.dataset.recordingcharacter === categorySelected.character03 ? (chracterRef03.current.src = mediaPath + '/' + categorySelected.recordingCharacter03On) : (chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter03) }
        { item.current.dataset.recordingcharacter === categorySelected.character04 ? (chracterRef04.current.src = mediaPath + '/' + categorySelected.recordingCharacter04On) : (chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter04) }
        { item.current.dataset.recordingcharacter === categorySelected.character05 ? (chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter05On) : (chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter05) }

        recordStart();
      } else {
        console.log('else');
        // chracterRef01.current.src= mediaPath + '/' + categorySelected.recordingCharacter01
        // chracterRef02.current.src= mediaPath + '/' + categorySelected.recordingCharacter02
        // chracterRef03.current.src= mediaPath + '/' + categorySelected.recordingCharacter03
        // chracterRef04.current.src= mediaPath + '/' + categorySelected.recordingCharacter04
        // chracterRef05.current.src= mediaPath + '/' + categorySelected.recordingCharacter05


      }
      //    }
    });
  };

  const recordStart = () => {

    playIconEventsTimer.start();

  }

  const onCanPlayThrough = () => {
    setTimeout(() => {
      startRef.current.style.display = 'none';

    }, 1000);

    videoRef.current.play();

  };

  const onStart = () => {
    return;
  }

  let audioRefIndex = 0;
  const recordReadyIcon = [[Countnum3, Countnum2, Countnum1]];
  const recordReadyIconTimer = new TaskTimer(700);
  recordReadyIconTimer.add([
    {
      id: 'recordReadyIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        // countPlayRef.current.style.display = 'block';
        // countRef.current.src =recordReadyIcon[0][2];
        console.log('state_recordReadyIconTimer1_isRecordingReady', isRecordingReady, 'isRecording', 'isMySoundPlaying', isMySoundPlaying);
        let nowIndex = recordReadyIcon[0].findIndex(item => item === countRef.current.src);
        console.log('nowIndex', nowIndex);
        let nextIndex = nowIndex - 1;
        countRef.current.src = recordReadyIcon[0][nextIndex];

        if (nextIndex < 0) {
          console.log('state_recordReadyIconTimer2_isRecordingReady', isRecordingReady, 'isRecording', 'isMySoundPlaying', isMySoundPlaying);

          recordReadyIconTimer.stop();
          countPlayRef.current.style.display = 'none';

          // recordBar transition

          Mp3Recorder.start().then(() => {
            console.log('state_recordReadyIconTimer3_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

            isRecordingReady = false;
            isRecording = true;
            console.log('state_recordReadyIconTimer4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);


            let recordSeconds = 1;
            let recordLimitTimer = setInterval(() => {
              if (isRecording) {
                console.log('state_recordReadyIconTimer5_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

                recordSeconds++;
                if (recordSeconds > 30) {
                  clearInterval(recordLimitTimer);

                  isRecording = false;
                  let s = parseInt(categorySelected.sentence[recordingNum].recordBarTime, 10) * 1000;

                  // categorySelected.sentence[recordingNum].recordBarTime
                  Mp3Recorder.stop();
                  //   setTimeout(() => {
                  //     Mp3Recorder.stop();
                  // }, s);

                  recordTimeOverModalRef.current.openModal();
                }
              } else {
                console.log('state_recordReadyIconTimer7_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

                clearInterval(recordLimitTimer);
              }
            }, 1000);
          }).catch((e) => {
            console.error(e);
          });



          recordReadyIconTimer.stop();


        } else {
          console.log('state_recordReadyIconTimer8_isRecordingReady', isRecordingReady, 'isRecording', 'isMySoundPlaying', isMySoundPlaying);

          console.log('countBbiAudioRef', countBbiAudioRef);
          countBbiAudioRef.current.play();
          setTimeout(() => {
            countBbiAudioRef.current.pause();
            countBbiAudioRef.current.currentTime = 0;
          }, 300);
        }
      }
    }
  ]).stop();



  // EggStudioRecord

  const btnNext = [
    require('../../images/activity-button/right-button.png').default,
    require('../../images/activity-button/right-button.png').default,
    require('../../images/activity-button/right-button.png').default,
    require('../../images/activity-button/right-button.png').default,
  ];
  console.log('categorySelected.video', categorySelected.video);
  const RenderActivityArea = () => {
    if (!guideFinished) {
      return <Guide setGuideFinished={setGuideFinished} />
    } else {
      if (categorySelected.name === "Video") {
        return (
          <div onContextMenu={(e) => e.preventDefault()}>
            <div className="flex">
              <div className="videoArea">
                <div className="top">
                  <video
                    autoPlay
                    controls
                    width={'100%'}
                    src={mediaPath + '/' + categorySelected.video}
                    controlsList={'nodownload'}
                    disablePictureInPicture
                    onEnded={onVideoEnded}
                  />
                </div>
              </div>
              <div className="character-wrap">
                <div className="characterArea">
                  <p>Character</p>
                  <div className="charcter_inner">
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter01} alt="" /></div>
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter02} alt="" /></div>
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter03} alt="" /></div>
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter04} alt="" /></div>
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter05} alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
            <>
            </>
            {
              (actInfo.actStatus === 'Progress' || actInfo.actStatus === 'Completed') ?
                <>
                  <BrowserView>
                    <div className="right-button"
                      onMouseEnter={() => { btnNextRef.current.src = btnNext[0]; }}
                      onMouseLeave={() => { btnNextRef.current.src = btnNext[1]; }}
                      onMouseDown={() => { btnNextRef.current.src = btnNext[2]; }}
                      onMouseUp={() => { btnNextRef.current.src = btnNext[1]; nextCategory(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </BrowserView>
                  <MobileView>
                    <div className="right-button"
                      onTouchStart={() => { btnNextRef.current.src = btnNext[2]; }}
                      onTouchEnd={() => { btnNextRef.current.src = btnNext[1]; nextCategory(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </MobileView>
                </>
                :
                <div className="right-button disabled" ref={nextButtonRef}>
                  <img src={btnNext[3]} alt="" />
                </div>
            }
          </div>
        );
      } else if (categorySelected.name === 'finished') {
        return (
          <Congratulations categorySelected={categorySelected} />
        );
      } else if (categorySelected.name === 'result') {
        const btnAgain = [
          require('../../images/common/btn_again_h.png').default,
          require('../../images/common/btn_again_n.png').default,
          require('../../images/common/btn_again_p.png').default,
        ];
        const btnNext = [
          require('../../images/common/btn_next_h.png').default,
          require('../../images/common/btn_next_n.png').default,
          require('../../images/common/btn_next_p.png').default,
        ];

        const LoadBtn = (props) => {
          const btnEvents = (e) => {
            let btns;
            if (btnAgain.findIndex(item => item === e.target.src) > -1) {
              btns = btnAgain;
            } else if (btnNext.findIndex(item => item === e.target.src) > -1) {
              btns = btnNext;
            } else {
              return;
            }

            if (e.type === "mouseover") {
              e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
              e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
              e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
              e.target.src = btns[0];

              if (btns === btnAgain) {
                setCategorySelected(categoryList[categorySelected.order]);
              } else if (btns === btnNext) {
                if (setNum === maxSetNum) {
                  /**
                   * 학생 Act 학습 정보 저장 API
                   * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
                   */
                  async function callApiAct() {
                    if (actInfo.actStatus !== 'Completed') {
                      const data = await API('post', 'act', {}, {
                        oCode: userSelector.info.oCode,
                        productId: userSelector.info.productId,
                        studentId: userSelector.info.studentId,
                        courseId: userSelector.info.courseId,
                        bookId: userSelector.info.bookId,
                        level: actInfo.level,
                        episodeId: episodeInfo.episodeId,
                        episodeCode: episodeInfo.episodeCode,
                        lessonId: lessonInfo.lessonId,
                        actId: actInfo.actId,
                        actType: actInfo.actType,
                        actStatus: "Completed",
                        actPoint: actInfo.actCompletPoint,
                        actCurrentPage: setNum,
                        question: JSON.stringify(orgActList),
                        answer: JSON.stringify(actList)
                      }).catch((error) => {
                        console.error(error);
                      });

                      console.log(data);

                      userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .actStatus = "Completed";
                      userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .file = categorySelected.file;
                      userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .fileUrl = categorySelected.fileUrl;
                      userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .player = categorySelected.player;

                      dispatch(userStore.save(userSelector.info));
                    }

                    let nextCategory = {
                      name: 'finished',
                      order: categorySelected.order,
                      retry: () => {
                        setSetNum(1);
                        if (actList.length > 0) {
                          const categoryList = actList.find(item => item.set === 1).category.sort((a, b) => a.order - b.order);
                          setCategoryList(categoryList);

                          categoryList.forEach((item, index) => {
                            if (index === 0) {
                              setMinCategoryNum(item.order);
                              setCategorySelected(categoryList[item.order]);
                            } else if (index === categoryList.length - 1) {
                              setMaxCategoryNum(item.order);
                            }
                          });
                        }
                      },
                    };
                    setCategorySelected(nextCategory);
                  }
                  callApiAct();
                } else {
                  setSetNum(setNum + 1);
                  if (actList.length > 0) {
                    const categoryList = actList.find(item => item.set === setNum + 1).category.sort((a, b) => a.order - b.order);
                    setCategoryList(categoryList);

                    categoryList.forEach((item, index) => {
                      if (index === 0) {
                        setMinCategoryNum(item.order);
                        setCategorySelected(categoryList[item.order]);
                      } else if (index === categoryList.length - 1) {
                        setMaxCategoryNum(item.order);
                      }
                    });
                  }
                }
              }
            }
          };

          return (
            <>
              <BrowserView>
                <img src={props.src} alt=""
                  onMouseOver={btnEvents}
                  onMouseLeave={btnEvents}
                  onMouseDown={btnEvents}
                  onMouseUp={btnEvents} />
              </BrowserView>
              <MobileView>
                <img src={props.src} alt=""
                  onTouchStart={btnEvents}
                  onTouchEnd={btnEvents} />
              </MobileView>
            </>
          )
        };

        return (
          <>
            <EggStudioResult categorySelected={categorySelected} mediaPath={actInfo.media_path.replace(`${apiHost}`, '')} />

            <div className="buttonGroup">
              <LoadBtn src={btnAgain[1]} />
              <LoadBtn src={btnNext[1]} />
            </div>
          </>
        );
      } else {

        const index = categorySelected.sentence.index;
        console.log('index', index);
        return (
          <>
            <div className="flex">
              <div className="videoArea">
                <div className="top">
                  <>
                    <div className="start_dim" ref={startRef}  >
                      <div>
                        <p>Are you ready to record?</p>
                        <div className="buttongroup">
                          <div>
                            <button className="btn-blue" onClick={onStart} >Start</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*count - 카운트 1,2,3*/}
                    <div className="control_play" ref={countPlayRef}>
                      <img
                        ref={countRef}
                        // src ={recordReadyIcon[0]} 
                        src={recordReadyIcon[0][2]}
                        alt=""
                      />
                    </div>
                    {/*count*/}
                    <LoadAudios />
                    {/* {categorySelected.sentence.map((item, index) => (
              <>
                      <audio
                          key={index}
                          ref={audioRef[audioRefIndex++]}
                          src={item.audio}
                          data-record={item.record}
                          data-recordstarttime={item.recordStartTime}
                          data-recordEndTime={item.recordEndTime}
                          data-recordBarTime={item.recordBarTime}
                          data-recordingIndex={recordingIndex}
                          data-text={item.text}
                      />           
              </>
                  ))
              } */}
                    <video
                      ref={videoRef}
                      autoPlay
                      controls
                      width={'50%'}
                      src={mediaPath + '/' + categorySelected.video}
                      controlsList={'nodownload'}
                      disablePictureInPicture
                      onTimeUpdate={onTimeUpdate}
                      onCanPlayThrough={onCanPlayThrough}
                    />
                  </>
                </div>
              </div>
              <div className="character-wrap">
                <div className="characterArea">
                  <p>Character</p>
                  <div className="charcter_inner">
                    <div><img ref={chracterRef01} src={mediaPath + '/' + categorySelected.recordingCharacter01} alt="" /></div>
                    <div><img ref={chracterRef02} src={mediaPath + '/' + categorySelected.recordingCharacter02} alt="" /></div>
                    <div><img ref={chracterRef03} src={mediaPath + '/' + categorySelected.recordingCharacter03} alt="" /></div>
                    <div><img ref={chracterRef04} src={mediaPath + '/' + categorySelected.recordingCharacter04} alt="" /></div>
                    <div><img ref={chracterRef05} src={mediaPath + '/' + categorySelected.recordingCharacter05} alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
            {
              actInfo.actStatus === 'Completed' ?
                <>
                  <BrowserView>
                    <div className="right-button"
                      onMouseEnter={() => {
                        btnNextRef.current.src = btnNext[0];
                      }}
                      onMouseLeave={() => {
                        btnNextRef.current.src = btnNext[1];
                      }}
                      onMouseDown={() => {
                        btnNextRef.current.src = btnNext[2];
                      }}
                      onMouseUp={() => {
                        btnNextRef.current.src = btnNext[1];
                        nextCategory();
                      }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </BrowserView>
                  <MobileView>
                    <div className="right-button"
                      onTouchStart={() => {
                        btnNextRef.current.src = btnNext[2];
                      }}
                      onTouchEnd={() => {
                        btnNextRef.current.src = btnNext[1]; nextCategory();
                      }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </MobileView>
                </>
                :
                <div className="right-button disabled" ref={nextButtonRef}>
                  <img src={btnNext[3]} alt="" />
                </div>
            }
          </>
        );
      }
    }
  };

  return (
    <div className="egg-studio-new">
      <div>
        <div className="contents d-flex flex-column justify-content-between">
          <audio ref={recordStartAudioRef} src={recordStartAudio} />
          <audio ref={countBbiAudioRef} src={countBbiAudio} />
          <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
          <CloseActivityModal ref={closeActivityModalRef} />
          <Feedback ref={feedbackRef} />
          <RecordTimeOverModal ref={recordTimeOverModalRef} />
          <AlertModal ref={alertModalRef} />

          <div className="route_bar d-flex align-items-center justify-content-end">
            <div className="right_icon">
              <img className="help_icon_img" src={HelpIcon} alt="" />
              <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
            </div>
          </div>

          <div className="box_parent">
            <div className="screen">
              <div className="studio_title">
                <span className="onair">
                  <img
                    ref={onairRef}
                    src={onairSign[1]}
                  />
                </span>
                <p>EGG STUDIO</p>
              </div>
              <div className="egg-studio-wrap">
                <div style={{ 'position': 'relative' }}>
                  <RecordBar
                    ref={recordBarRef}
                    eggStudioRef={eggStudioRef}
                    categorySelected={categorySelected}
                    setNextButtonEnabled={setNextButtonEnabled}
                    mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                    recordTimeOverModalRef={recordTimeOverModalRef}
                  // percent={percent} 
                  // handleClickBar={() => {props.toggleFilter(props.survey.no - 1, index)}}
                  // isSelected={props.selectedItem === index}
                  />
                  <RenderActivityArea />
                  <ReplayPlay
                    ref={replayPlayRef}
                    eggStudioRef={eggStudioRef}
                    categorySelected={categorySelected}
                    setNextButtonEnabled={setNextButtonEnabled}
                    mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
                    feedback={feedback}
                    recordTimeOverModalRef={recordTimeOverModalRef}
                  />
                </div>
                <div className="recArea">
                  {/* {recordstate.prerecord === "true"?  */}
                  <div className="mic" >{/*녹음중일때 아래 span태그 활성화, img도 MicOn으로 변경*/}
                    <img
                      src={recordIcon[0]}
                      ref={micRef}
                      onMouseOver={recordIconEvents}
                      onMouseLeave={recordIconEvents}
                      onMouseDown={recordIconEvents}
                      onMouseUp={recordIconEvents}
                      click={recordIconEvents}
                      alt="" />
                  </div>
                  <div className="speaker">
                    <img src={Speaker} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
