import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import RetryModal from '../modal/RetryModal';
import * as userStore from '../../modules/userStore';

import PopReply from "../../images/pop-default/restart-btn.png";
import PopHome from "../../images/pop-default/home-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";


/*css*/
import "../../css/layout.css";
// import "../../css/songtime.css";


const GoodSinger = (props) => {
    let location = useLocation();
    const userSelector = useSelector(store => store.userStore);
    const dispatch = useDispatch();
    const categoryList = props.categoryList;
    const categorySelected = props.categorySelected;
    const setCategorySelected = props.setCategorySelected;

    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
    const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

    const retryModalRef = useRef();
    let history = useHistory();

    const imgComplete = require('../../images/common/img_complete.png').default;
    const iconPoint = require('../../images/common/icon_point.png').default;
    const btnAgain = [
        require('../../images/common/btn_again_h.png').default,
        require('../../images/common/btn_again_n.png').default,
        require('../../images/common/btn_again_p.png').default,
    ];
    const btnHome = [
        require('../../images/common/btn_home_h.png').default,
        require('../../images/common/btn_home_n.png').default,
        require('../../images/common/btn_home_p.png').default,
    ];
    const btnNext = [
        require('../../images/common/btn_next_h.png').default,
        require('../../images/common/btn_next_n.png').default,
        require('../../images/common/btn_next_p.png').default,
    ];

    const points = actInfo.actCompletPoint;
    let stars = [];
    for (let i = 0; i < points; i++) {
        stars.push(i);
    }

    const tryAgain = () => {
        setCategorySelected(categoryList[0]);
    };

    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
        case 'HHT1':
            routerName = '/hh1/learning-home';
            break;
        case 'HHT2':
            routerName = '/hh2/learning-home';
            break;
        case 'CULTURE':
            routerName = '/culture/learning-home';
            break;
        default:
            break;
    }
    const learningHome = () => {
        history.push(routerName);
    };
    const next = () => {
        let nextEpisodeId = episodeInfo.episodeId;
        let nextLessonId = lessonInfo.lessonId;
        let actIndex = lessonInfo.acts.findIndex(item => item.actId === actInfo.actId);
        if (actIndex === lessonInfo.acts.length - 1) {
            let lessonIndex = episodeInfo.lessons.findIndex(item => item.lessonId === lessonInfo.lessonId);
            if (lessonIndex < episodeInfo.lessons.length - 1) {
                nextLessonId = episodeInfo.lessons[lessonIndex + 1].lessonId;
            } else {
                let episodeIndex = userSelector.info.episodes.findIndex(item => item.episodeId === episodeInfo.episodeId);
                if (episodeIndex < userSelector.info.episodes.length - 1) {
                    nextEpisodeId = userSelector.info.episodes[episodeIndex + 1].episodeId;
                    nextLessonId = userSelector.info.episodes[episodeIndex + 1].lessons[0].lessonId;
                }
            }
        }

        dispatch(userStore.lhSave({
            episodeId: nextEpisodeId,
            lessonId: nextLessonId
        }));

        history.push(routerName);
    };


    return (
        <>
            {/* <div className="modal_width"> */}
            <div className="modal_overlay"></div>
            <div className="modal_wrapper song-pop">
                <div className="modal_inner">
                    <div className="title">You’re really a good singer!</div>
                    <div className="buttongroup img-btn">
                        <span><img src={PopReply} onClick={tryAgain} alt="" /></span>
                        <span><img src={PopHome} onClick={learningHome} alt="" /></span>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
};

export default GoodSinger;