import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import AnswerTypeA from "./AnswerTypeA";
import AnswerTypeB from "./AnswerTypeB";
import AnswerTypeRecord from "./AnswerTypeRecord";

const Answer = forwardRef((props, ref) => {
    const miniSitcomRef = props.miniSitcomRef;
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = window.location.origin + props.mediaPath + '/';
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;

    const parentRef = useRef();
    const answerTypeARef = useRef();

    const [quizState, setQuizState] = useState({
        type: '',
        recordingIndex: -1,
        quizSelected: {
            type: "",
            text: [],
            list: []
        }
    });

    useImperativeHandle(ref, () => ({
        answerOpen(recordingIndex) {
            let type;
            const quiz = categorySelected.sentence[recordingIndex].quiz;
            if (!quiz) {
                type = 'R';
            } else if (quiz.type === 'word') {
                type = 'A';
            } else if (quiz.type === 'picture') {
                type = 'B';
            }
            setQuizState({
                recordingIndex: recordingIndex,
                type: type,
                quizSelected: quiz
            });
        },
        answerClose() {
            setQuizState({
                type: '',
                recordingIndex: -1,
                quizSelected: {
                    type: "",
                    text: [],
                    list: []
                }
            });
        }
    }));
    
    const btnClose = [
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_h.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_n.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_p.png').default,
    ];
    const LoadBtn = (props) => {
        let btns, className;
        if (btnClose.findIndex(item => item === props.src) > -1) {
            btns = btnClose;
            className = 'layer-close';
        } else {
            return;
        }
        const btnEvents = (e) => {
            if (e.type === "mouseover") {
                e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
                e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
                e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
                e.target.src = btns[0];

                if (btns === btnClose) {
                    setQuizState({ ...quizState, type: '' });
                }
            }
        };
        return (
            <div className="close-button">
                <BrowserView>
                    <span
                        className={className}
                        src={props.src}
                        alt=""
                        onMouseOver={btnEvents}
                        onMouseLeave={btnEvents}
                        onMouseDown={btnEvents}
                        onMouseUp={btnEvents}></span>
                </BrowserView>
                <MobileView>
                    <span
                        className={className}
                        src={props.src}
                        alt=""
                        onTouchStart={btnEvents}
                        onTouchEnd={btnEvents}></span>
                </MobileView>
            </div>
        )
    };

    const AnswerBlock = styled.div`
    & {
        display: ${(quizState.type === '' ? 'none' : 'block')};
    }
    `;

    return (
        <AnswerBlock ref={parentRef} className="btm_layer">
            <LoadBtn src={btnClose[1]} />
            {
                quizState.type === 'A' ?
                    <AnswerTypeA
                        ref={answerTypeARef}
                        quizState={quizState}
                        setQuizState={setQuizState}
                        feedback={feedback}
                    />
                    : quizState.type === 'B' ?
                        <AnswerTypeB
                            ref={answerTypeARef}
                            quizState={quizState}
                            setQuizState={setQuizState}
                            mediaPath={mediaPath}
                            feedback={feedback}
                            miniSitcomRef={miniSitcomRef}
                        />
                        : <AnswerTypeRecord
                            ref={answerTypeARef}
                            quizState={quizState}
                            setQuizState={setQuizState}
                            categorySelected={categorySelected}
                            setNextButtonEnabled={setNextButtonEnabled}
                            mediaPath={mediaPath}
                            feedback={feedback}
                            recordTimeOverModalRef={recordTimeOverModalRef}
                        />
            }
        </AnswerBlock>
    )
});

export default Answer;
