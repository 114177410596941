import React, { forwardRef, useEffect, useRef } from 'react';
import { Group, Image, Layer, Stage, Text } from 'react-konva';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import useImage from 'use-image';

const speakingBoosterFrame = require('../../images/activity-default/bg_speaking_booster.png').default;

const SpeakingParacticeMain = styled.div`
.konvajs-content {
    z-index:2;
    margin:0 auto;
    
  }
`;



const SpeakingBoosterMain = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath;
    const activityProps = props.activityProps;
    const pagenum = props.pagenum;

    const audioRef = useRef();
    const stageRef = useRef();

    const containerRef = useRef();
    let containerWidth = activityProps.defaultWidth;
    let containerHeight = activityProps.defaultHeight;
    let scaleX, scaleY, scale;
    useEffect(() => {
        containerWidth = containerRef.current.offsetWidth
        containerHeight = containerRef.current.offsetHeight;
        scaleX = containerWidth / activityProps.defaultWidth;
        scaleY = containerHeight / activityProps.defaultHeight;
        if (scaleX > scaleY) {
            containerWidth = activityProps.defaultWidth * scaleY;
        } else if (scaleX < scaleY) {
            containerHeight = activityProps.defaultHeight * scaleX;
        }
        scale = Math.min(scaleX, scaleY);
        stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
    }, []);
    useEffect(() => {
        const resizeListener = () => {
            containerWidth = containerRef.current.offsetWidth;
            containerHeight = containerRef.current.offsetHeight;
            scaleX = containerWidth / activityProps.defaultWidth;
            scaleY = containerHeight / activityProps.defaultHeight;
            if (scaleX > scaleY) {
                containerWidth = activityProps.defaultWidth * scaleY;
            } else if (scaleX < scaleY) {
                containerHeight = activityProps.defaultHeight * scaleX;
            }
            scale = Math.min(scaleX, scaleY);
            stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    });

    let audio = new Audio(mediaPath + categorySelected.audio);
    const onClickPlay = () => {

        if (audioRef.current.paused) {
            audioRef.current.currentTime = 0;
            mainAudioPlayingTimer.start();

            audioRef.current.play();
        } else {
            mainAudioPlayingTimer.stop();
            audioRef.current.pause();

        }
    }

    const LoadImage = (props) => {
        const [image, status] = useImage(props.imagePath);
        if (status !== "loaded") return null;
        let imageWidth = (props.width ? props.width : image.width);
        let imageHeight = (props.height ? props.height : image.height);
        let x = props.x;
        let y = props.y;
        if (props.center) {
            x = (activityProps.defaultWidth - imageWidth) / 2;
            y = (activityProps.defaultHeight - imageHeight) / 2;
        }
        if (props.x === 'center') {
            x = (activityProps.defaultWidth - imageWidth) / 2;
        }
        if (props.x === 'right') {
            x = (activityProps.defaultWidth - imageWidth) - 2;
        }
        return <Image
            id={props.id || ''}
            name={props.name || ''}
            image={image}
            x={x}
            y={y}
            width={props.width}
            height={props.height}
            stroke={props.stroke}
            strokeWidth={props.strokeWidth}
            cornerRadius={props.cornerRadius}
            onMouseOver={props.events}
            onMouseLeave={props.events}
            onMouseDown={props.events}
            onMouseUp={props.events}
            onTouchStart={props.events}
            onTouchEnd={props.events}
        />;
    };

    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];

    const playingIconImage = [useImage(playIcon[0][0])[0], useImage(playIcon[0][1])[0], useImage(playIcon[0][2])[0]];
    const [playingStopIconHoverImage, playingStopIconHoverStatus] = useImage(playIcon[1]);
    const [playingStopIconNormalImage, playingStopIconNormalStatus] = useImage(playIcon[2]);
    const [playingStopIconPushImage, playingStopIconPushStatus] = useImage(playIcon[3]);

    const mainAudioPlayingTimer = new TaskTimer(500);
    mainAudioPlayingTimer.add([
        {
            id: 'mainAudioTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = playingIconImage.findIndex(item => item === stageRef.current.findOne('#mainAudioPlayingIcon').image());
                let nextIndex = nowIndex + 1;
                if (nextIndex > 2) nextIndex = 0;
                stageRef.current.findOne('#mainAudioPlayingIcon').image(playingIconImage[nextIndex]);
                stageRef.current.batchDraw();
            }
        }
    ]).stop();

    const mainAudioEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";

            if (audioRef.current.paused) {
                e.target.image(playingStopIconHoverImage);
                e.target.getStage().batchDraw()
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (audioRef.current.paused) {
                e.target.image(playingStopIconNormalImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (audioRef.current.paused) {
                e.target.image(playingStopIconPushImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (audioRef.current.paused) {
                audioRef.current.currentTime = 0;
                mainAudioPlayingTimer.start();

                audioRef.current.play();
            } else {
                mainAudioPlayingTimer.stop();
                e.target.image(playingStopIconNormalImage);
                e.target.getStage().batchDraw();


                // audioRef.current.currentTime = 0;
                audioRef.current.pause();
            }
        }
    }
    const LoadMainAudioIcon = () => {
        if (playingStopIconNormalStatus !== "loaded") return null;
        return (
            <Image image={playingStopIconNormalImage}
                id={'mainAudioPlayingIcon'}
                x={(activityProps.defaultWidth - playingStopIconNormalImage.width)}
                y={0}
                onMouseOver={mainAudioEvents}
                onMouseLeave={mainAudioEvents}
                onMouseDown={mainAudioEvents}
                onMouseUp={mainAudioEvents}
                onTouchStart={mainAudioEvents}
                onTouchEnd={mainAudioEvents}
            />
        );
    };

    const onCanPlayThrough = () => {
        audioRef.current.volume = activityProps.defaultVolume;
        let playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
            playPromise.then(function () {
                mainAudioPlayingTimer.start();
                audioRef.current.play();
            }).catch(function (error) {
                console.error(error);
            });
        }
    };

    const onEnded = () => {
        mainAudioPlayingTimer.stop();

        stageRef.current.findOne('#mainAudioPlayingIcon').image(playingStopIconNormalImage);
        stageRef.current.batchDraw();

        audioRef.current.pause();
        // audioRef.current.currentTime = 0;
    };

    let textHeight = 70;
    let textLineHeight = activityProps.textLineHeight;
    if (categorySelected.subTitle && categorySelected.subTitle.length > 2) {
        textHeight = 60;
        textLineHeight = 1.3;
    }

    const PracticeTextArea = () => { // JHS 문장이 하나, 두개이면 세개이면 네개이면에 따라서 CSS가 다르게 나오도록 하게 해주세요.
        if (categorySelected.subTitleNum === "1" || categorySelected.subTitleNum === "2") {
            console.log("1,2");
            return (
                <>
                    {categorySelected.subTitle.map((item, index) => (

                        <div className='practice-text' onClick={onClickPlay}>
                            <div className='text'>
                                <span><img src={activityProps.characterMediaPath + item.icon} alt="" /></span>
                                <p>{item.text.normal0}<em className='point'>{item.text.highlight0}</em>{item.text.normal1}<em className='point'>{item.text.highlight1}</em>
                                    {item.text.normal2}<em className='point'>{item.text.highlight2}</em>{item.text.normal3}<em className='point'>{item.text.highlight3}</em>{item.text.normal4}</p>
                            </div>
                        </div>

                    ))}
                </>
            );
        } else if (categorySelected.subTitleNum === "3") {
            console.log("3")
            return (
                <>
                    {categorySelected.subTitle.map((item, index) => (

                        <div className='practice-text' onClick={onClickPlay}>
                            <div className='text'>
                                <span><img src={activityProps.characterMediaPath + item.icon} alt="" /></span>
                                <p>{item.text.normal0}<em className='point'>{item.text.highlight0}</em>{item.text.normal1}<em className='point'>{item.text.highlight1}</em>
                                    {item.text.normal2}<em className='point'>{item.text.highlight2}</em>{item.text.normal3}<em className='point'>{item.text.highlight3}</em>{item.text.normal4}</p>
                            </div>
                        </div>

                    ))}
                </>
            );
        } else {
            return (
                <>
                    {categorySelected.subTitle.map((item, index) => (

                        <div className='practice-text' onClick={onClickPlay}>
                            <div className='text'>
                                <span><img src={activityProps.characterMediaPath + item.icon} alt="" /></span>
                                <p>{item.text.normal0}<em className='point'>{item.text.highlight0}</em>{item.text.normal1}<em className='point'>{item.text.highlight1}</em>
                                    {item.text.normal2}<em className='point'>{item.text.highlight2}</em>{item.text.normal3}<em className='point'>{item.text.highlight3}</em>{item.text.normal4}</p>
                            </div>
                        </div>

                    ))}
                </>
            );
        }
    };


    return (
        <div style={{ width: '100%', height: '100%' }} ref={containerRef}>
            <audio
                ref={audioRef}
                src={mediaPath + categorySelected.audio}
                onCanPlayThrough={onCanPlayThrough}
                onEnded={onEnded}
            />
            <Stage
                width={activityProps.defaultWidth}
                height={activityProps.defaultHeight}
                ref={stageRef}>
                <Layer>
                    <LoadMainAudioIcon x={'right'} />
                </Layer>
            </Stage>
            <div className='position'>
                <p className="titleA">Sitcom Dialogue <span>{pagenum}</span></p>
                <div className='practice-wrap'>
                    <div className='practice-img'>
                        <img src={mediaPath + categorySelected.image} alt="" />
                    </div>
                    <PracticeTextArea />
                </div>
            </div>
        </div>
    );
});
export default SpeakingBoosterMain;