import React, { forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

const AnswerTypeA = forwardRef((props, ref) => {
    const quizState = props.quizState;
    const setQuizState = props.setQuizState;
    const feedback = props.feedback;

    useImperativeHandle(ref, () => ({
        setQuiz(quiz) {
            let choiceAllSelected = true;
            quiz.list.forEach(item => {
                if (!item.choiceSelected || item.choiceSelected === '') {
                    choiceAllSelected = false;
                }
            });
            if (choiceAllSelected) {
                setTimeout(() => {
                    setQuizState({
                        recordingIndex: quiz.recordingIndex,
                        type: 'R',
                        quizSelected: quiz
                    });
                }, 1000);
            } else {
                setQuizState({
                    recordingIndex: quiz.recordingIndex,
                    type: quiz.type,
                    quizSelected: quiz
                });
            }
        },
    }));

    const choiceClick = (e, quizIndex, choice) => {
        const tempQuizSelected = quizState.quizSelected;
        if (tempQuizSelected.list[quizIndex].choiceCorrect === choice) {
            feedback(true);
        } else {
            feedback(false);
            return;
        }
        tempQuizSelected.list[quizIndex].choiceSelected = choice;
        let choiceAllSelected = true;
        tempQuizSelected.list.forEach(item => {
            if (!item.choiceSelected || item.choiceSelected === '') {
                choiceAllSelected = false;
            }
        });
        if (choiceAllSelected) {
            setTimeout(() => {
                setQuizState({
                    recordingIndex: quizState.recordingIndex,
                    type: 'R',
                    quizSelected: tempQuizSelected
                });
            }, 1000);
        } else {
            setQuizState({
                recordingIndex: quizState.recordingIndex,
                type: quizState.type,
                quizSelected: tempQuizSelected
            });
        }
    };

    const AnswerTypeABlock = styled.div`
    
    `;

    return (
        <AnswerTypeABlock className="answer-parent">
            <div className="list-wrap answer-wrap word">
                {quizState.quizSelected.list.map((quizItem, quizIndex) => (
                    <div key={quizIndex} className="choices-list">
                        {quizItem.choice.map((item, index) => {
                            if (quizIndex == 0 ||
                                (quizIndex > 0 &&
                                    quizState.quizSelected.list[quizIndex - 1].choiceSelected)) {
                                return (
                                    <div
                                        key={index}
                                        className={'choices' + (item === quizItem.choiceSelected ? ' active' : '')}
                                        style={{ borderColor: (quizIndex == 0 ? '#fc856c' : '#fff') }}
                                        onClick={(e) => { choiceClick(e, quizIndex, item); }}
                                    >
                                        {item}
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        key={index}
                                        className={'choices disabled'}
                                    >
                                        {item}
                                    </div>
                                );
                            }
                        })}
                    </div>
                ))}
                <div className="buttongroup">
                    <button className="check-btn">Submit</button>
                </div>
            </div>
        </AnswerTypeABlock>
    )
});

export default AnswerTypeA;