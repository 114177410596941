import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import styled from "styled-components";

import PopReply from "../../images/pop-default/restart-btn.png";
import PopListen from "../../images/pop-default/listen-btn.png";
import PopListenDim from "../../images/pop-default/listen-dim-btn.png";
import PopPause from "../../images/pop-default/pause-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";

/*css*/
import "../../css/layout.css";
import "../../css/eggstudio.css";

const ReplayPlay = forwardRef((props, ref) => {
  const categorySelected = props.categorySelected;
  const setNextButtonEnabled = props.setNextButtonEnabled;
  const mediaPath = window.location.origin + props.mediaPath + '/';
  const feedback = props.feedback;
  const recordTimeOverModalRef = props.recordTimeOverModalRef;
  const replayPlayRef = useRef();
  useImperativeHandle(ref, () => ({
    replayPlayOpen() {
      replayPlayRef.current.style.display = 'block';
    },
    replayPlayClose() {
      replayPlayRef.current.style.display = 'none';
    }
  }));

  return (
    <div className="record-pop" ref={replayPlayRef} >
      <div className="buttongroup img-btn">
        <span className="full">
          <img src={PopListen} alt="" />
          {/* <img src={PopListenDim} alt="" /> */}
          {/* <img src={PopPause} alt="" /> */}
        </span>
        <span><img src={PopReply} alt="" /></span>
        <span><img src={PopNext} alt="" /></span>
      </div>
    </div>
  )
});

export default ReplayPlay;