import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import Guide from "../../components/guide/Guide";
import Feedback from "../../components/feedback/Feedback";
import CheckUp from "../../components/feedback/CheckUp";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import AlertModal from "../../components/modal/AlertModal";
import LeftButton from "../../images/activity-button/btn_pre_n.png";
import RightButton from "../../images/activity-button/btn_next_n.png";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import PlayRecord from "../../components/quiztime/PlayRecord";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import Answer from "../../components/activity-answer-quiztime/Answer";
import QuizTime from "../../components/quiztime/QuizTime";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";
import GoodJob from "../../components/congratulations/GoodJob";
import MiniSitcomResult from "../../components/result/MiniSitcomResult";
import ErrorModal from "../../components/modal/ErrorModal";

// click_sound
import click_sound from "../../audios/click_sound.mp3";

/*css*/
import "../../css/layout.css";
import "../../css/quiztime.css";

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Index = ({ type }) => {

  let location = useLocation();
  const types = type;
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(1);
  console.log('data_page_orgActList', orgActList);
  console.log('page_actList', actList);
  console.log('page_categoryList', categoryList);
  console.log('page_categorySelected', categorySelected);
  console.log('page_setNum', setNum);
  console.log('page_maxSetNum', maxSetNum);
  console.log('page_minCategoryNum', minCategoryNum);
  console.log('page_maxCategoryNum', maxCategoryNum);

  let minRecordCategoryNum = 2;

  const pagenum = parseInt(categorySelected.order, 10) + 1 || 1;
  let clickAudio = new Audio(click_sound);

  const errorModalRef = useRef();
  const routeLearningHome = () => {
    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
      case 'HHT1':
        routerName = '/hh1/learning-home';
        break;
      case 'HHT2':
        routerName = '/hh2/learning-home';
        break;
      case 'CULTURE':
        routerName = '/culture/learning-home';
        break;
      default:
        break;
    }
    history.push(routerName);
  };

  //20211208
  useEffect(() => {
    const fetchData = async (actContentData) => {
      let filePathReplaced = actInfo.file_path.replace(`${apiHost}`, '');
      console.log('filePathReplaced', filePathReplaced);
      await fetch(filePathReplaced)
        .then(res => res.json())
        .then(res => {
          console.log("data_minisitcomindex.jsx_res >>>", res);
          setOrgActList(JSON.parse(JSON.stringify(res)));
          console.log("data_minisitcomindex.jsx_setOrgActList(JSON.parse(JSON.stringify(res)))", JSON.parse(JSON.stringify(res)));

          try {
            const stdAnswer = JSON.parse(actContentData.stdAnswer);
            console.log('data_stdAnswer1', stdAnswer);

            res.map((res_item, res_index) => {
              res_item.category.map((category_item, category_index) => {


                if (stdAnswer.length > res_index &&
                  stdAnswer[res_index].category.length > category_index) {
                  // var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                  var fileUrlPath = stdAnswer[res_index].category[category_index].sentence.fileUrlPath;

                  console.log('data_res1', res);

                  if (fileUrlPath) {
                    category_item.sentence.fileUrlPath = fileUrlPath;
                    console.log('data_sentence.fileUrlPath', stdAnswer[res_index].category[category_index].sentence.fileUrlPath);
                    console.log('data_res2', res);
                    var player = new Audio(fileUrlPath);
                    if (player) {
                      category_item.sentence.player = player;
                      console.log('data_stdAnswer2', stdAnswer);
                      console.log('data_player', stdAnswer[res_index].category[category_index].sentence.player);
                      console.log('data_res3', res);

                    }
                  }
                }
              });
            });
          } catch (error) {
            console.log('Incorrect student answer data, ', error);
          }

          setActList(res);
          console.log('data_useEffect_res', res);

          const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
          console.log('data_useEffect_maxSetNum', maxSetNum);
          setMaxSetNum(maxSetNum);

          const categoryList = res.find(item => item.set === setNum).category;
          console.log('data_useEffect_categoryList', categoryList);
          setCategoryList(categoryList);

          categoryList.sort((a, b) => a.order - b.order).forEach((value, index) => {
            if (index === 0) {
              setMinCategoryNum(value.order);
              setCategorySelected(categoryList[value.order]);
            } else if (index === categoryList.length - 1) {
              setMaxCategoryNum(value.order);
            }
          });
        })
        .catch(error => {
          console.error(error);
          errorModalRef.current.openModal();
        });
    };



    console.log('lessonId', lessonInfo.lessonId);

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);
  console.log('data_useEffectOUT_categoryList', categoryList);

  const [guideFinished, setGuideFinished] = useState(false);

  useEffect(() => {
    if (actList.length > 0) {
      const categoryList = actList.find(item => item.set === setNum).category;
      setCategoryList(categoryList);

      categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
        if (index === 0) {
          setMinCategoryNum(item.order);
          setCategorySelected(categoryList[item.order]);
        } else if (index === categoryList.length - 1) {
          setMaxCategoryNum(item.order);
        }
      });
    }
  }, [setNum]);

  const quizTimeRef = useRef();

  const closeActivityModalRef = useRef();
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  };

  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  };

  const playrecordRef = useRef();
  const playrecordOpen = () => {
    playrecordRef.current.playrecordOpen();
  };
  const playrecordClose = () => {
    playrecordRef.current.playrecordClose();
  };
  const recordTimeOverModalRef = useRef();
  const alertModalRef = useRef();
  const openAlertModal = (text) => {
    if (setNum === 1 && categorySelected.order === minRecordCategoryNum) {
      alertModalRef.current.openModal(text);
      setTimeout(() => { alertModalRef.current.closeModal(); }, 4000);
    }
  };
  console.log('actInfo.actStatus', actInfo.actStatus);

  const prevCategory = () => {

    playrecordClose();
    if (categorySelected.order > minCategoryNum) {
      setCategorySelected(categoryList[categorySelected.order - 1]);
    }
  };
  const nextCategory = () => {
    console.log('nextCategory');
    playrecordClose();
    console.log('data_categorySelected.sentence', categorySelected.sentence);
    console.log('data_categorySelected.sentence.file', categorySelected.sentence.file);
    let newRecordExists = false;
    console.log('data_newRecordExists1', newRecordExists);
    if (categorySelected.sentence.file.size > 0) {
      newRecordExists = true;
      console.log('data_newRecordExists0', newRecordExists);
      // return false;

    }

    console.log('data_newRecordExists2', newRecordExists);
    console.log('data_categorySelected.sentence.file', categorySelected.sentence.file);

    if (newRecordExists && categorySelected.order < maxCategoryNum) {
      /**
       * 학생 Act 학습 정보 저장 API
       * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
       */
      async function callApiAct() {
        console.log('data_callApiAct()INSIDE_JSON.stringify(orgActList)', JSON.stringify(orgActList));
        console.log('data_callApiAct()INSIDE_JSON.stringify(actList)', JSON.stringify(actList));
        const data = await API('post', 'act', {}, {
          oCode: userSelector.info.oCode,
          productId: userSelector.info.productId,
          studentId: userSelector.info.studentId,
          courseId: userSelector.info.courseId,
          bookId: userSelector.info.bookId,
          level: actInfo.level,
          episodeId: episodeInfo.episodeId,
          episodeCode: episodeInfo.episodeCode,
          lessonId: lessonInfo.lessonId,
          actId: actInfo.actId,
          actType: actInfo.actType,
          actStatus: (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus),
          actPoint: 0,
          actCurrentPage: setNum,
          question: JSON.stringify(orgActList),
          answer: JSON.stringify(actList)
        }).catch((error) => {
          console.error(error);
        });

        console.log(data);

        userSelector.info
          .episodes.find(item => item.episodeId === location.state.episodeId)
          .lessons.find(item => item.lessonId === location.state.lessonId)
          .acts.find(item => item.actId === location.state.actId)
          .actStatus = (actInfo.actStatus === 'Ready' ? 'Progress' : actInfo.actStatus);

        dispatch(userStore.save(userSelector.info));
        console.log('data_actInfo.actStatus', actInfo.actStatus);

        let nextCategory;
        if (categorySelected.order < maxCategoryNum) {
          nextCategory = categoryList[categorySelected.order + 1];
        } else {
          nextCategory = {
            name: 'finished',
            order: categorySelected.order,
          };
        }
        setCategorySelected(nextCategory);
      }

      /**
       * 학생 파일 파일 업로드 API
       * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
       */
      async function callApiActFile() {
        if (categorySelected.sentence.file.size > 0) {
          console.log('data_POSTINSIDE');
          let form = new FormData();
          form.append('file', categorySelected.sentence.file);
          form.append('data', JSON.stringify({
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            episodeId: episodeInfo.episodeId,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actPage: setNum,
            // studentSoundNo: index + 1
          }));

          const data = await API('post', 'act/file', {}, form).catch((error) => {
            console.error(error);
          });

          console.log("==========================");
          console.log("학생 파일 파일 업로드이 완료 되었습니다.");
          console.log('data', data);
          console.log("==========================");

          if (!data.data || !data.data.fileUrlPath) {
            console.error("==========================");
            console.error("data 녹음 파일 경로 반환이 되지 않았습니다.");
            console.error("==========================");
          }

          categorySelected.sentence.fileUrlPath = data.data.fileUrlPath;
          console.log('data_categorySelected.sentence.fileUrlPath', categorySelected.sentence.fileUrlPath);
          console.log('data_data.data.fileUrlPath', data.data.fileUrlPath);

        }
        console.log('data_callApiAct();');
        callApiAct();
      }
      console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');
      callApiActFile();
    } else if (newRecordExists && setNum === maxSetNum) {
      /**
       * 학생 Act 학습 정보 저장 API
       * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
       */
      async function callApiAct() {
        if (actInfo.actStatus !== 'Completed') {
          const data = await API('post', 'act', {}, {
            oCode: userSelector.info.oCode,
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            level: actInfo.level,
            episodeId: episodeInfo.episodeId,
            episodeCode: episodeInfo.episodeCode,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actStatus: "Completed",
            actPoint: actInfo.actCompletPoint,
            actCurrentPage: setNum,
            question: JSON.stringify(orgActList),
            answer: JSON.stringify(actList)
          }).catch((error) => {
            console.error(error);
          });

          console.log(data);

          userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .actStatus = "Completed";
          userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .file = categorySelected.file;
          userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .fileUrl = categorySelected.fileUrl;
          userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .player = categorySelected.player;

          dispatch(userStore.save(userSelector.info));
        }

        let nextCategory = {
          name: 'finished',
          order: categorySelected.order,
          retry: () => {
            setSetNum(1);
            if (actList.length > 0) {
              const categoryList = actList.find(item => item.set === 1).category;
              setCategoryList(categoryList);

              categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                if (index === 0) {
                  setMinCategoryNum(item.order);
                  setCategorySelected(categoryList[item.order]);
                } else if (index === categoryList.length - 1) {
                  setMaxCategoryNum(item.order);
                }
              });
            }
          },
        };
        setCategorySelected(nextCategory);
      }
      callApiAct();
    } else {
      let nextCategory;
      if (categorySelected.order < maxCategoryNum) {
        nextCategory = categoryList[categorySelected.order + 1];
      } else {
        nextCategory = {
          name: 'finished',
          order: categorySelected.order,
        };
      }
      setCategorySelected(nextCategory);
    }

  };

  const nextCategoryR = () => {
    console.log('nextCategoryR1')
    playrecordClose();
    console.log('nextCategoryR2')
    let nextCategory;
    if (categorySelected.order < maxCategoryNum) {
      console.log('nextCategoryR3')
      nextCategory = categoryList[categorySelected.order + 1];
    } else {
      console.log('nextCategoryR4')
      nextCategory = {
        name: 'finished',
        order: categorySelected.order,
      };
    }
    setCategorySelected(nextCategory);


  };

  const btnPrevRef = useRef();
  const btnNextRef = useRef();
  const nextButtonRef = useRef();
  const setNextButtonEnabledR = () => {
    if (btnNextRef.current) return;


    if (categorySelected.sentence) {
      let errorIndex = -1;

      if (errorIndex > -1) {

      } else {
        nextButtonRef.current.children[0].src = btnNext[1];
        nextButtonRef.current.className = 'right-button';
        nextButtonRef.current.onmouseenter = () => { nextButtonRef.current.src = btnNext[0]; };
        nextButtonRef.current.onmouseleave = () => { nextButtonRef.current.src = btnNext[1]; };
        nextButtonRef.current.onmousedown = () => { nextButtonRef.current.src = btnNext[2]; };
        nextButtonRef.current.ontouchstart = () => { nextButtonRef.current.src = btnNext[2]; };
        nextButtonRef.current.onmouseup = () => { nextButtonRef.current.src = btnNext[1]; nextCategoryR(); };
        nextButtonRef.current.ontouchend = () => { nextButtonRef.current.src = btnNext[1]; nextCategoryR(); };
      }
    } else {

    }

  };

  const setNextButtonEnabled = () => {
    if (btnNextRef.current) return;


    if (categorySelected.sentence) {
      let errorIndex = -1;

      if (errorIndex > -1) {

      } else {
        nextButtonRef.current.children[0].src = btnNext[1];
        nextButtonRef.current.className = 'right-button';
        nextButtonRef.current.onmouseenter = () => { nextButtonRef.current.src = btnNext[0]; };
        nextButtonRef.current.onmouseleave = () => { nextButtonRef.current.src = btnNext[1]; };
        nextButtonRef.current.onmousedown = () => { nextButtonRef.current.src = btnNext[2]; };
        nextButtonRef.current.ontouchstart = () => { nextButtonRef.current.src = btnNext[2]; };
        nextButtonRef.current.onmouseup = () => { nextButtonRef.current.src = btnNext[1]; nextCategory(); };
        nextButtonRef.current.ontouchend = () => { nextButtonRef.current.src = btnNext[1]; nextCategory(); };
      }
    } else {

    }

  };

  const btnPre = [
    require('../../images/activity-button/btn_pre_h.png').default,
    require('../../images/activity-button/btn_pre_n.png').default,
    require('../../images/activity-button/btn_pre_p.png').default,
    require('../../images/activity-button/btn_pre_d.png').default,
  ];
  const btnNext = [
    require('../../images/activity-button/btn_next_h.png').default,
    require('../../images/activity-button/btn_next_n.png').default,
    require('../../images/activity-button/btn_next_p.png').default,
    require('../../images/activity-button/btn_next_d.png').default,
  ];

  const handleGuide = () => {
    setGuideFinished(false);
  };
  console.log('actInfo.actStatus', actInfo.actStatus);
  const RenderActivityArea = () => {
    if (!guideFinished) {
      return <Guide setGuideFinished={setGuideFinished} />
    } else {
      if (categorySelected.name === 'finished') {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <GoodJob
              categorySelected={categorySelected}
              setCategorySelected={setCategorySelected}
              categoryList={categoryList}
            />
          </div>
        );
      } else if (categorySelected.name === 'result') {
        const btnAgain = [
          require('../../images/common/btn_again_h.png').default,
          require('../../images/common/btn_again_n.png').default,
          require('../../images/common/btn_again_p.png').default,
        ];
        const btnNext = [
          require('../../images/common/btn_next_h.png').default,
          require('../../images/common/btn_next_n.png').default,
          require('../../images/common/btn_next_p.png').default,
        ];

        const LoadBtn = (props) => {
          const btnEvents = (e) => {
            let btns;
            if (btnAgain.findIndex(item => item === e.target.src) > -1) {
              btns = btnAgain;
            } else if (btnNext.findIndex(item => item === e.target.src) > -1) {
              btns = btnNext;
            } else {
              return;
            }

            if (e.type === "mouseover") {
              e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
              e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
              e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
              e.target.src = btns[0];

              if (btns === btnAgain) {
                setCategorySelected(categoryList[categorySelected.order]);
              } else if (btns === btnNext) {
                if (categorySelected.order < maxCategoryNum) {
                  let nextCategory = {
                    name: 'finished',
                    order: categorySelected.order,
                  };
                  setCategorySelected(nextCategory);
                } else {
                  if (setNum === maxSetNum) {
                    /**
                     * 학생 Act 학습 정보 저장 API
                     * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
                     */
                    async function callApiAct() {
                      if (actInfo.actStatus !== 'Completed') {
                        const data = await API('post', 'act', {}, {
                          oCode: userSelector.info.oCode,
                          productId: userSelector.info.productId,
                          studentId: userSelector.info.studentId,
                          courseId: userSelector.info.courseId,
                          bookId: userSelector.info.bookId,
                          level: actInfo.level,
                          episodeId: episodeInfo.episodeId,
                          episodeCode: episodeInfo.episodeCode,
                          lessonId: lessonInfo.lessonId,
                          actId: actInfo.actId,
                          actType: actInfo.actType,
                          actStatus: "Completed",
                          actPoint: actInfo.actCompletPoint,
                          actCurrentPage: setNum,
                          question: JSON.stringify(orgActList),
                          answer: JSON.stringify(actList)
                        }).catch((error) => {
                          console.error(error);
                        });

                        console.log(data);

                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .actStatus = "Completed";
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .file = categorySelected.file;
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .fileUrl = categorySelected.fileUrl;
                        userSelector.info
                          .episodes.find(item => item.episodeId === location.state.episodeId)
                          .lessons.find(item => item.lessonId === location.state.lessonId)
                          .acts.find(item => item.actId === location.state.actId)
                          .player = categorySelected.player;

                        dispatch(userStore.save(userSelector.info));
                      }

                      let nextCategory = {
                        name: 'finished',
                        order: categorySelected.order,
                        retry: () => {
                          setSetNum(1);
                          if (actList.length > 0) {
                            const categoryList = actList.find(item => item.set === 1).category;
                            setCategoryList(categoryList);

                            categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                              if (index === 0) {
                                setMinCategoryNum(item.order);
                                setCategorySelected(categoryList[item.order]);
                              } else if (index === categoryList.length - 1) {
                                setMaxCategoryNum(item.order);
                              }
                            });
                          }
                        },
                      };
                      setCategorySelected(nextCategory);
                    }
                    callApiAct();
                  } else {
                    setSetNum(setNum + 1);
                    if (actList.length > 0) {
                      const categoryList = actList.find(item => item.set === setNum + 1).category;
                      setCategoryList(categoryList);

                      categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                        if (index === 0) {
                          setMinCategoryNum(item.order);
                          setCategorySelected(categoryList[item.order]);
                        } else if (index === categoryList.length - 1) {
                          setMaxCategoryNum(item.order);
                        }
                      });
                    }
                  }
                }
              }
            }
          };

          return (
            <>
              {!isTablet && <BrowserView>
                <img src={props.src} alt=""
                  onMouseOver={btnEvents}
                  onMouseLeave={btnEvents}
                  onMouseDown={btnEvents}
                  onMouseUp={btnEvents} />
              </BrowserView>}
              <MobileView>
                <img src={props.src} alt=""
                  onTouchStart={btnEvents}
                  onTouchEnd={btnEvents} />
              </MobileView>
            </>
          )
        };

        const nextCategoryAtResult = () => {
          if (categorySelected.order < maxCategoryNum) {
            let nextCategory = {
              name: 'finished',
              order: categorySelected.order,
            };
            setCategorySelected(nextCategory);
          }
        };

        return (
          <div className="box-wrap d-flex justify-content-center align-items-center">
            <MiniSitcomResult
              categorySelected={categorySelected}
              nextCategoryAtResult={nextCategoryAtResult}
              mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
            />
            <div className="buttonGroup">
              <LoadBtn src={btnAgain[1]} />
              <LoadBtn src={btnNext[1]} />
            </div>
          </div>
        );
      } else {
        return (
          <div className="box-wrap d-flex justify-content-center align-items-center">
            <QuizTime
              ref={quizTimeRef}
              categorySelected={categorySelected}
              setCategorySelected={setCategorySelected}
              mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
              setNextButtonEnabled={setNextButtonEnabled}
              setNextButtonEnabledR={setNextButtonEnabledR}
              openAlertModal={openAlertModal}
              playrecordOpen={playrecordOpen}
              feedback={feedback}
            />
            {
              categorySelected.order > minCategoryNum &&
              <>
                <BrowserView>
                  <div className="left-button"
                    onMouseEnter={() => { btnPrevRef.current.src = btnPre[0]; }}
                    onMouseLeave={() => { btnPrevRef.current.src = btnPre[1]; }}
                    onMouseDown={() => { btnPrevRef.current.src = btnPre[2]; }}
                    onMouseUp={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
                  >
                    <img src={btnPre[1]} alt="" ref={btnPrevRef} />
                  </div>
                </BrowserView>
                <MobileView>
                  <div className="left-button"
                    onTouchStart={() => { btnPrevRef.current.src = btnPre[2]; }}
                    onTouchEnd={() => { btnPrevRef.current.src = btnPre[1]; prevCategory(); }}
                  >
                    <img src={btnPre[1]} alt="" ref={btnPrevRef} />
                  </div>
                </MobileView>
              </>
            }
            {
              actInfo.actStatus === 'Completed' ?
                <>
                  <BrowserView>
                    <div className="right-button"
                      onMouseEnter={() => { btnNextRef.current.src = btnNext[0]; }}
                      onMouseLeave={() => { btnNextRef.current.src = btnNext[1]; }}
                      onMouseDown={() => { btnNextRef.current.src = btnNext[2]; }}
                      onMouseUp={() => { btnNextRef.current.src = btnNext[1]; nextCategoryR(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </BrowserView>
                  <MobileView>
                    <div className="right-button"
                      onTouchStart={() => { btnNextRef.current.src = btnNext[2]; }}
                      onTouchEnd={() => { btnNextRef.current.src = btnNext[1]; nextCategoryR(); }}
                    >
                      <img src={btnNext[1]} alt="" ref={btnNextRef} />
                    </div>
                  </MobileView>
                </>
                :
                <div className="right-button disabled" ref={nextButtonRef}>
                  <img src={btnNext[3]} alt="" />
                </div>
            }
          </div>
        );
      }
    }
  };

  const checkUpRef = useRef();
  let idleSeconds = 0;
  let isIntervalRunning = true;

  const resetCheckUp = () => {
    idleSeconds = 0;
    isIntervalRunning = true;
  };

  const goToHome = () => {
    history.goBack();
  };

  return (
    <div className="quiz-time">
      <div className="contents d-flex flex-column justify-content-between">
        <RecordTimeOverModal ref={recordTimeOverModalRef} />
        <Feedback ref={feedbackRef} />
        <CloseActivityModal ref={closeActivityModalRef} />
        <CheckUp ref={checkUpRef} resetCheckUp={resetCheckUp} />
        <PlayRecord
          ref={playrecordRef}
          quizTimeRef={quizTimeRef}
          feedback={feedback}
          categorySelected={categorySelected}
          setNextButtonEnabled={setNextButtonEnabled}
          setNextButtonEnabledR={setNextButtonEnabledR}
          mediaPath={actInfo.media_path.replace(`${apiHost}`, '')}
          recordTimeOverModalRef={recordTimeOverModalRef}
        // RerecordModalRef={RerecordModalRef}
        // RerecordModalOpen={RerecordModalOpen}
        // RerecordModalClose={RerecordModalClose}
        />
        <div className={"route_bar d-flex align-items-center justify-content-between "
          + (types == 'culture' ? 'route-bar-background-culture' : types == 'hh1' ? 'route-bar-background-hh1' : 'route-bar-background-hh2')}>

          <div className="d-flex">
            <p>
              {lessonInfo.lessonTitle} &gt; <span
                className="extra-bold">{actInfo.actTitle}</span>
            </p>
          </div>
          <div className="right_icon">
            <div className="paging">
              <span className="current_num">0{pagenum}</span>
              <span className="total_num">/06</span>
            </div>
            <img className="help_icon_img" src={HelpIcon} onClick={() => handleGuide()} alt="" />
            <img className="cancel_icon_img" src={CancelIcon} onClick={() => handleClose()} alt="" />
          </div>
        </div>

        <div className="box_parent">
          <RenderActivityArea />
        </div>
      </div>
    </div>
  );
};

export default Index;